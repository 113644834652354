import * as Yup from "yup";
import { FormCarga } from "./type";

export const initialValues: FormCarga = {
    nome: "",
    produtos: "",
    fornecedor_id: 0,
};

export const schema = Yup.object().shape({
    nome: Yup.string(),
    produtos: Yup.string().required("Campo é obrigatório"),
    fornecedor_id: Yup.number(),
});
