import { useMemo } from "react";
import { Column } from "react-table";
import { SubTable } from "./subTable";
import {
    Table as TableI,
    TableMes,
} from "../../Hook/useDetalhePedidoPlataforma";
import {
    formatCurrentMoney,
    filterDetalhePedidoPlataforma,
} from "../../util/helper";

export interface Data {
    mes: string | number | React.ReactChild | React.ReactChild[];
    total_pedido: string | number | React.ReactChild | React.ReactChild[];
    qtd_voucher: string | number | React.ReactChild | React.ReactChild[];
    utilizados: string | number | React.ReactChild | React.ReactChild[];
    porcentagem: string | number | React.ReactChild | React.ReactChild[];
    valor_reais: string | number | React.ReactChild | React.ReactChild[];
    ver_detalhe?: TableMes[];
}

interface TableProps {
    pedidos: TableI;
    filtro: string;
}

export function Table(props: TableProps) {
    const { filtro } = props;

    const columns = useMemo<Column<Data>[]>(
        () => [
            {
                Header: "Mês",
                accessor: "mes",
            },
            {
                Header: "Total Pedido",
                accessor: "total_pedido",
            },
            {
                Header: "Qtd. Voucher",
                accessor: "qtd_voucher",
            },
            {
                Header: "Utilizados",
                accessor: "utilizados",
            },
            {
                Header: "%",
                accessor: "porcentagem",
            },
            {
                Header: "Valor em Reais",
                accessor: "valor_reais",
            },
            {
                Header: "",
                accessor: "ver_detalhe",
                width: 100,
                maxWidth: 100,
            },
        ],
        []
    );

    const keys = props.pedidos ? Object.keys(props.pedidos) : [];

    const pedidos: Data[] = keys.map((element) => {
        const valor_reais = props.pedidos[element].reduce(
            (acc, { total_vouchers_reais }) => {
                return acc + parseFloat(total_vouchers_reais);
            },
            0
        );

        const utilizados = props.pedidos[element].reduce(
            (acc, { total_vouchers_utilizados }) =>
                acc + total_vouchers_utilizados,
            0
        );

        const qtd_voucher = props.pedidos[element].reduce(
            (acc, { total_vouchers }) => acc + total_vouchers,
            0
        );

        const porcentagem = parseFloat(
            Number((utilizados / qtd_voucher) * 100).toFixed(2)
        );

        return {
            valor_reais: formatCurrentMoney(valor_reais),
            utilizados,
            mes: element,
            qtd_voucher,
            total_pedido: props.pedidos[element].length,
            porcentagem,
            ver_detalhe: props.pedidos[element],
        };
    });

    const dadosFiltrados = filterDetalhePedidoPlataforma(pedidos, filtro);

    return (
        <>
            {dadosFiltrados.map((item, key, arr) => (
                <SubTable
                    columns={columns}
                    data={[item]}
                    index={key}
                    key={key}
                />
            ))}
        </>
    );
}
