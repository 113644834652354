import { Text } from "aplication-yetz";
import { FooterStyles } from "./styles";

export function Footer() {
    const year = new Date().getFullYear();
    return (
        <FooterStyles>
            <Text color="gray300">
                YETZ LTDA | CNPJ: 28.325.166/0001-05 {year} | Todos os direitos
                reservados
            </Text>
        </FooterStyles>
    );
}
