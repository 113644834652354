import * as Yup from "yup";
const regex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;

export interface Fornecedor {
    nome: string;
    descricao: string;
    cnpj: string;
}

export const initialValues: Fornecedor = {
    nome: "",
    descricao: "",
    cnpj: "",
};

export const schema = Yup.object().shape({
    nome: Yup.string().required("Campo obrigatório"),
    descricao: Yup.string(),
    cnpj: Yup.string()
        .required("Campo obrigatório")
        .matches(regex, "CNPJ inválido"),
});
