import { Table, Flex } from "aplication-yetz";
import { Header } from "./header";
import { Data } from "./types";
import { column } from "./config";
import userImg from "../../assets/sistema/editar-user.svg";
// import deleteImg from "../../assets/sistema/excluir.svg";
import { ContainerTable } from "../tables/styles";
import Modal from "../NovoModal";
import { Add } from "./Form/add";
import { Edit } from "./Form/edit";
import { Excluir } from "./Excluir";
import { useFornecedor } from "../../Hook/useFornecedor";
import { Container } from "./styles";
import { formatCnpj } from "../../util/helper";
import { Loader } from "../Loader";
import { Permissions } from "../Permission";

export function Fornecedor() {
    const {
        loading,
        openModalAdd,
        setopenModalAdd,
        openModalEdit,
        setopenModalEdit,
        fornecedores,
        setCurrentFornecedor,
        openExcluirModal,
        setopenExcluirModal,
    } = useFornecedor();
    const data: Data[] = fornecedores.map((item) => {
        return {
            id: item.id,
            nome: item.nome,
            descricao: item?.descricao || "",
            cnpj: formatCnpj(item.cnpj || ""),
            editar: (
                <Flex width="131px" justifyContent="space-between">
                    <Permissions role="fornecedor.update">
                        <button
                            onClick={() => {
                                setCurrentFornecedor(item);
                                setopenModalEdit(true);
                            }}
                        >
                            <img
                                src={userImg}
                                alt=""
                                className="usuario-icone-user"
                            />
                        </button>
                    </Permissions>
                </Flex>
            ),
            // excluir: (
            //     <Flex width="131px" justifyContent="space-between">
            //         <button
            //             onClick={() => {
            //                 setCurrentFornecedor(item);
            //                 setopenExcluirModal(true);
            //             }}
            //         >
            //             <img
            //                 src={deleteImg}
            //                 alt=""
            //                 className="usuario-icone-user"
            //             />
            //         </button>
            //     </Flex>
            // ),
        };
    });

    return (
        <Container>
            <Header />
            {loading ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : (
                <ContainerTable>
                    <Table data={data} columns={column} />
                </ContainerTable>
            )}

            <Modal
                open={openModalAdd}
                handleClose={() => setopenModalAdd(false)}
            >
                <Add />
            </Modal>
            <Modal
                open={openModalEdit}
                handleClose={() => {
                    setopenModalEdit(false);
                    setCurrentFornecedor(null);
                }}
            >
                <Edit />
            </Modal>
            <Modal
                open={openExcluirModal}
                handleClose={() => setopenExcluirModal(false)}
            >
                <Excluir />
            </Modal>
        </Container>
    );
}
