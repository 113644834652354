import imgCard from "../../assets/sistema/ops.png";

import { StylesAlerta } from "./styles";

export function Error() {
    return (
        <StylesAlerta>
            <div className="container-img">
                <img src={imgCard} alt="" />
            </div>

            <div className="container-text">
                <p>
                    Verifique se <strong>digitou o link corretamente</strong>{" "}
                    (diferenciando maiúsculas/minúsculas) ou se no copiar/colar{" "}
                    <strong>não deixou nenhuma parte de fora.</strong>
                </p>
                <br />
                <br />
                <p>
                    Se ainda assim o erro persistir, fale com a pessoa que
                    enviou o link para você.
                </p>
            </div>
        </StylesAlerta>
    );
}
