// import { useHistory } from "react-router-dom";
// import ImgPdf from "../../assets/sistema/download-pdf-1.svg";
// import ImgSetaDir from "../../assets/sistema/seta-dir-btn.svg";
// // import { Container } from "./styles";
// import { useState } from "react";
// import { api } from "../../services";
// import { toast } from "react-toastify";
// import { Loader } from "../Loader";
interface ButtonVoucherPDFProps {
    currentRef: React.MutableRefObject<any>;
}

export function ButtonVoucherPDF(props: ButtonVoucherPDFProps) {
    // const history = useHistory();
    // const [loading, setLoading] = useState(false);
    // const hash_code = history.location.pathname.replace("/r/", "");
    // const windowFeature =
    //     "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes,dowload=yes,height=500,width=500,popup=yes";
    // async function downloadPDF() {
    //     try {
    //         setLoading(true);
    //         const response = await api.get<{ file: string }>(
    //             `/pedido/${hash_code}/download-voucher-pdf`
    //         );
    //         // window.open(response.data.file, "_blank", windowFeature);
    //         const link = document.createElement("a");
    //         link.href = response.data.file;
    //         link.download = "seu-arquivo.pdf"; // Defina o nome do arquivo para download

    //         // Simule um clique no link para iniciar o download
    //         link.click();
    //     } catch (error) {
    //         toast.error("Erro ao baixar voucher");
    //     }
    //     setLoading(false);
    // }

    return (

        <></>
        // <Container className={`${loading ? "disabled" : ""}`}>
        //     <button onClick={downloadPDF}>
        //         <div className="container-text-img">
        //             <img src={ImgPdf} alt="" />
        //             <p>Baixar Código(s)</p>
        //         </div>

        //         <div className="seta-dir">
        //             {loading ? (
        //                 <Loader height={20} width={20} />
        //             ) : (
        //                 <img src={ImgSetaDir} alt="" />
        //             )}
        //         </div>
        //     </button>
        // </Container>
    );
}
