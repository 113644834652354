import { StylesIngreso } from "./styles";
import imgIngressos from "../../assets/sistema/ingressos.svg";
import imgBebida from "../../assets/sistema/bebida.svg";
import imgPipoca from "../../assets/sistema/pipoca.svg";
import imgCopy from "../../assets/sistema/copy.svg";
import imgFundoCineMarckCombo from "../../assets/sistema/fundo-cineMarckCombo.png";
import { toast } from "react-toastify";

interface IngressoProps {
    codes: string[];
    count: number;
}

export function Ingresso(props: IngressoProps) {
    const { codes, count } = props;

    function copy(text: string) {
        const el = document.createElement("textarea");
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        toast.success(`Copiado`);
        return "Texto copiado com sucesso!";
    }

    const ingresso = codes[0] ? codes[0] : "";
    const refri = codes[1] ? codes[1] : "";
    const pipoca = codes[2] ? codes[2] : "";

    return (
        <StylesIngreso>
            <div className="container-img-combo">
                <img src={imgFundoCineMarckCombo} alt="" />
            </div>

            {ingresso ? (
                <div className="container-itens">
                    <div className="container-icone">
                        <img src={imgIngressos} alt="" />
                    </div>

                    <div className="container-condigo">
                        <h3>CÓDIGO INGRESSO {count}</h3>
                        <p>{ingresso}</p>
                    </div>

                    <button
                        className="container-copy"
                        onClick={() => copy(ingresso)}
                    >
                        <img src={imgCopy} alt="" />
                        copiar <br />
                        código
                    </button>
                </div>
            ) : (
                <></>
            )}

            {refri ? (
                <div className="container-itens">
                    <div className="container-icone">
                        <img src={imgBebida} alt="" />
                    </div>

                    <div className="container-condigo">
                        <h3>códiGo REFRIGERANTE {count}</h3>
                        <p>{refri}</p>
                    </div>

                    <button
                        className="container-copy"
                        onClick={() => copy(refri)}
                    >
                        <img src={imgCopy} alt="" />
                        copiar <br />
                        código
                    </button>
                </div>
            ) : (
                <></>
            )}

            {pipoca ? (
                <div className="container-itens">
                    <div className="container-icone">
                        <img src={imgPipoca} alt="" />
                    </div>

                    <div className="container-condigo">
                        <h3>CÓDIGO PIPOCA {count}</h3>
                        <p>{pipoca}</p>
                    </div>

                    <button
                        className="container-copy"
                        onClick={() => copy(pipoca)}
                    >
                        <img src={imgCopy} alt="" />
                        copiar <br />
                        código
                    </button>
                </div>
            ) : (
                <></>
            )}
        </StylesIngreso>
    );
}
