import { Container } from "./styles";
import imgLogo from "../../../assets/sistema/logo-mobile.svg";
import imgNotbook from "../../../assets/sistema/notbook.svg";

export function LoginMobile() {
    return (
        <Container>
            <div className="header-logo">
                <div className="container-img">
                    <img src={imgLogo} alt="" />
                </div>
            </div>

            <div className="container-description">
                <div className="container-img">
                    <img src={imgNotbook} alt="" />
                </div>

                <h1>
                    Acesse em um <br /> dispositivo desktop
                </h1>

                <p>
                    Para sua melhor experiência a aplicação{" "}
                    <strong>Estoque</strong> é acessível exclusivamente em
                    dispositivos mobile.
                </p>
            </div>
        </Container>
    );
}
