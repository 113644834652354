import { useState, useMemo, useEffect } from "react";
import { Flex, TextImg, Filter } from "aplication-yetz";

import { Modal } from "../Modal";
import { ModalFilter } from "./modal";

import imgCarrinho from "../../assets/sistema/carrinho.svg";

interface SubHeaderProps {
    setFiltro: (filtro: string) => void;
}

export interface Filtro {
    text: string;
    active: boolean;
}

export function SubHeader(props: SubHeaderProps) {
    const { setFiltro } = props;

    const filtro: Filtro[] = useMemo(() => {
        const currentYear = new Date().getFullYear();

        const result: Filtro[] = [];

        for (let i = 2021; i <= currentYear; i++) {
            result.push({
                text: i.toString(),
                active: i === currentYear,
            });
        }

        return result;
    }, []);

    const [updateFiltro, setUpdateFiltro] = useState<Filtro[]>(filtro);
    const [modal, setModal] = useState(false);

    const config = {
        filtro,
        updateFiltro: (filtro: Filtro[]) => setUpdateFiltro(filtro),
        onClose: () => setModal(false),
    };

    useEffect(() => {
        setUpdateFiltro(filtro);
    }, [filtro]);
    return (
        <>
            <Flex justifyContent="space-between" className="filtro" mb="64px">
                <TextImg as="h3" img={imgCarrinho}>
                    Pedidos Mensais
                </TextImg>

                <Flex paddingRight={55}>
                    <Flex className="ano">
                        <small>Ano</small>
                        <span>
                            {updateFiltro
                                .filter((item) => item.active && item.text)
                                .map((item) => item.text)
                                .join(" /")}
                        </span>
                    </Flex>

                    <Filter
                        onChange={(e) => setFiltro(e.target.value)}
                        onClick={() => setModal(!modal)}
                    />
                </Flex>
            </Flex>

            <Modal
                active={modal}
                component={ModalFilter}
                right={true}
                rest={config}
            />
        </>
    );
}
