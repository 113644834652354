import styled from "styled-components";

export const ModalStyles = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    bottom: 0px;
    left: 0px;
    background-color: transparent;
    z-index: -1;
    transition: all 0.4s;
    &.active {
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 1000;
        transition: all 0.4s;
        .container-body-modal-carga {
            bottom: -0px;
            transition: all 0.4s;
        }
    }

    .container-body-modal-carga {
        position: absolute;
        bottom: -500px;
        left: 0px;
        display: flex;
        align-items: center;
        height: 263px;
        width: 100%;
        background-color: ${({ theme }) => theme.colors.white};
        transition: all 0.4s;
    }

    .container-btns {
        display: flex;
        align-items: center;
        .label-arquivo {
            position: relative;
            button {
                position: absolute;
                top: 55%;
                transform: translateY(-50%);
                right: 35px;
                z-index: 20;
            }
        }

        .btn-upload {
            border: 1px solid ${({ theme }) => theme.colors.primary};
            height: 48px;
            width: 248px;
            margin-right: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            cursor: pointer;
            position: relative;
            padding-right: 15px;
            z-index: 10;
            p {
                width: 183px;
            }
        }

        .desativado {
            cursor: not-allowed;
            pointer-events: none;
        }
    }

    .cancelar-acao {
        border: 1px solid ${({ theme }) => theme.colors.primary};
    }

    .preview-text {
        &::before {
            top: 19px;
        }
    }

    .fechar-modal {
        position: absolute;
        top: 35px;
        right: 35px;
    }
`;
