import { Heading, Filter, Flex, ButtonSystem } from "aplication-yetz";
import { useHistory } from "react-router-dom";
import { ChangeEvent } from "react";
import { useVouchers } from "../../Hook/useVouchers";
import { Permissions } from "../Permission";

interface HeaderProps {
    setFilter: (filter: string) => void;
}

export function Header(props: HeaderProps) {
    const { setFilter } = props;
    const history = useHistory();
    const { modal, setModal } = useVouchers();

    return (
        <Flex
            justifyContent="space-between"
            alignItems="flex-end"
            pr="55px"
            mb="78px"
        >
            <Heading as="h2" fontSize="lg">
                Gerar Voucher
            </Heading>

            <Filter
                onClick={() => setModal(!modal)}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilter(e.target.value)
                }
            />
            <Permissions role="voucher-yetz.store">
                <Flex className="container-btns">
                    <ButtonSystem
                        ml="42px"
                        onClick={() => history.push("/vouchers/adicionar")}
                    >
                        GERAR VOUCHERS
                    </ButtonSystem>
                </Flex>
            </Permissions>
        </Flex>
    );
}
