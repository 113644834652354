import { Text } from "aplication-yetz";
import { FooterStyles } from "./styles";
import footerImg from "../../assets/sistema/logo-cine-mark.svg";

interface FooterProps {
    bgColor?: string;
}

export function Footer(props: FooterProps) {
    const year = new Date().getFullYear();

    return (
        <FooterStyles bgColor={props.bgColor}>
            <div className="container">
                <img src={footerImg} alt="" />
                <Text as="p">
                    ® YETZ LTDA. {year} Todos os direitos reservados | CNPJ:
                    28.325.166/0001-05.
                </Text>
            </div>
        </FooterStyles>
    );
}
