import { Table, Flex, Text } from "aplication-yetz";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { Column } from "react-table";

import { useCompras } from "../../Hook/useCompras";

import { Loader } from "../Loader";
import { Paginacao } from "../../components/tables/";
import { formatCurrentMoney } from "../../util/helper";

import { TableItens } from "./types";

import { Itens } from "./styles";

import Fechar from "../../assets/sistema/fechar-modal.svg";
import imgCopy from "../../assets/sistema/copy.svg";
import imgRefresh from "../../assets/sistema/icon-refresh.png";
interface BoxNoticacaoProps {
    rest: {
        onClick?: () => void;
        closeModal?: () => void;
    };
}

export function ModalItens(props: BoxNoticacaoProps) {
    const { getItensCompra, itensCompras, loadingItensCompra } = useCompras();

    function copy(text: string) {
        const el = document.createElement("textarea");
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        toast.success(`Copiado`);
        return "Texto copiado com sucesso!";
    }

    const columns: Column<TableItens>[] = [
        {
            Header: "IMG",
            accessor: "img",
        },
        {
            Header: "NOME",
            accessor: "nome",
        },
        {
            Header: "ID VARIAÇÃO",
            accessor: "id_variacao",
        },
        {
            Header: "SUBTOTAL",
            accessor: "subTotal",
        },
        {
            Header: "QTD",
            accessor: "qtd",
        },
        {
            Header: "OC FORNECEDOR",
            accessor: "oc_fornecedor",
            width: 200,
        },
        {
            Header: "STATUS",
            accessor: "status",
        },
    ];

    const data: TableItens[] = itensCompras.data
        ? itensCompras.data?.map((item) => {
              const {
                  id,
                  quantidade,
                  variacao,
                  valor,
                  fornecedor_code,
                  status,
                  log,
              } = item;

              return {
                  id,
                  id_variacao: variacao.id,
                  qtd: quantidade,
                  img: (
                      <Flex alignItems="center" justifyContent="center">
                          <img
                              width="57px"
                              src={variacao.produto?.foto_capa || ""}
                              alt=""
                          />
                      </Flex>
                  ),
                  nome: variacao.produto?.nome || "",
                  subTotal: valor ? formatCurrentMoney(parseFloat(valor)) : "",
                  oc_fornecedor: fornecedor_code,
                  status:
                      status === "ERRO" ? (
                          <>
                              <Text
                                  className="status-erro"
                                  textAlign="center"
                                  data-tip
                                  data-for={id}
                              >
                                  {status ? status : "--"}
                                  <button onClick={() => copy(log)}>
                                      <img src={imgCopy} alt="" />
                                  </button>
                              </Text>
                              <ReactTooltip id={id?.toString()} type="error">
                                  <span>{log}</span>
                              </ReactTooltip>
                          </>
                      ) : (
                          <Text textAlign="center">
                              {status ? status : "--"}
                          </Text>
                      ),
              };
          })
        : [];

    const nextPage =
        itensCompras?.current_page <= itensCompras?.last_page
            ? itensCompras.current_page + 1
            : itensCompras.current_page;

    const previusPage =
        itensCompras?.current_page >= 1
            ? itensCompras.current_page - 1
            : itensCompras.current_page;

    const canNextPage = nextPage <= itensCompras.last_page;
    const canPreviustPage = itensCompras?.from - 1 >= 1 ? true : false;

    return (
        <Itens>
            <button
                className="refresh"
                onClick={() => getItensCompra(itensCompras.data[0].compra_id)}
            >
                <img src={imgRefresh} alt="" />
            </button>
            <button
                className="close"
                onClick={() => {
                    if (props.rest.closeModal) {
                        props.rest.closeModal();
                    }
                }}
            >
                <img src={Fechar} alt="" />
            </button>
            {loadingItensCompra ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : (
                <>
                    <div className="table">
                        <Table columns={columns} data={data} pageSize={30} />
                    </div>
                    <Paginacao
                        page={itensCompras.current_page}
                        nextPage={() =>
                            getItensCompra(
                                itensCompras.data[0].compra_id,
                                nextPage.toString()
                            )
                        }
                        previousPage={() =>
                            getItensCompra(
                                itensCompras.data[0].compra_id,
                                previusPage.toString()
                            )
                        }
                        canPreviousPage={canPreviustPage}
                        canNextPage={canNextPage}
                        goToPage={(page) =>
                            getItensCompra(
                                itensCompras.data[0].compra_id,
                                page.toString()
                            )
                        }
                        lastPage={itensCompras.last_page}
                    />
                </>
            )}
        </Itens>
    );
}
