import { useEffect, useState, useCallback } from "react";
import { Container } from "./styles";

interface ProgresBarProps {
    width: number;
    color?: string;
}

export function Progress(props: ProgresBarProps) {
    const [value, setValue] = useState(0);
    const { width, color } = props;

    const progress = useCallback(() => {
        const timer = setInterval(() => {
            setValue((value) => {
                if (value < width) {
                    return value + 1;
                }

                return value;
            });
        }, 100);

        return () => clearInterval(timer);
    }, [width]);

    useEffect(() => {
        if (value <= width) {
            progress();
        }
    }, [progress, value, width]);

    return (
        <Container color={color}>
            <small>{value + ""}%</small>
            <div className="progress" style={{ width: value + "%" }}></div>
        </Container>
    );
}
