import { Grid } from "aplication-yetz";
import { useState } from "react";
import { Header } from "./header";
import { TableTotalGestao } from "./tableTotal";
import { TableReposicaoGestao } from "./tableReposicao";
import { TableOutletGestao } from "./tableOutlet";
import { TableVencidosGestao } from "./tableVencidos";
import { Cards } from "./cards";

export function Gestao() {
    const [filter, setFilter] = useState<string>("");

    console.log(filter);

    return (
        <>
            <Header setFilter={setFilter} />
            <Cards />
            <Grid gridTemplateColumns="repeat(2, 1fr)" gridGap="35px">
                <TableTotalGestao />
                <Grid gridGap="35px">
                    <TableReposicaoGestao />
                    <TableVencidosGestao />
                    <TableOutletGestao />
                </Grid>
            </Grid>
        </>
    );
}
