import { TextImg, Text } from "aplication-yetz";
import { Container } from "./styles";

interface CardTotalItensProps {
    img: string;
    description: string;
    total: string;
    center?: boolean;
}

export function CardTotalItens(props: CardTotalItensProps) {
    const { img, total, description, center } = props;

    return (
        <Container center={center}>
            <TextImg img={img} className="box-info-qtd-estoque">
                {description}
                <Text fontSize="mdl" pl="16px" bold={true}>
                    {total}
                </Text>
            </TextImg>
        </Container>
    );
}
