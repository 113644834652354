import { BoxDetalheProduto } from "../../components/BoxDetalheProduto";
import { BoxDetalheProdutoProps } from "../../components/BoxDetalheProduto/types";

import img01 from "../../assets/sistema/aguardando.svg";
import img07 from "../../assets/sistema/money.svg";
import img08 from "../../assets/sistema/vouchers.svg";

import { CardsStyles } from "./styles";

export function Cards() {
    const arrayCard: BoxDetalheProdutoProps[] = [
        {
            img: img01,
            subTitulo: "36",
            titulo: "vouchers resgatados",
        },
        {
            img: img07,
            subTitulo: "1200",
            titulo: "vouchers utilizados",
        },
        {
            img: img08,
            subTitulo: "1300",
            titulo: "total de pedidos",
        },
        {
            img: img08,
            subTitulo: "91/ 230",
            titulo: "média de pedidos",
        },
        {
            img: img08,
            subTitulo: "91/ 230",
            titulo: "valor em estoque",
        },
    ];

    return (
        <CardsStyles gridTemplateColumns="repeat(5, 1fr)" gridGap="30px">
            {arrayCard.slice(0, 5).map((item) => (
                <BoxDetalheProduto {...item} />
            ))}
        </CardsStyles>
    );
}
