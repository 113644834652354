import { useState } from "react";
import { Heading, Flex } from "aplication-yetz";
import TextField from "@mui/material/TextField";
import { SubHeaderStyles } from "./styles";
// import { Select } from "../Select";
// import { Option } from "../Select/types";

interface SubHeaderProps {
    setFiltro: (filtro: string) => void;
}

export function SubHeader(props: SubHeaderProps) {
    const { setFiltro } = props;

    const [value, setValue] = useState("");
    // const options: Option[] = [
    //     {
    //         label: "Selecione uma opção",
    //         value: 0,
    //     },
    // ];
    return (
        <SubHeaderStyles className="container">
            <div className="container-header">
                <Heading fontSize="mdl" as="h1">
                    Tutorial De Vouchers
                </Heading>
                <Flex>
                    {/* <Box width="277px" mr="32px">
                    <Select
                        options={options}
                        placeholder="Todos os Vouchers"
                        onChange={(item) => console.log(item)}
                    />
                </Box> */}

                    <div className="filtro">
                        <TextField
                            className="input-text"
                            label="Todos os Vouchers"
                            type="text"
                            value={value}
                            variant="standard"
                            fullWidth={true}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setValue(e.target.value);
                                setFiltro(e.target.value);
                            }}
                        />
                    </div>
                </Flex>
            </div>
        </SubHeaderStyles>
    );
}
