import * as React from "react";
import { Text, Box, ButtonSystem } from "aplication-yetz";

import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import imgXVerde from "../../assets/sistema/close.svg";
import imgCadastroCliente from "../../assets/sistema/sucess-cadastro-carga-infra.svg";

import { ModalStyles } from "./styles";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

interface AlertDialogSlideProps {
    title: string;
    description: string;
    open: boolean;
    handleClose: () => void;
    aceept: () => void;
    reject: () => void;
}

export default function AlertDialogSlide(props: AlertDialogSlideProps) {
    const { open, handleClose, aceept, reject, title, description } = props;
    const [loading, setLoading] = React.useState(false);
    async function handleAceept() {
        setLoading(true);
        await aceept();
        setLoading(false);
        handleClose();
    }

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <ModalStyles>
                    <div className="container-header">
                        <Text as="p">ATENÇÃO</Text>
                        <button onClick={handleClose}>
                            <img src={imgXVerde} alt="" />
                        </button>
                    </div>

                    <div className="container-sucess">
                        <img src={imgCadastroCliente} alt="" />

                        <Text as="h3">{title}</Text>

                        <Text as="p">{description}</Text>

                        <Box className="container-btns">
                            <ButtonSystem
                                background="gray200"
                                color="black"
                                onClick={reject}
                                loading={loading}
                            >
                                Não
                            </ButtonSystem>

                            <ButtonSystem
                                background="primary"
                                color="white"
                                loading={loading}
                                onClick={handleAceept}
                            >
                                Sim
                            </ButtonSystem>
                        </Box>
                    </div>
                </ModalStyles>
            </Dialog>
        </div>
    );
}
