import { useEffect, useState } from "react";
import { Box, ButtonSystem, Flex, Heading } from "aplication-yetz";
import {
    Formik,
    Field,
    Form,
    ErrorMessage,
    FormikProps,
    FormikHelpers,
} from "formik";
import { useProduto } from "../../Hook/useProduto";
import { Input, TextArea } from "../../components/Input";

import { addSchema, initialValues } from "./config";
import { Categoria } from "./types";

import { ContainerModal } from "../Produtos/styles";
import { toast } from "react-toastify";

import closeImg from "../../assets/sistema/fechar-modal.svg";

interface ModalCategoriaProps {
    rest: {
        title: string;
        description: string;
        onClose: (active: boolean) => void;
        categoriaSelecionada?: Categoria;
        id: number;
    };
}

export function ModalCategoria(props: ModalCategoriaProps) {
    const { rest } = props;
    const { id } = props.rest;

    const { storeCategoria, updateCategoria, getCategoria } = useProduto();
    const [value, setValue] = useState<Categoria>(initialValues);
    const [loading, setLoading] = useState<boolean>(false);

    const hasCategoria = id ? true : false;

    async function handleSubmit(
        values: Categoria,
        actions: FormikHelpers<Categoria>
    ) {
        setLoading(true);

        const dados = {
            ...values,
        };

        const response = hasCategoria
            ? await updateCategoria(dados, id)
            : await storeCategoria(dados);

        if (response.status === 200 || response.status === 201) {
            actions.resetForm();
            toast.success("sucesso!");
            rest.onClose(false);
            await getCategoria();
            // window.location.reload();
        }

        setLoading(false);
    }

    useEffect(() => {
        if (hasCategoria && rest.categoriaSelecionada) {
            setValue(rest.categoriaSelecionada);
        } else {
            setValue(initialValues);
        }
    }, [rest.categoriaSelecionada, hasCategoria]);

    return (
        <ContainerModal>
            <Flex justifyContent="space-between" alignItems="center" mb="34px">
                <Heading as="h2" fontSize="mdl">
                    {hasCategoria ? "Editar Categoria" : "Nova Categoria"}
                </Heading>
                <button
                    onClick={() => {
                        props.rest.onClose(false);
                    }}
                >
                    <img src={closeImg} alt="Fechar" />
                </button>
            </Flex>
            <Formik
                initialValues={value}
                enableReinitialize
                validationSchema={addSchema}
                onSubmit={(values: Categoria, actions) => {
                    handleSubmit(values, actions);
                }}
            >
                {(props: FormikProps<Categoria>) => {
                    return (
                        <Form>
                            <Box>
                                <div className="container-form">
                                    <Field
                                        component={Input}
                                        name="nome"
                                        label="NOME"
                                    />
                                    <ErrorMessage
                                        component="small"
                                        name="nome"
                                    />
                                </div>

                                <Field
                                    component={TextArea}
                                    name="descricao"
                                    label="Descrição"
                                />
                                <ErrorMessage
                                    component="small"
                                    name="descricao"
                                />
                            </Box>

                            <ButtonSystem
                                width={246}
                                height={56}
                                heightSpiner={30}
                                widthSpnier={30}
                                loading={loading}
                                type="submit"
                            >
                                SALVAR ALTERAÇÕES
                            </ButtonSystem>
                        </Form>
                    );
                }}
            </Formik>
        </ContainerModal>
    );
}
