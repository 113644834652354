import styled, { css } from "styled-components";

interface ContainerProps {}

const Loading = css`
    opacity: 0.8;
    pointer-events: none;
    cursor: not-allowed;
`;

export const Container = styled.div<ContainerProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #fff6f6;
    border-radius: 4px;

    padding: 8px;

    width: 121px;
    &.active {
        ${Loading}
    }

    button {
        cursor: pointer;
        border: none;
        background-color: transparent;
    }
    input[type="tel"] {
        border: none;
        width: 100%;
        background-color: #fff6f6;
        text-align: center;
        font-size: 1rem;
        color: #000000;
        font-weight: 400;
        line-height: 1.5;
        text-align: center;
    }
    input[type="tel"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    input[type="tel"] {
        -moz-appearance: textfield;
        appearance: textfield;
    }
`;
