import styled from "styled-components";

export const Container = styled.div`
    .container-logo {
        img {
            width: 70px;
            height: 50px;
            border-radius: 8px;
            object-fit: cover;
        }
    }

    .container-table {
        margin-top: 64px;
        .pagination {
            display: none;
        }
    }
`;
