import { Formik, Form, ErrorMessage, FormikHelpers, Field } from "formik";

import img from "../../assets/sistema/fechar-branco.svg";
import whats from "../../assets/sistema/email.svg";

import { ModalCompartilha } from "./styles";
import { useVoucherConfig } from "../../Hook/useVoucherConfig";
import { ResponseVoucher } from "../../pages/MultiplosVoucherGrandCru";
import { Email, email, validationEmail } from "./config";
interface CompartilhaProps {
    rest: {
        onClose: () => void;
        voucher?: ResponseVoucher;
    };
}

export function ModalEmail(props: CompartilhaProps) {
    const { pedidoShareUrl } = useVoucherConfig();
    const hasCupom =
        props.rest.voucher?.voucher && props.rest.voucher.voucher[0].cupom;

    async function compartilhaEmail(
        values: Email,
        formikHelpers: FormikHelpers<Email>
    ) {
        await pedidoShareUrl("email");
        window.location.href =
            "mailto:" +
            values.email +
            `?subject=Seu Voucher Digital&body=Aqui está seu ${
                hasCupom ? "cupom" : "voucher digital"
            } digital: ` +
            window.location;
    }

    return (
        <ModalCompartilha>
            <div className="header email">
                <h2>Compartilhar no Email</h2>
                <img src={img} alt="" onClick={() => props.rest.onClose()} />
            </div>
            <div className="body email">
                <p>
                    Preencha campos abaixo com o e-mail para o qual deseja
                    enviar o link do seu {hasCupom ? "cupom" : "voucher"}.
                </p>
                <Formik
                    enableReinitialize
                    validateOnMount
                    initialValues={email}
                    validationSchema={validationEmail}
                    onSubmit={compartilhaEmail}
                >
                    {(props) => {
                        return (
                            <Form>
                                <div className="campo-telefone">
                                    <img src={whats} alt="" />
                                    <Field
                                        type="email"
                                        name="email"
                                        placeholder="E-mail"
                                    />
                                </div>
                                <ErrorMessage name="phone" component="span" />

                                <button type="submit" disabled={!props.isValid}>
                                    COMPARTILHAR
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </ModalCompartilha>
    );
}
