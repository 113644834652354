import styled from "styled-components";

export const Container = styled.div`
    padding: 24px 32px;
    h1 {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 16px;
        text-align: center;
    }

    p {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 32px;
        text-align: center;
    }

    .container-btns {
        width: 70%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }
`;
