import { useState } from "react";
import { Text, Flex, TextImg, Box } from "aplication-yetz";
import { toast } from "react-toastify";
import { Produto, useProduto, UpdateVariacao } from "../../Hook/useProduto";
import { Toggle } from "../../components/Toggle";
import { Goback } from "../Goback";

import detalheProdutoImg from "../../assets/sistema/menu-produtos.svg";
import cardEditarImg from "../../assets/sistema/card-editar.svg";

import { Container } from "./styles";
import { Permissions } from "../Permission";

interface HeaderProps extends Produto {
    setModalProduto: (modalProduto: boolean) => void;
    setModalTutorial: (modalTutorial: boolean) => void;
}

export function Header(props: HeaderProps) {
    const { nome, status, setModalProduto, setModalTutorial, id } = props;
    const { updateStatusProduto } = useProduto();
    const [loading, setLoading] = useState<boolean>(false);

    async function updateProdutoStatus(values: UpdateVariacao, id: number) {
        setLoading(true);
        const response = await updateStatusProduto(values, id);

        if (response.status !== 200) {
            toast.error("Erro ao atualizar o valor da variação");
            setLoading(false);
            return;
        }

        setLoading(false);
    }
    return (
        <>
            <Container>
                <Goback title={nome} />

                <Flex ml="auto">
                    <Box mr="67px">
                        <Permissions role="produto.update">
                            <Toggle
                                active={status ? true : false}
                                titulo={
                                    <Text bold={true}>Disponibilidade</Text>
                                }
                                loading={loading}
                                onClick={() =>
                                    updateProdutoStatus(
                                        { status: status ? 0 : 1 },
                                        id
                                    )
                                }
                            />
                        </Permissions>
                    </Box>

                    <Box mr="67px">
                        <Permissions role="produto.update">
                            <button onClick={() => setModalTutorial(true)}>
                                <TextImg
                                    img={cardEditarImg}
                                    color="primary"
                                    bold={true}
                                >
                                    Passo a Passo para uso do Voucher
                                </TextImg>
                            </button>
                        </Permissions>
                    </Box>
                    <Box>
                        <Permissions role="produto.update">
                            <button onClick={() => setModalProduto(true)}>
                                <TextImg
                                    className="detalhe-produto"
                                    img={detalheProdutoImg}
                                    color="primary"
                                    bold={true}
                                >
                                    Editar dados do Produto
                                </TextImg>
                            </button>
                        </Permissions>
                    </Box>
                </Flex>
            </Container>
        </>
    );
}
