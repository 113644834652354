import { useState } from "react";
import { toast } from "react-toastify";
import { api } from "../../services/";
import { Loader } from "../Loader";
import exportarImg from "../../assets/sistema/exportar.svg";

interface DowloadCargaProps {
    id: number;
}

export function DowloadCarga(props: DowloadCargaProps) {
    const { id } = props;
    const [loading, setLoading] = useState(false);

    async function exportar() {
        setLoading(true);

        try {
            const response = await api.get(`carga/${id}/export`);

            const { data } = response;
            window.open(data.file);
        } catch (error: any) {
            const { data } = error.response;
            toast.error(data.message);
        }

        setLoading(false);
    }

    return (
        <button disabled={loading} onClick={() => exportar()}>
            {loading ? (
                <Loader height={30} width={30} />
            ) : (
                <img src={exportarImg} alt="" />
            )}
        </button>
    );
}
