import React from "react";
import { Column } from "react-table";

export interface Data {
    voucher: string | React.ReactChild | React.ReactChild[] | React.ReactNode;
    id: string | number;
    oc: string | React.ReactChild | React.ReactChild[] | React.ReactNode;
    fornecedor:
        | string
        | React.ReactChild
        | React.ReactChild[]
        | React.ReactNode;
    valor_reais:
        | string
        | React.ReactChild
        | React.ReactChild[]
        | React.ReactNode;
    compras_created_at:
        | string
        | React.ReactChild
        | React.ReactChild[]
        | React.ReactNode;
    status: string | React.ReactChild | React.ReactChild[] | React.ReactNode;
    data_hora: string | React.ReactChild | React.ReactChild[] | React.ReactNode;
}

export const column: Column<Data>[] = [
    {
        Header: "VOUCHER",
        accessor: "voucher",
    },
    {
        Header: "ID",
        accessor: "id",
    },
    {
        Header: "DATA DA COMPRA",
        accessor: "compras_created_at",
    },
    {
        Header: "ORDEM DE COMPRA",
        accessor: "oc",
    },
    {
        Header: "FORNECEDOR",
        accessor: "fornecedor",
    },
    {
        Header: "VALOR REAIS",
        accessor: "valor_reais",
    },
    {
        Header: "STATUS",
        accessor: "status",
    },
    {
        Header: "DATA/HORA STATUS ",
        accessor: "data_hora",
    },
];
