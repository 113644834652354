import { ChangeEvent, useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { Container } from "./styles";

import menoImg from "../../assets/sistema/menos.svg";
import maisImg from "../../assets/sistema/mais.svg";
import { Loader } from "../../components/Loader";
import { toast } from "react-toastify";

interface ContatorProps {
    onChange?: (value: number) => void;
    initialValue?: number;
    loading?: boolean;
}

export function Contador(props: ContatorProps) {
    const { onChange, initialValue, loading = false } = props;
    const [value, setValue] = useState(0);

    // eslint-disable-next-line
    const debouncedChange = useCallback(
        debounce((value: number) => {
            if (onChange) {
                onChange(value);
            }
        }, 2000),
        [onChange]
    );

    function decrementar() {
        if (value > 0) {
            setValue(value - 1);
            if (onChange) {
                onChange(value - 1);
            }
        }
    }

    function incrementar() {
        if (value + 1 > 999) {
            toast.error("Valor máximo é 999");
            return;
        }
        setValue(value + 1);
        if (onChange) {
            onChange(value + 1);
        }
    }

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        const regex = /[^\d]+/g;
        const value = e.target.value.replace(regex, "");

        if (value === "") {
            toast.error("Valor mínimo é 1");
            setValue(0);
            return;
        }

        if (value.length > 3) return;

        if (parseInt(value) === 0) return;

        debouncedChange(Number.parseInt(value));
        setValue(Number.parseInt(e.target.value));
    }

    useEffect(() => {
        if (initialValue) {
            setValue(initialValue);
        }
    }, [initialValue]);

    return (
        <Container className={`${loading ? "active" : ""}`}>
            {loading ? (
                <Loader width={30} height={30} />
            ) : (
                <>
                    <button onClick={decrementar}>
                        <img src={menoImg} alt="" />
                    </button>
                    <input
                        type="tel"
                        value={value}
                        onChange={handleChange}
                        onKeyUp={(e) => {
                            // setValue(Number.parseInt(e.key));
                            // if (
                            //     onChange &&
                            //     e.key !== "Backspace" &&
                            //     e.key !== "Delete"
                            // ) {
                            //     onChange(Number.parseInt(e.key));
                            // }
                        }}
                    />
                    {/* {value} */}
                    <button onClick={incrementar}>
                        <img src={maisImg} alt="" />
                    </button>
                </>
            )}
        </Container>
    );
}
