import { Sistema } from "../../components/Sistema";
import { UseAuthenticationProvider } from "../../Hook/useAuthentication";

export function PageSistema() {
    return (
        <UseAuthenticationProvider>
            <Sistema />
        </UseAuthenticationProvider>
    );
}
