import React, { createContext, useContext, useEffect, useState } from "react";
import { api } from "../services";
import { toast } from "react-toastify";

interface UseFornecedorProps {
    children: React.ReactNode;
}
export interface Fornecedor {
    id: number;
    nome: string;
    descricao: null | string;
    cnpj: null | string;
    created_at: string;
    updated_at: string;
}

const Context = createContext<UseFornecedor>({} as UseFornecedor);

interface UseFornecedor {
    loading: boolean;
    fornecedores: Fornecedor[];
    openModalAdd: boolean;
    setopenModalAdd: (value: boolean) => void;

    openModalEdit: boolean;
    setopenModalEdit: (value: boolean) => void;

    currentFornecedor: Fornecedor | null;
    setCurrentFornecedor: (value: Fornecedor | null) => void;

    openExcluirModal: boolean;
    setopenExcluirModal: (value: boolean) => void;

    addFornecedor(data: any): Promise<void>;
    updateFornecedor(data: any): Promise<void>;
    deleteFornecedor(): Promise<void>;
}

export function UseFornecedorProvider(props: UseFornecedorProps) {
    const { children } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [openModalAdd, setopenModalAdd] = useState(false);
    const [openModalEdit, setopenModalEdit] = useState(false);
    const [openExcluirModal, setopenExcluirModal] = useState(false);
    const [fornecedores, setFornecedores] = useState<Fornecedor[]>([]);
    const [currentFornecedor, setCurrentFornecedor] =
        useState<Fornecedor | null>(null);

    async function getFornecedores() {
        try {
            setLoading(true);
            const response = await api.get<Fornecedor[]>("/fornecedor");
            setFornecedores(response.data);
            setLoading(false);
            console.log(response);
        } catch (error) {
            toast.error("ops algo de errado aconteceu");
        }
    }

    async function addFornecedor(data: any) {
        try {
            setLoading(true);
            await api.post<Fornecedor>("/fornecedor", data);
            await getFornecedores();
            setopenModalAdd(false);
            toast.success("Sucesso!");
        } catch (error) {
            toast.error("ops algo de errado aconteceu");
        }
    }

    async function updateFornecedor(data: any) {
        const id = currentFornecedor?.id;
        if (!id) return;
        try {
            setLoading(true);
            await api.post<Fornecedor>(`/fornecedor/${id}`, data);
            await getFornecedores();
            setopenModalEdit(false);
            toast.success("Sucesso!");
        } catch (error) {
            toast.error("ops algo de errado aconteceu");
        }
    }

    async function deleteFornecedor() {
        const id = currentFornecedor?.id;
        if (!id) return;
        try {
            setLoading(true);
            await api.delete<Fornecedor>(`/fornecedor/${id}`);
            await getFornecedores();
            setopenExcluirModal(false);
            toast.success("Sucesso!");
        } catch (error) {
            toast.error("ops algo de errado aconteceu");
        }
    }

    useEffect(() => {
        getFornecedores();
    }, []);

    return (
        <Context.Provider
            value={{
                loading,
                fornecedores,
                openModalAdd,
                setopenModalAdd,

                openExcluirModal,
                setopenExcluirModal,

                openModalEdit,
                setopenModalEdit,

                currentFornecedor,
                setCurrentFornecedor,

                addFornecedor,
                updateFornecedor,
                deleteFornecedor,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function useFornecedor() {
    const context = useContext(Context);
    return context;
}
