import React, { createContext, useContext, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { api } from "../services";

interface UseUserData {
    loading: boolean;
    error: boolean;

    users: User[];
    user: User;
    roles: Roles[];

    getRoles: () => Promise<Roles[]>;
    create: (user: Create) => Promise<AxiosResponse>;
    update: (user: Create, id: number) => Promise<AxiosResponse>;
    show: (id: number) => Promise<void>;
}

interface UseUserProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

export interface Roles {
    id: number;
    name: string;
    updated_at: string;
}

export interface User {
    id: number;
    name: string;
    email: string;
    document: string;
    phone_number: string;
    roles: Array<Roles>;
    status: number;
    last_login?: string;
}

export interface UserData {
    admin: Array<User>;
}

export interface Create {
    name: string;
    email: string;
    phone_number: string;
    role: string;
    password: string;
    password_confirmation: string;
}

const UseUserContext = createContext<UseUserData>({} as UseUserData);

export function UseloginProvider(props: UseUserProviderProps) {
    const { children } = props;
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [users, setUsers] = useState<User[]>([]);
    const [user, setUser] = useState<User>({} as User);
    const [roles, setRoles] = useState<Roles[]>([]);

    const [onCreateAndUpdate, setOnCreateAndUpdate] = useState<number>(0);

    useEffect(() => {
        async function getUsers() {
            setLoading(true);
            try {
                const { data } = await api.get<UserData>("usuario");
                setUsers(data.admin);
                setLoading(false);
            } catch (error) {
                console.log(error);
                toast.error("ops algo de errado aconteceu");
                setLoading(false);
                setError(false);
            }
        }

        getUsers();
    }, [onCreateAndUpdate]);

    async function getRoles() {
        try {
            const { data } = await api.get<Roles[]>("role");
            setRoles(data);
            return data;
        } catch (error) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
            return error as Roles[];
        }
    }

    async function create(values: Create) {
        try {
            const response = await api.post<User>("usuario", {
                ...values,
            });

            setOnCreateAndUpdate(response.data.id);
            return response;
        } catch (error: any) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
            return error;
        }
    }

    async function update(values: Create, id: number) {
        try {
            const response = await api.post<User>(`usuario/${id}`, {
                ...values,
            });

            setOnCreateAndUpdate(onCreateAndUpdate + 1);
            return response;
        } catch (error: any) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
            return error;
        }
    }

    async function show(id: number) {
        try {
            const { data } = await api.get<User>(`usuario/${id}`);
            setUser(data);
        } catch (error) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
        }
    }

    return (
        <UseUserContext.Provider
            value={{
                error,
                loading,
                roles,
                users,
                user,
                getRoles,
                create,
                update,
                show,
            }}
        >
            {children}
        </UseUserContext.Provider>
    );
}

export function useUser() {
    const context = useContext(UseUserContext);
    return context;
}
