import { useEffect, useRef, useState } from "react";
import { Heading, Text, Box, ButtonSystem } from "aplication-yetz";
import TextField from "@mui/material/TextField";
import { CountDown } from "../Countdown";

import { Container } from "./styles";
import ValidarLogin from "../../assets/sistema/validae-login.svg";
import Fechar from "../../assets/sistema/fechar-simples.svg";
import setaCima from "../../assets/sistema/seta-cima.svg";

interface ModalValidaLoginProps {
    rest: {
        resend: () => void;
        confirm: (code: string) => void;
        onclose: () => void;
        loadingConfirmCode: boolean;
        hasLogin: boolean;
    };
}

export function ModalValidaLogin(props: ModalValidaLoginProps) {
    const [disabled, setDisabled] = useState(true);
    const [timer, setTimer] = useState(false);
    const input = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setTimer(true);
    }, []);

    return (
        <Container>
            <button
                className="close"
                onClick={() => {
                    if (props.rest.onclose) {
                        props.rest.onclose();
                    }
                }}
            >
                <img src={Fechar} alt="" />
            </button>

            <div className="text-img">
                <Box mb="18px">
                    <img src={ValidarLogin} alt="" />
                </Box>
                <Heading mb="10px" as="h3" fontSize="mdl">
                    VALIDAÇÃO DE LOGIN
                </Heading>
                <Text as="p" mb="24px">
                    Sua conta é protegida com autenticação de dois fatores.
                    Enviamos um WhatsApp. Por favor, digite o código abaixo.
                </Text>
                <Box mb="8px" width="100%">
                    <TextField
                        className={`input-text ${
                            props.rest.hasLogin === false ? "error" : ""
                        }`}
                        label="Código de validação"
                        variant="outlined"
                        fullWidth={true}
                        ref={input}
                    />
                </Box>

                {props.rest.hasLogin === false ? (
                    <Text as="span">Código inválido</Text>
                ) : null}

                {timer ? (
                    <CountDown setDisabled={setDisabled} setTimer={setTimer} />
                ) : null}

                <Box width="100%" mt="16px" mb="24px">
                    <ButtonSystem
                        loading={props.rest.loadingConfirmCode}
                        className="btn"
                        onClick={() => {
                            console.log(props.rest.confirm);
                            const value = (
                                (input.current as Element).querySelector(
                                    "input"
                                ) as HTMLInputElement
                            ).value;

                            if (props.rest.confirm && value) {
                                props.rest.confirm(value);
                            }
                        }}
                    >
                        CONTINUE
                    </ButtonSystem>
                </Box>

                <Box>
                    <button
                        disabled={disabled}
                        className="btn enviar"
                        onClick={() => {
                            if (props.rest.resend) {
                                props.rest.resend();
                                setTimer(true);
                                setDisabled(true);
                            }
                        }}
                    >
                        Reenviar código <img src={setaCima} alt="" />
                    </button>
                </Box>
            </div>
        </Container>
    );
}
