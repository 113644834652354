import styled from "styled-components";
import seloImg from "../../assets/sistema/selo.svg";

export const Container = styled.div`
    .table {
        .container-tables {
            .icone-us {
                &.enviado-por {
                    position: relative;
                    &::after {
                        content: "";
                        display: block;
                        width: 24px;
                        height: 24px;
                        -webkit-mask: url(${seloImg});
                        -webkit-mask-repeat: no-repeat;
                        position: absolute;
                        top: 50%;
                        right: -56px;
                        transform: translateY(-50%);
                        background-color: red;
                    }
                }
            }
        }
    }
`;

export const HeaderStyles = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 48px;
    margin-bottom: 51px;
    border-bottom: 1px solid #ececec;
`;

export const HeaderRight = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    .box-info-qtd-estoque {
        border: 1px solid #dfdfdf;
        margin-left: 45px;
        height: 56px;
        justify-content: center;
        padding: 0px 25px;
        padding-left: 64px;
        border-radius: 8px;
        &:before {
            top: 55%;
            left: 27px;
            transform: translateY(-50%);
        }
    }
`;

export const CardStyles = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 20px;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 56px;
    border-bottom: 1px solid #dfdfdf;
    img {
        &.logo {
            width: 272px;
            height: 182px;
            border-radius: 8px;
        }
    }
`;

export const SubHeaderStyles = styled.div`
    padding-top: 59px;
    display: flex;
    align-items: center;
    padding-left: 11px;
    padding-right: 55px;
    span {
        padding-left: 23px;
        &:before {
            top: 53%;
        }
    }
`;

export const LoaderStyles = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ModalCalendario = styled.div`
    padding: 0 32px;
    padding-top: 33px;
    width: 547px;
    height: 100vh;
    background-color: ${(props) => props.theme.colors.white};
    .container-calendario {
        div {
            div {
                width: 100%;
                .MuiCalendarPicker-root {
                    .MuiYearPicker-root {
                        overflow: hidden;
                        margin: 0px;
                        .PrivatePickersYear-root {
                            width: auto !important;
                            &:first-child {
                                justify-content: center;
                            }
                        }
                    }
                    div {
                        &:first-child {
                            div[style] {
                                width: 50%;
                            }
                        }
                        div {
                            &:first-child {
                                justify-content: space-between;
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const ModalFiltroStyles = styled.div`
    padding: 0 32px;
    padding-top: 33px;
    width: 547px;
    height: 100vh;
    background-color: ${(props) => props.theme.colors.white};
    .container-calendario {
        div {
            div {
                width: 100%;
                .MuiCalendarPicker-root {
                    .MuiYearPicker-root {
                        overflow: hidden;
                        margin: 0px;
                        .PrivatePickersYear-root {
                            width: auto !important;
                            &:first-child {
                                justify-content: center;
                            }
                        }
                    }
                    div {
                        &:first-child {
                            div[style] {
                                width: 50%;
                            }
                        }
                        div {
                            &:first-child {
                                justify-content: space-between;
                            }
                        }
                    }
                }
            }
        }
    }
`;
