import React from "react";
import { Column } from "react-table";

export interface Data {
    voucher: string | React.ReactChild | React.ReactChild[] | React.ReactNode;
    tempo: string | React.ReactChild | React.ReactChild[] | React.ReactNode;
}

export const column: Column<Data>[] = [
    {
        Header: "VOUCHER",
        accessor: "voucher",
    },
    {
        Header: "TEMPO DE ESPERA",
        accessor: "tempo",
    },
];
