import { Plataforma } from "../../components/Plataforma";
import { UsePlataformaProvider } from "../../Hook/usePlataforma";

export function PagePlataforma() {
    return (
        <UsePlataformaProvider>
            <Plataforma />
        </UsePlataformaProvider>
    );
}
