import { Text, Flex, Box, ButtonSystem } from "aplication-yetz";
import { Excluir } from "./styles";

interface BoxNoticacaoProps {
    rest: {
        img: string;
        title: string;
        width?: number;
        onClick?: () => void;
        closeModal?: () => void;

        motivo?: boolean;

        loading?: boolean;
    };
}

export function BoxExclusao(props: BoxNoticacaoProps) {
    const { rest } = props;

    return (
        <Excluir width={rest.width}>
            <Box>
                <img src={rest.img} alt="" width="50px" />
            </Box>

            <Text fontWeight="600" fontSize="mdl" color="white">
                {rest.title}
            </Text>

            <Flex width="205px" mt="32px" justifyContent="space-between">
                <Box width="93px">
                    <ButtonSystem
                        background="gray200"
                        color="black"
                        onClick={() => {
                            if (rest.closeModal) rest.closeModal();
                        }}
                    >
                        Não
                    </ButtonSystem>
                </Box>

                <Box width="93px">
                    <ButtonSystem
                        loading={props.rest.loading}
                        heightSpiner={23}
                        widthSpnier={22}
                        onClick={() => {
                            if (rest?.onClick) {
                                rest.onClick();
                            }
                        }}
                    >
                        Sim
                    </ButtonSystem>
                </Box>
            </Flex>
        </Excluir>
    );
}
