import { Box, Flex, Text } from "aplication-yetz";
import { useAuthentication } from "../../Hook/useAuthentication";
import logoImg from "../../assets/sistema/logo-novo-vinho.svg";
import setaDirImg from "../../assets/sistema/seta-dir.svg";

export function Header() {
    const { logout } = useAuthentication();
    return (
        <Box>
            <Flex
                alignItems="center"
                justifyContent="space-between"
                pt="4.75rem"
                pb="8.438rem"
            >
                <img src={logoImg} alt="" />

                <button onClick={() => logout()}>
                    <Flex>
                        <Text color="primary" mr="22px">
                            Sair
                        </Text>
                        <img src={setaDirImg} alt="" />
                    </Flex>
                </button>
            </Flex>
        </Box>
    );
}
