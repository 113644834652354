export function PageTeste() {
    return (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        // <iframe
        //     src="https://yetz-tester.s3.sa-east-1.amazonaws.com/teste_pdf20230811181514.pdf"
        //     style={{ width: "100%", height: "100%" }}
        //     frameBorder="0"
        //     scrolling="no"
        //     seamless
        //     allow="fullscreen"
        //     referrerPolicy="no-referrer"
        //     sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts allow-downloads"
        // />

        <a
            href="https://yetz-tester.s3.sa-east-1.amazonaws.com/teste_pdf20230811181514.pdf"
            rel="noopener noreferrer"
            download
        >
            testando
        </a>
    );
}
