import { Usuarios } from "../../components/Usuarios";
import { UseloginProvider } from "../../Hook/useUser";

export function PageUsuarios() {
    return (
        <UseloginProvider>
            <Usuarios />
        </UseloginProvider>
    );
}
