import { Box, Flex, Text } from "aplication-yetz";
import yetzImg from "../../assets/sistema/yetz.svg";
import { Container } from "./styles";

export function Footer() {
    const year = new Date().getFullYear();
    const widith = window.screen.width;
    return (
        <Container overflow="hidden">
            <Flex className="container">
                <Box mr="59px" className="">
                    <Flex alignItems="center" height="100%">
                        <Text fontSize="xss">
                            YETZ LTDA | CNPJ: 28.325.166/0001-05 | {year}
                            {widith < 480 ? <br /> : <>| </>}
                            Todos os direitos reservados
                        </Text>
                    </Flex>
                </Box>

                <Flex alignItems="center" className="logo-yetz">
                    <Flex
                        justifyContent="center"
                        alignItems="center"
                        pl="47px"
                        height="auto"
                        width="100%"
                        borderLeft="1px solid #000"
                    >
                        <img src={yetzImg} alt="" />
                    </Flex>
                </Flex>
            </Flex>
        </Container>
    );
}
