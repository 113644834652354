import { Flex, Heading, ButtonSystem } from "aplication-yetz";
import { useRelatorioCompras } from "../../Hook/useRelatorioCompra";
import { Permissions } from "../Permission";

interface HeaderProps {}

export function Header(props: HeaderProps) {
    const { setModalCreate } = useRelatorioCompras();
    return (
        <Flex alignItems="center" justifyContent="space-between" mb="68px">
            <Heading as="h1" fontSize="lg">
                Relatórios de compra
            </Heading>

            <Permissions role="relatorio.compras_carga.store">
                <ButtonSystem onClick={() => setModalCreate(true)}>
                    GERAR RELATÓRIO
                </ButtonSystem>
            </Permissions>
        </Flex>
    );
}
