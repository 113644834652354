import { useState } from "react";
import { HeaderModalDetalheCarga } from ".";
import { Heading, Flex, Grid, Text } from "aplication-yetz";
import { HistorioCargaById } from "../../Hook/useEstoque";
import { CardTotalItens } from "../../components/CardTotalItens";
import { TableModal } from "./tableModal";

import imgClose from "../../assets/sistema/fechar.svg";
import imgAnexo from "../../assets/sistema/anexo.svg";
import imgDinheiro from "../../assets/sistema/dinheiro.svg";

import { Modal, Filter } from "./styles";
import { ContainerTable } from "../../components/tables/styles";

interface ModaldetalheCargaProps {
    rest: {
        headerModalDetalheCarga: HeaderModalDetalheCarga;
        data?: HistorioCargaById[];
        onClose: () => void;
    };
}

export function ModalDetalheCarga(props: ModaldetalheCargaProps) {
    const [filtro, setFiltro] = useState<string>("");

    return (
        <Modal>
            <Flex alignItems="center" justifyContent="space-between">
                <Heading as="h3" lineHeight="unset" fontSize="mdl">
                    {props.rest?.headerModalDetalheCarga?.nome || ""}
                </Heading>

                <button
                    onClick={() => {
                        if (props.rest.onClose) {
                            props.rest.onClose();
                        }
                    }}
                >
                    <img src={imgClose} alt="" />
                </button>
            </Flex>

            <Text m="25px 0">
                {props.rest.headerModalDetalheCarga?.date
                    ? props.rest.headerModalDetalheCarga.date
                    : ""}
            </Text>

            <Grid gridTemplateColumns="1fr 1fr" gridGap="40px" mb="33px">
                <CardTotalItens
                    img={imgAnexo}
                    description="Total de VOUCHERS"
                    total={`${
                        props.rest.headerModalDetalheCarga?.total_voucher || "-"
                    }`}
                />
                <CardTotalItens
                    img={imgDinheiro}
                    description="Valor total"
                    total={`${
                        props.rest.headerModalDetalheCarga?.total_valor
                            ? props.rest.headerModalDetalheCarga.total_valor
                            : "-"
                    }`}
                    center={true}
                />
            </Grid>

            <Flex paddingRight="55px" marginBottom="45px">
                <Filter onChange={(e) => setFiltro(e.target.value)} />
            </Flex>

            <ContainerTable height="74px">
                <TableModal data={props.rest?.data} filtro={filtro} />
            </ContainerTable>
        </Modal>
    );
}
