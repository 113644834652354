import { Grid } from "aplication-yetz";
import { useState } from "react";
import { Header } from "./header";
import { Cards } from "./cards";
import { TableVencidosRelatorio } from "./tableVencidos";
import { TableOutletRelatorio } from "./tableOutlet";
import { TableMaisResgatadosRelatorio } from "./tableMaisResgatados";
import { GraficoResgates } from "./graficoResgates";
import { GraficoResgatePlataforma } from "./graficoResgatesPlataforma";

export function Relatorio() {
    const [filter, setFilter] = useState<string>("");

    console.log(filter);

    return (
        <>
            <Header setFilter={setFilter} />
            <Cards />
            <Grid gridTemplateColumns="repeat(2, 1fr)" gridGap="35px" mb="35px">
                <GraficoResgates />
                <GraficoResgatePlataforma />
            </Grid>
            <Grid gridTemplateColumns="repeat(4, 1fr)" gridGap="35px">
                <TableVencidosRelatorio />
                <TableOutletRelatorio />
                <TableMaisResgatadosRelatorio />
                <TableMaisResgatadosRelatorio />
            </Grid>
        </>
    );
}
