import { Categorias } from "../../components/Categorias";
import { UseProdutoProvider } from "../../Hook/useProduto";

export function PageCategorias() {
    return (
        <UseProdutoProvider>
            <Categorias />
        </UseProdutoProvider>
    );
}
