import { useEffect, useState } from "react";
import { Text } from "aplication-yetz";
import { Loader } from "../Loader";
import { useEstoque, HistorioCarga } from "../../Hook/useEstoque";
import { Header } from "./header";
import { TabelaEstoque } from "./estoque";
import { HeaderNotificacao } from "./headerNotificacao";
import { Container } from "./styles";

import { Modal } from "../../components/Modal";

import { ModalCargaEstoque } from "../ModalCargaEstoque";
import { ModalCargaVerificado } from "../ModalCargaVerificado";
import { ModalDetalheCarga } from "./modalDetalheCarga";
import { BoxExclusao } from "../../components/BoxNotificacao";

import imgConfirmacao from "../../assets/sistema/check-vermelho.svg";
import imgReprova from "../../assets/sistema/close.svg";

export interface HeaderModalDetalheCarga {
    total_voucher: number;
    total_valor: number | string;
    nome: string;
    date: string;
}

export function Estoque() {
    const {
        estoque,
        cargaEstoqueStatus,
        statusCargas,
        aprovarCarga,
        loadingAprovarCarga,
        loadingReprovarCarga,
        reprovarCarga,
        loadingEstoque,
        getEstoque,
    } = useEstoque();
    const [modalCarga, setModalCarga] = useState(false);
    const [modalVerificado, setModalVerificado] = useState(false);
    const [filtro, setFiltro] = useState("");

    const [detalheCargaEstoque, setDetalheCargaEstoque] = useState<
        HistorioCarga[]
    >([]);

    const [cargaId, setCargaId] = useState<number | null>(null);
    const [modalConfirmaAprovacao, setModalConfirmaAprovacao] =
        useState<boolean>(false);
    const [modalConfirmaReprova, setModalConfirmaReprova] =
        useState<boolean>(false);

    const [headerModalDetalheCarga, setHeaderModalDetalheCarga] =
        useState<HeaderModalDetalheCarga>();
    const [modalDetalheCarga, setModalDetalheCarga] = useState<boolean>(false);
    const [toggleHistoricoCargas, setToggleHistoricoCargas] =
        useState<boolean>(false);

    const configModal = {
        data: detalheCargaEstoque,
        headerModalDetalheCarga,
        onClose: () => setModalDetalheCarga(false),
    };

    useEffect(() => {
        getEstoque(filtro);
        // eslint-disable-next-line
    }, [filtro]);

    const confirmaAprovacao = {
        img: imgConfirmacao,
        loading: loadingAprovarCarga,
        title: (
            <Text as="p" fontWeight="600" fontSize="mdl" color="black">
                Tem certeza que deseja <br />
                aprovar esta carga?
            </Text>
        ),
        width: "500px",
        onClick: () => (cargaId ? aprovar(cargaId) : null),
        closeModal: () => {
            setModalConfirmaAprovacao(false);
            setCargaId(null);
        },
    };

    const confirmaReprova = {
        img: imgReprova,
        loading: loadingReprovarCarga,
        title: (
            <Text as="p" fontWeight="600" fontSize="mdl" color="black">
                Tem certeza que deseja <br />
                reprovar esta carga?
            </Text>
        ),
        width: "500px",
        onClick: () => (cargaId ? reprovar(cargaId) : null),
        closeModal: () => {
            setModalConfirmaReprova(false);
            setCargaId(null);
        },
    };

    function handleToggleHistoricoCargas() {
        setToggleHistoricoCargas(!cargaEstoqueStatus);
    }

    async function aprovar(id: number) {
        await aprovarCarga(id);
        setModalConfirmaAprovacao(false);
    }

    async function reprovar(id: number) {
        await reprovarCarga(id);
        setModalConfirmaReprova(false);
    }

    useEffect(() => {
        if (cargaEstoqueStatus) {
            setToggleHistoricoCargas(true);
        }
    }, [cargaEstoqueStatus]);

    return (
        <Container>
            <Header
                setFiltro={setFiltro}
                setModalCarga={setModalCarga}
                setModalVerificado={setModalVerificado}
                handleToggleHistoricoCargas={handleToggleHistoricoCargas}
            />

            <div
                className={`container-historico-carga ${
                    toggleHistoricoCargas ? "active" : ""
                }`}
                style={{
                    height: toggleHistoricoCargas
                        ? 68 * statusCargas.length
                        : 0,
                }}
            >
                {statusCargas.map((item) => (
                    <HeaderNotificacao
                        {...item}
                        setModalConfirmaAprovacao={setModalConfirmaAprovacao}
                        setModalConfirmaReprova={setModalConfirmaReprova}
                        setDetalheCargaEstoque={setDetalheCargaEstoque}
                        setModalDetalheCarga={setModalDetalheCarga}
                        setHeaderModalDetalheCarga={setHeaderModalDetalheCarga}
                        setCargaId={setCargaId}
                        key={item.id}
                    />
                ))}
            </div>

            {loadingEstoque ? (
                <div className="container-loading">
                    <Loader height={200} width={200} />
                </div>
            ) : (
                <TabelaEstoque estoque={estoque} filtro={filtro} />
            )}

            <ModalCargaEstoque active={modalCarga} setActive={setModalCarga} />

            <ModalCargaVerificado
                active={modalVerificado}
                setActive={setModalVerificado}
            />

            <Modal
                component={ModalDetalheCarga}
                active={modalDetalheCarga}
                rest={configModal}
            />

            <Modal
                component={BoxExclusao}
                active={modalConfirmaAprovacao}
                rest={confirmaAprovacao}
            />

            <Modal
                component={BoxExclusao}
                active={modalConfirmaReprova}
                rest={confirmaReprova}
            />
        </Container>
    );
}
