import { useState } from "react";
import { Table } from "./contentTable";
import {
    formatDateIso,
    formatCurrentMoney,
    filterVoucher,
} from "../../util/helper";
import { SummaryItens, useVouchers, Variacoes } from "../../Hook/useVouchers";

import { Column } from "react-table";
import { Loader } from "../Loader";
import { Paginacao } from "../tables";
import { Modal } from "../Modal";
import { ModalItens } from "./modalItens";

// import imgPc from "../../assets/sistema/image-table.png";
import { Data } from "./types";

interface Tableprops {
    filter: string;
}

function TableCompra(props: Tableprops) {
    const { vouchers, loadingVouchers, paginacaoVouchers } = useVouchers();

    const [modalItens, setModalItens] = useState(false);

    const columns: Column<Data>[] = [
        {
            Header: "Nº DO LOTE",
            accessor: "n_do_lote",
            width: 100,
        },
        {
            Header: "DATA E HORA",
            accessor: "data_e_hora",
            width: 180,
        },
        {
            Header: "PARCEIRO",
            accessor: "parceiro",
        },
        {
            Header: "TIPO",
            accessor: "tipo",
        },
        {
            Header: "GERADO POR",
            accessor: "gerado_por",
            width: 150,
        },
        {
            Header: "QTD.",
            accessor: "qtd",
            width: 50,
        },
        {
            Header: "VALOR",
            accessor: "valor",
            width: 180,
        },
        {
            Header: "STATUS",
            accessor: "status",
        },
        {
            Header: "Ações",
            accessor: "variacao",
            // width: 68,
        },
    ];

    const arrFilter = filterVoucher(props.filter, vouchers.data) || [];
    const data: Data[] =
        arrFilter.map((data) => {
            const itens = JSON.parse(data.summary) as SummaryItens;
            const variacoes = Object.values(itens.variacoes) as Variacoes[];
            return {
                n_do_lote: data.id,
                data_e_hora: formatDateIso(data.created_at),
                parceiro: data.prefixo,
                tipo: "Compra",
                gerado_por: "Admin",
                qtd: itens.total_itens,
                valor: formatCurrentMoney(itens.total_reais),
                status: data.status,
                variacao: variacoes,
            };
        }) ?? [];

    const nextPage =
        vouchers?.current_page <= vouchers?.last_page
            ? vouchers.current_page + 1
            : vouchers.current_page;

    const previusPage =
        vouchers?.current_page >= 1
            ? vouchers.current_page - 1
            : vouchers.current_page;

    const canNextPage = nextPage <= vouchers.last_page;
    const canPreviustPage = vouchers?.from - 1 >= 1 ? true : false;

    const confirmaExclusao = {
        onClick: () => null,
        closeModal: () => {
            setModalItens(false);
        },
    };

    if (loadingVouchers) {
        return (
            <div className="container-loading">
                <Loader height={200} width={200} />
            </div>
        );
    }

    return (
        <>
            <Modal
                component={ModalItens}
                active={modalItens}
                rest={confirmaExclusao}
            />
            {data.map((item, key, arr) => (
                <Table
                    columns={columns}
                    data={[item]}
                    index={key}
                    key={key}
                    setModalItens={setModalItens}
                />
            ))}

            <Paginacao
                page={vouchers.current_page}
                nextPage={() => paginacaoVouchers(nextPage)}
                previousPage={() => paginacaoVouchers(previusPage)}
                canPreviousPage={canPreviustPage}
                canNextPage={canNextPage}
                goToPage={(page) => paginacaoVouchers(page)}
                lastPage={vouchers.last_page}
            />
        </>
    );
}

export default TableCompra;
