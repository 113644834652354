import imgCard from "../../assets/sistema/encerrada.png";

import { StylesAlerta } from "./styles";

interface ExpiradoProps {
    date: string;
}

export function Expirado(props: ExpiradoProps) {
    return (
        <StylesAlerta>
            <div className="container-img">
                <img src={imgCard} alt="" />
            </div>

            <div className="container-text">
                <div className="container-date">
                    <p>
                        <strong>ESSE VOUCHER EXPIROU EM</strong>
                    </p>

                    <p>{props.date}</p>
                </div>
            </div>
        </StylesAlerta>
    );
}
