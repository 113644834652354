import { useState } from "react";
import { Header } from "./header";
import { Yetz } from "./yetz";
import { useVouchers, Vouchers } from "../../Hook/useVoucher";
import { getAllMonthName } from "../../util/helper";
import { Loader } from "../Loader";
import { Box } from "aplication-yetz";

export function ProdutosExpirados() {
    const { vouchers, loading } = useVouchers();
    const [closeAllTabs, setCloseAllTabs] = useState<boolean>(false);

    function handleClose(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        let target = e.target as HTMLDivElement;
        let hasactive = true;

        while (hasactive) {
            target = target?.parentElement as HTMLDivElement;

            if (target.classList.value.includes("active")) {
                hasactive = true;
                break;
            }

            if (target.classList.value.includes("mostrar-detalhes")) {
                hasactive = true;
                break;
            }

            if (target?.tagName === "BODY") {
                hasactive = false;
                break;
            }
        }

        return hasactive;
    }

    function somarValores(array: Vouchers[]): Vouchers {
        let somaValorTotal = 0;
        let somaTotalDisponivel = 0;
        let somaTotalOutlet = 0;
        let somaTotalVencido = 0;

        for (const item of array) {
            somaValorTotal += parseFloat(item.valor_total);
            somaTotalDisponivel += parseFloat(item.valor_disponivel);
            somaTotalOutlet += parseFloat(item.valor_outlet);
            somaTotalVencido += parseFloat(item.valor_vencido);
        }

        const objetoSomado: Vouchers = {
            total_disponivel: array.reduce(
                (acc, item) => acc + parseInt(item.total_disponivel + ""),
                0
            ),
            total_outlet: array.reduce(
                (acc, item) => acc + parseInt(item.total_outlet + ""),
                0
            ),
            total_vencido: array.reduce(
                (acc, item) => acc + parseInt(item.total_vencido + ""),
                0
            ),
            user_id: array[0].user_id,
            plataforma: getAllMonthName(array[0].mes + "") + " " + array[0].ano,
            color: array[0].color,
            light_color: array[0].light_color,
            mes: array[0].mes,
            ano: array[0].ano,
            total_itens: array.reduce((acc, item) => acc + item.total_itens, 0),
            valor_total: somaValorTotal.toFixed(2),
            valor_disponivel: somaTotalDisponivel.toFixed(2),
            valor_outlet: somaTotalOutlet.toFixed(2),
            valor_vencido: somaTotalVencido.toFixed(2),
        };

        return objetoSomado;
    }

    if (loading) {
        return (
            <Box position="absolute" top="45%" left="45%">
                <Loader width={150} height={150} />
            </Box>
        );
    }

    return (
        <div
            className="vouchers-expirados"
            onClick={(e) => {
                setCloseAllTabs(!handleClose(e));
            }}
        >
            <Header setFilter={() => {}} />
            {vouchers.map((item, key) => {
                const mes = somarValores(item);

                return (
                    <Yetz
                        mes={mes}
                        yetzlink={
                            item.filter(
                                (item) => item.plataforma === "YETZLINK"
                            )[0]
                        }
                        yetzcards={
                            item.filter(
                                (item) => item.plataforma === "YETZCARDS"
                            )[0]
                        }
                        yetzpromo={
                            item.filter(
                                (item) => item.plataforma === "YETZPROMO"
                            )[0]
                        }
                        voucher={item}
                        closeAllTabs={closeAllTabs}
                        key={key}
                    />
                );
            })}
        </div>
    );
}
