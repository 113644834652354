import { Column } from "react-table";

export interface Data {
    plataforma: string;
    voucher: string | React.ReactChild | React.ReactChild[] | React.ReactNode;
    valor: string;
    qtd: string;
    total: string;
    status: string;
}

export const column: Column<Data>[] = [
    {
        Header: "PLATAFORMA",
        accessor: "plataforma",
    },
    {
        Header: "VOUCHER",
        accessor: "voucher",
    },
    {
        Header: "VALOR",
        accessor: "valor",
    },
    {
        Header: "QTD",
        accessor: "qtd",
    },
    {
        Header: "TOTAL",
        accessor: "total",
    },
    {
        Header: "STATUS",
        accessor: "status",
    },
];
