import { Table, Flex } from "aplication-yetz";

import { Column } from "react-table";

import { useVouchers } from "../../Hook/useVouchers";

import { Loader } from "../Loader";

import { formatCurrentMoney } from "../../util/helper";

import { TableItens } from "./types";

import { Itens } from "./styles";

import Fechar from "../../assets/sistema/fechar-modal.svg";

interface BoxNoticacaoProps {
    rest: {
        onClick?: () => void;
        closeModal?: () => void;
    };
}

export function ModalItens(props: BoxNoticacaoProps) {
    const { loadingItensVoucher, variacoes, setVariacoes } = useVouchers();

    const columns: Column<TableItens>[] = [
        {
            Header: "IMG",
            accessor: "img",
        },

        {
            Header: "NOME",
            accessor: "nome",
        },
        {
            Header: "QTD",
            accessor: "qtd",
        },
        {
            Header: "VALOR YETZ",
            accessor: "valor_yetz",
        },
        {
            Header: "SUBTOTAL",
            accessor: "subTotal",
        },
    ];

    const data: TableItens[] = variacoes.length
        ? variacoes?.map((item) => {
              const { foto_capa, nome, quantidade, valor_reais, valor_yetz } =
                  item;

              return {
                  img: (
                      <Flex alignItems="center" justifyContent="center">
                          <img width="57px" src={foto_capa} alt="" />
                      </Flex>
                  ),
                  nome: nome,
                  valor_yetz: valor_yetz,
                  subTotal: valor_reais
                      ? formatCurrentMoney(parseFloat(valor_reais))
                      : "",
                  qtd: quantidade,
              };
          })
        : [];

    return (
        <Itens>
            <button
                className="close"
                onClick={() => {
                    if (props.rest.closeModal) {
                        props.rest.closeModal();
                        setVariacoes([]);
                    }
                }}
            >
                <img src={Fechar} alt="" />
            </button>
            {loadingItensVoucher ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : (
                <>
                    <div className="table">
                        <Table columns={columns} data={data} pageSize={30} />
                    </div>
                </>
            )}
        </Itens>
    );
}
