import Vimeo from "@u-wave/react-vimeo";
import { VideoStyles } from "./styles";
import imgX from "../../assets/sistema/fechar-modal-branco.svg";
interface ModalVideoProps {
    rest: {
        video: string;
        modalVideo: boolean;
        closeModal?: () => void;
    };
}

export function ModalVideo(props: ModalVideoProps) {
    const { rest } = props;

    const video = rest.video;

    if (video && rest.modalVideo) {
        return (
            <VideoStyles>
                <button
                    onClick={() => {
                        if (props.rest.closeModal) {
                            props.rest.closeModal();
                        }
                    }}
                >
                    <img src={imgX} alt="" />
                </button>
                <Vimeo
                    // src={video + "?embedparameter=value"}
                    video={video ? video : ""}
                    autoplay
                ></Vimeo>
            </VideoStyles>
        );
    }

    return <></>;
}
