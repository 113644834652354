import styled from "styled-components";

export const Container = styled.div`
    .container-passo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        p {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: ${({ theme }) => theme.colors.black};
            padding-left: 18px;
            position: relative;
            &:before {
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                background-color: ${({ theme }) => theme.colors.primary};
                position: absolute;
                top: 50%;
                left: 0px;
                transform: translateY(-50%);
                border-radius: 50%;
            }
        }
    }
`;
