import { Heading, Flex } from "aplication-yetz";
import { HeaderStyles, Destaque, BtnPasso } from "./styles";

import img from "../../assets/sistema/passo-branco.svg";
import img1 from "../../assets/sistema/alerta-circulo.svg";
import img2 from "../../assets/sistema/seta-dir.svg";

interface HeaderProps {
    setModal: (value: boolean) => void;

    nome: string | null;
    slug: string | null;
    setModalTutorial: (value: boolean) => void;
}

export function Header(props: HeaderProps) {
    const { nome, setModalTutorial } = props;
    const width = window.screen.width;

    // function openLink(link: string) {
    //     if (link) {
    //         window.open(`/passo-a-passo/${link}`);
    //     }
    // }

    return (
        <HeaderStyles>
            <Heading as="h3" fontSize="mdl">
                {width < 480 ? "Vale presente " : ""}
                {nome ? nome : ""}
            </Heading>

            <Flex className="topoPasso" alignItems="center">
                <Destaque>
                    <img src={img1} alt="" />
                    <span>ACESSE ANTES DE USAR</span>
                    <img src={img2} alt="" className="seta" />
                </Destaque>

                <BtnPasso onClick={() => setModalTutorial(true)}>
                    <img src={img} alt="" />
                    <span>Passo a Passo para uso do Voucher</span>
                </BtnPasso>
            </Flex>
        </HeaderStyles>
    );
}
