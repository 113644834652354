import { useState } from "react";
import imgSetaEsq from "../../../assets/sistema/seta-esq-vermelha.svg";

import Modal from "../../ModalAlert";
import { Container } from "./styles";
import { useLogEstoque } from "../../../Hook/useLogEstoque";
import { useHistory } from "react-router-dom";

export function Header() {
    const [modalAprovar, setModalAprovar] = useState(false);
    const [modalReprovar, setModalReprovar] = useState(false);
    const { aprovarCarga, reprovarCarga } = useLogEstoque();
    const history = useHistory();
    function handleCloseAprovar() {
        setModalAprovar(false);
    }

    function handleCloseReprovar() {
        setModalReprovar(false);
    }

    return (
        <>
            <Container>
                <div className="container-title">
                    <button onClick={() => history.goBack()}>
                        <img src={imgSetaEsq} alt="" />
                    </button>
                </div>

                <div className="container-btns">
                    <button onClick={() => setModalReprovar(true)}>
                        excluir carga
                    </button>
                    <button
                        onClick={() => setModalAprovar(true)}
                        className="aprovar"
                    >
                        aprovar carga
                    </button>
                </div>
            </Container>
            <Modal
                handleClose={handleCloseAprovar}
                reject={handleCloseAprovar}
                aceept={async () => {
                    await aprovarCarga();
                }}
                title="Aprovar carga"
                description="Está ação é irreversível"
                open={modalAprovar}
            />

            <Modal
                handleClose={handleCloseReprovar}
                reject={handleCloseReprovar}
                aceept={async () => {
                    await reprovarCarga();
                }}
                title="Reprovar carga"
                description="Está ação é irreversível"
                open={modalReprovar}
            />
        </>
    );
}
