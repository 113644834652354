import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface AlertDialogProps {
    handleAccpet: () => void;
    handleClose: () => void;
    open: boolean;
}

export function AlertDialog(props: AlertDialogProps) {
    const { handleAccpet, handleClose, open } = props;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Atenção</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Está ação é irreversível!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleAccpet} autoFocus>
                    ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}
