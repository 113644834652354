import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    grid-column: span 2;
    padding-top: 19px;
    margin-bottom: 22px;
    border-top: 1px solid #e1e1e1;
    cursor: pointer;
    &.disabled {
        opacity: 0.5;
        pointer-events: none;
        transition: all 0.5s;
        cursor: not-allowed;
    }
    opacity: 1;
    pointer-events: all;
    transition: all 0.5s;
    a,
    button {
        display: flex;
        align-items: center;
        justify-content: space-between !important;
        margin: 0px !important;
        padding-left: 13px !important;
        padding-right: 29px !important;
        border-radius: 4px;
        width: 100%;
        height: 39px;
        background: #f17e21;
        .container-text-img {
            display: flex;
            align-items: center;
        }

        .seta-dir {
            display: flex;
            align-items: flex-end;
        }

        p {
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-left: 12px;
            margin-bottom: 0 !important;
        }
    }
`;
