import { Box, Text, ButtonSystem } from "aplication-yetz";
import { Field, ErrorMessage, FieldProps } from "formik";
import { Input } from "../Input";

import setUploadeImg from "../../assets/sistema/seta-upload.svg";
import closeImg from "../../assets/sistema/close.svg";

interface FormularioProps extends FieldProps {
    setPreview: (active: boolean) => void;
    setFornecedor: any;
    modalActive?: boolean;
}

export function Formulario(props: FormularioProps) {
    const { field, form, setPreview } = props;
    const { setFieldValue } = form;
    const nome = field.value?.name;

    return (
        <>
            <Box width="229px">
                <Field component={Input} name="nome" label="CARGA VERIFICADO" />
                <ErrorMessage component="small" name="nome" />
            </Box>

            <div className="container-btns">
                <div className="label-arquivo">
                    <label
                        htmlFor="file-produto-verificado"
                        className="btn-upload"
                    >
                        {!nome ? <img src={setUploadeImg} alt="" /> : null}
                        <Text color="primary" ellipsis={true} as="p">
                            {nome ? nome : "SELECIONE O ARQUIVO"}
                        </Text>
                    </label>
                    {nome ? (
                        <button
                            type="button"
                            onClick={(e) => {
                                e.stopPropagation();
                                setFieldValue("produtos", "");
                            }}
                        >
                            <img src={closeImg} alt="" />
                        </button>
                    ) : (
                        <></>
                    )}
                </div>

                <input
                    type="file"
                    name="file-produto-verificado"
                    id="file-produto-verificado"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) => {
                        setFieldValue(
                            "produtos",
                            e.target.files?.length ? e.target.files[0] : null
                        );
                    }}
                />

                <ButtonSystem
                    background={`${nome ? "primary" : "gray200"}`}
                    type="button"
                    color="white"
                    className={`${nome ? "" : "desativado"}`}
                    onClick={() => setPreview(true)}
                >
                    ENVIAR
                </ButtonSystem>
            </div>
        </>
    );
}
