import { toast } from "react-toastify";

import { ModalEmail } from "../../ValePresente/modalEmail";
import { ModalWhatsapp } from "../../ValePresente/modalWhatsapp";
import { Modal } from "../../../components/Modal";

import { formatCurrentMoney, formatDateIso } from "../../../util/helper";
import { Footer as FooterDesk } from "../../../components/Footer";
import { ResponseVoucher } from "../../../pages/MultiplosVoucherGrandCru";
import { Loader } from "../../Loader";
// import { Footer } from "./footer";
import { Footer } from "../../../components/Footer";

import { Container } from "./styles";

import imgCopy from "../../../assets/sistema/copy-gran-gru-itau.svg";
import imgEditar from "../../../assets/sistema/editar-gran-cru.svg";
import imgEmail from "../../../assets/sistema/email.svg";
import imgWhats from "../../../assets/sistema/whats.svg";
import imgGranGruItau from "../../../assets/sistema/present-gran-gru-itau.svg";
import { useEffect, useRef, useState } from "react";
// import { ButtonVoucherPDFv2 } from "../../ButtonVoucherPDF/ButtonVoucherPDFV2";

interface UniclassSemValorProps {
    setModalTutorial: (value: boolean) => void;
    voucher: ResponseVoucher;
    loading: boolean;
    name?: string;
}

export function MvGrandGruItauShop(props: UniclassSemValorProps) {
    const [modalEmail, setModalEmail] = useState(false);
    const [modalWhatsap, setModalWhatsapp] = useState(false);
    const [link, setLink] = useState("");
    const componentRef = useRef<any>();
    const width = window.screen.width;

    const codigo = props?.voucher?.voucher
        ? props.voucher?.voucher[0]?.codes[0]
        : ("" as string);

    const configWhats = {
        onClose: () => setModalWhatsapp(false),
        voucher: props.voucher,
    };

    const configEmail = {
        onClose: () => setModalEmail(false),
        voucher: props.voucher,
    };
    const data = props?.voucher?.voucher
        ? formatDateIso(props?.voucher?.voucher[0].date || "")
              .split("-")[0]
              .replace(/\./g, "/")
        : "";

    const validade = props?.voucher?.voucher
        ? props.voucher?.voucher[0].validade
        : "";

    function copy(text: string) {
        const el = document.createElement("textarea");
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        toast.success(`Copiado`);
        return "Texto copiado com sucesso!";
    }

    function openLink() {
        window.location.href =
            "itaupersonnalite://itau_shop?route=/collection/vinhos-grand-cru-mv-person";
    }

    useEffect(() => {
        const link =
            props?.voucher.voucher
                ?.map((item) => item.codes)
                .join(",")
                .replace(/,/g, ".") || "";

        setLink(link);
    }, [props?.voucher?.voucher]);

    return (
        <Container
            ref={componentRef}
            bgColor="#000d3c"
            className="voucher-link"
        >
            {props.loading ? (
                <div className="loader">
                    <Loader />
                </div>
            ) : (
                <>
                    <div className="container">
                        <div className="container-text">
                            <h3>MINHAS VANTAGENS</h3>
                        </div>

                        <div className="container-par">
                            <h5>
                                {formatCurrentMoney(
                                    parseFloat(
                                        props?.voucher?.voucher
                                            ? props?.voucher?.voucher[0].valor +
                                                  ""
                                            : ""
                                    )
                                )}{" "}
                                de crédito para vinhos <br />
                                Grand Cru no Itaú Shop
                            </h5>
                        </div>

                        <div className="card-retangulo grandCru">
                            <div className="container-voucher">
                                <div className="container-title">
                                    <div className="container-img">
                                        <img src={imgGranGruItau} alt="" />
                                    </div>

                                    <h5>Benefício Resgatado</h5>
                                </div>

                                <div className="container-description">
                                    <h5>Copie o código abaixo para usar:</h5>
                                </div>

                                <div className="item">
                                    <p>{codigo}</p>

                                    <button onClick={() => copy(link)}>
                                        <img src={imgCopy} alt="" />
                                        Copiar
                                        <br />
                                        código
                                    </button>
                                </div>

                                {validade === "VENCIDO" && (
                                    <small>Vencido em: {data}</small>
                                )}
                                {validade === "VENCIMENTO" && (
                                    <small>Vencimento em: {data}</small>
                                )}
                                {validade !== null &&
                                validade !== "VENCIDO" &&
                                validade !== "VENCIMENTO" ? (
                                    <small>
                                        Validade: {validade} dias a partir de{" "}
                                        {data}
                                    </small>
                                ) : null}
                                {validade === null && (
                                    <small className="campo-amarelo">
                                        Validade: {data}
                                    </small>
                                )}

                                <div className="container-btn-ir-para-app">
                                    <button onClick={openLink}>
                                        Ir para o app
                                    </button>
                                </div>

                                <div className="btn-voucher">
                                    <button
                                        onClick={() =>
                                            props.setModalTutorial(true)
                                        }
                                    >
                                        <img src={imgEditar} alt="" />
                                        <small>Saiba como usar</small>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="container-compartilhar">
                            <p className="text-comaprtilhe">
                                Aproveite e Compartilhe
                            </p>
                            {/* <ButtonVoucherPDFv2 currentRef={componentRef} /> */}

                            <div className="container-btns">
                                <button
                                    className="whats"
                                    onClick={() => setModalWhatsapp(true)}
                                >
                                    <img src={imgWhats} alt="" />
                                    WhatsApp
                                </button>
                                <button
                                    className="email"
                                    onClick={() => setModalEmail(true)}
                                >
                                    <img src={imgEmail} alt="" />
                                    E-mail
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <Modal
                component={ModalWhatsapp}
                active={modalWhatsap}
                rest={configWhats}
            />
            <Modal
                component={ModalEmail}
                active={modalEmail}
                rest={configEmail}
            />

            {width > 1280 ? <FooterDesk /> : <Footer />}
        </Container>
    );
}
