import { TextImg } from "aplication-yetz";
import { FieldProps } from "formik";
import imgCamera from "../../assets/sistema/camera.svg";
import { useProduto } from "../../Hook/useProduto";
import { BoxGalery } from "./boxGalery";

interface GaleryProps extends FieldProps {}
export function Galery(props: GaleryProps) {
    const { AddItemGaleria, galeria } = useProduto();

    return (
        <>
            <label
                htmlFor="galery"
                aria-disabled
                className={`${galeria.length >= 5 ? "disabled" : ""}`}
            >
                <TextImg
                    mt="39px"
                    color="primary"
                    className="text-img"
                    img={imgCamera}
                >
                    Galeria
                </TextImg>
            </label>

            <input
                type="file"
                id="galery"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => {
                    if (e.target.files?.length) {
                        AddItemGaleria(e.target.files);
                    }
                }}
            />

            <div className="galeria">
                {galeria.map((item, key, arr) => (
                    <BoxGalery {...item} arrayGalery={arr} />
                ))}
            </div>
        </>
    );
}
