import { useState } from "react";
import { Flex, ButtonSystem } from "aplication-yetz";

import { Check } from "../../components/Check";
import { usePedido } from "../../Hook/usePedido";

import imgFechar from "../../assets/sistema/fechar-modal.svg";

import { ModalStyles } from "./styles";
import { Filtro } from "./header";
import { toast } from "react-toastify";

interface ModalFilterProps {
    rest: {
        filtro: Filtro[];
        onClose: () => void;
        updateFiltro: (filtro: Filtro[]) => void;
    };
}

export function ModalFilter(props: ModalFilterProps) {
    const { rest } = props;
    const { filtro } = rest;
    const { loadPedido } = usePedido();
    const [initialFiltro, setInitialFiltro] = useState<Filtro[]>(filtro);
    const [url, setUrl] = useState("");
    const [checkItens, setCheckItens] = useState<boolean>(false);

    function handleFiltro(item: Filtro) {
        const newFiltro = initialFiltro.map((filtro) => {
            if (filtro.text === item.text) {
                filtro.active = !filtro.active;
            }

            return filtro;
        });

        const checkHasItem = newFiltro.every((item) => item.active === false);

        setInitialFiltro(newFiltro);

        if (checkHasItem) {
            setCheckItens(true);
            toast.error("Selecione ao menos um ano");
        } else {
            setCheckItens(false);
            setUrl(
                newFiltro
                    .reduce((acc, item) => {
                        return (acc += item.active
                            ? `years[]=${item.text}&`
                            : "");
                    }, "?")
                    .replace(/&$/g, "")
            );
            rest.updateFiltro(newFiltro);
        }
    }

    function filtroAno() {
        if (url.length > 2) {
            loadPedido(url);
            if (props.rest.onClose) {
                props.rest.onClose();
            }
        }
    }

    return (
        <ModalStyles>
            <Flex className="header-modal-filtro">
                <h3>Filtros</h3>
                <button
                    onClick={() => {
                        if (checkItens) {
                            toast.error("Selecione ao menos um ano");
                        } else {
                            props.rest.onClose();
                        }
                    }}
                >
                    <img src={imgFechar} alt="fechar" />
                </button>
            </Flex>

            <p>ANO</p>
            <Flex className="filtro">
                {initialFiltro.map((item) => (
                    <Check {...item} onclick={() => handleFiltro(item)} />
                ))}
            </Flex>

            {!checkItens ? (
                <ButtonSystem onClick={() => filtroAno()}>FILTRAR</ButtonSystem>
            ) : null}
        </ModalStyles>
    );
}
