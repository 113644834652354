import * as Yup from "yup";

const regexPhone = /^\([0-9]{2}\) [0-9]{4,5}-[0-9]{4}$/;

export interface WhatsApp {
    phone: string;
}

export const whatsApp: WhatsApp = {
    phone: "",
};

export const validationWhatsApp = Yup.object().shape({
    phone: Yup.string()
        .matches(regexPhone, "Telefone inválido")
        .required("Telefone é obrigatório"),
});

export interface Email {
    email: string;
}

export const email: Email = {
    email: "",
};

export const validationEmail = Yup.object().shape({
    email: Yup.string()
        .email("E-mail inválido")
        .required("E-mail é obrigatório"),
});
