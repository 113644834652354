import { Flex, Text, VariantColors } from "aplication-yetz";
import { daysBetween } from "../../util/helper";
import { Container } from "./styles";
import { TextProps } from "./types";
import calendarioImg from "../../assets/sistema/calendario-estoque.svg";

export function Validade(props: TextProps) {
    const {
        text,
        vencido = false,
        outlet = false,
        pre_outlet = false,
        valido = false,
        validade,
    } = props;

    let color: VariantColors = "primary";
    let descricao = "";

    if (vencido) {
        color = "gray700";
        descricao = `Vencido`;
    }
    if (outlet) {
        color = "orange400";
        descricao = `${
            validade ? daysBetween(validade) + " dias de outlet" : ""
        } `;
    }
    if (pre_outlet) {
        color = "orange200";
        descricao = "Vencimento Próximo";
    }
    if (valido) {
        color = "green300";
        descricao = "Dentro da Validade";
    }

    return (
        <Container color={color}>
            <Flex flexDirection="column" height="100%" alignItems="start">
                <Text color={color} fontSize="sm" bold={true}>
                    {text}
                </Text>
                <Text color="gray700">{descricao}</Text>

                <img src={calendarioImg} alt="" />
            </Flex>
        </Container>
    );
}
