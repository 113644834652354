import { Box } from "aplication-yetz";
import { HeaderStyles } from "./styles";
import logoImg from "../../assets/sistema/logo-vinho.svg";

export function Header() {
    return (
        <HeaderStyles>
            <div className="container">
                <Box>
                    <img src={logoImg} alt="" />
                </Box>
            </div>
        </HeaderStyles>
    );
}
