import { useState } from "react";
import { ButtonSystem } from "aplication-yetz";
import { Formik, Field, Form, ErrorMessage, FormikProps } from "formik";
import { Input, InputMask } from "../../Input";
import { useFornecedor } from "../../../Hook/useFornecedor";
import { initialValues, schema, Fornecedor } from "./config";
import { Container } from "./styles";

export function Add() {
    const { addFornecedor } = useFornecedor();
    const [loading, setLoading] = useState<boolean>(false);
    return (
        <Container>
            <h1>Novo Fornecedor</h1>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={schema}
                onSubmit={async (values) => {
                    values = {
                        ...values,
                        cnpj: values.cnpj.replace(/\D/g, ""),
                    };
                    setLoading(true);
                    await addFornecedor(values);
                    setLoading(false);
                }}
            >
                {(props: FormikProps<Fornecedor>) => {
                    const { isValid } = props;
                    return (
                        <Form>
                            <div className="container-form">
                                <Field
                                    component={Input}
                                    name="nome"
                                    label="Nome"
                                />
                                <ErrorMessage component="small" name="nome" />
                            </div>
                            <div className="container-form">
                                <Field
                                    component={Input}
                                    name="descricao"
                                    label="Descrição"
                                />
                                <ErrorMessage
                                    component="small"
                                    name="descricao"
                                />
                            </div>

                            <div className="container-form">
                                <Field
                                    component={InputMask}
                                    mask="99.999.999/9999-99"
                                    name="cnpj"
                                    label="CNPJ"
                                />
                                <ErrorMessage component="small" name="cnpj" />
                            </div>

                            <ButtonSystem
                                mt="42px"
                                width="210px"
                                loading={loading}
                                heightSpiner={18}
                                widthSpnier={18}
                                // @ts-ignore
                                disabled={!isValid}
                            >
                                Cadastrar Fornecedor
                            </ButtonSystem>
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
}
