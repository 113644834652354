import { Table } from "aplication-yetz";
import { HistorioCargaById } from "../../Hook/useEstoque";
import {
    formatCurrentMoney,
    filterDetalheCarga,
    formatCurrentYETZ,
} from "../../util/helper";
import { columnModal } from "./config";
import { StylesTableDetalhes } from "./styles";

interface TabelaModalProps {
    data?: HistorioCargaById[] | undefined;
    filtro: string;
}

export function TableModal(props: TabelaModalProps) {
    const { data = [], filtro } = props;

    const arrMap = data
        .filter((item) => !!item.produto)
        .map((item) => {
            const { id } = item;

            const dataModal = {
                img: item.produto?.foto_capa,
                id,
                nome: item.produto?.nome,
                vencimento: item.total,
                valor_em_reais: formatCurrentMoney(
                    parseFloat(item.valor_reais)
                ),
                valor_em_yetz: `${formatCurrentYETZ(
                    parseFloat(item.valor_yetz)
                )} YETZ`,
            };

            return dataModal;
        });

    const arrFiltrado = filterDetalheCarga(arrMap, filtro);

    const dados = arrFiltrado.map((item) => {
        return {
            img: (
                <div className="container-img">
                    <img src={item.img} alt="" />
                </div>
            ),
            id: item.id,
            nome: item.nome,
            vencimento: item.vencimento,
            valor_em_reais: item.valor_em_reais,
            valor_em_yetz: item.valor_em_yetz,
        };
    });

    return (
        <StylesTableDetalhes>
            <Table data={dados} columns={columnModal} />
        </StylesTableDetalhes>
    );
}
