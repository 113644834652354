import { useState } from "react";
import { useEstoque } from "../../Hook/useEstoque";
import { Loader } from "../Loader";
import { Header } from "./header";
import { TableCargas } from "./table";

export function HistoricoCargas() {
    const [filtro, setFiltro] = useState("");
    const { loadingCargas } = useEstoque();
    return (
        <>
            <Header setFiltro={setFiltro} />
            {loadingCargas ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : (
                <TableCargas filtro={filtro} />
            )}
        </>
    );
}
