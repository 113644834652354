import { ISelos } from "./selos";
import imgLojaVirtual from "../../assets/sistema/selo-virtual.svg";
import { Variacao } from "./buttonVaricao";

export function LojaVirtual(props: Omit<ISelos, "selo">) {
    const { link, variacao, templateId } = props;

    // if (variacao) {
    //     return <Variacao title={variacao} descricao="Loja Virtual e APP" />;
    // }

    if (templateId !== 1) {
        return <Variacao title={variacao || ""} descricao="Loja Virtual" />;
    }
    return (
        <>
            <h3>VOCÊ PODERÁ UTILIZAR ESSE VOUCHER DIGITAL</h3>
            <button
                onClick={() => {
                    if (link) {
                        window.open(link);
                    }
                }}
            >
                <img src={imgLojaVirtual} alt="" />
                <span>Loja Virtual</span>
            </button>
        </>
    );
}
