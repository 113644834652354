import { Goback } from "../../components/Goback";

import { HeaderStyles } from "./styles";

export function Header() {
    return (
        <HeaderStyles>
            <Goback title="Detalhes Plataforma" />
        </HeaderStyles>
    );
}
