import { UsePlataformaProvider } from "../../Hook/usePlataforma";
import { DetalhePlataforma } from "../../components/DetalhePlataforma";

export function PageDetalhePlataforma() {
    return (
        <UsePlataformaProvider>
            <DetalhePlataforma />
        </UsePlataformaProvider>
    );
}
