import styled from "styled-components";

interface ContainerTableProps {
    height?: string;
}
export const ContainerTable = styled.div<ContainerTableProps>`
    button {
        &.btn-bloqueado {
            width: 30px;
            height: 30px;
            span {
                &:before {
                    left: 10px;
                }
            }
        }
    }
    &.curso {
        .table {
            .tr {
                &.body {
                    cursor: pointer;
                }
            }
        }
    }
    .acoes {
        display: block;
        width: 23.01px;
        height: 23.01px;
        padding: 0px;
        &:before {
            transform: translateY(-50%);
        }
    }

    .tr {
        &.header {
            margin-bottom: 35.5px;
        }

        &.body {
            .td {
                span {
                    &.link {
                        padding-left: 25px;
                        pointer-events: all;
                        cursor: pointer;
                        &:before {
                            height: 17.01px;
                            left: 11px;
                        }
                    }
                }
            }
        }

        &.tr {
            &.body {
                height: ${(props) => (props.height ? props.height : "56px")};
                .img-card {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                .foto_capa {
                    border-radius: 5px;
                    width: 100%;
                }

                span {
                    pointer-events: none;
                    &.user {
                        &:before {
                            width: 18px;
                            height: 20px;
                            top: 45%;
                            mask-size: contain;
                            background: ${({ theme }) => theme.colors.gray300};
                        }
                    }
                }
                span {
                    &.voucher {
                        &:before {
                            width: 24px;
                            height: 24px;
                            top: 50%;
                            mask-size: contain;
                            background: ${({ theme }) => theme.colors.gray300};
                        }
                    }
                }
            }
        }
    }
`;

export const PaginacaoStyles = styled.div`
    .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        margin-bottom: 16px;
        position: relative;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        .pre {
            margin-right: 10px;
        }

        .next {
            margin-right: 10px;
        }

        .container-input {
            margin: 0px 15px;
            input {
                height: 35px;
                padding: 0px 15px;
                border: 1px solid ${({ theme }) => theme.colors.gray300};
                border-radius: 5px;
            }
        }

        button {
            width: 40px;
            height: 40px;
            background-color: ${({ theme }) => theme.colors.primary};
            color: ${(props) => props.theme.colors.white};
            font-size: 20px;
            border: none;
            border-radius: 4px;
            font-size: 14px;
            line-height: 100%;
            margin-left: 16px;
            cursor: pointer;
            border: 0.900002px solid;
            transition: all 0.5s;
        }
    }
`;
