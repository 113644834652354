import { Flex, Grid } from "aplication-yetz";
import styled from "styled-components";

export const HeaderStyles = styled(Flex)`
    padding-bottom: 50px;
    margin-bottom: 51px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
`;

export const CardsStyles = styled(Grid)`
    padding-bottom: 50px;
    margin-bottom: 70px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
    & > div {
        &:first-child {
            background-color: #d3faff;
        }
    }
`;
export const CardsDetalheStyles = styled(Grid)`
    padding-bottom: 50px;
    margin-bottom: 70px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
`;

export const SubHeaderStyles = styled(Flex)`
    justify-content: space-between;
    margin-bottom: 64px;
    .catalogo {
        &:before {
            width: 28px;
            height: 29px;
        }
    }
`;

export const LoaderStyles = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ContainerNovaPlataforma = styled.div`
    .filtro {
        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
            padding-left: 24px;
            &:before {
                width: 28px;
                height: 29px;
            }
        }
    }

    .ano {
        flex-direction: column;
        margin-right: 64px;
        small {
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: #4b4b4b;
        }

        span {
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            color: #000000;
        }
    }
`;

export const Container = styled.div`
    display: block;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    .btn-detalhe {
        span {
            padding: 0px;
            &:before {
                left: unset;
                right: -30px;
                width: 13px;
                height: 9px;
            }
        }
    }
    .tr {
        &.header {
            &.hide-header {
                display: none !important;
            }
        }
    }
    .container-tables {
        height: 72px;
        overflow: hidden;
        transition: all 0.5s;
        &.active {
            transition: all 0.5s;
        }
    }

    &.editar-acao {
    }

    .status {
        border: none;
        border: 1px solid #1cde90;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        color: #1cde90;
        background-color: transparent;
        cursor: auto;
        &.processando {
            color: #ffa500;
            border: 1px solid #ffa500;
        }

        &.agendada {
            color: #0097d5;
            border: 1px solid #0097d5;
        }

        &.disparada {
        }

        &.rascunho {
            color: #9d9d9d;
            border: 1px solid #9d9d9d;
        }
    }

    .table {
        border-spacing: 0;

        .header {
            background: transparent;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #fff;
            padding-bottom: 24px;
            width: fit-content;
            border-bottom: 2px solid #5f5f5f;
            .th {
                align-self: center;
                overflow-wrap: anywhere;
            }
        }

        .tr {
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 100%;
            color: #4a4a4a;

            width: 100% !important;
            :last-child {
                .td {
                    border-bottom: 0;
                }
            }
            &.body {
                border-radius: 4px;
                font-weight: 600;
                font-size: 14px;
                line-height: 120%;
                position: relative;
                .td {
                    align-self: center;
                    overflow-wrap: anywhere;
                    height: 72px;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #ececec;
                    &.center {
                        text-align: center;
                    }
                    span {
                        &.enviado {
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 100%;
                            color: #1cde90;
                            border: 1px solid #1cde90;
                            box-sizing: border-box;
                            border-radius: 4px;
                            padding: 5px;
                        }
                    }
                }

                .opcoes {
                    position: absolute;
                    right: 10px;
                    top: 45%;
                    cursor: pointer;
                }

                .dropdown-opcoes {
                    background-color: #fff;
                    border-radius: 4px;
                    height: 0px;
                    position: absolute;
                    right: 10px;
                    top: 60%;
                    overflow: hidden;
                    transition: all 0.5s;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    padding: 0 20px;
                    z-index: 1;
                    &.active {
                        overflow: inherit;
                        height: 100px;
                        transition: all 0.5s;
                    }
                    span {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 100%;
                        color: #fefefe;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        img {
                            margin-right: 13px;
                            width: 20px;
                        }
                    }
                }
            }
        }

        .td {
            .visualizado,
            span {
                display: flex;
                justify-content: center;
                img {
                    margin-right: 13px;
                }
            }
        }

        .th,
        .td {
            margin: 0;
            padding: 0.5rem;
            ${
                "" /* In this example we use an absolutely position resizer,
       so this is required. */
            }
            position: relative;

            :last-child {
                border-right: 0;
            }

            .resizer {
                display: inline-block;
                width: 10px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                transform: translateX(50%);
                z-index: 1;
                ${
                    "" /* prevents from scrolling while dragging on touch devices */
                }
                touch-action:none;
            }
        }
    }
`;

export const ContentTable = styled.div`
    background-color: #e6fcff;
    display: flex;
    flex-direction: column;
    .header-table-acordion {
        display: grid;
        grid-template-columns: 1fr 1fr 150px 1fr 1fr 1fr 1fr 105px;
        justify-content: space-around;
        padding: 20px 34px;
        border-bottom: 2px solid #5f5f5f;
        div {
            &:last-child {
                text-align: center;
            }
        }
    }
    .body-table-acordion {
        display: grid;
        grid-template-columns: 1fr 1fr 150px 1fr 1fr 1fr 1fr 105px;
        padding: 20px 34px;
        width: 100%;
        justify-content: space-around;
        position: relative;

        button {
            text-align: center;
        }
        &:last-child {
            &:before {
                opacity: 1;
                height: 2px;
                width: 100%;
                background-color: #5f5f5f;
            }
        }
        &:before {
            content: "";
            display: block;
            width: calc(100% - 40px);
            height: 1px;
            background-color: #9c9c9c33;
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
`;

type BoxCatalogoStylesProps = {
    isSelected: boolean;
};

export const BoxCatalogoStyles = styled.div<BoxCatalogoStylesProps>`
    padding-bottom: 50px;
    margin-bottom: 51px;
    border: ${({ isSelected }) =>
        isSelected ? "3px solid #27ae60" : "1px solid #dfdfdf"};
    border-radius: 8px;
    padding: 24px;
    display: flex;
    align-items: center;
    grid-gap: 16px;
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0.5)};
    cursor: pointer;

    img {
        width: 91px;
    }
`;

export const ConfirmaCatalogo = styled.div`
    position: sticky;
    bottom: -3rem;
    background-color: #fff;
    height: 114px;
    width: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btns {
        grid-gap: 10px;
        .btn-cancelar {
            color: #930035;
            border: 1px solid #930035;
        }
    }
    .qtds-aprovar {
        grid-gap: 35px;
    }
`;
