import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Error } from "../../components/ValePresente/error";
// import { Encerrada } from "../../components/ValePresente/encerrada";
import { Footer } from "../../components/ValePresente/footer";
import { Bloqueado } from "../../components/ValePresente/bloqueado";

import { Modal } from "../../components/Modal";
import { ModalVideo } from "../../components/PassoAPasso/video";
import { ModalTutorial } from "../../components/PassoAPasso/modal";

import { MultiplosVoucher } from "../../components/MultiplosVoucher";
import axios from "axios";
import { useVoucherConfig } from "../../Hook/useVoucherConfig";

export interface ResponseVoucher {
    success: boolean;
    error: string;
    status: string;
    // produto_id?: number;
    voucher?: Voucher[];
}

export interface Voucher {
    date: string | null;
    code: string | null;
    password: string | null;
    descricao: string | null;
    foto_capa: string | null;
    nome: string | null;
    selo: string | null;
    tutorial_observacao: string | null;
    tutorial_passos: string | null;
    tutorial_url: string | null;
    validade: string | null;
    valor: string | null;
    slug: string | null;
    template?: string | null;
    informacoes_importantes: string;
    detalhes: string;
    expires_at: string | null | any;
    variacao?: any;
    external_link: string;
    external_link_label: string;
}

export function PageMultiplosVoucherSandBox() {
    const history = useHistory();
    const { setHashCode } = useVoucherConfig();
    const [voucher, setVoucher] = useState<ResponseVoucher>(
        {} as ResponseVoucher
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [modalVideo, setModalVideo] = useState<boolean>(false);
    const [modalTutorial, setModalTutorial] = useState<boolean>(false);

    const hash_code = history.location.pathname.replace("/n/", "");

    const configProduto = {
        title: "Produtos",
        video: voucher?.voucher?.length
            ? voucher?.voucher[0]?.tutorial_url
            : "",
        tutorial: voucher?.voucher?.length
            ? voucher?.voucher[0]?.tutorial_passos
            : "[]",
        img: voucher?.voucher?.length ? voucher?.voucher[0]?.foto_capa : "",
        tutorialObservacao: voucher?.voucher?.length
            ? voucher?.voucher[0]?.tutorial_observacao
            : "",
        onClose: () => {
            setModalTutorial(false);
            setModalVideo(false);
        },
        openModalVideo: () => {
            setModalTutorial(false);
            setModalVideo(true);
        },
        dados: voucher?.voucher?.length ? voucher?.voucher[0] : {},
    };

    const configVideo = {
        video: voucher?.voucher?.length
            ? voucher?.voucher[0]?.tutorial_url
            : "",
        modalVideo: modalVideo,
        closeModal: () => {
            setModalTutorial(true);
            setModalVideo(false);
        },
        // video: "81db6ab30a",
    };

    function clickOutModal(e: React.MouseEvent<HTMLElement>) {
        const element = (e.target as HTMLElement).dataset;

        if (element?.closemodal) {
            setModalTutorial(false);
            setModalVideo(false);
        }
    }

    useEffect(() => {
        async function checkToken() {
            setLoading(true);
            try {
                const { data } = await axios.post<ResponseVoucher>(
                    `https://yetzestoquesandbox.yetzcards.com.br/api/pedido/check-url-group`,
                    {
                        hash_code,
                    }
                );

                setHashCode(hash_code);

                // if (data.voucher?.[0].template === "LINK") {
                //     if (data.voucher[0]?.code) {
                //         window.open(data.voucher[0]?.code, "_self");
                //         // history.push("/");
                //         return;
                //     }
                // }

                const regexHasUrl =
                    /(http|https):\/\/(www\.)?[a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

                if (
                    regexHasUrl.test(
                        data.voucher?.length ? data.voucher[0].code || "" : ""
                    )
                ) {
                    window.location.href = data.voucher?.length
                        ? data.voucher[0].code || ""
                        : "";
                } else {
                    setVoucher(data);
                }
            } catch (error: any) {
                console.log(error);
            }
            setLoading(false);
        }

        checkToken();
    }, [hash_code, history, setHashCode]);

    return (
        <>
            {voucher.success === false ? (
                <>
                    {voucher?.error === "Link not found" ? (
                        <Error />
                    ) : (
                        <Bloqueado />
                    )}

                    <Footer />
                </>
            ) : (
                <MultiplosVoucher
                    setModalTutorial={setModalTutorial}
                    voucher={voucher}
                    loading={loading}
                />
            )}

            <Modal
                component={ModalTutorial}
                active={modalTutorial}
                rest={configProduto}
                onClick={clickOutModal}
                maxWidthMobile={true}
            />
            <Modal
                onClick={clickOutModal}
                component={ModalVideo}
                active={modalVideo}
                rest={configVideo}
            />
        </>
    );
}
