import { usePlataforma } from "../../Hook/usePlataforma";
import { BoxDetalheProduto } from "../../components/BoxDetalheProduto";
import { BoxDetalheProdutoProps } from "../../components/BoxDetalheProduto/types";
import { formatDateIso } from "../../util/helper";

import img01 from "../../assets/sistema/logo-card-01.svg";
import img07 from "../../assets/sistema/logo-card-07.svg";
import img08 from "../../assets/sistema/logo-card-08.svg";
import img10 from "../../assets/sistema/logo-card-09.svg";

import { CardsStyles } from "./styles";

export function Cards() {
    const { user } = usePlataforma();

    const arrayCard: BoxDetalheProdutoProps[] = [
        {
            img: img01,
            subTitulo: user.id ? user.id + "" : "",
            titulo: "código",
        },
        {
            img: img07,
            subTitulo: user.name ? user.name : "",
            titulo: "YETZ",
        },
        {
            img: img08,
            subTitulo: user.email ? user.email : "",
            titulo: "Yetz Cards",
        },
        {
            img: img10,
            subTitulo: user.created_at ? formatDateIso(user.created_at) : "",
            titulo: "data",
        },
    ];

    return (
        <CardsStyles gridTemplateColumns="repeat(4, 1fr)" gridGap="30px">
            {arrayCard.slice(0, 5).map((item) => (
                <BoxDetalheProduto {...item} />
            ))}
        </CardsStyles>
    );
}
