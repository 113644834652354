import { Text, Heading } from "aplication-yetz";
import { CardStyles, Link } from "./styles";

import imgSeta from "../../assets/sistema/seta-dir.svg";

interface CardProps {
    nome: string;
    categoria: string;
    foto_capa: string;
    tutorial_passos: string;
    tutorial_observacao: string;
    tutorial_url: string;
    external_link: string;
    setModalTutorial: (active: boolean) => void;
    setTutorial: (tutorial: string) => void;
    setVideo: (tutorial: string) => void;
    setLink: (link: string) => void;
    setImgVoucher: (tutorial: string) => void;
    setTutorialObservacao: (tutorial: string) => void;
}

export function Card(props: CardProps) {
    const {
        nome,
        categoria,
        foto_capa,
        tutorial_observacao,
        setModalTutorial,
        setTutorial,
        setVideo,
        tutorial_passos,
        tutorial_url,
        setImgVoucher,
        setTutorialObservacao,
        setLink,
        external_link,
    } = props;

    return (
        <CardStyles>
            <div className="container-text-img">
                <div className="container-img">
                    <img src={foto_capa} alt="" />
                </div>

                <div className="container-text">
                    <Heading fontSize="mdl" as="h4">
                        {nome}
                    </Heading>
                    <Text color="gray300" mb="24px">
                        {categoria}
                    </Text>
                </div>
            </div>

            <Link
                onClick={() => {
                    setModalTutorial(true);
                    setTutorial(tutorial_passos);
                    setVideo(tutorial_url);
                    setLink(external_link);
                    setImgVoucher(foto_capa);
                    setTutorialObservacao(tutorial_observacao);
                }}
            >
                Acessar Tutorial
                <img src={imgSeta} alt="" />
            </Link>
        </CardStyles>
    );
}
