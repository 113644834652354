import styled, { DefaultTheme } from "styled-components";
import { theme, VariantColors } from "aplication-yetz";

interface styledProps {
    status: VariantColors;
}

interface ThemedProps extends styledProps {
    theme: DefaultTheme;
}

const getColor = ({ status, theme }: ThemedProps) => {
    return theme.colors[status];
};

export const Container = styled.span<styledProps>`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: ${theme.colors.black};
    display: block;
    position: relative;
    width: fit-content;
    &:before {
        content: "";
        display: block;
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        top: 50%;
        left: -15px;
        transform: translateY(-50%);
        background-color: ${getColor};
    }
`;
