// import { HTMLElement } from "react";
import { Text, Flex } from "aplication-yetz";
import { List, ListRowRenderer } from "react-virtualized";
import { Link } from "react-router-dom";
import { TableMes } from "../../Hook/useDetalhePedidoPlataforma";
import {
    formatDateIso,
    formataCounter,
    formatCurrentMoney,
} from "../../util/helper";

import imgCadeado from "../../assets/sistema/cadeado.svg";

import { ContentTable } from "./styles";

interface ContentSubTableProps {
    content: TableMes[];
}

export function ContentSubTable(props: ContentSubTableProps) {
    const { content } = props;
    const width =
        (document.querySelector(".content") as HTMLElement).offsetWidth - 122;

    const rowRender: ListRowRenderer = ({ index, key, style }) => {
        return (
            <div key={key} style={style}>
                <Link
                    to={{
                        pathname: `/pedidos/detalhe/${content[index].id}`,
                        state: {
                            id: content[index].id,
                        },
                    }}
                >
                    <Body {...content[index]} />
                </Link>
            </div>
        );
    };

    return (
        <ContentTable>
            <Header />

            <List
                height={content.length * 63 > 500 ? 500 : content.length * 63}
                width={width}
                rowHeight={63}
                overscanRowCount={1}
                rowCount={content ? content?.length : 0}
                rowRenderer={rowRender}
            />
        </ContentTable>
    );
}

export function Header() {
    return (
        <Flex className="header-table-acordion">
            <Text fontWeight="bold" ml="-10px">
                Pedido
            </Text>
            <Text fontWeight="bold" ml="-10px">
                data / hora
            </Text>
            <Text fontWeight="bold" ml="-10px">
                QTd.
            </Text>
            <Text fontWeight="bold" ml="-10px">
                VALOR TOTAL
            </Text>
            <Text fontWeight="bold" ml="-10px">
                JOB
            </Text>
            <Text fontWeight="bold" ml="-10px">
                Status
            </Text>
            <Text width="16px" textAlign="center" ml="-15px" fontWeight="bold">
                ações
            </Text>
        </Flex>
    );
}

interface BodyProps extends TableMes {}
export function Body(props: BodyProps) {
    const {
        id,
        created_at,
        total_vouchers,
        total_vouchers_reais,
        job,
        status,
    } = props;

    return (
        <div className="body-table-acordion">
            <Text>{id}</Text>
            <Text>{formatDateIso(created_at)}</Text>
            <Text>{formataCounter(total_vouchers + "")}</Text>
            <Text>{formatCurrentMoney(parseFloat(total_vouchers_reais))}</Text>
            <Text>{job ? job : ""}</Text>
            <Text>{status}</Text>
            <button>
                <img src={imgCadeado} alt="" />
            </button>
        </div>
    );
}
