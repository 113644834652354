import { useEffect, useState } from "react";
import { Table, Flex } from "aplication-yetz";
import { Variacao } from "../../Hook/useProduto";
import { ContainerTable } from "../tables/styles";
import {
    formatCurrentMoney,
    filterVariacao,
    formatCurrentYETZ,
} from "../../util/helper";
import { column } from "./config";
import { Data } from "./type";
import { AlertaEstoque } from "./alertaEstoque";
import { Disponibilidade } from "./disponibilidade";
import { StatusAlertaEstoque } from "./statusAlertaEstoque";

import editarImg from "../../assets/sistema/editar-vinho.svg";
import excluirImg from "../../assets/sistema/excluir.svg";
import { Permissions } from "../Permission";

interface TabelaProps {
    variacaoes: Variacao[];
    filtro: string;
    setInitalValuesVariacao: (variacao: Variacao) => void;
    setModalVariacao: (modal: boolean) => void;
    setModalExclusao: (modal: boolean) => void;
    setIdExclusaoVariacao: (id: number) => void;
}

export function Tabela(props: TabelaProps) {
    const {
        variacaoes,
        filtro,
        setInitalValuesVariacao,
        setModalVariacao,
        setModalExclusao,
        setIdExclusaoVariacao,
    } = props;

    const [dados, setDados] = useState<Variacao[]>([]);

    const arrFilter = filterVariacao(dados, filtro);

    const data: Data[] = arrFilter.map((variacao) => {
        const {
            id,
            valor_reais,
            alerta_estoque,
            status,
            status_alerta_estoque,
        } = variacao;

        return {
            cod_variacao: id,
            valor_em_reais: formatCurrentMoney(parseFloat(valor_reais)),
            valor_em_yetz: `${formatCurrentYETZ(variacao.valor_yetz)} YETZ`,
            alerta_de_estoque: (
                <Permissions role="produto.variacao.update">
                    <AlertaEstoque alerta_estoque={alerta_estoque} id={id} />
                </Permissions>
            ),
            status_alerta_estoque: (
                <Permissions role="produto.variacao.update">
                    <StatusAlertaEstoque
                        status_alerta_estoque={status_alerta_estoque}
                        id={id}
                    />
                </Permissions>
            ),
            disponibilidade: (
                <Permissions role="produto.variacao.update">
                    <Disponibilidade status={status} id={id} />
                </Permissions>
            ),

            acoes: (
                <Flex width="75px" justifyContent="space-between">
                    <Permissions role="produto.variacao.update">
                        <button
                            onClick={() => {
                                setInitalValuesVariacao(variacao);
                                setModalVariacao(true);
                            }}
                        >
                            <img src={editarImg} alt="" />
                        </button>
                    </Permissions>

                    <Permissions role="produto.variacao.destroy">
                        <button
                            onClick={() => {
                                setModalExclusao(true);
                                setIdExclusaoVariacao(id);
                            }}
                        >
                            <img src={excluirImg} alt="" />
                        </button>
                    </Permissions>
                </Flex>
            ),
        };
    });

    useEffect(() => {
        setDados(variacaoes);
    }, [variacaoes]);

    return (
        <ContainerTable>
            <Table data={data} columns={column} />
        </ContainerTable>
    );
}
