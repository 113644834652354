import { ContainerYetz } from "./styles";
import { Card } from "./card";
import { TableVouchersExpirados, Detalhes } from "./table";
import { useCallback, useEffect, useRef, useState } from "react";
import { Vouchers } from "../../Hook/useVoucher";
import { api } from "../../services";

export interface Active {
    link: boolean;
    promo: boolean;
    cards: boolean;
    mes: boolean;
}

interface YetzProps {
    closeAllTabs: boolean;
    voucher: Vouchers[];
    mes: Vouchers;
    yetzlink: Vouchers;
    yetzpromo: Vouchers;
    yetzcards: Vouchers;
}

type UpdateActive<T> = keyof T;

export function Yetz(props: YetzProps) {
    const { mes, yetzcards, yetzlink, yetzpromo } = props;
    const refItens = useRef<HTMLDivElement[]>([]);
    const [detalhes, setDetalhes] = useState<Detalhes[]>([]);
    const [active, setActive] = useState<Active>({
        link: false,
        promo: false,
        cards: false,
        mes: false,
    });

    const handleHeightScroll = useCallback((item: HTMLDivElement) => {
        const height = item?.querySelector("div")?.offsetHeight;
        item.style.height = `${height}px`;
    }, []);

    function handleClearHeight(item: HTMLDivElement[]) {
        item.forEach((el) => {
            el.style.height = `0px`;
        });
    }

    async function updateActive(
        tab: UpdateActive<Active>,
        ano: number,
        mes: number,
        id: number
    ) {
        const objActive: Active = {
            link: false,
            promo: false,
            cards: false,
            mes: false,
        };
        try {
            if (active[tab]) {
                setActive(objActive);
            } else {
                const response = await api.get(
                    `relatorio/vouchers-expirados/details?ano=${ano}&mes=${mes}&user_id=${id}`
                );

                setDetalhes(response.data);

                console.log(response.data);

                setActive({
                    ...objActive,
                    [tab]: true,
                });
            }
        } catch (error) {
            console.log(error);
            setActive(objActive);
        }
    }

    useEffect(() => {
        if (props.closeAllTabs) {
            setActive({
                link: false,
                promo: false,
                cards: false,
                mes: false,
            });
        }
    }, [props.closeAllTabs]);

    useEffect(() => {
        handleClearHeight(refItens.current);

        if (active.mes && refItens.current[0]) {
            handleHeightScroll(refItens.current[0]);
        }
        if (active.link && refItens.current[1]) {
            handleHeightScroll(refItens.current[1]);
        }
        if (active.promo && refItens.current[2]) {
            handleHeightScroll(refItens.current[2]);
        }
        if (active.cards && refItens.current[3]) {
            handleHeightScroll(refItens.current[3]);
        }
    }, [active, handleHeightScroll]);

    return (
        <ContainerYetz>
            <div className="container-mes">
                <Card updateActive={updateActive} active={active} {...mes} />
            </div>

            {props?.yetzlink ? (
                <Card
                    updateActive={updateActive}
                    active={active}
                    {...yetzlink}
                />
            ) : (
                <></>
            )}
            {props?.yetzpromo ? (
                <Card
                    updateActive={updateActive}
                    active={active}
                    {...yetzpromo}
                />
            ) : (
                <></>
            )}
            {props?.yetzcards ? (
                <Card
                    updateActive={updateActive}
                    active={active}
                    {...yetzcards}
                />
            ) : (
                <></>
            )}

            <div
                className={`container-table mes ${active.mes ? "active" : ""}`}
                ref={(el: HTMLDivElement) => (refItens.current[0] = el)}
            >
                <TableVouchersExpirados data={detalhes} />
            </div>

            {props?.yetzlink ? (
                <div
                    className={`container-table link ${
                        active.link ? "active" : ""
                    }`}
                    ref={(el: HTMLDivElement) => (refItens.current[1] = el)}
                >
                    <TableVouchersExpirados
                        data={detalhes.filter(
                            (item) => item.plataforma === "yetzlink"
                        )}
                    />
                </div>
            ) : (
                <></>
            )}

            {props?.yetzpromo ? (
                <div
                    className={`container-table promo ${
                        active.promo ? "active" : ""
                    }`}
                    ref={(el: HTMLDivElement) => (refItens.current[2] = el)}
                >
                    <TableVouchersExpirados
                        data={detalhes.filter(
                            (item) => item.plataforma === "yetzpromo"
                        )}
                    />
                </div>
            ) : (
                <></>
            )}

            {props?.yetzcards ? (
                <div
                    className={`container-table cards ${
                        active.cards ? "active" : ""
                    }`}
                    ref={(el: HTMLDivElement) => (refItens.current[3] = el)}
                >
                    <TableVouchersExpirados
                        data={detalhes.filter(
                            (item) => item.plataforma === "yetzcards"
                        )}
                    />
                </div>
            ) : (
                <></>
            )}
        </ContainerYetz>
    );
}
