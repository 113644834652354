import { ISelos } from "./selos";
import imgLojaFisica from "../../assets/sistema/loja-fisica.svg";
import { Variacao } from "./buttonVaricao";
export function LojaFisica(props: Omit<ISelos, "selo">) {
    const { link, variacao } = props;

    if (variacao) {
        return <Variacao title={variacao} descricao="Loja Física" />;
    }

    return (
        <>
            <>
                <h3>VOCÊ PODERÁ UTILIZAR ESSE VOUCHER DIGITAL</h3>
                <button
                    onClick={() => {
                        window.open(link);
                    }}
                >
                    <img src={imgLojaFisica} alt="" />
                    <span>Loja Física</span>
                </button>
            </>
        </>
    );
}
