import { Menu as MeunuUikit, Text } from "aplication-yetz";
import { toast } from "react-toastify";
import { api } from "../../services/";
import { links } from "./config";
// import logoImg from "../../assets/sistema/logo-sistema.svg";
import logoImg from "../../assets/sistema/logo-novo-vinho.svg";
import { getLocalStore } from "../../util/helper";

export function Menu() {
    const storage = getLocalStore();
    const linksFilter = links.filter((link) => {
        return storage?.permissions[link.persmission];
    });

    async function logout() {
        try {
            await api.post("usuario/logout");
            localStorage.clear();
            window.location.reload();
        } catch (error) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
        }
    }

    return (
        <MeunuUikit
            links={linksFilter}
            logo={logoImg}
            sair={
                <button onClick={() => logout()}>
                    <Text color="primary">Sair</Text>
                </button>
            }
        />
    );
}
