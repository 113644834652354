import { Text, Box } from "aplication-yetz";
import { ToggleProps } from "./types";
import { Container } from "./styles";
import { Loader } from "../../components/Loader";

export interface ChangeYesOrNoProps {
    active: boolean | number;
}

export const ChangeYesOrNo = (props: ChangeYesOrNoProps) => {
    const { active } = props;
    return active ? (
        <Text pl="8px" bold={true}>
            Sim
        </Text>
    ) : (
        <Text pl="8px" bold={true}>
            Não
        </Text>
    );
};

export function Toggle(props: ToggleProps) {
    const { active, onClick, titulo, loading } = props;

    return (
        <Container>
            <div className={`container-loader ${loading ? "active" : ""}`}>
                <Loader height={30} width={30} />

                <div
                    className={`${active === true ? "active" : ""} toggle`}
                    onClick={onClick}
                >
                    <span></span>
                </div>

                <div className="title">
                    {titulo ? (
                        <Box ml="20px">
                            <Text fontWeight="600">{titulo}</Text>
                        </Box>
                    ) : (
                        <ChangeYesOrNo active={active} />
                    )}
                </div>
            </div>
        </Container>
    );
}
