import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { useHistory } from "react-router-dom";
import { containString } from "../util/helper";
import { AxiosResponse } from "axios";
import { Pedido } from "./usePedido";
import { User } from "./useUser";
import { toast } from "react-toastify";
// import { AxiosResponse } from "axios";
import { api } from "../services";

interface UseDetalhePedidoProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface DetalhePedidoData {
    pedidos: DetalhePedidoPlataforma;
    detalhePedido: DetalhePedido;
    getDetalhePedidoPlataforma: (url?: string) => Promise<AxiosResponse>;
    loadingDetalhePedido: boolean;
    handleBloquear: (id: number, status: string) => Promise<AxiosResponse>;
    loadingBloquear: boolean;

    handleCancelar: (id: number) => Promise<AxiosResponse>;
    loadingCancelar: boolean;
}

export interface DetalhePedido {
    created_at: string;
    custom_data: string;
    customer: string;
    customer_id: string;
    id: number;
    postback_url: string;
    status: string;
    updated_at: string;
    user_id: number;
    valor_reais: string;
    valor_yetz: string;
    itens: Itens[];
    user: User;
    hash_file: string | null;
    job: string | null;
}

interface DetalhePedidoPlataforma {
    head: Head[];
    table: Table;
}

export interface Head {
    id: number;
    name: string;
    color: null | string;
    light_color: null | string;
    total_pedidos: number;
    total_vouchers: number;
    total_vouchers_utilizados: number;
    total_vouchers_reais: string;
    total_jobs?: number;
}

export interface Table {
    [key: string]: TableMes[];
}

export interface TableMes {
    id: number;
    created_at: string;
    job: null | string;
    status: string;
    total_vouchers: number;
    total_vouchers_utilizados: number;
    total_vouchers_reais: string;
}

export interface Itens {
    cargas_created_at: string;
    compras_created_at: null | string;
    created_at: string;
    expires_at: null | string;
    file: null | string;
    file_generated_at: null | string;
    fornecedor_c_id: null | string;
    fornecedor_c_nome: null | string;
    fornecedor_code: null | string;
    fornecedor_id: 1;
    fornecedor_nome: string;
    foto_capa: string;
    generated_at: null | string;
    hash_code: string;
    id: number;
    last_log: LastLog;
    oc: null | string;
    pedido_estoque_id: number;
    pedido_id: number;
    pedido_item_id: null | string;
    produto_estoque_id: number;
    produto_id: number;
    produto_validade: null | string;
    produto_variacao_id: number;
    status: string;
    updated_at: string;
    valor_reais: string;
    valor_yetz: string;
    vencimento: string;

    produto: Produto;
}

interface LastLog {
    conteudo: null | string;
    created_at: string;
    descricao: string;
    id: number;
    meta_info: string;
    pedido_item_id: number;
    status: string;
    updated_at: string;
}

interface Produto {
    foto_capa: string;
}

const UseDetalhePedidoContext = createContext<DetalhePedidoData>(
    {} as DetalhePedidoData
);

export interface HistoryProps {
    id?: number;
}

export function UseDetalhePedidoProvider(props: UseDetalhePedidoProviderProps) {
    const { children } = props;
    const [loadingBloquear, setLoadingBloquear] = useState(false);
    const [loadingCancelar, setLoadingCancelar] = useState(false);
    const history = useHistory<HistoryProps>();
    const id = history.location.state?.id;
    const pathname = history.location.pathname;

    const [pedidos, setPedidos] = useState<DetalhePedidoPlataforma>(
        {} as DetalhePedidoPlataforma
    );
    const [loadingDetalhePedido, setLoadingDetalhePedido] = useState(true);

    const [detalhePedido, setDetalhePedido] = useState<DetalhePedido>(
        {} as DetalhePedido
    );

    const getDetalhePedidoPlataforma = useCallback(
        async (url?: string) => {
            const currentYear = new Date().getFullYear();
            setLoadingDetalhePedido(true);
            try {
                const response: AxiosResponse =
                    await api.get<DetalhePedidoPlataforma>(
                        `pedido/plataforma-pedidos/${id}${
                            url ? `${url}` : "?years[]=" + currentYear
                        }`
                    );

                const { data } = response;

                if (data.table.length === 0) {
                    toast.error("Nenhum pedido encontrado");
                }

                setPedidos(data);
                setLoadingDetalhePedido(false);

                return response.data;
            } catch (error) {}
        },
        [id]
    );

    async function getDetalhePedido(id: number) {
        try {
            const { data } = await api.get<DetalhePedido>(`pedido/${id}`);
            setDetalhePedido(data);
        } catch (error) {
            console.log(error);
        }
    }

    async function handleBloquear(id: number, status: string) {
        setLoadingBloquear(true);
        try {
            const response = await api.post<Pedido>(`pedido/bloquear`, {
                pedido_id: id,
            });

            if (response.status === 200) {
                toast.success(
                    `Pedido ${
                        status === "BLOQUEADO" ? "desbloqueado" : "bloqueado"
                    }  com sucesso!`
                );
            }

            if (history.location.state?.id) {
                await getDetalhePedidoPlataforma();
            }

            if (id) {
                getDetalhePedido(id);
            }

            setLoadingBloquear(false);
            return response;
        } catch (error: any) {
            console.log(error);
            toast.error("Erro ao bloquear pedido!");
            return error;
        }
    }

    async function handleCancelar(id: number) {
        setLoadingCancelar(true);
        try {
            const response = await api.post<Pedido>(`pedido/cancelar`, {
                pedido_id: id,
            });

            if (response.status === 200) {
                toast.success("Pedido cancelado com sucesso!");
            }

            if (history.location.state?.id) {
                await getDetalhePedidoPlataforma();
            }

            if (id) {
                getDetalhePedido(id);
            }

            setLoadingCancelar(false);
            return response;
        } catch (error: any) {
            console.log(error);
            toast.error("Erro ao cancelado pedido!");
            return error;
        }
    }

    useEffect(() => {
        if (containString(pathname, "/plataforma")) {
            getDetalhePedidoPlataforma();
        }

        if (!containString(pathname, "/pedidos/plataforma/") && id) {
            getDetalhePedido(id);
        }
    }, [id, pathname, getDetalhePedidoPlataforma]);

    return (
        <UseDetalhePedidoContext.Provider
            value={{
                pedidos,
                detalhePedido,

                getDetalhePedidoPlataforma,
                loadingDetalhePedido,

                handleBloquear,
                loadingBloquear,

                handleCancelar,
                loadingCancelar,
            }}
        >
            {children}
        </UseDetalhePedidoContext.Provider>
    );
}

export function useDetalhePedido() {
    const context = useContext(UseDetalhePedidoContext);
    return context;
}
