import { useState } from "react";
import { Header } from "./header";
import { Cards } from "./cards";
import { Filtro } from "./filtro";
import { TableDetalhePedido } from "./table";

import { Container } from "./styles";

export function DetalhePedido() {
    const [valueFilter, setValueFilter] = useState("");
    return (
        <Container>
            <Header />
            <Cards />
            <Filtro setValueFilter={setValueFilter} />
            <TableDetalhePedido valueFilter={valueFilter} />
        </Container>
    );
}
