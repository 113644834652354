import { useState } from "react";
import { Table } from "./contentTable";
import {
    formatDateIso,
    formatCurrentMoney,
    filterCompras,
} from "../../util/helper";
import { useCompras } from "../../Hook/useCompras";

import { Column } from "react-table";
import { Loader } from "../Loader";
import { Paginacao } from "../../components/tables/";
import { Modal } from "../../components/Modal";
import { ModalItens } from "./modalItens";

// import imgPc from "../../assets/sistema/image-table.png";
import { Data } from "./types";

interface Tableprops {
    filter: string;
}

function TableCompra(props: Tableprops) {
    const { compras, loadingCompras, paginacaoCompras } = useCompras();

    const [modalItens, setModalItens] = useState(false);

    const columns: Column<Data>[] = [
        {
            Header: "Nº da compra",
            accessor: "n_da_compra",
            width: 100,
        },
        {
            Header: "DATA E HORA",
            accessor: "data_e_hora",
            width: 180,
        },
        {
            Header: "FORNECEDOR",
            accessor: "fornecedor",
        },
        {
            Header: "COMPRA FEITA POR",
            accessor: "compra_feita_por",
        },
        {
            Header: "TÍTULO",
            accessor: "nome",
            width: 150,
        },
        {
            Header: "JOB",
            accessor: "job",
            width: 180,
        },
        {
            Header: "QTD.",
            accessor: "qtd",
            width: 50,
        },
        {
            Header: "Solicitado / Gerado",
            accessor: "solicitado_gerado",
            width: 160,
        },
        {
            Header: "VALOR",
            accessor: "valor",
        },
        {
            Header: "STATUS",
            accessor: "status",
        },
        {
            Header: "Ações",
            accessor: "variacao",
            // width: 68,
        },
    ];

    const arrFilter = filterCompras(props.filter, compras.data) || [];
    const data: Data[] =
        arrFilter.map((data) => {
            return {
                n_da_compra: data.id,
                data_e_hora: data.created_at
                    ? formatDateIso(data.created_at)
                    : "",
                fornecedor: data.fornecedor.nome,
                compra_feita_por: data.user.name,
                nome: data.nome,
                job: data.job,
                qtd: data.itens,
                solicitado_gerado: `${data.itens || ""} / ${
                    data.itens_sucesso || ""
                }`,
                valor: data.valor_total
                    ? formatCurrentMoney(parseFloat(data.valor_total))
                    : "",
                status: data.status,

                variacao: "",
            };
        }) ?? [];

    const nextPage =
        compras?.current_page <= compras?.last_page
            ? compras.current_page + 1
            : compras.current_page;

    const previusPage =
        compras?.current_page >= 1
            ? compras.current_page - 1
            : compras.current_page;

    const canNextPage = nextPage <= compras.last_page;
    const canPreviustPage = compras?.from - 1 >= 1 ? true : false;

    const confirmaExclusao = {
        onClick: () => null,
        closeModal: () => {
            setModalItens(false);
        },
    };

    if (loadingCompras) {
        return (
            <div className="container-loading">
                <Loader height={200} width={200} />
            </div>
        );
    }

    return (
        <>
            <Modal
                component={ModalItens}
                active={modalItens}
                rest={confirmaExclusao}
            />
            {data.map((item, key, arr) => (
                <Table
                    columns={columns}
                    data={[item]}
                    index={key}
                    key={key}
                    setModalItens={setModalItens}
                />
            ))}

            <Paginacao
                page={compras.current_page}
                nextPage={() => paginacaoCompras(nextPage)}
                previousPage={() => paginacaoCompras(previusPage)}
                canPreviousPage={canPreviustPage}
                canNextPage={canNextPage}
                goToPage={(page) => paginacaoCompras(page)}
                lastPage={compras.last_page}
            />
        </>
    );
}

export default TableCompra;
