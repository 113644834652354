import styled from "styled-components";
import { Grid, Flex } from "aplication-yetz";
import imgMenu from "../../assets/sistema/menu-plataforma.svg";

export const ContainerCards = styled(Grid)`
    grid-template-columns: repeat(auto-fit, minmax(520px, 520px));
    grid-gap: 24px;
`;

export const Header = styled(Flex)`
    justify-content: space-between;
    padding-right: 55px;
    margin-bottom: 60px;
    h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 39px;
        color: #000000;
    }

    .ano {
        flex-direction: column;
        margin-right: 64px;
        small {
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: #4b4b4b;
        }

        span {
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            color: #000000;
        }
    }
`;

export const CardStyles = styled(Flex)`
    border: 1px solid #cfcfcf;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 35px;
    padding-left: 120px;
    flex-direction: column;
    position relative;
    .container-icone {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        position: absolute;
        left: 35px;
        &:before {
            content: "";
            display: block;
            width: 28.01px;
            height: 28.01px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -webkit-mask: url(${imgMenu});
            -webkit-mask-repeat: no-repeat;
            background-color: #000;
        }
    }

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #4b4b4b;
    }

    h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
    }
    h4 {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
    }
    .plataforma {
        justify-content: space-between;
        width: 100%;
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid #cecece;

        span {
            display: flex;
            align-items: center;
            justify-content: center;

            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #000000;

            width: 52px;
            height: 35px;
            left: 0px;
            top: 17px;

            background: #d3faff;
            border-radius: 4px;
        }
    }

    .vouchers {
    }
`;

export const ModalStyles = styled.div`
    background: #fff;
    height: 100vh;
    width: 500px;
    padding: 30px 38px 0px 56px;
    P {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: #959595;
        padding-top: 34px;
    }
    .filtro {
        border-bottom: 1px solid #cfcfcf;
        padding-bottom: 19px;
        margin-bottom: 26px;
        & > div {
            margin-right: 64px;
        }
    }
    .header-modal-filtro {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
        }
    }
`;

export const StylesTable = styled.div`
    .tr {
        &.body {
            cursor: pointer;
        }
    }
`;

export const ModalStylesAlert = styled.div`
    padding: 34px 24px 47px 24px;
    background-color: ${(props) => props.theme.colors.white};
    width: 375px;
    height: auto;

    button {
        svg {
            width: 24px !important;
            height: 24px !important;
        }
    }
    .container-sucess {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            width: 166px;
        }
        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: ${(props) => props.theme.fonts.mdl};
            line-height: 120%;
            text-align: center;
            color: ${(props) => props.theme.colors.primary};
            margin-bottom: 34px;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: ${(props) => props.theme.fonts.md};
            line-height: 140%;
            text-align: center;

            margin-bottom: 32px;
        }
    }

    .container-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e1e2e3;
        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary};
        }
    }

    .container-content {
        width: 100%;
        .container-img {
            padding: 13px;
            border: 0.710526px solid #bcdfba;
            width: 54px;
            height: 54px;
            border-radius: 50%;
            margin: 0 auto;
            margin-bottom: 21px;
            img {
                width: 28px;
                display: block;
            }
        }
        span {
            display: block;
            width: 254px;
            margin: 0 auto;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            text-align: center;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary};
            margin-bottom: 52px;
        }
    }
`;
