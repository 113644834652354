import imgCard from "../../assets/sistema/encerrada.png";

import { StylesAlerta } from "./styles";

export function Encerrada() {
    return (
        <StylesAlerta>
            <div className="container-img">
                <img src={imgCard} alt="" />
            </div>

            <div className="container-text">
                <p>
                    <strong>ESSA AÇÃO FOI ENCERRADA.</strong>{" "}
                </p>
                <br />
                <br />
                <p>
                    Se tiver dúvidas acione o <br /> FALE CONOSCO:
                    cards@yetzlink.com.br
                </p>
            </div>
        </StylesAlerta>
    );
}
