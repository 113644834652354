import { Header } from "./header";
import { ContainerTable } from "../tables/styles";
import { Table, Text } from "aplication-yetz";
import { Modal } from "../Modal";
import { useRelatorioVouchers } from "../../Hook/useRelatorioVoucher";
import { BoxExclusao } from "../BoxNotificacao";
import { NovoRelatorio } from "./novoRelatorio";
import { Data, column } from "./config";
import imgExcluir from "../../assets/sistema/fechar-modal.svg";
import imgLixeira from "../../assets/sistema/excluir.svg";
import imgDowload from "../../assets/sistema/dowload.svg";
import { Loader } from "../Loader";
import { Container } from "./styles";
import { formatDateTimeDb, formatDateUser } from "../../util/helper";

export function RelatorioVoucher() {
    const {
        data,
        setIdRelatorio,
        modalCreate,
        modalDelete,
        setModalDelete,
        deleteRelatorio,
        loading,
    } = useRelatorioVouchers();

    const dados: Data[] = data.map((data) => {
        const periodo = `${formatDateUser(data.start)} - ${formatDateUser(
            data.end
        )}`.replace(/\//g, ".");

        return {
            titulo: data.title,
            data_hora: formatDateTimeDb(data.created_at),
            periodo,
            criado_por: data.usuario,
            status: data.status,
            excluir: (
                <button
                    onClick={() => {
                        setModalDelete(true);
                        setIdRelatorio(data.id);
                    }}
                >
                    <img src={imgExcluir} alt="" />
                </button>
            ),
            acoes: (
                <button
                    onClick={() => {
                        window.open(data.file, "_blank");
                    }}
                >
                    <img src={imgDowload} alt="" />
                </button>
            ),
        };
    });

    const excluir = {
        img: imgLixeira,
        title: (
            <Text as="p" fontWeight="600" fontSize="mdl" color="black">
                Atencção está ação não poderá ser desfeita!
            </Text>
        ),
        width: "500px",
        onClick: async () => await deleteRelatorio(),
        closeModal: () => {
            setModalDelete(false);
            setIdRelatorio(null);
        },
    };

    return (
        <Container>
            <Header />

            {loading ? (
                <div className="container-loader">
                    <Loader />
                </div>
            ) : (
                <ContainerTable>
                    <Table data={dados} columns={column} />
                </ContainerTable>
            )}

            <Modal
                component={NovoRelatorio}
                active={modalCreate}
                right={true}
            />
            <Modal
                component={BoxExclusao}
                active={modalDelete}
                rest={excluir}
            />
        </Container>
    );
}
