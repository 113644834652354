import { ButtonSystem, Heading, Flex } from "aplication-yetz";
import { useHistory } from "react-router-dom";

import setaEsqImg from "../../assets/sistema/seta-esq.svg";

interface GoBackProps {
    title: string;
}

export function Goback(props: GoBackProps) {
    const history = useHistory();
    const { title } = props;
    return (
        <button onClick={() => history.goBack()}>
            <Flex>
                <ButtonSystem padding="15px 17px">
                    <img src={setaEsqImg} alt="" />
                </ButtonSystem>

                <Heading as="h2" ml="35px" fontSize="lg">
                    {title}
                </Heading>
            </Flex>
        </button>
    );
}
