import { useState, useEffect, useMemo } from "react";

import { Loader } from "../Loader";
import { useProduto } from "../../Hook/useProduto";
import { Modal } from "../../components/Modal";
import { ModalProduto } from "./modalProduto";
import { Header } from "./header";
import TabelaProdutos from "../../components/TabelaProdutos";
import { Paginacao } from "../../components/tables/";
import { filterProduto } from "../../util/helper";
import { usePagination } from "../../Hook/usePagination";
import { Permissions } from "../Permission";

export function Produtos() {
    const { currentPage, gotoPage } = usePagination();

    const { produto, loadingProduto, getProdutos } = useProduto();
    const [modal, setModal] = useState(false);
    const [filter, setFilter] = useState("");

    const arrFilter = useMemo(
        () => (produto.data ? filterProduto(produto.data, filter) : []),
        [produto, filter]
    );

    useEffect(() => {
        getProdutos(filter, currentPage);
        // eslint-disable-next-line
    }, [filter, currentPage]);

    const config = {
        title: "Produtos",
        description: "Adicionar produtos",
        onClose: () => setModal(false),
        modalActive: modal,
    };

    const nextPage =
        produto?.current_page <= produto?.last_page
            ? produto.current_page + 1
            : produto.current_page;

    const previusPage =
        produto?.current_page >= 1
            ? produto.current_page - 1
            : produto.current_page;

    const canNextPage = nextPage <= produto.last_page;
    const canPreviustPage = produto?.from - 1 >= 1 ? true : false;

    return (
        <>
            <Header
                setModal={setModal}
                setFilter={(filter) => {
                    setFilter(filter);
                    gotoPage(0);
                }}
            />
            {loadingProduto ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : (
                <>
                    <TabelaProdutos produto={arrFilter} />
                    <Paginacao
                        page={produto.current_page}
                        canPreviousPage={canPreviustPage}
                        canNextPage={canNextPage}
                        nextPage={() => gotoPage(nextPage)}
                        previousPage={() => gotoPage(previusPage)}
                        goToPage={(page) => gotoPage(page)}
                        lastPage={produto.last_page}
                    />
                </>
            )}
            <Permissions role="produto.store">
                <Modal
                    component={ModalProduto}
                    active={modal}
                    rest={config}
                    right
                />
            </Permissions>
        </>
    );
}
