import { DetalhePedido } from "../../components/DetalhePedido";
import { UseDetalhePedidoProvider } from "../../Hook/useDetalhePedidoPlataforma";

export function PageDetalhePedido() {
    return (
        <UseDetalhePedidoProvider>
            <DetalhePedido />
        </UseDetalhePedidoProvider>
    );
}
