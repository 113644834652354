import { VariantColors } from "aplication-yetz";
import { Container } from "./styles";

interface statusProps {
    valor: number;
    colors: VariantColors;
}

export function Status(props: statusProps) {
    const { valor, colors } = props;
    return <Container status={colors}>{valor}</Container>;
}
