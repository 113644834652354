import { toast } from "react-toastify";

import { ModalEmail } from "../ValePresente/modalEmail";
import { ModalWhatsapp } from "../ValePresente/modalWhatsapp";
import { Modal } from "../../components/Modal";

import { formatCurrentMoney, formatDateIso } from "../../util/helper";
import { Footer as FooterDesk } from "../../components/Footer";
import { ResponseVoucher } from "../../pages/MultiplosVoucherGrandCru";
import { Loader } from "../Loader";

import { Container } from "./stylesGrandCru";

import imgCopy from "../../assets/sistema/copy.svg";
import imgEditar from "../../assets/sistema/editar.svg";
import imgEmail from "../../assets/sistema/email.svg";
import imgWhats from "../../assets/sistema/whats.svg";
import { useEffect, useRef, useState } from "react";
import { ButtonVoucherPDF } from "../ButtonVoucherPDF";
import { ButtonPersonalizado } from "./ButtonPersonalizado";

interface PersonaliteCodigoSemValorProps {
    setModalTutorial: (value: boolean) => void;
    voucher: ResponseVoucher;
    loading: boolean;
    name?: string;
}

export function UniclassLink(props: PersonaliteCodigoSemValorProps) {
    const [modalEmail, setModalEmail] = useState(false);
    const [modalWhatsap, setModalWhatsapp] = useState(false);
    const [link, setLink] = useState("");
    const componentRef = useRef<any>();

    const configWhats = {
        onClose: () => setModalWhatsapp(false),
        voucher: props.voucher,
    };

    const configEmail = {
        onClose: () => setModalEmail(false),
        voucher: props.voucher,
    };
    const data = props?.voucher?.voucher
        ? formatDateIso(props?.voucher?.voucher[0].date || "")
              .split("-")[0]
              .replace(/\./g, "/")
        : "";

    const validade = props?.voucher?.voucher
        ? props.voucher?.voucher[0].validade
        : "";

    const titleBtn = props?.voucher?.voucher
        ? props.voucher.voucher[0].external_link_label
        : "";
    const linkBtn = props?.voucher?.voucher
        ? props.voucher.voucher[0].external_link
        : "";
    const hasCustomBtn = linkBtn && titleBtn ? true : false;

    function copy(text: string) {
        const el = document.createElement("textarea");
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        toast.success(`Copiado`);
        return "Texto copiado com sucesso!";
    }

    function openLink() {
        window.location.href = link ? link : linkBtn;
    }

    useEffect(() => {
        const link =
            props?.voucher.voucher
                ?.map((item) => item.codes)
                .join(",")
                .replace(/,/g, ".") || "";

        setLink(link);
    }, [props?.voucher?.voucher]);

    return (
        <Container
            ref={componentRef}
            bgColor="#0131FF"
            className="voucher-link"
        >
            {props.loading ? (
                <div className="loader">
                    <Loader />
                </div>
            ) : (
                <>
                    <div className="container">
                        <div className="container-text">
                            <h3>MINHAS VANTAGENS</h3>
                        </div>

                        <div className="container-par">
                            <h5>
                                {props?.voucher?.voucher ? (
                                    <>
                                        CRÉDITO DE{" "}
                                        {formatCurrentMoney(
                                            parseFloat(
                                                props?.voucher?.voucher
                                                    ? props?.voucher?.voucher[0]
                                                          .valor + ""
                                                    : ""
                                            )
                                        )}
                                        <br />
                                    </>
                                ) : (
                                    <></>
                                )}

                                {props.name}
                            </h5>
                        </div>

                        <div className="card-retangulo grandCru">
                            <div className="container-voucher">
                                <div className="item">
                                    <ButtonPersonalizado
                                        onClick={openLink}
                                        title={
                                            hasCustomBtn
                                                ? titleBtn
                                                : "Resgatar Crédito"
                                        }
                                    />
                                    <button onClick={() => copy(link)}>
                                        <img src={imgCopy} alt="" />
                                        copiar <br />
                                        link
                                    </button>
                                </div>

                                {validade === "VENCIDO" && (
                                    <small>Vencido em: {data}</small>
                                )}
                                {validade === "VENCIMENTO" && (
                                    <small>Vencimento em: {data}</small>
                                )}
                                {validade !== null &&
                                validade !== "VENCIDO" &&
                                validade !== "VENCIMENTO" ? (
                                    <small>
                                        Validade: {validade} dias a partir de{" "}
                                        {data}
                                    </small>
                                ) : null}
                                {validade === null && (
                                    <small className="campo-amarelo">
                                        Validade: {data}
                                    </small>
                                )}
                            </div>
                        </div>

                        <div className="btn-voucher">
                            <button
                                onClick={() => props.setModalTutorial(true)}
                            >
                                <img src={imgEditar} alt="" />
                                <p>Saiba como usar</p>
                            </button>
                        </div>

                        <div className="container-compartilhar">
                            <p className="text-comaprtilhe">
                                Aproveite e compartilhe
                            </p>
                            <ButtonVoucherPDF currentRef={componentRef} />

                            <div className="container-btns">
                                <button
                                    className="whats"
                                    onClick={() => setModalWhatsapp(true)}
                                >
                                    <img src={imgWhats} alt="" />
                                    WhatsApp
                                </button>
                                <button
                                    className="email"
                                    onClick={() => setModalEmail(true)}
                                >
                                    <img src={imgEmail} alt="" />
                                    Email
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <Modal
                component={ModalWhatsapp}
                active={modalWhatsap}
                rest={configWhats}
            />
            <Modal
                component={ModalEmail}
                active={modalEmail}
                rest={configEmail}
            />

            <FooterDesk />
        </Container>
    );
}
