import img from "../../assets/sistema/fechar-branco.svg";
import whats from "../../assets/sistema/email.svg";

import { ModalCompartilha } from "./styles";
import { useVoucherConfig } from "../../Hook/useVoucherConfig";

interface CompartilhaProps {
    rest: {
        onClose: () => void;
    };
}

export function ModalEmail(props: CompartilhaProps) {
    const { pedidoShareUrl } = useVoucherConfig();
    async function compartilhaEmail() {
        const input = document.querySelector(
            "#input-email"
        ) as HTMLInputElement;
        await pedidoShareUrl("email");
        window.open(
            "mailto:" +
                input.value +
                "?subject=Seu Voucher Digital&body=Aqui está seu voucher digital: " +
                window.location,
            "_target"
        );
    }

    return (
        <ModalCompartilha>
            <div className="header email">
                <h2>Compartilhar no Email</h2>
                <img src={img} alt="" onClick={() => props.rest.onClose()} />
            </div>
            <div className="body email">
                <p>
                    Preencha campos abaixo com o e-mail para o qual deseja
                    enviar o link do seu Voucher.
                </p>
                <div className="campo-telefone">
                    <img src={whats} alt="" />
                    <input type="email" id="input-email" placeholder="E-mail" />
                </div>

                <button onClick={() => compartilhaEmail()}>COMPARTILHAR</button>
            </div>
        </ModalCompartilha>
    );
}
