import { CardStyled } from "./styles";
import imgSetaBaixo from "../../assets/sistema/seta-baixo.svg";
import imgMenuplataforma from "../../assets/sistema/menu-plataforma.svg";
import { Active } from "./yetz";
import { Vouchers } from "../../Hook/useVoucher";
import { formatCurrentMoney } from "../../util/helper";
import { api } from "../../services";
import { toast } from "react-toastify";
import { Loader } from "../Loader";
import { useState } from "react";
import { Permissions } from "../Permission";

type UpdateActive<T> = keyof T;
interface CardProps extends Vouchers {
    updateActive(
        tab: UpdateActive<Active>,
        ano: number,
        mes: number,
        id: number
    ): Promise<void>;
    active: Active;
}

export function Card(props: CardProps) {
    const { plataforma, updateActive, active } = props;
    const [loading, setLoading] = useState(false);
    let cor = "";
    let tab: UpdateActive<Active>;

    switch (plataforma) {
        case "YETZLINK":
            cor = "#D3FAFF";
            tab = "link";
            break;
        case "YETZPROMO":
            cor = "#F1E3FF";
            tab = "promo";
            break;
        case "YETZCARDS":
            cor = "#FFEED3";
            tab = "cards";
            break;
        default:
            cor = "#fff";
            tab = "mes";
    }

    async function handleDownload() {
        try {
            const outputFilename = `${Date.now()}.xlsx`;

            const response: any = await api.get(
                `relatorio/vouchers-expirados/exports?ano=${props.ano}&mes=${props.mes}`,
                {
                    responseType: "blob",
                }
            );

            var data = new Blob([response.data]);
            var xlsxURL = window.URL.createObjectURL(data);
            var tempLink;
            tempLink = document.createElement("a");
            tempLink.href = xlsxURL;
            tempLink.setAttribute("download", outputFilename);
            tempLink.click();

            toast.success("Download realizado com sucesso!");
        } catch (error: any) {
            console.log(error.response);

            toast.error("Erro ao exportar arquivo");
        }
    }

    return (
        <CardStyled bgIcone={cor}>
            <div className="container-card">
                <div className="container-mes">
                    <h3>{props.plataforma}</h3>

                    <div className="container-icone">
                        <img src={imgMenuplataforma} alt="" />
                    </div>
                </div>

                <div className="container-total">
                    <div className="item">
                        <h5>Total Expirado</h5>
                        <h6>{props.total_itens}</h6>
                    </div>
                    <div className="item">
                        <h5>Valor</h5>
                        <h6>
                            {formatCurrentMoney(parseFloat(props.valor_total))}
                        </h6>
                    </div>
                </div>

                <div className="detalhes">
                    <div className="container-item">
                        <h5>disponivel</h5>
                        <h6>{props.total_disponivel}</h6>
                        <h6>
                            {formatCurrentMoney(
                                parseFloat(props.valor_disponivel)
                            )}
                        </h6>
                    </div>
                    <div className="container-item">
                        <h5>Outlet</h5>
                        <h6>{props.total_outlet}</h6>
                        <h6>
                            {formatCurrentMoney(parseFloat(props.valor_outlet))}
                        </h6>
                    </div>
                    <div className="container-item">
                        <h5>Vencido</h5>
                        <h6>{props.total_vencido}</h6>
                        <h6>
                            {formatCurrentMoney(
                                parseFloat(props.valor_vencido)
                            )}
                        </h6>
                    </div>
                </div>

                <div className="mostrar-detalhes">
                    <Permissions role="relatorio.vouchers_expirados.exports">
                        <button onClick={() => handleDownload()}>
                            DOWNLOAD
                        </button>
                    </Permissions>

                    <Permissions role="relatorio.vouchers_expirados.details">
                        <button
                            className={` btn-detalhes ${
                                active[tab] ? "active" : ""
                            }`}
                            onClick={async () => {
                                setLoading(true);
                                await updateActive(
                                    tab,
                                    props.ano,
                                    props.mes as number,
                                    props.user_id
                                );
                                setLoading(false);
                            }}
                        >
                            VER DETALHES
                            {loading ? (
                                <Loader width={20} height={20} />
                            ) : (
                                <img src={imgSetaBaixo} alt="" />
                            )}
                        </button>
                    </Permissions>
                </div>
            </div>
        </CardStyled>
    );
}
