import { useEffect, useState } from "react";
import { usePlataforma, UpdateCatalogoData } from "../../Hook/usePlataforma";
import { Toggle } from "../../components/Toggle";
import { toast } from "react-toastify";

interface StatusProps {
    active: boolean;
    id: number;
}

export function Status(props: StatusProps) {
    const { id } = props;
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const { updateCatalogo } = usePlataforma();

    async function handleToggle(dados: UpdateCatalogoData, id: number) {
        setLoading(true);

        const response = await updateCatalogo(dados, id);

        if (response.status === 200) {
            toast.success("Atualizado com sucesso!");
        }

        setLoading(false);
    }

    useEffect(() => {
        setActive(props.active);
    }, [props.active]);

    return (
        <Toggle
            loading={loading}
            active={active}
            onClick={() => {
                handleToggle(
                    {
                        status: !active ? 1 : 0,
                    },
                    id
                );
            }}
        />
    );
}
