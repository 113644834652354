import { DetalheEstoque } from "../../Hook/useDetalheEstoque";
import { BoxDetalheProduto } from "../../components/BoxDetalheProduto";
import { BoxDetalheProdutoProps } from "../../components/BoxDetalheProduto/types";

import img01 from "../../assets/sistema/logo-card-01.svg";
import img02 from "../../assets/sistema/logo-card-02.svg";
import img03 from "../../assets/sistema/logo-card-03.svg";
import img04 from "../../assets/sistema/logo-card-04.svg";
import img05 from "../../assets/sistema/logo-card-05.svg";
import img06 from "../../assets/sistema/logo-card-06.svg";

import { CardStyles } from "./styles";

export function Cards(props: DetalheEstoque) {
    const { variacao } = props;

    const arrayCard: BoxDetalheProdutoProps[] = [
        {
            img: img01,
            subTitulo: variacao.produto?.id + "",
            titulo: "código",
        },
        {
            img: img02,
            subTitulo:
                variacao.produto?.categorias
                    .map((categoria) => categoria.categoria.nome)
                    .join(", ") || "",
            titulo: "Categoria",
        },
        {
            img: img03,
            subTitulo: variacao.produto?.tipo || "",
            titulo: "Tipo",
        },
        {
            img: img04,
            subTitulo: variacao.produto?.data_outlet + " dia(s)" || "",
            titulo: "Entra em Outlet",
        },
        {
            img: img05,
            subTitulo: variacao.produto?.desconto_outlet + "%" || "",
            titulo: "Desconto Outlet",
        },
        {
            img: img06,
            subTitulo: "utilizado em",
            titulo: "Loja Virtual",
        },
    ];

    return (
        <CardStyles>
            <img src={variacao.produto?.foto_capa} alt="" className="logo" />
            {arrayCard.map((item) => (
                <BoxDetalheProduto {...item} />
            ))}
        </CardStyles>
    );
}
