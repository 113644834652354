import styled, { css } from "styled-components";

const center = css`
    .box-info-qtd-estoque {
        align-items: center;
        &:before {
            position: static;
            transform: unset !important;
            margin-right: 11px;
        }
    }
`;

interface ContainerProps {
    center?: boolean;
}
export const Container = styled.div<ContainerProps>`
    width: 100%;
    ${(props) => (props.center ? center : null)}
    .box-info-qtd-estoque {
        width: 100%;
        border: 1px solid #dfdfdf;
        height: 56px;
        justify-content: center;
        padding: 0px 25px;
        padding-left: 64px;
        border-radius: 8px;
        &:before {
            top: 49%;
            left: 28px;
            transform: translateY(-50%);
        }
    }
`;
