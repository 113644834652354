import { Column } from "react-table";

export interface Categoria {
    descricao: string;
    nome: string;
}

export interface Data {
    nome: string | React.ReactChild | React.ReactChild[] | React.ReactNode;
    id: string | number;
    acoes: string | React.ReactChild | React.ReactChild[] | React.ReactNode;
}

export const column: Column<Data>[] = [
    {
        Header: "ID",
        accessor: "id",
    },
    {
        Header: "NOME",
        accessor: "nome",
    },
    {
        Header: "Ações",
        accessor: "acoes",
    },
];
