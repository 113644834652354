import React, { createContext, useContext, useEffect, useState } from "react";
import { api } from "../services";

export interface Vouchers {
    ano: number;
    color: null | string;
    light_color: null | string;
    mes: number | string;
    plataforma: string;
    valor_disponivel: string;
    valor_outlet: string;
    valor_total: string;
    valor_vencido: string;
    user_id: number;
    total_itens: number;

    total_disponivel: string | number;
    total_outlet: string | number;
    total_vencido: string | number;
}

interface UseVouchersProviderProps {
    children: React.ReactNode;
}

interface UseVoucherData {
    vouchers: Vouchers[][];
    loading: boolean;
}

const UseVouchersContext = createContext<UseVoucherData>({} as UseVoucherData);

export function UseVoucherProvider(props: UseVouchersProviderProps) {
    const { children } = props;

    const [vouchers, setVouchers] = useState<Vouchers[][]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    async function getVouchers() {
        try {
            setLoading(true);
            const { data } = await api.get<Vouchers[]>(
                "/relatorio/vouchers-expirados"
            );

            const meses = [...new Set(data.map((item) => item.mes))];

            const vouchers = meses.map((mes) => {
                return data.filter((item) => item.mes === mes);
            });

            setVouchers(vouchers);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        getVouchers();
    }, []);

    return (
        <UseVouchersContext.Provider
            value={{
                vouchers,
                loading,
            }}
        >
            {children}
        </UseVouchersContext.Provider>
    );
}

export function useVouchers() {
    const context = useContext(UseVouchersContext);
    return context;
}
