import styled from "styled-components";

export const ContainerHeader = styled.div`
    background-color: #f9f9f9;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 76px;
    background-color: white;
    border-bottom: 1px solid #ececec;
`;
