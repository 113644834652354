import { useState } from "react";
import { Toggle } from "../Toggle";
import { useProduto, UpdateVariacao } from "../../Hook/useProduto";
import { toast } from "react-toastify";

interface StatusAlertaEstoqueProps {
    status_alerta_estoque: number;
    id: number;
}

export function StatusAlertaEstoque(props: StatusAlertaEstoqueProps) {
    const { status_alerta_estoque, id } = props;
    const { updateVariacao } = useProduto();

    const [loading, setLoading] = useState<boolean>(false);
    async function updateVariacaoStatus(value: UpdateVariacao, id: number) {
        setLoading(true);
        const response = await updateVariacao(value, id);

        if (response.status !== 200) {
            toast.error("Erro ao atualizar a variação");
            setLoading(false);
            return;
        }

        setLoading(false);
    }

    return (
        <Toggle
            active={status_alerta_estoque ? true : false}
            loading={loading}
            onClick={() =>
                updateVariacaoStatus(
                    { status_alerta_estoque: status_alerta_estoque ? 0 : 1 },
                    id
                )
            }
        />
    );
}
