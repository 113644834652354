import { Grid } from "aplication-yetz";
import styled from "styled-components";

export const CardsStyles = styled(Grid)`
    padding-bottom: 50px;
    margin-bottom: 70px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
`;

export const BoxTabela = styled.div`
    border: 1px solid #cfcfcf;
    border-radius: 8px;
    padding: 25px;
    .titulo {
        display: flex;
        align-items: center;
        grid-gap: 16px;
        margin-bottom: 20px;
        h1 {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
        }
    }
    .topoRelatorio {
        background: #f8f8f8;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 48px;
        padding: 0 15px;
        .titulo {
            margin-bottom: 0;
        }
        .tipos {
            display: flex;
            grid-gap: 30px;
        }
        span {
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: #4b4b4b;
            cursor: pointer;
            &.active {
                font-weight: 700;
                color: #930035;
                position: relative;
                &:before {
                    content: "";
                    display: block;
                    width: 67px;
                    height: 1px;
                    background-color: #930035;
                    position: absolute;
                    bottom: -18px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
`;
