import { useEffect, useState } from "react";
import { Text, Grid, Flex, ButtonSystem } from "aplication-yetz";
import { toast } from "react-toastify";
import { filterDetalhePlataforma } from "../../util/helper";
import { formatCurrentMoney } from "../../util/helper";
import { usePlataforma } from "../../Hook/usePlataforma";
import { Loader } from "../../components/Loader";
import { Toggle } from "../Toggle";
import { Modal } from "../Modal";
import { BoxExclusao } from "../BoxNotificacao";
import { BoxCatalogo } from "./boxCatalogo";
import { Header } from "./header";
import { Cards } from "./cards";
import { SubHeader } from "./subHeader";
import { LoaderStyles, ConfirmaCatalogo } from "./styles";

import alertaImg from "../../assets/sistema/alerta.svg";
import { Permissions } from "../Permission";

export function DetalhePlataforma() {
    const { catalogo, user, updateCatalogo } = usePlataforma();

    const { catalogo_id = [] } = user;

    const [filter, setFilter] = useState<string>("");

    const [hasEstoque, setHasEstoque] = useState<boolean>(false);
    const [status, setStatus] = useState<boolean>(false);

    const [modalAtivacao, setModalAtivacao] = useState<boolean>(false);
    const [loadingToggleTodosProdutos, setLoadingToggleTodosProdutos] =
        useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);

    const arrFilter = filterDetalhePlataforma(filter, catalogo);

    // const data: Data[] = arrFilter.map((item) => {
    //     const { produto, id, nome, valor_reais, valor_yetz } = item;
    //     const { categorias, foto_capa } = produto;
    //     const { categoria } = categorias[0];

    //     console.log(catalogo_id.includes(id));

    //     return {
    //         img: (
    //             <Box width="74px">
    //                 <img src={foto_capa} alt="" className="foto_capa" />
    //             </Box>
    //         ),
    //         id,
    //         cartao: nome,
    //         categoria: categoria.nome,
    //         valor_reais: formatCurrentMoney(valor_reais),
    //         valor_yetz: valor_yetz + " YETZ",
    //         ativo: <Ativo catalogo_id={catalogo_id} id={id} />,
    //     };
    // });

    const excluir = {
        img: alertaImg,
        title: (
            <Text as="p" fontWeight="600" fontSize="mdl" color="black">
                Tem certeza que deseja <br /> alterar o status de todos os
                produtos?
            </Text>
        ),
        width: "500px",
        loading: loadingToggleTodosProdutos,
        onClick: () => handleClick(),
        closeModal: () => setModalAtivacao(false),
    };

    async function handleClick() {
        setLoadingToggleTodosProdutos(true);

        const arrCatalogoIds: Array<number> = catalogo.map((item) => item.id);

        const active = catalogo_id.length === catalogo.length;

        const config = {
            "sem-estoque": hasEstoque ? 1 : 0,
            status: status ? 1 : 0,
            catalogo: !active ? arrCatalogoIds : [],
        };

        const response = await updateCatalogo(config);

        if (response.status === 200) {
            toast.success("Status alterado com sucesso!");
        }

        setLoadingToggleTodosProdutos(false);
        setModalAtivacao(false);
    }

    useEffect(() => {
        setHasEstoque(user["sem-estoque"]);
        setStatus(user.status ? true : false);
    }, [user]);

    const [itensAtivos, setItensAtivos] = useState<number[]>([]);

    useEffect(() => {
        const unique_ids = new Set(catalogo_id);
        setItensAtivos(Array.from(unique_ids.values()));
    }, [catalogo_id]);

    function toggleItem(itemId: number) {
        const index = itensAtivos.indexOf(itemId);
        if (index >= 0) {
            setItensAtivos((itens) => [
                ...itens.slice(0, index),
                ...itens.slice(index + 1),
            ]);
        } else {
            setItensAtivos((itens) => [...itens, itemId]);
        }
    }

    function selecionarTodos() {
        if (itensAtivos.length === arrFilter.length) {
            setItensAtivos([]);
            return;
        } else {
            setItensAtivos(() => arrFilter.map(({ id }) => id));
        }
    }

    async function salvarItensAtivos() {
        console.log("salvar ", itensAtivos);
        setLoading(true);

        const config = {
            catalogo: itensAtivos,
        };

        const response = await updateCatalogo(config);

        if (response.status === 200) {
            toast.success("Ativo atualizado com sucesso!");
        }
        setLoading(false);
    }

    function cancelarItensMarcados() {
        const unique_ids = new Set(catalogo_id);
        setItensAtivos(Array.from(unique_ids.values()));
    }

    return catalogo.length > 0 ? (
        <>
            <Header
                estoque={hasEstoque}
                status={status}
                catalogosIds={catalogo_id}
            />
            <Cards />

            <SubHeader
                setFilter={setFilter}
                setModalAtivacao={setModalAtivacao}
                catalogosIds={catalogo_id}
                catalogo={catalogo}
                estoque={hasEstoque}
                status={status}
            />

            <Grid gridTemplateColumns="repeat(5, 1fr)" gridGap="35px">
                {arrFilter.map((item, index) => (
                    <BoxCatalogo
                        key={index}
                        id={item.id}
                        img={item.foto_capa}
                        valor_reais={formatCurrentMoney(item.valor_reais)}
                        valor_yetz={item.valor_yetz.toString()}
                        ativo={itensAtivos.includes(item.id)}
                        handleClick={toggleItem}
                    />
                ))}
            </Grid>

            <ConfirmaCatalogo>
                <Flex className="qtds-aprovar">
                    {itensAtivos.length} • {arrFilter.length}
                    <Toggle
                        active={
                            itensAtivos.length === arrFilter.length
                                ? true
                                : false
                        }
                        titulo={<Text bold={true}>Aprovar todos</Text>}
                        onClick={selecionarTodos}
                    />
                </Flex>
                {loading ? (
                    <Loader height={100} width={100} />
                ) : (
                    <Flex className="btns">
                        <Permissions role="usuario.update">
                            <ButtonSystem
                                className="btn-cancelar"
                                p="16px 32px"
                                fontSize="xs"
                                background="white"
                                onClick={cancelarItensMarcados}
                            >
                                CANCELAR
                            </ButtonSystem>
                        </Permissions>

                        <Permissions role="usuario.update">
                            <ButtonSystem
                                p="16px 32px"
                                fontSize="xs"
                                onClick={salvarItensAtivos}
                            >
                                SALVAR
                            </ButtonSystem>
                        </Permissions>
                    </Flex>
                )}
            </ConfirmaCatalogo>

            <Modal
                component={BoxExclusao}
                active={modalAtivacao}
                rest={excluir}
            />
        </>
    ) : (
        <LoaderStyles>
            <Loader />
        </LoaderStyles>
    );
}
