import { Table, Flex } from "aplication-yetz";
import { column, Data } from "./types";
import { BoxTabela } from "./styles";

import img from "../../assets/sistema/aguardando.svg";
import img1 from "../../assets/sistema/image-table.png";

export function TableTotalGestao() {
    const data: Data[] = [
        {
            voucher: (
                <Flex alignItems="center" className="boxVoucher">
                    <img src={img1} alt="" />
                    <span>Americanas R$ 80</span>
                </Flex>
            ),
            qtd: "12",
            tempo: "8 dias",
        },
        {
            voucher: (
                <Flex alignItems="center" className="boxVoucher">
                    <img src={img1} alt="" />
                    <span>Americanas R$ 80</span>
                </Flex>
            ),
            qtd: "12",
            tempo: "8 dias",
        },
        {
            voucher: (
                <Flex alignItems="center" className="boxVoucher">
                    <img src={img1} alt="" />
                    <span>Americanas R$ 80</span>
                </Flex>
            ),
            qtd: "12",
            tempo: "8 dias",
        },
        {
            voucher: (
                <Flex alignItems="center" className="boxVoucher">
                    <img src={img1} alt="" />
                    <span>Americanas R$ 80</span>
                </Flex>
            ),
            qtd: "12",
            tempo: "8 dias",
        },
        {
            voucher: (
                <Flex alignItems="center" className="boxVoucher">
                    <img src={img1} alt="" />
                    <span>Americanas R$ 80</span>
                </Flex>
            ),
            qtd: "12",
            tempo: "8 dias",
        },
        {
            voucher: (
                <Flex alignItems="center" className="boxVoucher">
                    <img src={img1} alt="" />
                    <span>Americanas R$ 80</span>
                </Flex>
            ),
            qtd: "12",
            tempo: "8 dias",
        },
    ];

    return (
        <BoxTabela>
            <div className="titulo">
                <img src={img} alt="" />
                <h1>Total resgates aguardando estoque</h1>
            </div>

            <Table data={data} columns={column} />
        </BoxTabela>
    );
}
