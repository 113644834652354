import { Column } from "react-table";
import { Data } from "./types";

export const columns: Column<Data>[] = [
    {
        Header: " ",
        accessor: "foto",
    },
    {
        Header: "Status",
        accessor: "status",
    },
    {
        Header: "Descrição",
        accessor: "descricao",
    },
    {
        Header: "linha",
        accessor: "linha",
    },
];
