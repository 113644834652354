import { useState, useEffect } from "react";
import { UseCineMarkComboProvider, useCineMarkCombo } from "./hook";

import { Error } from "../../components/ValePresenteCineMarckCombo/error";
// import { Encerrada } from "../../components/ValePresenteCineMarckCombo/encerrada";
import { Footer } from "../../components/ValePresenteCineMarckCombo/footer";
import { Bloqueado } from "../../components/ValePresenteCineMarckCombo/bloqueado";
import { ValePresenteCineMarckCombo } from "../../components/ValePresenteCineMarckCombo";

import { Modal } from "../../components/Modal";
import { ModalVideo } from "../../components/PassoAPasso/video";
import { ModalTutorial } from "../../components/PassoAPasso/modal";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { ExpiradoPersonalite } from "../../components/ValePresente/expiradoPersonnalite";
import { Expirado } from "../../components/ValePresente/expirado";
import { formatDateUser } from "../../util/helper";
import { useVoucherConfig } from "../../Hook/useVoucherConfig";

export interface ResponseVoucher {
    success: boolean;
    error: string;
    status: string;
    expires_at: string | null;
    voucher?: Voucher[];
    codes?: string[][];
}

export interface Voucher {
    date: string | null;
    code: string | null;
    password: string | null;
    descricao: string | null;
    expires_at: string | null;
    foto_capa: string | null;
    nome: string | null;
    selo: string | null;
    tutorial_observacao: string | null;
    tutorial_passos: string | null;
    tutorial_url: string | null;
    validade: string | null;
    valor: string | null;
    slug: string | null;
    status: string;
    produto_id?: number;
}

export function CineMarkComboWrap() {
    const history = useHistory();
    const { setCodes } = useCineMarkCombo();
    const { setHashCode } = useVoucherConfig();
    const [voucher, setVoucher] = useState<Voucher>({} as Voucher);
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean | null>(null);
    const [error, setError] = useState<string>("");

    const [modalVideo, setModalVideo] = useState<boolean>(false);
    const [modalTutorial, setModalTutorial] = useState<boolean>(false);

    const hash_code = history.location.pathname.replace("/c/", "");
    const personalite = [116, 123, 124];
    const configProduto = {
        title: "Produtos",
        video: voucher?.tutorial_url ? voucher?.tutorial_url : "",
        tutorial: voucher?.tutorial_passos ? voucher?.tutorial_passos : "[]",
        img: voucher?.foto_capa ? voucher?.foto_capa : "",
        tutorialObservacao: voucher?.tutorial_observacao
            ? voucher?.tutorial_observacao
            : "",
        onClose: () => {
            setModalTutorial(false);
            setModalVideo(false);
        },
        openModalVideo: () => {
            setModalTutorial(false);
            setModalVideo(true);
        },
    };

    const configVideo = {
        video: voucher?.tutorial_url ? voucher?.tutorial_url : "",
        modalVideo: modalVideo,
        closeModal: () => {
            setModalTutorial(true);
            setModalVideo(false);
        },
        // video: "81db6ab30a",
    };

    function clickOutModal(e: React.MouseEvent<HTMLElement>) {
        const element = (e.target as HTMLElement).dataset;

        if (element?.closemodal) {
            setModalTutorial(false);
            setModalVideo(false);
        }
    }

    useEffect(() => {
        async function checkToken() {
            setLoading(true);
            try {
                const { data } = await axios.post<ResponseVoucher>(
                    `https://yetz.app/api/pedido/check-url-combo`,
                    {
                        hash_code,
                    }
                );

                setHashCode(hash_code);

                if (!data?.voucher?.length) {
                    toast.error("Algo de errado aconteceu");
                    setLoading(false);
                    setSuccess(false);
                    setError("eita");
                    return;
                }
                if (!data?.codes?.length) {
                    toast.error("Algo de errado aconteceu");
                    setLoading(false);
                    setSuccess(false);
                    setError("eita");
                    return;
                }

                setSuccess(data.success);
                setError(data.error);
                setCodes(data.codes);

                const regexHasUrl =
                    /(http|https):\/\/(www\.)?[a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

                if (regexHasUrl.test(data?.voucher[0].code || "")) {
                    window.location.href = data?.voucher[0].code || "";
                } else {
                    setVoucher(data?.voucher[0]);
                }
            } catch (error: any) {
                console.log(error);
                toast.error("Algo de errado aconteceu");
                setLoading(false);
                setSuccess(false);
                setError("eita");
                return;
            }
            setLoading(false);
        }

        checkToken();
    }, [hash_code, setCodes, setHashCode]);

    return (
        <>
            {/* <div className="sandBox">
                <h1>Sand box</h1>
            </div> */}

            {success === false ? (
                <>
                    {error === "Link not found" && <Error />}
                    {voucher.status === "BLOQUEADO" && <Bloqueado />}
                    {personalite.includes(voucher.produto_id as number) ? (
                        <ExpiradoPersonalite
                            date={
                                voucher.expires_at
                                    ? formatDateUser(voucher.expires_at)
                                    : ""
                            }
                        />
                    ) : (
                        error === "Link expirado" && (
                            <Expirado
                                date={
                                    voucher.expires_at
                                        ? formatDateUser(voucher.expires_at)
                                        : ""
                                }
                            />
                        )
                    )}
                </>
            ) : (
                <ValePresenteCineMarckCombo
                    voucher={voucher ? voucher : undefined}
                    loading={loading}
                    setModalTutorial={setModalTutorial}
                    sandbox={true}
                />
            )}

            <Footer />

            <Modal
                component={ModalTutorial}
                active={modalTutorial}
                rest={configProduto}
                onClick={clickOutModal}
                maxWidthMobile={true}
            />

            <Modal
                onClick={clickOutModal}
                component={ModalVideo}
                active={modalVideo}
                rest={configVideo}
            />
        </>
    );
}

export function CineMarkCombo() {
    return (
        <UseCineMarkComboProvider>
            <CineMarkComboWrap />
        </UseCineMarkComboProvider>
    );
}
