import { ChangeEvent } from "react";
import { Box, Heading, Input, Button } from "aplication-yetz";
import { Formik, Form, ErrorMessage, FormikProps } from "formik";
import { useAuthentication } from "../../Hook/useAuthentication";
import { initialValues, validation, MyFormValues } from "./types";
import logoImg from "../../assets/sistema/yetzestoque_branco.svg";
import { maskCPF } from "../../util/helper";

// src/assets/sistema/yetzestoque_branco.svg

export function FormLogin() {
    const { login, loading } = useAuthentication();

    return (
        <Box>
            <img src={logoImg} alt="" />

            <Heading as="h2" color="white" mb="63px" fontSize="lg">
                Acesso Administrador
            </Heading>
            <Box>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validation}
                    onSubmit={(values, actions) => {
                        values = {
                            ...values,
                            document: values.document.replace(/\D/g, ""),
                        };

                        login(values);
                        actions.resetForm();
                    }}
                >
                    {(props: FormikProps<MyFormValues>) => {
                        const { setFieldValue, values } = props;

                        return (
                            <Form>
                                <Input
                                    placeholder="DIGITE SEU  LOGIN"
                                    name="document"
                                    width="100%"
                                    mb="28px"
                                    type="tel"
                                    autoComplete="organization"
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        const document = maskCPF(
                                            e.target.value
                                        );

                                        if (document.length <= 14) {
                                            setFieldValue("document", document);
                                        }
                                    }}
                                    value={values.document}
                                />
                                <ErrorMessage
                                    component="small"
                                    name="document"
                                />

                                <Input
                                    placeholder="DIGITE SUA SENHA"
                                    type="password"
                                    width="100%"
                                    mb="28px"
                                    name="password"
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setFieldValue(
                                            "password",
                                            e.target.value
                                        )
                                    }
                                />

                                <ErrorMessage
                                    component="small"
                                    name="password"
                                />

                                <Button
                                    type="submit"
                                    title="Entrar"
                                    color="orange200"
                                    background="white"
                                    loading={loading}
                                />
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </Box>
    );
}
