import { useEffect, useState, memo } from "react";
import { Box } from "aplication-yetz";
import { useVouchers, FornecedorById } from "../../Hook/useVouchers";
import { UseCarrinhoProvider } from "./Hook";
import { Goback } from "../Goback";
import { Header } from "./header";
import { Produto } from "./produto";
import { Carrinho } from "./carrinho";
import { FinalizarCompra } from "./finalizar";
import Modal from "../NovoModal";
import { Container } from "./styles";
import { toast } from "react-toastify";
import { Loader } from "../Loader";

function VoucherAdicionar() {
    const { getVouchersByFornecedor } = useVouchers();
    const [filtro, setFiltro] = useState(0);
    const [compras, setCompras] = useState<FornecedorById[]>([]);
    const [finalizarCompra, setFinalizarCompra] = useState(false);
    const [loadingCompras, setLoadingCompras] = useState(false);

    useEffect(() => {
        async function fetchData() {
            setLoadingCompras(true);
            const response = await getVouchersByFornecedor(filtro);

            if (response.status === 200) {
                setCompras(response.data.data);
            } else {
                toast.error("Erro ao carregar");
            }
            setLoadingCompras(false);
        }
        if (filtro !== 0) {
            fetchData();
        }
    }, [filtro, getVouchersByFornecedor]);

    return (
        <Container>
            <Box mb="73px">
                <Goback title="Novo Lote" />
            </Box>

            <div className="content-produto">
                <Header setFiltro={setFiltro} />

                {loadingCompras ? (
                    <div className="container-loading">
                        <Loader height={200} width={200} />
                    </div>
                ) : (
                    compras.map((item) => <Produto key={item.id} {...item} />)
                )}

                <Carrinho setFinalizarCompra={setFinalizarCompra} />

                <Modal
                    handleClose={() => setFinalizarCompra(!finalizarCompra)}
                    open={finalizarCompra}
                >
                    <FinalizarCompra
                        handleClose={() => setFinalizarCompra(false)}
                        fornecedor={filtro}
                    />
                </Modal>
            </div>
        </Container>
    );
}

function WrapperCarrinho() {
    return (
        <UseCarrinhoProvider>
            <VoucherAdicionar />
        </UseCarrinhoProvider>
    );
}

export const CarrinhoMemorized = memo(WrapperCarrinho);
