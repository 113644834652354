import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { getParamUrl } from "../util/helper";
import { useHistory } from "react-router-dom";
import { api } from "../services";
import { toast } from "react-toastify";
import { ConfigPage } from "./type";
interface UseLogEstoqueProps {
    children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseLogEstoque {
    logEstoque: LogEstoqueData;
    loading: boolean;
    getLogs: (page?: number) => Promise<void>;
    aprovarCarga(): Promise<any>;
    reprovarCarga(): Promise<any>;
}

interface LogEstoqueData extends ConfigPage {
    data: LogEstoque[];
}

interface LogEstoque {
    carga_id: number;
    carga_log_status_regra_id: number;
    created_at: string;
    data: string;
    foto_caṕa: string;
    id: number;
    produto_variacao_id: number;
    regra: Regras;
    row: number;
    updated_at: string;
    valor_reais: string;
    valor_yetz: string;
}

interface Regras {
    created_at: string;
    id: number;
    name: string;
    tipo: string;
    updated_at: string;
}

const Context = createContext<UseLogEstoque>({} as UseLogEstoque);

export function UseLogEstoqueProvider(props: UseLogEstoqueProps) {
    const { children } = props;
    const [loading, setLoading] = useState(false);
    const [logEstoque, setLogEstoque] = useState<LogEstoqueData>(
        {} as LogEstoqueData
    );

    const history = useHistory<{ id: number }>();
    const historyId = history.location.state?.id;
    const id = historyId ? historyId : getParamUrl();

    const getLogs = useCallback(
        async (page?: number) => {
            try {
                setLoading(true);
                const url = page
                    ? `/carga/${id}/logs?page=${page}`
                    : `/carga/${id}/logs`;

                const response = await api.get(url);
                setLogEstoque(response.data);
                setLoading(false);
            } catch (error) {
                toast.error("Erro ao carregar os logs");
                console.log(error);
                setLoading(false);
            }
        },
        [id]
    );

    async function aprovarCarga() {
        try {
            const response = await api.post(`carga/${id}/aprovar`);

            toast.success("Carga em Aprovação");

            return response;
        } catch (error: any) {
            toast.error("Ops algo de errado aconteceu ");
            return error;
        }
    }

    async function reprovarCarga() {
        try {
            const response = await api.post(`carga/${id}/reprovar`);

            toast.success("Carga aprovada com sucesso!");

            return response;
        } catch (error: any) {
            toast.error("Ops algo de errado aconteceu ");
            return error;
        }
    }

    useEffect(() => {
        getLogs();
    }, [getLogs]);

    return (
        <Context.Provider
            value={{
                loading,
                logEstoque,
                getLogs,
                aprovarCarga,
                reprovarCarga,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function useLogEstoque() {
    const context = useContext(Context);
    return context;
}
