import { Column } from "react-table";
import { Data } from "./types";

export const column: Column<Data>[] = [
    {
        Header: "ID",
        accessor: "id",
    },

    {
        Header: "NOME",
        accessor: "nome",
    },

    {
        Header: "USUÁRIO",
        accessor: "usuario",
    },

    {
        Header: "DATA",
        accessor: "data",
    },

    {
        Header: "SEM ESTOQUE",
        accessor: "sem_estoque",
    },
    {
        Header: "PEDIDO OUTLET",
        accessor: "pedido_outlet",
    },

    {
        Header: "STATUS",
        accessor: "status",
    },

    {
        Header: "",
        accessor: "catalogo",
        width: 85,
        maxWidth: 85,
    },
];
