import { useHistory, useLocation } from 'react-router-dom'

type UsePaginationData = {
    currentPage?: string,
    gotoPage: (page: number) => void
}

function usePagination(): UsePaginationData {
    const history = useHistory();

    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);

    const currentPage = searchParams.get("page");

    function gotoPage(page: number) {
        if (page > 0) {
            searchParams.set("page", page.toString());
        } else {
            searchParams.delete("page");
        }

        const search = searchParams.toString();

        const path = `${location.pathname}${search.length ? "?" + search : ""}`;

        history.push(path, location.state);
    }

    return {
        currentPage: currentPage ?? undefined,
        gotoPage
    }
}

export { usePagination }