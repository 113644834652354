import { Formik, Field, Form, ErrorMessage, FormikProps } from "formik";
import { initialValues, validation, FormRelatorio } from "./config";
import { Heading, Flex, ButtonSystem } from "aplication-yetz";
import { useRelatorioCompras } from "../../Hook/useRelatorioCompra";
import imgFechar from "../../assets/sistema/fechar-modal.svg";
import { Input, InputMask } from "../../components/Input";
import { Modal } from "./styles";
export function NovoRelatorio() {
    const { create, setModalCreate } = useRelatorioCompras();

    return (
        <Modal>
            <Flex justifyContent="space-between" alignItems="center" mb="34px">
                <Heading as="h2" fontSize="mdl">
                    Novo relatório
                </Heading>
                <button onClick={() => setModalCreate(false)}>
                    <img src={imgFechar} alt="Fechar" />
                </button>
            </Flex>

            <Formik
                initialValues={initialValues}
                enableReinitialize
                isInitialValid
                validationSchema={validation}
                onSubmit={create}
            >
                {(props: FormikProps<FormRelatorio>) => {
                    return (
                        <Form>
                            <div className="container-form">
                                <Field
                                    component={Input}
                                    name="title"
                                    label="TÍTULO"
                                />
                                <ErrorMessage component="small" name="title" />
                            </div>
                            <div className="container-form">
                                <Field
                                    component={InputMask}
                                    mask="99/99/9999"
                                    name="start"
                                    label="DATA DE ÍNICIO"
                                />
                                <ErrorMessage component="small" name="start" />
                            </div>
                            <div className="container-form">
                                <Field
                                    component={InputMask}
                                    mask="99/99/9999"
                                    name="end"
                                    label="DATA DE FIM"
                                />
                                <ErrorMessage component="small" name="end" />
                            </div>

                            <ButtonSystem
                                mt="42px"
                                width="220px"
                                loading={props.isSubmitting}
                                heightSpiner={18}
                                widthSpnier={18}
                            >
                                Cadastrar Relatório
                            </ButtonSystem>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
}
