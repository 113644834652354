import { useState } from "react";
import { Text } from "aplication-yetz";
import { useHistory } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { HistoryProps, useProduto, Variacao } from "../../Hook/useProduto";

import { Tabela } from "./table";
import { Header } from "./header";
import { SubHeader } from "./subHeader";
import { BoxDetalheProduto } from "../../components/BoxDetalheProduto";
import { BoxDetalheProdutoProps } from "../../components/BoxDetalheProduto/types";

import { Modal } from "../../components/Modal";
import { NovaVariacao } from "./modalVariacao";
import { TutorialProduto } from "./modalTutorial";
import { ModalProduto } from "../Produtos/modalProduto";
import { BoxExclusao } from "../../components/BoxNotificacao";

import { RegrasDetalheProduto } from "../../components/RegrasDetalheProduto";
import { RegrasDetalheProdutoProps } from "../../components/RegrasDetalheProduto/types";

import img01 from "../../assets/sistema/logo-card-01.svg";
import img02 from "../../assets/sistema/logo-card-02.svg";
import img03 from "../../assets/sistema/logo-card-03.svg";
import img04 from "../../assets/sistema/logo-card-04.svg";
import img05 from "../../assets/sistema/logo-card-05.svg";
import imgExcluir from "../../assets/sistema/excluir.svg";

import { RegrasStyles, BoxCards, LoaderStyles } from "./styles";
import { Permissions } from "../Permission";

export function ProdutoDetalhe() {
    const history = useHistory<HistoryProps>();
    const { showProduto: produto, deleteVariacao } = useProduto();
    const [filtro, setFiltro] = useState<string>("");
    const [modalProduto, setModalProduto] = useState<boolean>(false);
    const [modalVariacao, setModalVariacao] = useState<boolean>(false);
    const [modalTutorial, setModalTutorial] = useState<boolean>(false);
    const [modalExlusao, setModalExclusao] = useState<boolean>(false);
    const [idExclusaoVariacao, setIdExclusaoVariacao] = useState<number | null>(
        null
    );
    const [initalValuesVariacao, setInitalValuesVariacao] = useState<Variacao>(
        {} as Variacao
    );

    if (!history.location.state?.id) {
        history.goBack();
        return <></>;
    }

    if (Object.values(produto).length === 0) {
        return (
            <LoaderStyles>
                <Loader />
            </LoaderStyles>
        );
    }

    const arrayCard: BoxDetalheProdutoProps[] = [
        {
            img: img01,
            subTitulo: produto.id,
            titulo: "código",
        },
        {
            img: img02,
            subTitulo: produto.categorias
                .map((categoria) => categoria.categoria.nome)
                .join(", "),
            titulo: "Categoria",
        },
        {
            img: img03,
            subTitulo: produto.tipo,
            titulo: "Tipo",
        },
        {
            img: img04,
            subTitulo: produto.data_outlet,
            titulo: "30 dias",
        },
        {
            img: img05,
            subTitulo: "Desconto Outlet",
            titulo: produto.desconto_outlet,
        },
    ];

    const arrayRegras: RegrasDetalheProdutoProps[] = [
        {
            titulo: "Regras de Utilização",
            texto: produto.detalhes,
        },
        {
            titulo: "Descrição",
            texto: produto.descricao,
        },
        {
            titulo: "Informações Importantes",
            texto: produto.informacoes_importantes,
        },
        {
            titulo: "Informações Temporárias",
            texto: produto.informacoes_temporarias,
        },
    ];

    const {
        nome,
        tipo,
        categorias,
        status,
        descricao,
        detalhes,
        informacoes_importantes,
        informacoes_temporarias,
        url_tutorial,
        foto_capa,
        selo,
        alerta_estoque,
        desconto_outlet,
        data_outlet,
        template,
        template_voucher_id,
        validade,
        tutorial_passos,
        external_link,
        external_link_label,
        template_validade_fixa,
    } = produto;

    const initialValues = {
        nome,
        tipo,
        categorias: categorias.map((categoria) => categoria.categoria.id),
        status,
        descricao,
        detalhes,
        informacoes_importantes,
        informacoes_temporarias,
        url_tutorial,
        foto_capa,
        selo,
        alerta_estoque,
        desconto_outlet,
        data_outlet,
        template,
        template_voucher_id,
        template_validade_fixa,
        validade,
        tutorial_passos,
        external_link: external_link ? external_link : "",
        external_link_label: external_link_label ? external_link_label : "",
    };

    const configProduto = {
        title: "Produtos",
        description: "Adicionar produtos",
        onClose: () => setModalProduto(false),
        produto: initialValues,
        id: produto.id,
        modalActive: modalProduto,
    };

    const configVariacao = {
        onClose: () => {
            setModalVariacao(false);
            setInitalValuesVariacao({} as Variacao);
        },
        id: produto.id,
        variacao: initalValuesVariacao,
    };

    // console.log(produto.tutorial_passos);intialValue

    const configTutorial = {
        onClose: () => {
            setModalTutorial(false);
        },

        id: produto.id,
        tutorial: {
            tutorial_passos: produto.tutorial_passos
                ? produto.tutorial_passos
                : "[]",
            tutorial_observacao: produto.tutorial_observacao,
            tutorial_url: produto.tutorial_url,
            external_link: produto.external_link ? produto.external_link : "",
            external_link_label: produto.external_link_label
                ? produto.external_link_label
                : "",
        },
    };

    const excluir = {
        img: imgExcluir,
        title: (
            <Text as="p" fontWeight="600" fontSize="mdl" color="black">
                Tem certeza que deseja <br />
                excluir está variação?
            </Text>
        ),
        width: "500px",
        onClick: () => excluirVariacao(),
        closeModal: () => setModalExclusao(false),
    };

    async function excluirVariacao() {
        if (idExclusaoVariacao) {
            deleteVariacao(idExclusaoVariacao);
        }
        setModalExclusao(false);
    }

    return (
        <>
            <Header
                {...produto}
                setModalProduto={setModalProduto}
                setModalTutorial={setModalTutorial}
            />
            <BoxCards
                alignItems="flex-end"
                justifyContent="space-between"
                gridTemplateColumns="repeat(6, 1fr)"
                gridGap="22px"
                mb="74px"
            >
                <>
                    <img
                        src={produto.foto_capa}
                        alt=""
                        className="img-destaque"
                    />
                    {arrayCard.map((item, key) => (
                        <BoxDetalheProduto {...item} key={key} />
                    ))}
                </>
            </BoxCards>

            <RegrasStyles>
                {arrayRegras.map((item, key) => (
                    <RegrasDetalheProduto {...item} key={key} />
                ))}
            </RegrasStyles>

            <SubHeader
                setFiltro={setFiltro}
                setModalVariacao={setModalVariacao}
            />

            <Tabela
                variacaoes={produto.variacoes}
                filtro={filtro}
                setInitalValuesVariacao={setInitalValuesVariacao}
                setModalVariacao={setModalVariacao}
                setModalExclusao={setModalExclusao}
                setIdExclusaoVariacao={setIdExclusaoVariacao}
            />

            <Permissions role="produto.variacao.update">
                <Modal
                    component={NovaVariacao}
                    active={modalVariacao}
                    right={true}
                    rest={configVariacao}
                />
            </Permissions>

            <Permissions role="produto.update">
                <Modal
                    component={ModalProduto}
                    active={modalProduto}
                    right={true}
                    rest={configProduto}
                />
            </Permissions>

            <Permissions role="produto.destroy">
                <Modal
                    component={BoxExclusao}
                    active={modalExlusao}
                    rest={excluir}
                />
            </Permissions>

            <Permissions role="produto.update">
                <Modal
                    component={TutorialProduto}
                    active={modalTutorial}
                    rest={configTutorial}
                    right={true}
                />
            </Permissions>
        </>
    );
}
