import React, { createContext, useContext, useState, useEffect } from "react";
import { Roles } from "./useUser";
import { api } from "../services";
import { Variacao } from "./useProduto";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";

interface UseVouchersProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface UseVouchersData {
    vouchers: DataVouchers;
    loadingVouchers: boolean;
    // getVouchers: () => Promise<DataVouchers>;
    getFornecedor: () => Promise<Fornecedor[]>;
    getVouchersByFornecedor(id?: number): Promise<AxiosResponse<DataForncedor>>;
    storeVoucher(values: StoreVouchers): Promise<AxiosResponse>;
    aprovaVoucher(id?: number): Promise<AxiosResponse>;
    reprovaVoucher(id?: number): Promise<AxiosResponse>;
    paginacaoVouchers: (page: number) => void;
    itensVouchers: DataItensVouchers;
    getItensVoucher: (id: number, page?: string) => void;
    loadingItensVoucher: boolean;
    modal: boolean;
    setModal: (value: boolean) => void;
    setFilter: (value: string) => void;
    variacoes: Variacoes[];
    setVariacoes: (value: Variacoes[]) => void;
}

export interface DataVouchers {
    data: Voucher[];
    current_page: number;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string | null;
    per_page: number;
    prev_page_url: string | null;
    to: number;
    total: number;
}

export interface Voucher {
    carga_id: number;
    created_at: string;
    id: number;
    parceiro_id: number;
    prefixo: string;
    status: string;
    summary: string;
    updated_at: string;
    user_id: number;
    validade: string;
}

export interface SummaryItens {
    total_itens: 1;
    total_reais: 20;
    variacoes: Variacoes;
}

export interface Variacoes {
    foto_capa: string;
    nome: string;
    quantidade: number;
    valor_reais: string;
    valor_yetz: string;
}

export interface Fornecedor {
    id: number;
    nome: string;
    descrição: null | string;
    cnpj: null | string;
    created_at: string;
    updated_at: string;
}

export interface User {
    id: number;
    name: string;
    email: string;
    status: number;
    color: null | string;
    light_color: null | string;
    phone_number: string;
    voucher_limit_time: null | string;
    last_login: string;
    created_at: null | string;
    updated_at: string;
    whitelist: boolean;
    "sem-estoque": boolean;
    "pedido-outlet": boolean;
    roles: Roles[];
}

export interface DataItensVouchers {
    data: Itens[];
    current_page: number;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string | null;
    per_page: number;
    prev_page_url: string | null;
    to: number;
    total: number;
}

interface Itens {
    id: number;
    compra_id: number;
    produto_variacao_id: number;
    fornecedor_code: string;
    quantidade: number;
    valor: string;
    variacao: Variacao;
    status: string;
    log: string;
}

export interface DataForncedor {
    data: FornecedorById[];
    current_page: number;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string | null;
    per_page: number;
    prev_page_url: string | null;
    to: number;
    total: number;
}

export interface FornecedorById {
    alerta_estoque: number;
    created_at: string;
    foto_capa: string;
    id: number;
    nome: string;
    porcentagem: null;
    produto_id: number;
    status: number;
    status_alerta_estoque: number;
    tipo: string;
    updated_at: string;
    valor_reais: string;
    valor_yetz: string;
}

export interface StoreVouchers {
    job?: string;
    nome?: string;
    parceiro_id: number;
    variacoes: StoreVaricao[];
}

interface StoreVaricao {
    produto_variacao_id: number;
    quantidade: number;
}

const UseVouchersContext = createContext<UseVouchersData>(
    {} as UseVouchersData
);

export function UseVoucherProvider(props: UseVouchersProviderProps) {
    const { children } = props;
    const [itensVouchers, setItensVouchers] = useState<DataItensVouchers>(
        {} as DataItensVouchers
    );
    const [vouchers, setVouchers] = useState<DataVouchers>({} as DataVouchers);
    const [variacoes, setVariacoes] = useState<Variacoes[]>([]);
    const [loadingVouchers, setLoadingVouchers] = useState<boolean>(false);
    const [modal, setModal] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>("");
    const [loadingItensVoucher, setLoadingItensVoucher] =
        useState<boolean>(false);

    async function getVouchers(filter?: string) {
        setLoadingVouchers(true);

        try {
            const { data } = await api.get<DataVouchers>(
                `/voucher-yetz${filter}`
            );
            setVouchers(data);
        } catch (error) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
        } finally {
            setLoadingVouchers(false);
        }
    }

    async function paginacaoVouchers(page: number) {
        setLoadingVouchers(true);
        const hasFilter = filter ? `&STATUS=${filter}` : "";
        try {
            const { data } = await api.get<DataVouchers>(
                `voucher-yetz?page=${page}${hasFilter}`
            );
            setVouchers(data);
        } catch (error) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
        } finally {
            setLoadingVouchers(false);
        }
    }

    async function getItensVoucher(id: number, page?: string) {
        setLoadingItensVoucher(true);
        try {
            const params: Record<string, string> = {};

            if (page?.length) {
                params["page"] = page;
            }

            const { data } = await api.get<DataItensVouchers>(
                `/compra/${id}/itens`,
                {
                    params,
                }
            );
            setItensVouchers(data);
            return data;
        } catch (error) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
        } finally {
            setLoadingItensVoucher(false);
        }
    }

    async function getFornecedor() {
        try {
            const { data } = await api.get<Fornecedor[]>("/parceiro");
            return data;
        } catch (error) {
            return error as Fornecedor[];
        }
    }

    async function getVouchersByFornecedor(id: number = 1) {
        try {
            const response = await api.get<DataForncedor>(
                `/parceiro/produtos?tipo=VOUCHER_YETZ`
            );
            return response;
        } catch (error: any) {
            console.log(error);
            throw new Error(error);
        }
    }

    async function storeVoucher(values: StoreVouchers) {
        try {
            const response = await api.post<StoreVouchers>("/voucher-yetz", {
                ...values,
            });

            toast.success("Voucher salva com sucesso!");
            return response;
        } catch (error: any) {
            toast.error("Erro ao salvar compra!");
            return error;
        }
    }

    async function aprovaVoucher(id: number) {
        try {
            const response = await api.post<StoreVouchers>(
                `voucher-yetz/${id}/aprovar`
            );

            toast.success("Voucher aprovada com sucesso!");
            return response;
        } catch (error: any) {
            toast.error("Erro ao aprovar compra!");
            return error;
        }
    }

    async function reprovaVoucher(id: number) {
        try {
            const response = await api.post<StoreVouchers>(
                `voucher-yetz/${id}/reprovar`
            );

            toast.success("Voucher reprovada com sucesso!");
            return response;
        } catch (error: any) {
            toast.error("Erro ao reprovar compra!");
            return error;
        }
    }

    useEffect(() => {
        const hasFilter = filter ? `?STATUS=${filter}` : "";
        if (window.location.pathname === "/vouchers") {
            getVouchers(hasFilter);
        }
    }, [filter]);

    return (
        <UseVouchersContext.Provider
            value={{
                vouchers,
                loadingVouchers,
                // getVouchers,
                modal,
                setModal,
                setFilter,
                getFornecedor,
                getVouchersByFornecedor,
                storeVoucher,
                aprovaVoucher,
                reprovaVoucher,
                paginacaoVouchers,
                itensVouchers,
                getItensVoucher,
                loadingItensVoucher,
                variacoes,
                setVariacoes,
            }}
        >
            {children}
        </UseVouchersContext.Provider>
    );
}

export function useVouchers() {
    const context = useContext(UseVouchersContext);
    return context;
}
