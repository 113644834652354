import { Link } from "react-router-dom";
import { memo } from "react";
import { usePedido } from "../../Hook/usePedido";
import { filterPedidoPlataforma } from "../../util/helper";

import { CardMemo } from "./cards";
import { ContainerCards } from "./styles";

interface PlataformaProps {
    filtro: string;
}

function Plataforma(props: PlataformaProps) {
    const { filtro } = props;
    const { pedidoPlataforma } = usePedido();

    const dadosFiltrados = filterPedidoPlataforma(pedidoPlataforma, filtro);

    return (
        <ContainerCards>
            {dadosFiltrados.map((card) => (
                <Link
                    to={{
                        pathname: `/pedidos/plataforma/${card.id}`,
                        state: {
                            id: card.id,
                        },
                    }}
                >
                    <CardMemo {...card} />
                </Link>
            ))}
        </ContainerCards>
    );
}

export const PlataformaMemo = memo(Plataforma);
