import { Column } from "react-table";
import { Data, Usuario } from "./types";
import * as Yup from "yup";

const regex =
    /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

// eslint-disable-next-line no-useless-escape
const regexCPF = /^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}$/;

export const column: Column<Data>[] = [
    {
        Header: "NOME",
        accessor: "nome",
    },
    {
        Header: "E-MAIL",
        accessor: "email",
    },
    {
        Header: "CELULAR",
        accessor: "celular",
    },
    {
        Header: "LOGIN",
        accessor: "login",
    },
    {
        Header: "ULTIMO ACESSO",
        accessor: "ultimo_acesso",
    },
    {
        Header: "PERFIL",
        accessor: "perfil",
    },
    {
        Header: "AÇÕES",
        accessor: "acoes",
        width: 131,
        maxWidth: 131,
    },
];

export const initialValues: Usuario = {
    name: "",
    email: "",
    document: "",
    phone_number: "",
    status: 0,
    role: "",
    password: "",
    password_confirmation: "",
};

export const addSchema = Yup.object().shape({
    name: Yup.string().required("Nome é obrigatório"),
    email: Yup.string()
        .email("E-mail inválido")
        .required("E-mail é obrigatório"),
    document: Yup.string()
        .required("Campo Obrigatório")
        .matches(regexCPF, "CPF inválido"),
    phone_number: Yup.string().required("Celular é obrigatório"),
    status: Yup.string(),
    role: Yup.string().required("Perfil é obrigatório"),
    password: Yup.string()
        .required("Senha Obrigatório")
        .min(8, "Senha deve ter no mínimo 8 caracteres")
        .matches(
            regex,
            "Senha deve conter letras, números e caracteres especiais"
        ),
    password_confirmation: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Senha Não Confere"
    ),
});

export const updateSchema = Yup.object().shape({
    name: Yup.string().required("Nome é obrigatório"),
    email: Yup.string()
        .email("E-mail inválido")
        .required("E-mail é obrigatório"),
    document: Yup.string()
        .required("Campo Obrigatório")
        .matches(regexCPF, "CPF inválido"),
    phone_number: Yup.string().required("Celular é obrigatório"),
    status: Yup.string(),
    role: Yup.string().required("Perfil é obrigatório"),
    password: Yup.string()
        .min(8, "Senha deve ter no mínimo 8 caracteres")
        .matches(
            regex,
            "Senha deve conter letras, números e caracteres especiais"
        ),
    password_confirmation: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Senha Não Confere"
    ),
});
