import { Text } from "aplication-yetz";
import { formatCurrentYETZ } from "../../util/helper";
import { BoxCatalogoStyles } from "./styles";

export interface BoxCatalogoProps {
    id: number;
    img: string;
    valor_reais: string;
    valor_yetz: string;
    ativo: boolean;
    handleClick: (id: number) => void;
}

export function BoxCatalogo({
    id,
    img,
    valor_reais,
    valor_yetz,
    ativo,
    handleClick,
}: BoxCatalogoProps) {
    return (
        <BoxCatalogoStyles isSelected={ativo} onClick={() => handleClick(id)}>
            <div className="container-img">
                <img src={img} alt="" />
            </div>

            <div className="container-text">
                <Text fontSize="md" textTransform="uppercase">
                    {id}
                </Text>
                <Text fontSize="md" textTransform="uppercase">
                    {valor_reais}
                </Text>
                <Text fontSize="md" textTransform="uppercase">
                    {formatCurrentYETZ(parseFloat(valor_yetz))} YETZ
                </Text>
            </div>
        </BoxCatalogoStyles>
    );
}
