import { useState } from "react";
import { Header } from "./header";
import { Modal } from "../ModalCenter";
import Table from "./table";
import { useVouchers } from "../../Hook/useVouchers";
import { Filter } from "./filtros";

export function Voucher() {
    const [filter, setFilter] = useState<string>("");
    const { modal, setModal } = useVouchers();

    return (
        <>
            <Header setFilter={setFilter} />
            <Table filter={filter} />

            <Modal open={modal} handleClose={() => setModal(!modal)}>
                <Filter />
            </Modal>
        </>
    );
}
