import { LojaVirtual } from "./buttonLojaVirtual";
import { ExclusivoNoAPP } from "./buttonExclusivoNoAPP";
import { FisicaEVirtual } from "./buttonFisicaEVirtual";
import { LojaFisica } from "./buttonLojaFisica";
import { Telefone } from "./buttonTelefone";
import { LojaVirtualEAPP } from "./buttonLojaVirtualEAPP";
import { useEffect, useState } from "react";

export interface ISelos {
    idVariacao: number;
    link: string;
    selo: number;
    variacao?: string;
    templateId: number;
}

const arrVariacao = [
    {
        title: "VOCÊ PODERÁ UTILIZAR ESSE VOUCHER DIGITAL",
        id: [343, 356, 123, 322, 342, 116, 124, 145, 146],
    },
];

export function Selos(props: ISelos) {
    const { idVariacao, link, selo } = props;
    const [variacao, setVariacao] = useState("");

    useEffect(() => {
        arrVariacao.forEach((item) => {
            if (item.id.includes(idVariacao)) {
                setVariacao(item.title);
            }
        });
    }, [idVariacao]);

    switch (selo) {
        case 1:
            return (
                <LojaVirtual
                    idVariacao={idVariacao}
                    link={link}
                    variacao={variacao}
                    templateId={props.templateId}
                />
            );
        case 2:
            return (
                <LojaFisica
                    idVariacao={idVariacao}
                    link={link}
                    variacao={variacao}
                    templateId={props.templateId}
                />
            );
        case 3:
            return (
                <FisicaEVirtual
                    idVariacao={idVariacao}
                    link={link}
                    variacao={variacao}
                    templateId={props.templateId}
                />
            );
        case 4:
            return (
                <ExclusivoNoAPP
                    idVariacao={idVariacao}
                    link={link}
                    variacao={variacao}
                    templateId={props.templateId}
                />
            );
        case 5:
            return (
                <Telefone
                    idVariacao={idVariacao}
                    link={link}
                    variacao={variacao}
                    templateId={props.templateId}
                />
            );
        case 6:
            return (
                <LojaVirtualEAPP
                    idVariacao={idVariacao}
                    link={link}
                    variacao={variacao}
                    templateId={props.templateId}
                />
            );

        default:
            return <></>;
    }
}
