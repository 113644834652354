import { Relatorio } from "../../components/Relatorio";
import { UseRelatorioProvider } from "../../Hook/useRelatorio";

export function PageRelatorio() {
    return (
        <UseRelatorioProvider>
            <Relatorio />
        </UseRelatorioProvider>
    );
}
