import { useState, useEffect } from "react";
import { TextImg, Text, Flex } from "aplication-yetz";
import { useHistory } from "react-router-dom";
import { HeaderModalDetalheCarga } from ".";
import { HistorioCarga, useEstoque } from "../../Hook/useEstoque";
import { Loader } from "../Loader";
import { formatDateIso, formatCurrentMoney } from "../../util/helper";
import { api } from "../../services";
import userImg from "../../assets/sistema/userAlerta.svg";
import anexoImg from "../../assets/sistema/anexo.svg";
import dowloadImg from "../../assets/sistema/dowload.svg";
import checkImg from "../../assets/sistema/check-com-backgroun.svg";
import olhoImg from "../../assets/sistema/olho.svg";
import fecharImg from "../../assets/sistema/fechar.svg";
import ImgLog from "../../assets/sistema/log-sistema.svg";

import { HeaderNotificacaoStyles } from "./styles";
import { toast } from "react-toastify";

interface Header extends HistorioCarga {
    setDetalheCargaEstoque: (value: HistorioCarga[]) => void;
    setModalDetalheCarga: (value: boolean) => void;
    setHeaderModalDetalheCarga: (props: HeaderModalDetalheCarga) => void;
    setModalConfirmaAprovacao: (value: boolean) => void;
    setModalConfirmaReprova: (value: boolean) => void;
    setCargaId: (value: number) => void;
}

export function HeaderNotificacao(props: Header) {
    const {
        nome,
        id,
        total_qtd,
        created_at,
        total_reais,
        setModalConfirmaAprovacao,
        setModalConfirmaReprova,
        setCargaId,
    } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const { getHistoricoCargasByIdSummary } = useEstoque();

    async function open(props: Header) {
        const {
            setDetalheCargaEstoque,
            setHeaderModalDetalheCarga,
            setModalDetalheCarga,
        } = props;
        setModalDetalheCarga(true);
        try {
            const { data } = await getHistoricoCargasByIdSummary(id);
            const { total, total_reais, ...rest } = JSON.parse(data.summary);
            const arrayDeObjetos = Object.values(rest) as any;
            const formatedArry = arrayDeObjetos.map((item: any) => {
                return {
                    ...item,
                    id: item.id,
                    total: item.quantidade,
                    produto: {
                        foto_capa: item.foto_capa,
                        nome: item.nome,
                    },
                };
            });

            console.log(formatedArry);

            setDetalheCargaEstoque([]);
            setDetalheCargaEstoque(formatedArry);

            setHeaderModalDetalheCarga({} as HeaderModalDetalheCarga);
            setHeaderModalDetalheCarga({
                nome,
                total_valor: formatCurrentMoney(Number.parseFloat(total_reais)),
                total_voucher: total,
                date: formatDateIso(created_at),
            });
        } catch (error) {
            setModalDetalheCarga(false);
        }
    }

    async function exportar() {
        setLoading(true);

        const carga_id = props.id;

        try {
            const response = await api.get(`carga/${carga_id}/export`);

            const { data } = response;
            window.open(data.file);
        } catch (error: any) {
            const { data } = error.response;
            toast.error(data.message);
        }

        setLoading(false);
    }

    useEffect(() => {}, []);

    return (
        <HeaderNotificacaoStyles>
            <h3>{nome}</h3>

            <TextImg color="gray300" className="user" img={userImg}>
                {nome}
            </TextImg>

            <Text>{id}</Text>

            <Text>{formatDateIso(created_at)}</Text>

            <TextImg img={anexoImg}>{total_qtd}</TextImg>

            <Text>{formatCurrentMoney(Number.parseFloat(total_reais))}</Text>

            <Flex justifyContent="space-between" width="180px">
                <Flex width="250px" justifyContent="space-between">
                    <button onClick={() => open(props)}>
                        <img src={olhoImg} alt="" />
                    </button>
                    <button onClick={() => history.push(`estoque/log/${id}`)}>
                        <img src={ImgLog} alt="" />
                    </button>

                    <button onClick={() => exportar()}>
                        {loading ? (
                            <Loader height={22} width={22} />
                        ) : (
                            <img src={dowloadImg} alt="" />
                        )}
                    </button>

                    <button
                        onClick={() => {
                            setModalConfirmaAprovacao(true);
                            setCargaId(props.id);
                        }}
                    >
                        <img src={checkImg} alt="" />
                    </button>

                    <button
                        onClick={() => {
                            setModalConfirmaReprova(true);
                            setCargaId(props.id);
                        }}
                    >
                        <img src={fecharImg} alt="" />
                    </button>
                </Flex>
            </Flex>
        </HeaderNotificacaoStyles>
    );
}
