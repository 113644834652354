import { EstoqueProvider } from "../../Hook/useEstoque";
import { UseCompraProvider } from "../../Hook/useCompras";
import { Estoque } from "../../components/Estoque";

export function PageEstoque() {
    return (
        <UseCompraProvider>
            <EstoqueProvider>
                <Estoque />
            </EstoqueProvider>
        </UseCompraProvider>
    );
}
