import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    width: auto;
    .check {
        width: 16px;
        height: 16px;
        border: 1px solid #cfcfcf;
        margin-right: 10px;
        cursor: pointer;
    }
    &.active {
        .check {
            background: #930035;
        }
    }
`;
