import { UseLogEstoqueProvider } from "../../Hook/useLogEstoque";
import { LogEstoque } from "../../components/LogEstoque";

export function PageLogEstoque() {
    return (
        <UseLogEstoqueProvider>
            <LogEstoque />
        </UseLogEstoqueProvider>
    );
}
