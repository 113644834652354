import styled from "styled-components";

export const Container = styled.div`
    width: 400px;
    padding: 0px 32px;
    h1 {
        margin: 32px 0px;
    }
    button {
        width: auto;
    }
`;
