import styled from "styled-components";

export const Container = styled.div`
    padding: 46px 34px 33px 34px;
    background: #fff;
    width: 375px;
    .input-text {
        &.error {
            .MuiOutlinedInput-notchedOutline {
                border-color: red !important;
            }

            .MuiOutlinedInput-input {
                color: red !important;
            }

            .MuiInputLabel-root {
                color: red !important;
            }
        }
    }
    .btn {
        width: 100%;
        &.enviar {
            background: transparent;
            color: ${({ theme }) => theme.colors.primary};
            img {
                position: relative;
                top: 4px;
                left: 4px;
            }
        }
    }
    .timer {
        width: 100%;
        display: flex;
        align-items: center;
    }
    .close {
        position: absolute;
        top: 14px;
        right: 13px;
    }
    .text-img {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        p {
            color: #807d7d;
        }

        span {
            text-align: left;
            display: block;
            width: 100%;
            color: red;
        }
    }
`;
