import { MouseEvent, MutableRefObject } from "react";
import { LocalStoreProps } from "../Hook/useAuthentication";
import { User } from "../Hook/useUser";
import { Produto, Variacao, Categoria } from "../Hook/useProduto";
import { Catalogo, Plataforma } from "../Hook/usePlataforma";
import { Plataforma as PedidoPlataforma } from "../Hook/usePedido";
import { Estoque, HistorioCarga } from "../Hook/useEstoque";
import { dados } from "../Hook/useWhitelist";
import { Dados } from "../components/Pedido/table";
import { ModalFiltroDados } from "../components/EstoqueDetalhe";
import { Data as DetalhePedidoPlataforma } from "../components/DetalhePedidoPlataforma/novaTable";
import { Compras } from "../Hook/useCompras";
import { Voucher } from "../Hook/useVouchers";

export function clearHeight(itens: HTMLDivElement[]) {
    itens.forEach(function (item) {
        if (item) {
            item.classList.remove("active");
            item.style.height = "75px";
        }
    });
}

export function realParaFloat(valor: string): number {
    // Remove todos os caracteres que não sejam dígitos ou vírgula
    const valorLimpo = valor.replace(/[^0-9,]/g, "");

    // Substitui a vírgula por ponto
    const valorComPonto = valorLimpo.replace(",", ".");

    // Converte o valor em string para número de ponto flutuante (float)
    const valorFloat = parseFloat(valorComPonto);

    return valorFloat;
}

export function msg422(msg: any) {
    const keys = Object.keys(msg);

    return keys
        .map((key: string) => {
            return msg[key];
        })
        .join(", ");
}

export function handleOpen(
    refItens: MutableRefObject<HTMLDivElement[]>,
    e: MouseEvent<Element>,
    key: number
) {
    let el = refItens.current[key];

    let altura = (el.querySelector(".conteudo") as HTMLElement).offsetHeight;

    const checkClass = (e.target as Element).classList.value;
    const checkTag = (e.target as Element).tagName;
    const elementosDisable = [
        "toggle",
        "active toggle",
        "false toggle",
        "undefined toggle",
    ];

    const tagsDisable = ["SPAN"];

    if (
        !elementosDisable.includes(checkClass) &&
        !tagsDisable.includes(checkTag)
    ) {
        if (el?.classList.value.includes("active")) {
            el.style.height = "75px";
            el.classList.remove("active");
        } else {
            clearHeight(refItens.current);
            el?.classList.add("active");

            if (el) {
                el.style.height = altura + 76 + "px";
            }
        }
    }
}

export function handleOpenPlataforma(
    refItens: MutableRefObject<HTMLDivElement[]>,
    e: MouseEvent<Element>,
    key: number
) {
    let el = refItens.current[key];

    let altura = (el.querySelector(".conteudo") as HTMLElement).offsetHeight;

    const checkClass = (e.target as Element).classList.value;
    const checkTag = (e.target as Element).tagName;
    const elementosDisable = [
        "toggle",
        "active toggle",
        "false toggle",
        "undefined toggle",
    ];

    const tagsDisable = ["SPAN"];

    if (
        !elementosDisable.includes(checkClass) &&
        !tagsDisable.includes(checkTag)
    ) {
        if (el?.classList.value.includes("active")) {
            el.style.height = "72px";
            el.classList.remove("active");
        } else {
            clearHeight(refItens.current);
            el?.classList.add("active");

            if (el) {
                el.style.height = altura + 76 + "px";
            }
        }
    }
}

export function getToken() {
    try {
        if (localStorage && Object.entries(localStorage)[0]) {
            if (Object.entries(localStorage)[0][1]) {
                const local = JSON.parse(Object.entries(localStorage)[0][1]);
                if (local.token) return local.token;
            }
        }
    } catch (error) {
        console.log(error);
        return false;
    }

    return false;
}

export function getLocalStore(): LocalStoreProps | undefined {
    if (Object.entries(localStorage)[0]) {
        if (Object.entries(localStorage)[0][1]) {
            const local: LocalStoreProps = JSON.parse(
                Object.entries(localStorage)[0][1]
            );
            return local;
        }
    }
}

export function filterUser(filtro: string, arr: User[]) {
    if (arr.length && filtro !== "") {
        arr = arr.filter(
            (user) =>
                JSON.stringify(user, [
                    "name",
                    "email",
                    "phone_number",
                    "status",
                ])
                    .toLocaleLowerCase()
                    .includes(filtro.toLocaleLowerCase()) ||
                JSON.stringify(user.roles, ["name", "updated_at"])
                    .toLocaleLowerCase()
                    .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

export function filterPlataforma(filtro: string, arr: Plataforma[]) {
    if (arr.length && filtro !== "") {
        arr = arr.filter((plataforma) =>
            JSON.stringify(plataforma, ["id", "name", "email"])
                .toLocaleLowerCase()
                .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

export function filterCompras(filtro: string, arr: Compras[]) {
    if (arr?.length && filtro !== "") {
        arr = arr.filter((plataforma) =>
            JSON.stringify(plataforma, ["job", "nome", "valor_total"])
                .toLocaleLowerCase()
                .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

export function filterVoucher(filtro: string, arr: Voucher[]) {
    if (arr?.length && filtro !== "") {
        arr = arr.filter((plataforma) =>
            JSON.stringify(plataforma, ["prefixo", "status", "validade"])
                .toLocaleLowerCase()
                .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

export function filterWhitelist(filtro: string, arr: dados[]) {
    if (arr && arr.length && filtro !== "") {
        arr = arr.filter((whitelist) =>
            JSON.stringify(whitelist, ["id", "ip", "created_at"])
                .toLocaleLowerCase()
                .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

export function filterDetalhePlataforma(filtro: string, arr: Catalogo[]) {
    if (arr.length && filtro !== "") {
        arr = arr.filter((catalogo) =>
            JSON.stringify(catalogo, [
                "id",
                "nome",
                "valor_yetz",
                "valor_reais",
            ])
                .toLocaleLowerCase()
                .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

export function formatDateTimeDb(date: string) {
    const dateTime = new Date(date);
    const dateFormated = dateTime.toLocaleDateString("pt-BR");
    const timeFormated = dateTime.toLocaleTimeString("pt-BR");
    const fullDate = dateFormated + " - " + timeFormated;
    return fullDate.replace(/\//g, ".");
}

export function formatDateDb(date: string) {
    return date.replace(/\//g, "-").split("-").reverse().join("-");
}

export function formatCnpj(cnpj: string) {
    return cnpj
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1/$2")
        .replace(/(\d{4})(\d)/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1");
}

export function formatDateIso(date: string) {
    const dateIso = new Date(date);
    const dateFormated = dateIso.toLocaleDateString("pt-BR");
    const timeFormated = dateIso.toLocaleTimeString("pt-BR");
    const fullDate = dateFormated + " - " + timeFormated;
    return fullDate;
    // return fullDate.replace(/\//g, ".");
}

export function formatDateUser(date: string) {
    const dateIso = new Date(date);
    const dateFormated = dateIso.toLocaleDateString("pt-BR");

    return dateFormated;
}

export function daysBetween(endDate: string) {
    const start = new Date();
    const end = new Date(endDate);
    const diffTime = Math.abs(end.getTime() - start.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays - 1;
}

export function formatCurrentMoney(value: number) {
    return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    }).format(value);
}

export function containString(str: string, search: string) {
    return str.indexOf(search) < 0 ? false : true;
}

export function formatCurrentYETZ(value: number) {
    const valor = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    })
        .format(value)
        .replace("R$", "");

    const posVirgula = valor.indexOf(",");

    return valor.substring(0, posVirgula);
}

export function filterProduto(arr: Produto[], filtro: string) {
    if (arr.length && filtro !== "") {
        arr = arr.filter(
            (user) =>
                JSON.stringify(user, ["id", "tipo", "nome", "url_tutorial"])
                    .toLocaleLowerCase()
                    .includes(filtro.toLocaleLowerCase()) ||
                JSON.stringify(user.variacoes, [
                    "nome",
                    "alerta_estoque",
                    "valor_reais",
                    "valor_yetz",
                ])
                    .toLocaleLowerCase()
                    .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

export function filterCategoria(arr: Categoria[], filtro: string) {
    if (arr.length && filtro !== "") {
        arr = arr.filter((user) =>
            JSON.stringify(user, ["id", "nome", "descricao"])
                .toLocaleLowerCase()
                .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

export function filterVariacao(arr: Variacao[], filtro: string) {
    if (arr.length && filtro !== "") {
        arr = arr.filter((user) =>
            JSON.stringify(user, [
                "alerta_estoque",
                "id",
                "nome",
                "valor_reais",
                "valor_yetz",
            ])
                .toLocaleLowerCase()
                .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

export function filterPedidoPlataforma(
    arr: PedidoPlataforma[],
    filtro: string
) {
    if (arr.length && filtro !== "") {
        arr = arr.filter((pedidoPlataforma) =>
            JSON.stringify(pedidoPlataforma, [
                "id",
                "name",
                "total_pedidos",
                "total_vouchers",
                "total_vouchers_reais",
                "total_vouchers_utilizados",
            ])
                .toLocaleLowerCase()
                .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

export function filterEstoque(arr: Estoque[], filtro: string) {
    if (arr.length && filtro !== "") {
        arr = arr.filter((user) =>
            JSON.stringify(user, ["nome", "valor_yetz", "valor_reais", "id"])
                .toLocaleLowerCase()
                .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

interface FiltroDetalhePedido {
    id: number;
    valor_reais: string;
    data_hora: string;
    status: string;
    compras_created_at: string;
    voucher: JSX.Element;
    oc: string;
    fornecedor: string;
}
export function filterDetalhePedido(
    arr: FiltroDetalhePedido[],
    filtro: string
) {
    if (arr.length && filtro !== "") {
        arr = arr.filter((detalhePedido) =>
            JSON.stringify(detalhePedido, [
                "id",
                "valor_reais",
                "status",
                "data_hora",
            ])
                .toLocaleLowerCase()
                .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

interface HistorioCargaById {
    img: string | undefined;
    id: number;
    nome: string | undefined;
    vencimento: number;
    valor_em_reais: string;
    valor_em_yetz: string;
}

export function filterDetalheCarga(arr: HistorioCargaById[], filtro: string) {
    if (arr.length && filtro !== "") {
        arr = arr.filter((user) =>
            JSON.stringify(user, [
                "id",
                "nome",
                "vencimento",
                "valor_em_reais",
                "valor_em_yetz",
            ])
                .toLocaleLowerCase()
                .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

interface FiltroTutorial {
    nome: string;
    categoria: string;
    foto_capa: string;
    tutorial_passos: string;
    tutorial_url: string;
    tutorial_observacao: string;
    external_link: string;
}

export function filterTutorial(arr: FiltroTutorial[], filtro: string) {
    if (arr.length && filtro !== "") {
        arr = arr.filter((user) =>
            JSON.stringify(user, ["nome", "categoria"])
                .toLocaleLowerCase()
                .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

interface filtroDetalheEstoque {
    id: number;
    validade: string;
    variacao_total: number;
    variacao_disp: number;
    created_at: string;
    outlet: boolean;
    name: string;
    validade_db?: string;
    atualizar_validade: boolean;
    fornecedor: string;
    status: {
        outlet: boolean;
        vencido: boolean;
        pre_outlet: boolean;
        valido: boolean;
    };
}

export function filterDetalheEstoque(
    arr: filtroDetalheEstoque[],
    filtro: string,
    dadosModal: ModalFiltroDados
) {
    if (dadosModal.user && dadosModal.user !== "") {
        arr = arr.filter((user) => user.name === dadosModal.user);
    }

    if (dadosModal.data && dadosModal.data !== "") {
        arr = arr.filter((user) => user.validade_db === dadosModal.data);
    }

    if (arr.length && filtro !== "") {
        arr = arr.filter((user) =>
            JSON.stringify(user, [
                "id",
                "validade",
                "variacao_total",
                "variacao_disp",
                "name",
                "created_at",
            ])
                .toLocaleLowerCase()
                .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

export function filterHistoricoCarga(arr: HistorioCarga[], filtro: string) {
    if (arr.length && filtro !== "") {
        arr = arr.filter((carga) =>
            JSON.stringify(carga, [
                "id",
                "nome",
                "name",
                "total",
                "total_reais_formatado",
                "created_at",
            ])
                .toLocaleLowerCase()
                .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

export function filterPedidos(arr: Dados[], filtro: string) {
    if (arr.length && filtro !== "") {
        arr = arr.filter((pedido) =>
            JSON.stringify(pedido, [
                "id",
                "pedido_id",
                "created_at",
                "status",
                "valor_reais",
            ])
                .toLocaleLowerCase()
                .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

interface FiltroDetalhePedidos {
    id: number;
    valor_reais: string;
    created_at: string;
    status: string;
    img: string;
}

export function filterDetalhePedidos(
    arr: FiltroDetalhePedidos[],
    filtro: string
) {
    if (arr.length && filtro !== "") {
        arr = arr.filter((pedido) =>
            JSON.stringify(pedido, [
                "id",
                "valor_reais",
                "created_at",
                "status",
            ])
                .toLocaleLowerCase()
                .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}
export function filterDetalhePedidoPlataforma(
    arr: DetalhePedidoPlataforma[],
    filtro: string
) {
    if (arr.length && filtro !== "") {
        arr = arr.filter(
            (pedido) =>
                JSON.stringify(pedido, [
                    "mes",
                    "total_pedido",
                    "qtd_voucher",
                    "utilizados",
                    "porcentagem",
                    "valor_reais",
                ])
                    .toLocaleLowerCase()
                    .includes(filtro.toLocaleLowerCase()) ||
                JSON.stringify(pedido?.ver_detalhe, [
                    "id",
                    "job",
                    "status",
                    "total_vouchers",
                    "total_vouchers_utilizados",
                    "total_vouchers_reais",
                ])
                    .toLocaleLowerCase()
                    .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

export function formatPhoneNumber(phoneNumberString: string) {
    if (!phoneNumberString) return false;

    const ddd = phoneNumberString.substr(2, 2);
    phoneNumberString = phoneNumberString.substr(4);

    var length = phoneNumberString.length;
    var telefoneFormatado;

    if (length === 8) {
        telefoneFormatado =
            "(" +
            ddd +
            ") " +
            phoneNumberString.substring(0, 4) +
            "-" +
            phoneNumberString.substring(4, 8);
    } else if (length === 9) {
        telefoneFormatado =
            "(" +
            ddd +
            ") " +
            phoneNumberString.substring(0, 5) +
            "-" +
            phoneNumberString.substring(5, 9);
    }

    var arr = telefoneFormatado?.split("");

    if (arr) {
        var telefone = arr
            .map((item, key) => {
                if (key >= 5 && key <= 12) {
                    return "*";
                } else {
                    return item;
                }
            })
            .join("");

        return telefone;
    }

    return "";
}

export function formataMoney(value: string) {
    if (!value) return "";

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

    return "R$ " + value;
}

export const maskCPF = (v: string) => {
    v = v.replace(/\D/g, "");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return v;
};
export function formataCounter(value: string) {
    if (!value) return "";

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{3})$/, "$1.$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ",");

    return value;
}

export function ConverMoneyInNumber(value: string) {
    if (!value) return "";

    value = value.replace(/\D/g, "");

    return value;
}

export function phoneNumber(phone: string) {
    if (phone) {
        return phone
            .replace(/\D/g, "")
            .replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, "$1 + ($2) $3-$4");
    }
    return phone;
}

export function getAllMonthName(mes: string) {
    switch (mes) {
        case "1":
            return "Janeiro";
        case "2":
            return "Fevereiro";
        case "3":
            return "Março";
        case "4":
            return "Abril";
        case "5":
            return "Maio";
        case "6":
            return "Junho";
        case "7":
            return "Julho";
        case "8":
            return "Agosto";
        case "9":
            return "Setembro";
        case "10":
            return "Outubro";
        case "11":
            return "Novembro";
        case "12":
            return "Dezembro";
        default:
            return "";
    }
}

export function getParamUrl() {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const pathSplit = url.pathname.split("/");
    const id = pathSplit[pathSplit.length - 1];
    return parseInt(id + "");
}
