import styled from "styled-components";

export const Container = styled.div`
    padding-top: 40.67px;
    height: 100vh;
    .content-stone {
        margin: 0 auto;
        width: 308px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .container-img {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 17px;
    }

    .description {
        margin-bottom: 50px;
        h3 {
            font-family: "Inter";
            font-size: 22px;
            font-weight: 600;
            line-height: 24.2px;
            text-align: center;
            margin-bottom: 7px;
        }

        h4 {
            font-family: "Inter";
            font-size: 22px;
            font-weight: 600;
            line-height: 24.2px;
            text-align: center;
            color: #017f1cfa;
        }
    }

    .container-voucher {
        width: 100%;
        background: #f1f1f1;
        border-radius: 8px;
        padding: 18px 24px;
        margin-bottom: 33px;

        .btn-copy {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-family: "Inter";
            font-size: 8px;
            font-weight: 700;
            line-height: 9.68px;
            text-align: left;
            text-transform: uppercase;
            img {
                margin-right: 6.7px;
            }
        }
        .container-titulo {
            margin-bottom: 6.14px;
            h3 {
                font-family: "Inter";
                font-size: 12.27px;
                font-weight: 500;
                line-height: 14.85px;
                letter-spacing: 0.04em;
                text-align: center;
                text-transform: uppercase;
            }
        }

        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 13.34px;
            background-color: #fff;
            border-radius: 8px;
            padding: 0 16px;
            height: 65px;
            .senha {
                display: flex;
                align-items: center;
                span {
                    font-family: "Inter";
                    font-size: 12.27px;
                    font-weight: 500;
                    line-height: 14.85px;
                    letter-spacing: 0.04em;
                    text-align: left;
                    text-transform: uppercase;
                    color: #2b323b;
                }
                p {
                    font-size: 19px;
                    font-weight: 700;
                    line-height: 14.85px;
                    letter-spacing: 0.04em;
                    text-align: left;
                    text-transform: uppercase !important;
                    margin-left: 4px;
                    color: #2b323b;
                    position: relative;
                    top: -2px;
                }
            }
        }

        .codigo {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 13.34px;
            border-bottom: 1px solid #d9d9d9;
            margin-bottom: 16px;

            h5 {
                font-size: 19px;
                font-weight: 700;
                line-height: 25.06px;
                text-align: left;
                text-transform: uppercase;
            }
        }

        .container-validade {
            p {
                font-family: "Inter";
                font-size: 12px;
                font-weight: 600;
                line-height: 14.52px;
                text-align: left;
                color: #2b323b;
                text-align: center;
            }
        }
    }

    .container-btns {
        .tutorial {
            border-radius: 7px;
            background-color: #00ea33;

            font-family: "Inter";
            font-size: 14px;
            font-weight: 600;
            line-height: 16.94px;
            text-align: center;
            width: 100%;
            height: 38px;
            margin-bottom: 8px;
        }

        .voltar {
            border-radius: 7px;
            background-color: #f1f3f5;

            font-family: "Inter";
            font-size: 14px;
            font-weight: 600;
            line-height: 16.94px;
            text-align: center;
            width: 100%;
            height: 38px;
        }
    }

    .footer {
        display: flex;
        justify-content: center;
        margin-top: auto;
        border-top: 1px solid #2b323b;
        padding-top: 14px;
        margin-bottom: 10px;
        .container-img {
            display: flex;
            align-items: center;
        }

        p {
            font-family: "Lato";
            font-size: 12px;
            font-weight: 900;
            line-height: 16.8px;
            text-align: center;
            color: #2b323b;
            a {
                font-family: "Lato";
                font-size: 12px;
                font-weight: 900;
                line-height: 16.8px;
                text-align: center;
                color: #2b323b;
                text-decoration: underline;
            }
        }
    }
`;
