import { EstoqueDetalhe } from "../../components/EstoqueDetalhe";
import { UseDetalheEstoqueProvider } from "../../Hook/useDetalheEstoque";
import { UseloginProvider } from "../../Hook/useUser";
export function PageDetalheEstoque() {
    return (
        <UseloginProvider>
            <UseDetalheEstoqueProvider>
                <EstoqueDetalhe />
            </UseDetalheEstoqueProvider>
        </UseloginProvider>
    );
}
