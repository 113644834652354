import { Text } from "aplication-yetz";
import { useEffect, useState } from "react";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { Container } from "./styles";

import imgPassos from "../../assets/sistema/remove-passos.svg";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface DrafEditorProps {
    onChange: (value: string) => void;
    values?: string;
    label?: string;
    onChangeButton?: () => void;
}

export function DrafEditor(props: DrafEditorProps) {
    const { onChange, values, onChangeButton, label } = props;

    const [editorState, setEditorState] = useState<EditorState>(
        EditorState.createEmpty()
    );

    useEffect(() => {
        if (values) {
            const contentState = ContentState.createFromBlockArray(
                htmlToDraft(values).contentBlocks
            );

            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
        }
    }, [values]);

    return (
        <Container>
            <div className="container-passo">
                <Text as="p">{label}</Text>
                <button
                    type="button"
                    onClick={() => {
                        if (onChangeButton) {
                            onChangeButton();
                        }
                    }}
                >
                    <img src={imgPassos} alt="" />
                </button>
            </div>
            {/* https://jpuri.github.io/react-draft-wysiwyg/#/docs */}
            <Editor
                toolbarClassName="toolbar-class"
                toolbar={{
                    options: ["inline", "history", "link"],
                    inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: [
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough",
                        ],
                    },

                    history: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ["undo", "redo"],
                    },
                }}
                defaultEditorState={editorState}
                editorState={editorState}
                onEditorStateChange={(newState) => {
                    setEditorState(newState);
                    onChange(
                        draftToHtml(convertToRaw(newState.getCurrentContent()))
                    );
                }}
            />
        </Container>
    );
}
