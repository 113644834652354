import React, { createContext, useContext, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { useHistory, NavLinkProps } from "react-router-dom";
import { api } from "../services";

interface UsePlataformaData {
    plataforma: Plataforma[];
    loadingPataforma: boolean;

    catalogo: Catalogo[];
    updateCatalogo: (
        catalogo: UpdateCatalogoData,
        id?: number
    ) => Promise<AxiosResponse>;
    loadingCatalogo: boolean;

    user: UserData;
    loadingUser: boolean;
}

interface UsePlataformaProviderProps {
    children: React.ReactNode;
}

interface Roles {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
}

export interface Plataforma {
    id: number;
    name: string;
    email: string;
    status: number;
    ["sem-estoque"]: boolean;
    ["pedido-outlet"]: boolean;
    roles: Roles[];
}

interface PlataformaData {
    plataforma: Plataforma[];
}

export interface Catalogo {
    id: number;
    nome: string;
    valor_yetz: number;
    valor_reais: number;
    produto: Produto;
    foto_capa: string;
}

export interface UpdateCatalogoData {
    ["sem-estoque"]?: number;
    status?: number;
    catalogo?: number[];
    ["pedido-outlet"]?: number;
}

interface Produto {
    categorias: Categorias[];
    foto_capa: string;
}

interface Categorias {
    categoria: {
        logo: string;
        nome: string;
    };
}

interface UserData {
    catalogo_id: Array<number>;
    created_at: string;
    email: string;
    id: number;
    name: string;
    status: number;
    ["sem-estoque"]: boolean;
}

const PlataformaContext = createContext<UsePlataformaData>(
    {} as UsePlataformaData
);

export function UsePlataformaProvider(props: UsePlataformaProviderProps) {
    const history = useHistory<NavLinkProps>();

    const [plataforma, setPlataforma] = useState<Plataforma[]>([]);
    const [loadingPataforma, setLoadingPataforma] = useState<boolean>(true);

    const [catalogo, setCatalogo] = useState<Catalogo[]>([]);
    const [loadingCatalogo, setLoadingCatalogo] = useState<boolean>(true);
    const [monitCatalogo, setMonitCatalogo] = useState<any>();

    const [user, setUser] = useState<UserData>({} as UserData);
    const [loadingUser, setLoadingUser] = useState<boolean>(true);

    const { children } = props;

    useEffect(() => {
        async function getPlataforma() {
            setLoadingPataforma(true);

            try {
                const { data } = await api.get<PlataformaData>("/usuario");
                setPlataforma(data.plataforma);
                setLoadingPataforma(false);
            } catch (error) {
                setLoadingPataforma(false);
                console.log(error);
            }
        }

        if (history.location.pathname === "/plataforma") {
            getPlataforma();
        }
    }, [history, monitCatalogo]);

    useEffect(() => {
        async function getCatalogo() {
            setLoadingCatalogo(true);

            try {
                const { data } = await api.get<Catalogo[]>(
                    "/produto/variacao/catalogo"
                );
                setCatalogo(data);
                setLoadingCatalogo(false);
            } catch (error) {
                setLoadingCatalogo(false);
                console.log(error);
            }
        }

        async function getUser() {
            setLoadingUser(true);

            try {
                const { data } = await api.get<UserData>(
                    `/usuario/${history.location.state.id}`
                );
                setUser(data);
                setLoadingUser(false);
            } catch (error) {
                setLoadingUser(false);
                console.log(error);
            }
        }

        if (history.location.state?.id) {
            getCatalogo();
            getUser();
        }
    }, [history, monitCatalogo]);

    async function updateCatalogo(config: UpdateCatalogoData, id?: number) {
        try {
            const response = await api.post(
                `/usuario/${id ? id : history.location.state.id}`,
                { ...config }
            );

            if (response.status === 200) {
                setMonitCatalogo(config);
            }

            return response;
        } catch (error: any) {
            console.log(error);
            return error;
        }
    }

    return (
        <PlataformaContext.Provider
            value={{
                plataforma,
                loadingPataforma,

                catalogo,
                updateCatalogo,
                loadingCatalogo,

                user,
                loadingUser,
            }}
        >
            {children}
        </PlataformaContext.Provider>
    );
}

export function usePlataforma() {
    const context = useContext(PlataformaContext);

    return context;
}
