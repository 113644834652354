import { Flex, Filter, Box } from "aplication-yetz";
import { Goback } from "../Goback";

interface HeaderProps {
    setFiltro: (filtro: string) => void;
}

export function Header(props: HeaderProps) {
    const { setFiltro } = props;

    return (
        <Flex justifyContent="space-between" pr="55px" mb="61px">
            <Goback title="Historico de cargas" />

            <Box>
                <Filter onChange={(e) => setFiltro(e.target.value)} />
            </Box>
        </Flex>
    );
}
