import { useState } from "react";
import { Box, Flex } from "aplication-yetz";
import { Modal } from "../../components/Modal";
import { ModalValidaLogin } from "../../components/ModalValidaLogin";

import { FormLogin } from "./FormLogin";
import homemImg from "../../assets/sistema/homem.png";
import { Form, Container } from "./styles";

import { useAuthentication } from "../../Hook/useAuthentication";
import { useEffect } from "react";
import { Authenticator } from "../Authenticator";

export function Login() {
    const [modal, setModal] = useState(false);

    const {
        modalConfirm,
        genereteAuthCode,
        confirmAuthCode,
        loadingConfirmCode,
        authenticator,
        hasLogin,
    } = useAuthentication();

    const config = {
        resend: () => genereteAuthCode(),
        confirm: (code: string) => confirmAuthCode(code),
        onclose: () => setModal(false),
        loadingConfirmCode,
        hasLogin,
    };

    useEffect(() => {
        setModal(modalConfirm);
    }, [modalConfirm]);

    return (
        <Container>
            {authenticator ? (
                <Authenticator />
            ) : (
                <Box width="100vw" height="100vh" pt="9.375rem">
                    <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        width="1120px"
                        margin="0 auto"
                    >
                        <Box>
                            <img src={homemImg} alt="" />
                        </Box>
                        <Form>
                            <FormLogin />
                        </Form>
                    </Flex>
                </Box>
            )}

            <Modal component={ModalValidaLogin} active={modal} rest={config} />
        </Container>
    );
}
