import { ButtonSystem } from "aplication-yetz";
import { useFornecedor } from "../../../Hook/useFornecedor";
import { Container } from "./styles";
import { Permissions } from "../../Permission";
export function Header() {
    const { setopenModalAdd } = useFornecedor();
    return (
        <Container>
            <h1>Fornecedor</h1>
            <Permissions role="fornecedor.store">
                <ButtonSystem onClick={() => setopenModalAdd(true)}>
                    Novo Fornecedor
                </ButtonSystem>
            </Permissions>
        </Container>
    );
}
