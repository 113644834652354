import React, { createContext, useContext, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { useHistory } from "react-router-dom";

import { containString } from "../util/helper";
import { toast } from "react-toastify";
// import { AxiosResponse } from "axios";
import { api } from "../services";

interface UsePedidoProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface PedidoData {
    pedido: Pedido;
    loadingPedido: boolean;
    handleBloquear: (id: number, status: string) => Promise<AxiosResponse>;
    paginacaoPedido: (page: number) => void;
    loadPedidoByToken: (token: string) => Promise<AxiosResponse<PedidoToken>>;
    loadPedido: (url?: string) => void;
    pedidoPlataforma: Plataforma[];
}

export interface Pedido {
    data: PedidoDados[];
    current_page: number;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string | null;
    per_page: number;
    prev_page_url: string | null;
    to: number;
    total: number;
}

export interface PedidoToken {
    item: PedidoDados;
    pedidos: PedidoDados[];
}

export interface PedidoDados {
    attempts: number;
    created_at: string;
    custom_data: string;
    customer: string;
    customer_id: string;
    expiration_control: number;
    hash_file: null | string;
    id: number;
    is_outlet: number;
    job: string;
    link_used_open_after_expires_at: number;
    plataforma: string;
    postback_url: string;
    status: string;
    total_itens: number;
    updated_at: string;
    user_id: number;
    valor_reais: string;
    valor_yetz: string;
}

export interface Itens {
    id: number;
    pedido_id: number;
    produto_variacao_id: number;
    produto_estoque_id: number;
    hash_code: string;
    status: string;
    valor_yetz: string;
    valor_reais: string;
    created_at: string;
    updated_at: string;
    estoque: Estoque;
}

export interface Estoque {
    id: number;
    carga_id: null;
    produto_variacao_id: number;
    vencimento: null;
    status: string;
    created_at: string;
    updated_at: string;
}

export interface Plataforma {
    color: null | string;
    light_color?: null | string;
    id: number;
    name: string;
    total_pedidos: number;
    total_vouchers: number;
    total_vouchers_reais: string;
    total_vouchers_utilizados: number;
    total_jobs?: number;
}

export interface HistoryProps {
    id?: number;
}

const UsePedidoContext = createContext<PedidoData>({} as PedidoData);

export function UsePedidoProvider(props: UsePedidoProviderProps) {
    const { children } = props;

    const history = useHistory<HistoryProps>();
    const pathname = history.location.pathname;
    const id = history.location.state?.id;

    const [pedido, setPedido] = useState<Pedido>({} as Pedido);
    const [loadingPedido, setLoadingPedido] = useState<boolean>(false);
    const [pedidoPlataforma, setPedidoPlataforma] = useState<Plataforma[]>([]);

    async function handleBloquear(id: number, status: string) {
        try {
            const response = await api.post<Pedido>(`pedido/bloquear`, {
                pedido_id: id,
            });

            const { data } = response;

            if (response.status === 200) {
                toast.success(
                    `Pedido ${
                        status === "BLOQUEADO" ? "desbloqueado" : "bloqueado"
                    }  com sucesso!`
                );
            }

            const updatePedido = pedido.data?.map((item) => {
                if (item.id === id) {
                    return { ...item, ...data.data };
                }
                return item;
            });

            if (updatePedido) {
                setPedido({ ...pedido, data: [...updatePedido] });
            }
            return response;
        } catch (error: any) {
            console.log(error);
            toast.error("Erro ao bloquear pedido!");
            return error;
        }
    }

    async function loadPedido(url?: string) {
        const currentYear = new Date().getFullYear();
        setLoadingPedido(true);

        try {
            const { data } = await api.get<Plataforma[]>(
                url
                    ? "pedido/plataformas" + url
                    : `pedido/plataformas?years[]=` + currentYear
            );

            if (data.length === 0) {
                toast.error("Nenhum pedido encontrado!");
            }

            setPedidoPlataforma(data);
        } catch (error) {
            toast.error("Erro ao carregar pedidos!");
        }

        // try {
        //     const response = await api.get<Pedido>("/pedido");
        //     const { data } = response;
        //     setPedido(data);
        // } catch (error) {
        //     toast.error("Erro ao carregar pedidos");
        // }
        setLoadingPedido(false);
    }
    async function paginacaoPedido(page: number) {
        setLoadingPedido(true);
        try {
            const response = await api.get<Pedido>(`/pedido?page=${page}`);
            const { data } = response;
            setPedido(data);
        } catch (error) {
            toast.error("Erro ao carregar pedidos");
        }
        setLoadingPedido(false);
    }

    async function loadPedidoByToken(token: string) {
        try {
            const response = await api.post<PedidoToken>(`pedido/busca-url`, {
                busca: token,
            });
            return response;
        } catch (error: any) {
            toast.error("Token não encontrado");
            return error;
        }
    }

    useEffect(() => {
        if (containString(pathname, "pedidos") && !id) {
            loadPedido();
        }
    }, [pathname, id]);

    return (
        <UsePedidoContext.Provider
            value={{
                pedido,
                paginacaoPedido,
                loadingPedido,
                handleBloquear,
                loadPedidoByToken,
                loadPedido,
                pedidoPlataforma,
            }}
        >
            {children}
        </UsePedidoContext.Provider>
    );
}

export function usePedido() {
    const context = useContext(UsePedidoContext);
    return context;
}
