import produtoImg from "../../assets/sistema/produto.svg";
import estoqueImg from "../../assets/sistema/estoque.svg";
import pedidoImg from "../../assets/sistema/pedido.svg";
import relatorioImg from "../../assets/sistema/relatorio.svg";
import usuarioImg from "../../assets/sistema/usuario.svg";

export interface box {
    name: string;
    img: string;
    link: string;
}

export const config: box[] = [
    {
        name: "Produto",
        img: produtoImg,
        link: "/produtos",
    },
    {
        name: "Estoque",
        img: estoqueImg,
        link: "/estoque",
    },
    {
        name: "Pedidos",
        img: pedidoImg,
        link: "/pedidos",
    },
    {
        name: "Relatórios",
        img: relatorioImg,
        link: "/relatorios",
    },
    {
        name: "Usuários",
        img: usuarioImg,
        link: "/usuarios",
    },
];
