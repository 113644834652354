import styled from "styled-components";

export const Container = styled.div`
    p {
        &.roboto-mono {
            font-family: "Zen Kaku Gothic New", monospace !important;
            font-size: 18px !important;
        }
    }
`;
