import React, { createContext, useContext, useState } from "react";
import { api } from "../services";

interface UseVoucherConfigProviderProps {
    children: React.ReactNode;
}

const useVoucherConfigContext = createContext<UseVoucherConfig>(
    {} as UseVoucherConfig
);

interface UseVoucherConfig {
    setHashCode: (value: string) => void;
    hashCode: string;

    pedidoShareUrl(url: urlParams): Promise<void>;
}

type urlParams = "whatsapp" | "email";

export function UseVoucherConfigProvider(props: UseVoucherConfigProviderProps) {
    const { children } = props;
    const [hashCode, setHashCode] = useState<string>("");

    async function pedidoShareUrl(url: urlParams) {
        if (!hashCode) return;

        try {
            await api.post(`pedido/share-url`, {
                hash_code: hashCode,
                type: url,
            });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <useVoucherConfigContext.Provider
            value={{ hashCode, setHashCode, pedidoShareUrl }}
        >
            {children}
        </useVoucherConfigContext.Provider>
    );
}

export function useVoucherConfig() {
    const context = useContext(useVoucherConfigContext);
    return context;
}
