import { Text } from "aplication-yetz";
import { FooterStyles } from "./styles";
import footerImg from "../../assets/sistema/logo-footer.svg";

export function Footer() {
    const year = new Date().getFullYear();
    return (
        <FooterStyles>
            <Text as="p">
                YETZ LTDA | CNPJ: 28.325.166/0001-05 | {year} | Todos os
                direitos reservados
            </Text>
            <div className="container-logo">
                <img src={footerImg} alt="" />
            </div>

            <Text as="p" className="descricao">
                Este site é melhor visualizado em navegadores com suporte às
                especificações HTML5 e CSS3 (Google Chrome, Mozilla Firefox,
                Microsoft Edge, Safari entre outros). Recomendamos que você
                utilize a versão mais atual destes navegadores.
            </Text>
        </FooterStyles>
    );
}
