import { useState } from "react";
import { Flex, Box, TextImg, Text, ButtonSystem } from "aplication-yetz";
import { Formik, Field, Form, FormikProps, FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { useEstoque } from "../../Hook/useEstoque";

import { Formulario } from "./form";

import { schema, initialValues } from "./config";
import { FormCarga, keyOfProduto } from "./type";

import cargaImg from "../../assets/sistema/carga.svg";
import fecharImg from "../../assets/sistema/fechar-modal.svg";

import { ModalStyles } from "./styles";

interface ModalCargaEstoqueProps {
    active: boolean;
    setActive: (value: boolean) => void;
}

export function ModalCargaVerificado(props: ModalCargaEstoqueProps) {
    const { active, setActive } = props;
    const { cargaVerificaPedidoItemStatus } = useEstoque();
    const [preview, setPreview] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    async function handleSubmit(
        values: FormData,
        actions: FormikHelpers<FormCarga>
    ) {
        setLoading(true);

        const response = await cargaVerificaPedidoItemStatus(values);

        if (response.status === 200) {
            actions.resetForm();
            toast.success("sucesso!");
            setActive(false);
            setPreview(false);
        }

        setLoading(false);
    }

    return (
        <ModalStyles className={active ? "active" : ""}>
            <div className="container-body-modal-carga">
                <button
                    className="fechar-modal"
                    onClick={() => {
                        setActive(false);
                        setPreview(false);
                    }}
                >
                    <img src={fecharImg} alt="" />
                </button>
                <Box width="1000px" m="0 auto">
                    <TextImg
                        img={cargaImg}
                        pl="27px"
                        color="primary"
                        ml="-29px"
                        className={preview ? "preview-text" : ""}
                    >
                        {preview ? (
                            <Text color="black" fontSize="mdl" bold={true}>
                                Tem certeza que deseja prosseguir <br /> com a
                                adição da carga?
                            </Text>
                        ) : (
                            <Text color="black" fontSize="mdl" bold={true}>
                                Insira um título para a sua carga de verificados
                            </Text>
                        )}
                    </TextImg>

                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={schema}
                        onSubmit={(values: FormCarga, actions) => {
                            const keys = Object.keys(
                                values
                            ) as keyOfProduto<FormCarga>[];

                            const formData = new FormData();

                            keys.forEach((key) => {
                                formData.append(key, values[key]);
                            });

                            handleSubmit(formData, actions);
                        }}
                    >
                        {(props: FormikProps<FormCarga>) => {
                            const { values, setFieldValue } = props;
                            return (
                                <Form>
                                    <Flex
                                        width="100%"
                                        justifyContent="space-between"
                                    >
                                        {preview ? (
                                            <Flex
                                                alignItems="flex-end"
                                                justifyContent="space-between"
                                                width="100%"
                                                mt="24px"
                                            >
                                                <Box width="145px">
                                                    <Text
                                                        color="primary"
                                                        fontSize="mdl"
                                                        bold={true}
                                                        ellipsis={true}
                                                    >
                                                        {values.nome}
                                                    </Text>
                                                </Box>

                                                <Box>
                                                    <Text mb="14px">
                                                        ARQUIVO
                                                    </Text>

                                                    <Text>
                                                        {values.produtos?.name}
                                                    </Text>
                                                </Box>

                                                <Flex
                                                    width="206px"
                                                    justifyContent="space-between"
                                                >
                                                    <ButtonSystem
                                                        background="white"
                                                        color="primary"
                                                        className="cancelar-acao"
                                                        height="48px"
                                                        onClick={() => {
                                                            setPreview(false);
                                                            setFieldValue(
                                                                "produtos",
                                                                ""
                                                            );
                                                        }}
                                                    >
                                                        NÃO
                                                    </ButtonSystem>

                                                    <ButtonSystem
                                                        type="submit"
                                                        height="48px"
                                                        width="94px"
                                                        widthSpnier={20}
                                                        heightSpiner={20}
                                                        loading={loading}
                                                    >
                                                        SIM
                                                    </ButtonSystem>
                                                </Flex>
                                            </Flex>
                                        ) : (
                                            <Field
                                                component={Formulario}
                                                name="produtos"
                                                setPreview={setPreview}
                                                modalActive={active}
                                            />
                                        )}
                                    </Flex>
                                </Form>
                            );
                        }}
                    </Formik>
                </Box>
            </div>
        </ModalStyles>
    );
}
