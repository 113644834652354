import { getLocalStore } from "../../util/helper";

interface PermissionProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
    role: string;
}

export function Permissions(props: PermissionProps) {
    const { children, role } = props;

    const hasPermission = getLocalStore()?.permissions[role];

    return <>{hasPermission ? children : null}</>;
}
