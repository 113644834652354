import { ISelos } from "./selos";
import imgLojaFisicaVirtual from "../../assets/sistema/fisica-virtual.svg";
import { Variacao } from "./buttonVaricao";

export function FisicaEVirtual(props: Omit<ISelos, "selo">) {
    const { link, variacao } = props;

    if (variacao) {
        return <Variacao title={variacao} descricao="Loja Física e Virtual" />;
    }
    return (
        <>
            <h3>VOCÊ PODERÁ UTILIZAR ESSE VOUCHER DIGITAL</h3>
            <button
                onClick={() => {
                    if (link) {
                        window.open(link);
                    }
                }}
            >
                <img src={imgLojaFisicaVirtual} alt="" />
                <span>Loja Física e Virtual</span>
            </button>
        </>
    );
}
