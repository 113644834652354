import { ChangeEvent } from "react";
import { toast } from "react-toastify";
import { PaginacaoStyles } from "./styles";

interface PaginacaoProps {
    page: number;
    lastPage: number;
    goToPage: (page: number) => void;
    previousPage: (page: number) => void;
    nextPage: (page: number) => void;
    canPreviousPage: boolean;
    canNextPage: boolean;
}

export function Paginacao(props: PaginacaoProps) {
    const {
        page,
        lastPage,
        goToPage,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
    } = props;

    // const [value, setValue] = useState<string>("");

    return (
        <PaginacaoStyles>
            <div className="pagination">
                <div className="pre">
                    <button
                        onClick={() => goToPage(0)}
                        disabled={!canPreviousPage}
                    >
                        {"<<"}
                    </button>{" "}
                    <button
                        onClick={() => previousPage(page - 1)}
                        disabled={!canPreviousPage}
                    >
                        {"<"}
                    </button>{" "}
                </div>
                <div className="next">
                    <button
                        onClick={() => nextPage(page + 1)}
                        disabled={!canNextPage}
                    >
                        {">"}
                    </button>{" "}
                    <button
                        onClick={() => goToPage(lastPage)}
                        disabled={!canNextPage}
                    >
                        {">>"}
                    </button>{" "}
                </div>
                <span>
                    Page <strong>{`${page} de ${lastPage}`}</strong>
                    {"  "}
                </span>
                <span>| Vá para pagina: </span>{" "}
                <div className="container-input">
                    <input
                        type="number"
                        style={{ width: "100px" }}
                        defaultValue={page}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            // setValue(e.target.value);
                            setTimeout(() => {
                                if (parseInt(e.target.value) <= lastPage) {
                                    goToPage(parseInt(e.target.value));
                                } else {
                                    if (e.target.value !== "") {
                                        toast.error("Página inválida");
                                    }
                                }
                            }, 2000);
                        }}
                    />
                </div>
            </div>
        </PaginacaoStyles>
    );
}
