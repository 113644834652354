import { Grid } from "aplication-yetz";
import { formatDateIso, formatCurrentMoney } from "../../util/helper";
import { useDetalhePedido } from "../../Hook/useDetalhePedidoPlataforma";
import { BoxDetalheProduto } from "../BoxDetalheProduto";
import { BoxDetalheProdutoProps } from "../BoxDetalheProduto/types";
import imgCodigo from "../../assets/sistema/logo-card-01.svg";
import imgDataHora from "../../assets/sistema/logo-card-09.svg";
import imgPlataforma from "../../assets/sistema/logo-card-07.svg";
import imgRegistrado from "../../assets/sistema/logo-card-10.svg";
import imgValor from "../../assets/sistema/logo-card-12.svg";
import imgStatus from "../../assets/sistema/logo-card-13.svg";
import imgJob from "../../assets/sistema/job.svg";

export function Cards() {
    const { detalhePedido } = useDetalhePedido();

    const status = detalhePedido?.itens
        ? detalhePedido?.itens.reduce((acc, item) => {
              return item.status === "GERADO" ? acc + 1 : acc;
          }, 0)
        : 0;
    const totalItens = detalhePedido?.itens ? detalhePedido?.itens.length : 0;

    const porcentagem = parseFloat(
        Number((status / totalItens) * 100).toFixed(2)
    );

    const valorTotal = detalhePedido?.id
        ? detalhePedido?.itens.reduce((acc, { valor_reais }) => {
              return acc + parseFloat(valor_reais);
          }, 0)
        : 0;

    const arrayCard: BoxDetalheProdutoProps[] = [
        {
            img: imgCodigo,
            titulo: "código",
            subTitulo: detalhePedido?.id ? detalhePedido?.id : "",
        },
        {
            img: imgDataHora,
            titulo: "Data / hora",
            subTitulo: detalhePedido?.created_at
                ? formatDateIso(detalhePedido?.created_at)
                : "",
        },
        {
            img: imgPlataforma,
            titulo: "plataforma",
            subTitulo: detalhePedido?.user?.name
                ? detalhePedido?.user?.name
                : "",
        },
        {
            img: imgRegistrado,
            titulo: "Registro",
            subTitulo: "PROTOCOLO",
        },
        {
            img: imgValor,
            titulo: "VAlor total",
            subTitulo: formatCurrentMoney(valorTotal),
        },
        {
            img: imgStatus,
            titulo: `STATUS - ${status}/${totalItens} ${porcentagem}%`,
            subTitulo: "GERADO",
        },
        {
            img: imgJob,
            titulo: "job",
            subTitulo: detalhePedido?.job ? detalhePedido?.job : "",
        },
    ];

    //
    return (
        <Grid
            gridTemplateColumns="repeat(7, 1fr)"
            gridGap="35px"
            className="cards-detalhe-pedido"
        >
            {arrayCard.map((item, index) => {
                return <BoxDetalheProduto {...item} key={index} />;
            })}
        </Grid>
    );
}
