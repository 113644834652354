import styled from "styled-components";
import { Flex, Filter as Filtro } from "aplication-yetz";
import { Link } from "react-router-dom";
import seloImg from "../../assets/sistema/selo.svg";

export const Container = styled.div`
    .container-historico-carga {
        height: 0px;
        overflow: hidden;
        transition: height 0.5s;
        &.active {
            transition: height 0.5s;
        }
    }
`;

export const LinkStyles = styled(Link)`
    display: flex;
    align-items: center;
`;

export const ContainerTable = styled.div`
    padding-top: 48px;
    .table {
        .container-tables {
            .icone-us {
                position: relative;
                &.active {
                    &::after {
                        background-color: red;
                    }
                }
                &::after {
                    content: "";
                    display: block;
                    width: 24px;
                    height: 24px;
                    mask: url(${seloImg});
                    mask-repeat: no-repeat;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: ${({ theme }) => theme.colors.gray300};
                }
            }
        }
    }
`;

export const HeaderNotificacaoStyles = styled.div`
    padding: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff7f7;
    border-radius: 4px;
    height: 64px;
    margin-bottom: 4px;
    .user {
        color: ${(props) => props.theme.colors.black};
    }
`;

export const ModalStyles = styled(Flex)`
    height: 263px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    position: fixed;
    bottom: 0px;
    left: 0px;
    align-items: center;
    .container-btns {
        display: flex;
        align-items: center;
        margin-left: auto;
        .label-arquivo {
            position: relative;
            button {
                position: absolute;
                top: 55%;
                transform: translateY(-50%);
                right: 35px;
                z-index: 20;
            }
        }
        .btn-upload {
            border: 1px solid ${({ theme }) => theme.colors.primary};
            height: 48px;
            width: 248px;
            margin-right: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            cursor: pointer;
            position: relative;
            padding-right: 15px;
            z-index: 10;
            p {
                width: 183px;
            }
        }
        .desativado {
            cursor: not-allowed;
            pointer-events: none;
        }
    }
`;

export const Modal = styled.div`
    width: 861px;
    height: 80vh;
    overflow-y: scroll;
    padding: 36px 48px 24px 40px;
    background-color: ${({ theme }) => theme.colors.white};
    padding-bottom: 24px;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 5px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 92, 118, 0.5);
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

export const Filter = styled(Filtro)`
    width: 100%;
`;

export const StylesTableDetalhes = styled.div`
    .container-img {
        img {
            width: 75px;
            height: 50px;
            border-radius: 8px;
        }
    }
`;
