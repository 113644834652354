import { Grid } from "aplication-yetz";
import styled from "styled-components";

export const CardsStyles = styled(Grid)`
    padding-bottom: 50px;
    margin-bottom: 70px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
`;

export const BoxTabela = styled.div`
    border: 1px solid #cfcfcf;
    border-radius: 8px;
    padding: 25px;
    .titulo {
        background: #d3faff;
        border-radius: 4px;
        padding: 16px;
        display: flex;
        align-items: center;
        grid-gap: 16px;
        margin-bottom: 20px;
        h1 {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
        }
        &.reposicao {
            background: #d3ffe5;
        }
        &.outlet {
            background: #feffd3;
        }
        &.vencidos {
            background: #ffe3d3;
        }
    }
    .boxVoucher {
        grid-gap: 10px;
        img {
            width: 36px;
            height: 24px;
        }
    }
`;
