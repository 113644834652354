import { NovoDetalhe } from "../../components/DetalhePedidoPlataforma/novoDetalhePedido";
import { UseDetalhePedidoProvider } from "../../Hook/useDetalhePedidoPlataforma";
import { UsePedidoProvider } from "../../Hook/usePedido";
export function PageDetalhePedidoPlataforma() {
    return (
        <UsePedidoProvider>
            <UseDetalhePedidoProvider>
                <NovoDetalhe />
            </UseDetalhePedidoProvider>
        </UsePedidoProvider>
    );
}
