import { PageLogin } from "../pages/Login";
import { PageSistema } from "../pages/Sistema";
import { PageProdutos } from "../pages/Produtos";
import { PageProdutosExpirados } from "../pages/ProdutosExpirados";
import { PageCategorias } from "../pages/Categorias";
import { DetalheProduto } from "../pages/DetalheProduto";
import { PageEstoque } from "../pages/Estoque";
import { PageLogEstoque } from "../pages/LogEstoque";
import { PageDetalheEstoque } from "../pages/DetalheEstoque";
import { PageHistoricoCargas } from "../pages/HistoricoCargas";
import { PagePedidos } from "../pages/Pedidos";
import { PageDetalhePedidoPlataforma } from "../pages/DetalhePedidoPlataforma";
import { PageDetalhePedido } from "../pages/DetalhePedido";
import { PageUsuarios } from "../pages/Usuarios";
import { PageValePresente } from "../pages/PageValePresente";
import { PageRelatorioCompra } from "../pages/RelatorioCompra";
import { PageRelatorioVoucher } from "../pages/RelatorioVoucher";
import { PageMultiplosVoucher } from "../pages/MultiplosVoucher";
import { PageMultiplosVoucherGrandCru } from "../pages/MultiplosVoucherGrandCru";
import { PageMultiplosVoucherSandBox } from "../pages/PageMultiplosVoucherSandbox";

import { PageSandBox } from "../pages/SandBox";
import { CineMarkCombo } from "../pages/CineMarkCombo";
import { CineMarkComboSandBox } from "../pages/CineMarkComboSandBox";
import { PagePlataforma } from "../pages/PagePlataforma";
import { PageDetalhePlataforma } from "../pages/PageDetalhePlataforma";
import { PagePassoAPasso } from "../pages/PassoAPasso";
import { PagePassoAPassoPromo } from "../pages/PassoAPassoPromo";
import { PageCompra } from "../pages/Compra";
import { PageVouchers } from "../pages/Voucher";
import { PageCromprarAdicionar } from "../pages/CompraAdicionar";
import { PageVoucherAdicionar } from "../pages/VoucherAdicionar";
// import { PageWhitelist } from "../pages/Whitelist";
// import { PageDetalheWhitelist } from "../pages/Whitelist/detalhes";
import { PageGestao } from "../pages/Gestao";
import { PageRelatorio } from "../pages/Relatorio";
import { PageVouchersExpirados } from "../pages/VouchersExpirados";
import { LoginSuperAdm } from "../pages/LoginSuperAdm";
import { PageFornecedor } from "../pages/Fornecedor";

import { routesProps } from "./types";
import { PageTeste } from "../pages/PageTeste";

export const routes: routesProps[] = [
    { path: "/", exact: true, component: PageLogin, isPrivate: false },
    { path: "/sistema", exact: true, component: PageSistema, isPrivate: true },
    {
        path: "/produtos",
        exact: true,
        component: PageProdutos,
        isPrivate: true,
    },
    {
        path: "/produtos-expirados",
        exact: true,
        component: PageProdutosExpirados,
        isPrivate: true,
        permission: "relatorio.vouchers_expirados.index",
    },
    {
        path: "/categorias",
        exact: true,
        component: PageCategorias,
        isPrivate: true,
    },
    {
        path: "/produtos/detalhe/:id",
        exact: true,
        component: DetalheProduto,
        isPrivate: true,
    },
    {
        path: "/plataforma",
        exact: true,
        component: PagePlataforma,
        isPrivate: true,
        permission: "usuario.index",
    },
    {
        path: "/plataforma/detalhe/:id",
        exact: true,
        component: PageDetalhePlataforma,
        isPrivate: true,
        permission: "produto.variacao.index",
    },
    { path: "/estoque", exact: true, component: PageEstoque, isPrivate: true },
    {
        path: "/estoque/log/:id",
        exact: true,
        component: PageLogEstoque,
        isPrivate: true,
    },
    {
        path: "/estoque/detalhe/:id",
        exact: true,
        component: PageDetalheEstoque,
        isPrivate: true,
        permission: "produto.variacao.show",
    },
    {
        path: "/estoque/historico",
        exact: true,
        component: PageHistoricoCargas,
        isPrivate: true,
        permission: "carga.index",
    },
    {
        path: "/pedidos",
        exact: true,
        component: PagePedidos,
        isPrivate: true,
        permission: "pedido.index",
    },
    {
        path: "/pedidos/plataforma/:id",
        exact: true,
        component: PageDetalhePedidoPlataforma,
        isPrivate: true,
        permission: "pedido.plataforma_pedidos.show",
    },
    {
        path: "/pedidos/detalhe/:id",
        exact: true,
        component: PageDetalhePedido,
        isPrivate: true,
        permission: "pedido.show",
    },
    {
        path: "/usuarios",
        exact: true,
        component: PageUsuarios,
        isPrivate: true,
    },
    {
        path: "/fornecedor",
        exact: true,
        component: PageFornecedor,
        isPrivate: true,
        permission: "fornecedor.index",
    },
    // {
    //     path: "/whitelist",
    //     exact: true,
    //     component: PageWhitelist,
    //     isPrivate: true,
    // },
    // {
    //     path: "/usuarios/:usuarioId/whitelist",
    //     exact: true,
    //     component: PageDetalheWhitelist,
    //     isPrivate: true,
    // },
    {
        path: "/passo-a-passo/:id",
        exact: true,
        component: PagePassoAPasso,
        isPrivate: false,
    },
    {
        path: "/passo-a-passo/promo/:id",
        exact: true,
        component: PagePassoAPassoPromo,
        isPrivate: false,
    },
    {
        path: "/passo-a-passo",
        exact: true,
        component: PagePassoAPasso,
        isPrivate: false,
    },
    {
        path: "/r/:token",
        exact: true,
        component: PageValePresente,
        isPrivate: false,
    },
    {
        path: "/teste",
        exact: true,
        component: PageTeste,
        isPrivate: false,
    },
    {
        path: "/s/:token",
        exact: true,
        component: PageSandBox,
        isPrivate: false,
    },
    {
        path: "/c/:token",
        exact: true,
        component: CineMarkCombo,
        isPrivate: false,
    },
    {
        path: "/d/:token",
        exact: true,
        component: CineMarkComboSandBox,
        isPrivate: false,
    },
    {
        path: "/m/:token",
        exact: true,
        component: PageMultiplosVoucher,
        isPrivate: false,
    },
    {
        path: "/g/:token",
        exact: true,
        component: PageMultiplosVoucherGrandCru,
        isPrivate: false,
    },
    {
        path: "/n/:token",
        exact: true,
        component: PageMultiplosVoucherSandBox,
        isPrivate: false,
    },
    {
        path: "/compra",
        exact: true,
        component: PageCompra,
        isPrivate: true,
        permission: "compra.index",
    },
    {
        path: "/compra/adicionar",
        exact: true,
        component: PageCromprarAdicionar,
        isPrivate: true,
        permission: "compra.store",
    },
    {
        path: "/vouchers",
        exact: true,
        component: PageVouchers,
        isPrivate: true,
        permission: "voucher-yetz.index",
    },
    {
        path: "/vouchers/adicionar",
        exact: true,
        component: PageVoucherAdicionar,
        isPrivate: true,
        permission: "voucher-yetz.store",
    },
    {
        path: "/vouchers/relatorio",
        exact: true,
        component: PageRelatorioVoucher,
        isPrivate: true,
    },
    {
        path: "/gestao",
        exact: true,
        component: PageGestao,
        isPrivate: true,
    },
    {
        path: "/relatorios",
        exact: true,
        component: PageRelatorio,
        isPrivate: true,
    },
    {
        path: "/relatorio-compra",
        exact: true,
        component: PageRelatorioCompra,
        isPrivate: true,
        permission: "relatorio.compras_carga.index",
    },
    {
        path: "/vouchers-expirados",
        exact: true,
        component: PageVouchersExpirados,
        isPrivate: true,
    },
    {
        path: "/token/:token",
        exact: true,
        component: LoginSuperAdm,
        isPrivate: false,
    },
    // {
    //     path: "*",
    //     component: PageSistema,
    //     isPrivate: true,
    //     exact: false,
    // },
];
