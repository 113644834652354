import styled from "styled-components";

interface ContainerProps {
    width?: number;
}

export const Container = styled.div`
    display: block;
    max-width: 100%;

    .tr {
        &.header {
            &.hide-header {
                display: none !important;
            }
        }
    }
    .container-tables {
        height: 75px;
        overflow: hidden;
        transition: all 0.5s;
        &.active {
            transition: all 0.5s;
        }
    }

    &.editar-acao {
    }

    .status {
        border: none;
        border: 1px solid #1cde90;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        color: #1cde90;
        background-color: transparent;
        cursor: auto;
        &.processando {
            color: #ffa500;
            border: 1px solid #ffa500;
        }

        &.agendada {
            color: #0097d5;
            border: 1px solid #0097d5;
        }

        &.disparada {
        }

        &.rascunho {
            color: #9d9d9d;
            border: 1px solid #9d9d9d;
        }
    }

    .table {
        border-spacing: 0;

        .header {
            background: transparent;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #fff;
            padding-bottom: 32px;
            width: fit-content;
            border-bottom: 2px solid #5f5f5f;
            .th {
                align-self: center;
                overflow-wrap: anywhere;
            }
        }

        .tr {
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 100%;
            color: #4a4a4a;
            width: 100% !important;

            :last-child {
                .td {
                    border-bottom: 0;
                }
            }
            &.body {
                border-radius: 4px;
                font-weight: 600;
                font-size: 14px;
                line-height: 120%;
                position: relative;
                height: 75px;
                .td {
                    align-self: center;
                    overflow-wrap: anywhere;
                    .reprovaCompra {
                        display: flex;
                        align-items: center;
                        grid-gap: 5px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: #930035;
                        border: 2px solid #930035;
                        padding: 5px;
                        border-radius: 5px;
                        img {
                            height: 12px;
                        }
                    }
                    .button-open-table {
                        img {
                            transform: rotateX(0deg);
                            transition: all 0.5s;
                        }

                        &.active {
                            img {
                                transform: rotateX(180deg);
                                transition: all 0.5s;
                            }
                        }
                    }
                    &.center {
                        text-align: center;
                    }
                    span {
                        &.enviado {
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 100%;
                            color: #1cde90;
                            border: 1px solid #1cde90;
                            box-sizing: border-box;
                            border-radius: 4px;
                            padding: 5px;
                        }
                    }
                }

                .opcoes {
                    position: absolute;
                    right: 10px;
                    top: 45%;
                    cursor: pointer;
                }

                .dropdown-opcoes {
                    background-color: #fff;
                    border-radius: 4px;
                    height: 0px;
                    position: absolute;
                    right: 10px;
                    top: 60%;
                    overflow: hidden;
                    transition: all 0.5s;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    padding: 0 20px;
                    z-index: 1;
                    &.active {
                        overflow: inherit;
                        height: 100px;
                        transition: all 0.5s;
                    }
                    span {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 100%;
                        color: #fefefe;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        img {
                            margin-right: 13px;
                            width: 20px;
                        }
                    }
                }
            }
        }

        .td {
            .visualizado,
            span {
                display: flex;
                justify-content: center;
                img {
                    margin-right: 13px;
                }
            }
        }

        .th,
        .td {
            margin: 0;
            padding: 0.5rem;

            ${
                "" /* In this example we use an absolutely position resizer,
       so this is required. */
            }
            position: relative;

            :last-child {
                border-right: 0;
            }

            .resizer {
                display: inline-block;
                width: 10px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                transform: translateX(50%);
                z-index: 1;
                ${
                    "" /* prevents from scrolling while dragging on touch devices */
                }
                touch-action:none;
            }
        }
    }
`;

export const ContainerHeader = styled.div`
    background-color: #f9f9f9;
    .header-detalhe-compra {
        display: grid;
        align-items: center;
        justify-content: space-around;
        grid-template-columns: 75px 100px 152px 130px 90px 23px 30px;
        height: 45px;
        text-align: center;
        border-bottom: 1px solid #5f5f5f;
    }
`;

export const ContainerBody = styled.div`
    display: grid;
    grid-template-columns: 75px 100px 152px 130px 90px 23px 30px;
    align-items: center;
    justify-content: space-around;
    height: 76px;
    background-color: white;
    border-bottom: 1px solid #ececec;
    text-align: center;
    .img-voucher {
        img {
            width: 100%;
        }
    }
`;

export const Itens = styled.div<ContainerProps>`
    background: ${({ theme }) => theme.colors.white};
    border-radius: 16px;
    height: auto;

    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 60px 60px;

    width: 1200px;
    max-height: 80vh;

    .table {
        .status-erro {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
        }
        div[role="rowgroup"] {
            overflow-y: scroll;
            height: 60vh;
            .pagination {
                display: none;
            }
            &::-webkit-scrollbar {
                -webkit-appearance: none;
            }

            &::-webkit-scrollbar:vertical {
                width: 5px;
            }

            &::-webkit-scrollbar:horizontal {
                height: 12px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgba(89, 92, 118, 0.5);
            }

            &::-webkit-scrollbar-track {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }
    }

    .container-loading {
        height: 200px;
    }

    .container-input {
        height: auto;
        textarea {
            height: auto;
        }
    }
    p {
        width: fit-content;
    }

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
    }
    .refresh {
        position: absolute;
        bottom: 40px;
        right: 30px;
    }
`;

export const FilterStyles = styled.div`
    padding: 32px;
    min-height: 300px;
`;
