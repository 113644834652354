import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";

import { UpdateVariacao, Variacao, Produto } from "./useProduto";
import { msg422 } from "../util/helper";
import { User } from "./useUser";

import { api } from "../services";

interface EstoqueData {
    store: (values: FormData) => Promise<AxiosResponse>;
    cargaVerificaPedidoItemStatus: (values: FormData) => Promise<AxiosResponse>;
    loadingEstoque: boolean;
    estoque: DataEstoque;

    updateVariacao: (
        value: UpdateVariacao,
        id: number
    ) => Promise<AxiosResponse>;

    excluirCarga: (id: number) => Promise<AxiosResponse>;
    aprovarCarga: (id: number) => Promise<AxiosResponse>;
    reprovarCarga: (id: number) => Promise<AxiosResponse>;
    getHistoricoCargasById: (id: number) => Promise<AxiosResponse>;
    getHistoricoCargasByIdSummary: (id: number) => Promise<AxiosResponse>;
    getCargas: () => void;
    loadingCargas: boolean;
    loadingExClusaoCarga: boolean;
    loadingAprovarCarga: boolean;
    loadingReprovarCarga: boolean;
    cargaEstoqueStatus: boolean;
    cargas: Carga[];
    statusCargas: HistorioCarga[];
    historicoCargas: HistorioCarga[];
    loadingCargaStatus: boolean;

    getEstoque: (search?: string, page?: string) => void;
}

interface ProdutoProvider {
    children: React.ReactNode;
}

export interface DataEstoque {
    data: Estoque[];
    current_page: number;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string | null;
    per_page: number;
    prev_page_url: string | null;
    to: number;
    total: number;
}

export interface Estoque {
    id: number;
    produto_id: number;
    nome: string;
    status: number;
    valor_yetz: string;
    valor_reais: string;
    alerta_estoque: number;
    created_at: string | null;
    updated_at: string;
    total_disponivel: number;

    status_alerta_estoque: number;
    temp_estoque: number;

    val_outlet: number;
    val_pre_outlet: number;
    val_valido: number;
    val_vencido: number;
}

export interface Carga {
    id: number;
    user_id: number;
    nome: string;
    status: string;
    total: number;
    created_at: string;
    total_reais: string;
    atualizar_validade: boolean;
    user: User;
    estoque: EstoqueCarga[];
}

export interface HistorioCarga {
    created_at: string;
    id: number;
    nome: string;
    status: string;
    total_qtd: number;
    total_reais: string;
    updated_at: string;
    user_id: number;
    usuario: string;
}
export interface HistorioCargaById {
    alerta_estoque: number;
    created_at: null;
    id: number;
    nome: string;
    produto_id: number;
    status: number;
    status_alerta_estoque: number;
    total: number;
    total_disponivel: number;
    updated_at: null;
    val_outlet: number;
    val_pre_outlet: number;
    val_valido: number;
    val_vencido: number;
    valor_reais: string;
    valor_yetz: string;
    produto: Produto;
}

export interface EstoqueCarga {
    id: number;
    carga_id: number;
    produto_variacao_id: number;
    vencimento: string;
    status: string;
    created_at: string;
    variacao: Variacao;
}

const EstoqueContext = createContext<EstoqueData>({} as EstoqueData);

export function EstoqueProvider(props: ProdutoProvider) {
    const { children } = props;

    const [estoque, setEstoque] = useState<DataEstoque>({} as DataEstoque);
    const [loadingEstoque, setLoadingEstoque] = useState<boolean>(false);

    const [cargas, setCargas] = useState<Carga[]>([]);
    const [statusCargas, setStatusCargas] = useState<HistorioCarga[]>([]);
    const [historicoCargas, setHistoricoCargas] = useState<HistorioCarga[]>([]);

    const [loadingCargas, setLoadingCargas] = useState<boolean>(false);
    const [cargaEstoqueStatus, setCargaEstoqueStatus] =
        useState<boolean>(false);

    const [loadingCargaStatus, setLoadingCargaStatus] =
        useState<boolean>(false);
    const [loadingReprovarCarga, setLoadingReprovarCarga] =
        useState<boolean>(false);
    const [loadingAprovarCarga, setLoadingAprovarCarga] =
        useState<boolean>(false);
    const [loadingExClusaoCarga, setLoadingExClusaoCarga] =
        useState<boolean>(false);

    async function store(values: FormData) {
        const headers = {
            "Content-Type": "multipart/form-data",
        };

        try {
            const response = await api.post("/estoque/import", values, {
                headers,
            });

            await getCargaStatus();

            return response;
        } catch (error: any) {
            const response: string =
                error?.response?.data?.missing_columns || {};
            const campos = Object.values(response).join(", ");

            if (error.response.status === 400) {
                toast.error(`Dados inválidos ${campos}`);
                return error;
            }

            if (error.response.status === 422) {
                toast.error(
                    `Campos obrigatórios ${msg422(error.response?.data)}`
                );
                return error;
            }

            if (error.response.status === 500) {
                toast.error(`${error.response.message}`);
                return error;
            }

            toast.error("Erro interno");
            return error;
        }
    }

    async function cargaVerificaPedidoItemStatus(values: FormData) {
        const headers = {
            "Content-Type": "multipart/form-data",
        };

        try {
            const response = await api.post(
                "/estoque/verify-pedido-item-status",
                values,
                {
                    headers,
                }
            );

            await getCargaStatus();

            return response;
        } catch (error: any) {
            const response: string =
                error?.response?.data?.missing_columns || {};
            const campos = Object.values(response).join(", ");

            if (error.response.status === 400) {
                toast.error(`Dados inválidos ${campos}`);
                return error;
            }

            if (error.response.status === 422) {
                toast.error(
                    `Campos obrigatórios ${msg422(error.response?.data)}`
                );
                return error;
            }

            toast.error("Erro interno");
            return error;
        }
    }

    async function excluirCarga(carga_id: number) {
        setLoadingExClusaoCarga(true);
        try {
            const response = await api.delete(`carga/${carga_id}`);

            toast.success("Carga excluida com sucesso");

            await getHistoricoCargas();

            setLoadingExClusaoCarga(false);

            return response;
        } catch (error: any) {
            toast.error("Ops algo de errado aconteceu ");
            setLoadingExClusaoCarga(false);
            return error;
        }
    }

    async function aprovarCarga(carga_id: number) {
        setLoadingAprovarCarga(true);
        try {
            const response = await api.post(`carga/${carga_id}/aprovar`);

            toast.success("Carga em Aprovação");

            await getEstoque();
            await getCargaStatus();

            setLoadingAprovarCarga(false);

            return response;
        } catch (error: any) {
            toast.error("Ops algo de errado aconteceu ");
            setLoadingAprovarCarga(false);
            return error;
        }
    }

    async function reprovarCarga(carga_id: number) {
        setLoadingReprovarCarga(true);
        try {
            const response = await api.post(`carga/${carga_id}/reprovar`);

            toast.success("Carga aprovada com sucesso!");

            await getCargaStatus();

            setLoadingReprovarCarga(false);

            return response;
        } catch (error: any) {
            toast.error("Ops algo de errado aconteceu ");
            setLoadingReprovarCarga(false);
            return error;
        }
    }

    async function updateVariacao(value: UpdateVariacao, id: number) {
        try {
            const response = await api.post<Estoque>(
                `/produto/variacao/${id}`,
                {
                    ...value,
                }
            );

            const { data } = response;
            console.log(data);

            // const updateEstoque = estoque.data?.map((item) => {
            //     if (item.id === data.id) {
            //         return data;
            //     }
            //     return item;
            // });

            // setEstoque(updateEstoque);

            toast.success("Variacao atualizada com sucesso");
            return response;
        } catch (error: any) {
            console.log(error);

            return error;
        }
    }

    async function getCargaStatus() {
        setLoadingCargaStatus(true);

        try {
            const { data } = await api.get<HistorioCarga[]>(
                "carga?status[]=aguardando&status[]=processando"
            );

            setStatusCargas(data);
            setCargaEstoqueStatus(true);
        } catch (error) {
            toast.error("Erro ao carregar carga status");
        }

        setLoadingCargaStatus(false);
    }

    async function getEstoque(search?: string, page?: string) {
        setLoadingEstoque(true);
        try {
            const params: Record<string, string> = {};

            if (search?.length) {
                params["search"] = search;
            }

            if (page?.length) {
                params["page"] = page;
            }

            const { data } = await api.get<DataEstoque>("/estoque", {
                params,
            });
            setEstoque(data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingEstoque(false);
        }
    }

    async function getCargas() {
        setLoadingCargas(true);
        try {
            const { data } = await api.get<Carga[]>("carga");
            setCargas(data);
        } catch (error) {
            toast.error("Erro ao carregar cargas");
        }
        setLoadingCargas(false);
    }

    async function getHistoricoCargas() {
        setLoadingCargas(true);
        try {
            const { data } = await api.get<HistorioCarga[]>("carga");
            setHistoricoCargas(data);
        } catch (error) {
            toast.error("Erro ao carregar cargas");
        }
        setLoadingCargas(false);
    }

    async function getHistoricoCargasById(id: number) {
        try {
            const response = await api.get<HistorioCargaById[]>(`carga/${id}`);
            return response;
        } catch (error: any) {
            toast.error("Erro ao carregar cargas");
            return error;
        }
    }

    async function getHistoricoCargasByIdSummary(id: number) {
        try {
            const response = await api.get(`carga/${id}/summary`);
            return response;
        } catch (error: any) {
            toast.error("Erro ao carregar cargas");
            return error;
        }
    }

    useEffect(() => {
        if (window.location.pathname === "/estoque") {
            getCargaStatus();
        }
    }, []);

    useEffect(() => {
        if (window.location.pathname === "/estoque/historico") {
            getHistoricoCargas();
        }
    }, []);

    return (
        <EstoqueContext.Provider
            value={{
                loadingEstoque,
                estoque,
                store,
                cargaVerificaPedidoItemStatus,
                updateVariacao,
                getCargas,
                getHistoricoCargasById,
                getHistoricoCargasByIdSummary,
                historicoCargas,
                excluirCarga,
                aprovarCarga,
                reprovarCarga,
                loadingCargas,
                loadingAprovarCarga,
                loadingReprovarCarga,
                loadingExClusaoCarga,

                cargas,
                cargaEstoqueStatus,
                statusCargas,
                loadingCargaStatus,
                getEstoque,
            }}
        >
            {children}
        </EstoqueContext.Provider>
    );
}

export function useEstoque() {
    const context = useContext(EstoqueContext);

    return context;
}
