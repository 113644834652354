import axios, { AxiosRequestConfig } from "axios";
import { getToken } from "../util/helper";
const baseUrl = process.env.REACT_APP_BASE_HTTP;
const baseUrlSistema = process.env.REACT_APP_BASE_HTTP_SISTEMA;

const hasUser = window.location.href.indexOf("/r/") > 0;
const hasStep = window.location.href.indexOf("/passo-a-passo/") > 0;
export const api = axios.create({
    baseURL: hasUser || hasStep ? baseUrl : baseUrlSistema,
});
export const setToken = () => {
    api.interceptors.request.use(function (config: AxiosRequestConfig) {
        config.headers.authorization = `Bearer ${getToken()}`;
        return config;
    });
};
