import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { TextImg, Text, Table } from "aplication-yetz";
import { Header } from "./header";
import { PlataformaMemo } from "./plataforma";
import { usePedido, PedidoDados } from "../../Hook/usePedido";
import { Loader } from "../../components/Loader";
import { Modal } from "../../components/ModalCenter";
import { ModalBloquear } from "./modalAlert";
import { column } from "./config";
import { formatDateIso, formatCurrentMoney } from "../../util/helper";
import { Data } from "./types";
import checkImg from "../../assets/sistema/check.svg";
import cadeadoImg from "../../assets/sistema/cadeado.svg";
import { StylesTable } from "./styles";

export function NovoPedido() {
    const {
        pedidoPlataforma,
        loadingPedido,
        loadPedidoByToken,
        handleBloquear,
    } = usePedido();
    const [filtro, setFiltro] = useState("");
    const [pedidoToken, setPedidoToken] = useState<PedidoDados[]>([]);
    const [alert, setAlert] = useState(false);
    const [id, setId] = useState<number | null>(null);
    const history = useHistory();

    useEffect(() => {
        // const checkToken = /[A-Za-z0-9][.]/g;

        async function getToken(filtro: string) {
            const token = await loadPedidoByToken(filtro);
            const { data } = token;

            console.log(data.pedidos as any);
            if (token.status === 200) {
                setPedidoToken(data.pedidos);
            } else {
                setPedidoToken([]);
            }
        }

        if (filtro.length > 5) {
            getToken(filtro);
        } else {
            setPedidoToken([]);
        }
    }, [filtro, loadPedidoByToken]);

    const dadosToken = pedidoToken.map((item) => {
        const {
            id,
            created_at,
            status,
            valor_reais,
            customer,
            plataforma,
            job,
            total_itens,
        } = item;

        return {
            id,
            created_at,
            status,
            valor_reais,
            total_itens,
            customer,
            plataforma,
            job,
        };
    });

    const data: Data[] = dadosToken.map((pedido) => {
        const {
            id,
            created_at,
            status,
            valor_reais,
            total_itens,
            customer,
            job,
            plataforma,
        } = pedido;
        return {
            pedido: <Text>{id}</Text>,
            data_hora: <Text>{formatDateIso(created_at)}</Text>,
            plataforma: <Text>{plataforma}</Text>,
            qtd: <Text>{total_itens}</Text>,
            valor_total: (
                <Text>
                    {valor_reais
                        ? formatCurrentMoney(parseFloat(valor_reais))
                        : "-"}
                </Text>
            ),
            customer: customer,
            status: (
                <TextImg color="gray300" img={checkImg}>
                    <Text>{status}</Text>
                </TextImg>
            ),
            job: <Text>{job}</Text>,
            acaoes: (
                <button
                    className="btn-bloqueado"
                    onClick={() => {
                        setId(id);
                        setAlert(true);
                        // handleBloquear(id, status);
                    }}
                >
                    <TextImg
                        color={status === "BLOQUEADO" ? "primary" : "gray300"}
                        img={cadeadoImg}
                        className="btn-bloqueado"
                    ></TextImg>
                </button>
            ),
            onClick: () => {
                history.push({
                    pathname: `/pedidos/detalhe/${id}`,
                    state: { id },
                });
            },
        };
    });

    if (pedidoPlataforma.length === 0 && loadingPedido === true) {
        return (
            <div className="container-loading">
                <Loader />
            </div>
        );
    }

    async function handleBloquearPedido() {
        if (id) {
            const item = dadosToken.find((item) => item.id === id);
            if (item) {
                setId(null);
                await handleBloquear(id, item.status);
                setAlert(false);
            }
        }
    }

    return (
        <>
            <Header setFiltro={setFiltro} />
            <PlataformaMemo filtro={filtro} />
            <Modal open={alert} handleClose={() => setAlert(false)}>
                <ModalBloquear
                    handleAction={handleBloquearPedido}
                    handeClose={() => setAlert(false)}
                />
            </Modal>
            {data.length > 0 ? (
                <StylesTable>
                    <Table columns={column} data={data} />{" "}
                </StylesTable>
            ) : null}
        </>
    );
}
