import { useState } from "react";

import { Text, ButtonSystem, Flex } from "aplication-yetz";
import { ModalStylesAlert } from "./styles";

interface ModalBloquearProps {
    handleAction: () => void;
    handeClose: () => void;
}

export function ModalBloquear(props: ModalBloquearProps) {
    const { handleAction, handeClose } = props;
    const [loading, setLoading] = useState(false);
    return (
        <ModalStylesAlert>
            <div className="container-sucess">
                <Text as="h3">Atenção</Text>

                <Text as="p">Deseja realmente prosseguir com esta ação?</Text>

                <Flex width="250px" justifyContent="space-between">
                    <ButtonSystem background="black" onClick={handeClose}>
                        NÃO
                    </ButtonSystem>
                    <ButtonSystem
                        background="primary"
                        loading={loading}
                        onClick={async () => {
                            setLoading(true);
                            await handleAction();
                            setLoading(false);
                        }}
                    >
                        SIM
                    </ButtonSystem>
                </Flex>
            </div>
        </ModalStylesAlert>
    );
}
