import { Text } from "aplication-yetz";
import Countdown, { CountdownRenderProps } from "react-countdown";

import Timer from "../../assets/sistema/timer.svg";

interface CountdownProps {
    setDisabled: (value: boolean) => void;
    setTimer: (value: boolean) => void;
}

export function CountDown(props: CountdownProps) {
    const { setDisabled, setTimer } = props;
    return (
        <Countdown
            date={Date.now() + 20000}
            onComplete={() => {
                setDisabled(false);
                setTimer(false);
            }}
            renderer={(props: CountdownRenderProps) => {
                const { hours, minutes, seconds } = props;

                return (
                    <div className="timer">
                        <img src={Timer} alt="" />
                        <Text ml="5px">{hours <= 9 ? "0" + hours : hours}</Text>
                        :<Text>{minutes <= 9 ? "0" + minutes : minutes}</Text>:
                        <Text>{seconds <= 9 ? "0" + seconds : seconds}</Text>
                    </div>
                );
            }}
        />
    );
}
