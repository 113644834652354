import styled, { css } from "styled-components";
import { Flex, Grid } from "aplication-yetz";

export const Container = styled.div`
    width: 1062px;
    height: 100vh;
    margin: 0 auto;
    padding-top: 52px;
    display: flex;
    flex-direction: column;
    font-family: "Zen Kaku Gothic New";
    .loader {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .card {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
    }

    @media (max-width: 1280px) {
        width: 90%;
    }

    @media (max-width: 480px) {
        padding-top: 31px;
    }
`;

export const BtnPasso = styled.button`
    background: #930035;
    border-radius: 4px;
    display: flex;
    grid-gap: 10px;
    align-items: center;
    height: 54px;
    padding: 0 20px;
    span {
        font-weight: 500;
        font-size: 13.5569px;
        line-height: 16px;

        color: #ffffff;
    }
`;

export const HeaderStyles = styled(Flex)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    @media (max-width: 480px) {
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 29px;
        h3 {
            line-height: normal;
            margin-bottom: 18px;
        }

        .topoPasso {
            flex-direction: column;
            grid-gap: 20px;
            max-width: 343px;
            width: 100%;
            ${BtnPasso}{
                width: 100%;
            }
        }
    }
`;

export const CardsStyles = styled(Grid)`
    grid-template-columns: repeat(3, 343px);
    grid-gap: 16px;
    margin-bottom: 78px;

    .container-selo {
        width: 180px;
        margin: 0 auto;
        padding-left: 15px;
        .text-img {
            &:before {
                top: 35%;
            }
        }
    }

    .card {
        width: 100%;
        border-radius: 5px;
        height: 216px;
    }

    .box-valor {
        height: 216px;
        div[data-text] {
            font-size: ${({ theme }) => theme.fonts.lg};
            font-weight: bold;
        }
    }

    .codigo-vale {
        h4 {
            font-family: "Roboto Mono";
        }
        div {
            overflow-wrap: anywhere;
        }
        img {
            margin-right: 6px;
        }
        button {
            padding-top: 2px;
            display: flex;
            text-transform: uppercase;
            align-items: center;
            font-size: 8px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-left: auto;
            white-space: nowrap;
            text-align: left;
            color: #000;
        }
    }

    @media (max-width: 1024px) {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    @media (max-width: 480px) {
        grid-template-columns: 1fr;
        width: 283px;
        margin: 0 auto;
        margin-bottom: 32px;
        grid-gap: 0px;

        .container-selo {
            margin-bottom: 32px;
        }

        .box-valor {
            height: auto;
            margin-bottom: 21px;
        }
        .card {
            width: 100%;
            height: 189px;
            margin: 0 auto;
            margin-bottom: 28px;
            display: block;
        }
    }
`;

interface BoxCodigoProps {
    password?: string;
}

const password = css`
    display: flex;
    width: 100%;
    .codigo-vale {
        border-bottom: none !important;
        margin: 0px !important;
        padding: 0 !important;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;

export const BoxCodigo = styled.div<BoxCodigoProps>`
    background: #f1f1f1;
    padding: 38px 46px;
    border-radius: 6.77844px;
    ${(props) => (props.password ? password : "")}
    .codigo-vale {
        border-bottom: 0.847305px solid #d9d9d9;
        padding-bottom: 16px;
        margin-bottom: 16px;
    }

    @media (max-width: 480px) {
        padding: 38px 20px;
        span,
        small {
            text-align: center;
        }
    }
`;

export const DetalheStyles = styled(Grid)`
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    grid-gap: 16px;
    
    padding-bottom: 49px;
    margin-bottom: auto;
    .btn-pdf {
        width: 207px;
        & > div {
            border-top: none;
        }
    }
    .text-img {
        padding-left: 30px;
        &:before {
            left: 9px;
            transform: translate(-50%, -40%);
        }
    }
    .descricao {
        grid-column: span 2;
        .campo-vermelho {
            background: #ffe6e6;
            border-radius: 8px;
            color: #930035;
            display: block;
            padding: 5px 20px;
            width: fit-content;
        }
        .campo-amarelo {
            background: #ffedd7;
            border-radius: 8px;
            color: #a25e0c;
            display: block;
            padding: 5px 20px;
            width: fit-content;
        }
        .campo-verde {
            background: #d7ffe2;
            border-radius: 8px;
            color: #000000;
            display: block;
            padding: 5px 20px;
            width: fit-content;
        }
    }
    .compartilhar {
        margin-top: 80px;
        h2 {
            font-weight: 500;
            font-size: 13.5569px;
            line-height: 16px;
            color: #000000;
            margin-bottom: 20px;
        }
        .compWhatsapp {
            background: #28c554;
            border-radius: 4px;
            padding: 10px;
            display: flex;
            align-items: center;
            grid-gap: 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #ffffff;
            width: 207px;
            height: 39px;
            margin-bottom: 10px;
            cursor: pointer;
            position: relative;
            .seta {
                position: absolute;
                right: 20px;
            }
        }
        .compEmail {
            background: #930035;
            border-radius: 4px;
            padding: 10px;
            display: flex;
            align-items: center;
            grid-gap: 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #ffffff;
            width: 207px;
            height: 39px;
            margin-bottom: 10px;
            cursor: pointer;
            position: relative;
            .seta {
                position: absolute;
                right: 20px;
            }
        }
    }

    @media (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
        .descricao {
            grid-column: unset;
        }
    }

    @media (max-width: 480px) {
        grid-template-columns: 1fr;
        padding-bottom: 90px;
        width: 283px;
        margin: 0 auto;
        .text-img {
            width: 100%;
            justify-content: center;
            padding-left: 30px;
        }
        .descricao {
            text-align: center;
        }
        .compartilhar {
            margin: 0 auto;
            width: 100%;
            .compWhatsapp,
            .compEmail,
            .btn-pdf {
                width: 100%;
            }

            .btn-pdf {
                margin-top: 24px;
                padding-top: 21px;
                border-top: 1px solid #e1e1e1;
            }
        }
    }
`;

export const FooterStyles = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 81px;
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #f17e21;
        margin-left: auto;
        margin-right: 28px;
        text-align: center;
        &.descricao {
            margin-left: 0px;
            margin-right: 75px;
            width: 654.85px;
        }
    }

    .container-logo {
        width: 280px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 3px solid #f17e21;
        border-right: 3px solid #f17e21;
        height: 44.5px;
        margin-right: 86px;
    }

    @media (max-width: 1366px) {
        .container-logo {
            margin: 0px;
            width: 186px;
        }
        p {
            margin: 0px;
            &.descricao {
                margin: 0px;
            }
        }
    }

    @media (max-width: 1024px) {
        flex-direction: row-reverse;
        padding: 0px 5%;
        .container-logo {
            border: none;
        }

        p {
            &.descricao {
                display: none;
            }
        }
    }

    @media (max-width: 480px) {
        .container-logo {
            display: none;
        }
    }
`;

export const ErrorStyles = styled.div`
    .container-title-text {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 63px;
        margin-bottom: 84px;
        .box-text {
            border-bottom: 1px solid #f1f1f1;
            padding-bottom: 48px;
            padding-top: 15px;
        }
        h1 {
            font-size: 48px;
            color: ${({ theme }) => theme.colors.primary};
            line-height: 140%;
        }
    }

    .box-img-e-passo-a-passo {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 63px;
        margin-bottom: 93px;
        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            line-height: 34px;
            margin-bottom: 32px;
        }
    }

    @media (max-width: 1024px) {
        .container-title-text {
            h1 {
                font-size: 36px;
            }
        }
    }

    @media (max-width: 768px) {
        .container-title-text {
            grid-template-columns: 1fr;
            text-align: center;
        }

        .box-img-e-passo-a-passo {
            grid-template-columns: 1fr;
            h3 {
                text-align: center;
            }
        }
    }

    @media (max-width: 480px) {
        .container-title-text {
            grid-gap: 24px;
            margin-bottom: 32px;
            .box-text {
                padding-top: 0;
                padding-bottom: 20px;
            }
            h1 {
                font-size: 24px;
            }
        }

        .box-img-e-passo-a-passo {
            grid-gap: 24px;
            h3 {
                font-size: 24px;
            }
            img {
                &.card-error-img {
                    width: 50%;
                }
            }
        }
    }
`;

export const Modal = styled.div`
    width: fit-content;
    padding: 32px;
    background-color: ${({ theme }) => theme.colors.white};
    ul {
        li {
            list-style: none;
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
`;

export const ModalCompartilha = styled.div`
    width: 540px;
    background-color: ${({ theme }) => theme.colors.white};
    .header {
        background: #00a651;
        padding: 25px;
        &.email {
            background: #930035;
        }
        h2 {
            font-weight: 700;
            font-size: 22px;
            line-height: 140%;
            text-align: center;
            color: #ffffff;
        }
        img {
            position: absolute;
            right: 20px;
            top: 20px;
        }
    }
    .body {
        padding: 32px;
        .campo-telefone {
            display: flex;
            justify-content: center;
            margin: 20px 0;
            img {
                background: #00a651;
                border: 1px solid #00a651;
                box-sizing: border-box;
                border-radius: 5px 0px 0px 5px;
                padding: 12px;
            }
            input {
                border: 1px solid #e0e0e0;
                box-sizing: border-box;
                border-radius: 5px;
                width: 254px;
                padding: 0 20px;
            }
        }
        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;
            text-align: center;
            color: #343434;
        }
        span {
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            color: #707070;
            display: block;
        }
        button {
            background: #00a651;
            border-radius: 4px;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            letter-spacing: 0.42px;
            text-transform: uppercase;
            color: #ffffff;
            margin: 20px auto;
            display: block;
            padding: 10px 20px;
        }
        &.email {
            .campo-telefone {
                img {
                    background: #930035;
                    border: 1px solid #930035;
                }
            }
            button {
                background: #930035;
            }
        }
    }
    @media (max-width: 480px) {
        width: 100%;
    }
`;

export const ListStyles = styled.ul`
    width: 100%;
    li {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        &:hover {
            span {
                background-color: ${({ theme }) => theme.colors.primary};
                color: ${({ theme }) => theme.colors.white};
                transition: all 0.3s ease-in-out;
            }
        }
        &:last-child {
            margin-bottom: 0px;
        }
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49px;
            height: 49px;
            color: ${({ theme }) => theme.colors.primary};
            border: 1px solid #cfcfcf;
            border-radius: 4px;
            font-weight: bold;
            cursor: default;
            margin-right: 24px;
        }

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 140%;
            width: 100%;
            color: #4b4b4b;
        }
    }
`;

export const Destaque = styled.div`
    background: #ffe7e7;
    border-radius: 8px;
    height: 54px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-right: 20px;
    span {
        font-weight: 600;
        font-size: 12px;
        line-height: 140%;
        color: #930035;
        margin: 0 20px 0 10px;
    }
    @media (max-width: 480px) {
        margin: 0;
        width: 100%;
        .seta {
            transform: rotate(90deg);
        }
    }
`;



export const StylesAlerta = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #f17e21 0%, #fcb614 100%);
    min-height: calc(100vh - 81px);
    .container-text {
        margin-left: 86px;
        width: 473px;
        .container-date {
            margin-top: 135px;
            p {
                font-weight: 600;
            }
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 140%;
            text-align: center;
            color: #ffffff;
            strong {
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 140%;
                color: #941a32;
            }
        }
    }

    @media (max-width: 1366px) {
        .container-img {
            width: 45%;
            img {
                width: 100%;
            }
        }
    }

    @media (max-width: 1024px) {
        padding: 0 5%;
        .container-text {
            p {
                font-size: 18px;
                strong {
                    font-size: 18px;
                }
            }
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        .container-img {
            width: auto;
        }
        .container-text {
            margin: 0px;
        }
    }

    @media (max-width: 480px) {
        .container-text {
            width: 90%;
            margin: 0 auto;
            .container-date {
                margin-top: 32px;
            }
        }
    }
`;

interface ExpiradoStylesPersonaliteProps {
    bg?: string;
}

export const ExpiradoStylesPersonalite = styled.div<ExpiradoStylesPersonaliteProps>`
    background: ${(props) => (props?.bg ? props.bg : "#000d3c")};
    text-align: center;
    padding: 40px 0px 83px 0px;
    h1 {
        font-style: normal;
        font-weight: 900;
        font-size: 289.676px;
        line-height: 140%;
        text-align: center;
        color: ${(props) => props.theme.colors.white};
        text-transform: uppercase;
    }

    button {
        background: ${(props) => props.theme.colors.white};
        border-radius: 100px;

        font-style: normal;
        font-weight: 700;
        font-size: 65.9544px;
        line-height: 140%;
        text-align: center;

        padding: 2.5px 48px;
        height: 92px;
        color: ${(props) => (props?.bg ? props.bg : "#000d3c")} !important;
        margin-bottom: 53px;
        cursor: auto;
    }

    p {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 140%;
        /* or 34px */

        text-align: center;

        color: ${(props) => props.theme.colors.white};
        width: 664px;
        margin: 0 auto;
        margin-bottom: 113px;
    }

    small {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        /* or 22px */

        text-align: center;

        color: ${(props) => props.theme.colors.white};
        display: block;
    }

    @media (max-width: 1366px) {
        height: calc(100vh - 81px);
        padding: 0px 0px 32px 0px;
        h1 {
            font-size: 189.676px;
        }
    }

    @media (max-width: 480px) {
        padding: 0px;
        padding-top: 84px;
        display: flex;
        flex-direction: column;
        h1 {
            font-size: 130.158px;
            line-height: 140%;
        }

        button {
            width: 258px;
            height: 41px;

            font-style: normal;
            font-weight: 700;
            font-size: 29.6349px;
            line-height: 140%;
            text-align: center;
            padding: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            margin-bottom: 46px;
            color: #000d3c;
        }

        p {
            font-size: 16px;
            line-height: 140%;
            width: 306px;
            font-weight: 700;
            margin-bottom: auto;
        }
        small {
            margin-bottom: 108px;
        }
    }
`;
