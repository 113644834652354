import { useMemo } from "react";
import { Table, TextImg, Text } from "aplication-yetz";
import { Link } from "react-router-dom";
import { formatCurrentMoney, formatCurrentYETZ } from "../../util/helper";
import { DataEstoque, useEstoque } from "../../Hook/useEstoque";
import { ContainerStatus } from "../../components/ContainerStatus";
import { ContainerTable } from "../../components/tables/styles";
import { Paginacao } from "../../components/tables/";

import { Data } from "./types";
import { statusItens } from "../../components/ContainerStatus";
import { columns } from "./config";

import { ContainerTable as ContainerTableEstoque } from "./styles";
import userImg from "../../assets/sistema/editar-user.svg";
import { Permissions } from "../Permission";

interface TabelaEstoqueProps {
    estoque: DataEstoque;
    filtro: string;
}

export function TabelaEstoque(props: TabelaEstoqueProps) {
    const { getEstoque } = useEstoque();
    const { estoque, filtro } = props;

    // const arrFiltrado = estoque.data ? filterEstoque(estoque.data, filtro) : [];

    const arrFiltrado = useMemo(
        () => (estoque.data ? estoque.data : []),
        [estoque]
    );

    const data: Data[] = arrFiltrado.map((item) => {
        const {
            id,
            nome,
            valor_reais,
            valor_yetz,
            total_disponivel,
            val_vencido,
            val_outlet,
            val_valido,
            val_pre_outlet,
        } = item;

        const statusItens: statusItens[] = [
            {
                valor: val_vencido,
                colors: "gray700",
            },
            {
                valor: val_outlet,
                colors: "orange400",
            },
            {
                valor: val_pre_outlet,
                colors: "orange200",
            },
            {
                valor: val_valido,
                colors: "green300",
            },
        ];

        return {
            id,
            nome: nome?.toLocaleUpperCase(),
            valor: formatCurrentMoney(parseFloat(valor_reais)),
            valor_yetz: `${formatCurrentYETZ(parseFloat(valor_yetz))} YETZ`,
            estoque: (
                <TextImg
                    color="gray300"
                    className={`icone-us ${val_outlet > 0 ? "active" : ""}`}
                    img=""
                >
                    <Text>{total_disponivel}</Text>
                </TextImg>
            ),
            status: <ContainerStatus itens={statusItens} />,
            editar: (
                <Permissions role="produto.variacao.show">
                    <Link
                        to={{
                            pathname: `/estoque/detalhe/${id}`,
                            state: {
                                id,
                            },
                        }}
                    >
                        <img src={userImg} alt="" />
                    </Link>
                </Permissions>
            ),
        };
    });

    const nextPage =
        estoque?.current_page <= estoque?.last_page
            ? estoque.current_page + 1
            : estoque.current_page;

    const previusPage =
        estoque?.current_page >= 1
            ? estoque.current_page - 1
            : estoque.current_page;

    const canNextPage = nextPage <= estoque.last_page;
    const canPreviustPage = estoque?.from - 1 >= 1 ? true : false;

    return (
        <ContainerTableEstoque>
            <ContainerTable>
                <Table columns={columns} data={data} />
                <Paginacao
                    page={estoque.current_page}
                    nextPage={() => getEstoque(filtro, nextPage.toString())}
                    previousPage={() =>
                        getEstoque(filtro, previusPage.toString())
                    }
                    canPreviousPage={canPreviustPage}
                    canNextPage={canNextPage}
                    goToPage={(page) => getEstoque(filtro, page.toString())}
                    lastPage={estoque.last_page}
                />
            </ContainerTable>
        </ContainerTableEstoque>
    );
}
