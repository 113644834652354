import { Table, Box } from "aplication-yetz";
import {
    formatCurrentMoney,
    formatDateIso,
    filterDetalhePedido,
} from "../../util/helper";
import { useDetalhePedido } from "../../Hook/useDetalhePedidoPlataforma";
import { column } from "./types";

interface TableDetalhePedidoProps {
    valueFilter: string;
}

export function TableDetalhePedido(props: TableDetalhePedidoProps) {
    const { valueFilter } = props;

    const { detalhePedido } = useDetalhePedido();

    const data = detalhePedido.id
        ? detalhePedido.itens.map((item) => {
              const lastLog = item?.last_log?.created_at || undefined;
              return {
                  id: item.id,
                  valor_reais: formatCurrentMoney(parseFloat(item.valor_reais)),
                  compras_created_at: item.compras_created_at
                      ? item.compras_created_at
                      : item.cargas_created_at,
                  data_hora: lastLog
                      ? formatDateIso(lastLog ? lastLog : "")
                      : "-",
                  status: item.status,
                  oc: item.oc ? item.oc : "-",
                  fornecedor: item.fornecedor_nome,
                  voucher: (
                      <Box width="74px" height="50px">
                          <img
                              src={item.produto.foto_capa}
                              alt=""
                              className="img-card"
                          />
                      </Box>
                  ),
              };
          })
        : [];

    const dataFilter = filterDetalhePedido(data, valueFilter);

    return <Table data={dataFilter} columns={column} />;
}
