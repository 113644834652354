import { Flex, Heading, Filter, ButtonSystem, Text } from "aplication-yetz";
import { Link } from "react-router-dom";
import { ChangeEvent, useCallback, useState } from "react";
import debounce from "lodash.debounce";
import { api } from "../../services";
import { toast } from "react-toastify";
import { Permissions } from "../Permission";

interface HeaderProps {
    setModal: (active: boolean) => void;
    setFilter: (filter: string) => void;
}

export function Header(props: HeaderProps) {
    const { setModal, setFilter } = props;
    const hasSanbox = process?.env?.REACT_APP_ENV === "sandbox" || false;
    const [loadingSync, setLoadingSync] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);

    // eslint-disable-next-line
    const debouncedChange = useCallback(
        debounce((value: string) => setFilter(value), 800),
        []
    );

    function handleFilterChange(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();

        const newValue = event.target.value;

        debouncedChange(newValue);
    }

    async function exportar() {
        try {
            setLoadingExport(true);
            const outputFilename = `${Date.now()}.csv`;

            const response: any = await api.get(`relatorio/list-produtos`, {
                responseType: "blob",
            });

            var data = new Blob([response.data]);
            var csvURL = window.URL.createObjectURL(data);
            var tempLink;
            tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", outputFilename);
            tempLink.click();
            setLoadingExport(false);
            toast.success("Download realizado com sucesso!");
        } catch (error: any) {
            console.log(error.response);
            setLoadingExport(false);
            toast.error("Erro ao exportar arquivo");
        }
    }

    async function syncSanbox() {
        try {
            setLoadingSync(true);
            await api.post(`debugs/sync-sandbox`, {
                user_id: "3",
            });
            setLoadingSync(false);
            toast.success("Sincronização realizada com sucesso!");
        } catch (error: any) {
            console.log(error.response);
            setLoadingSync(false);
            toast.error("Erro ao sincronizar sandbox");
        }
    }

    return (
        <Flex>
            <Heading as="h2" fontSize="lg">
                Produtos
            </Heading>

            <Filter ml="auto" mr="200px" onChange={handleFilterChange} />

            <Flex alignItems="center" ml="auto" justifyContent="space-between">
                {/* <ButtonSystem mr="16px">
                    <img src={itensImg} alt="" />
                </ButtonSystem> */}

                <Permissions role="categoria.index">
                    <Link to="/categorias">
                        <Text
                            className="categorias"
                            color="primary"
                            bold={true}
                            mr="40px"
                        >
                            Categorias
                        </Text>
                    </Link>
                </Permissions>

                <Permissions role="produto.store">
                    <ButtonSystem onClick={() => setModal(true)}>
                        Adicionar produto
                    </ButtonSystem>
                </Permissions>

                <Permissions role="relatorio.list_produtos">
                    <ButtonSystem
                        ml="12px"
                        onClick={exportar}
                        loading={loadingExport}
                    >
                        Exportar
                    </ButtonSystem>
                </Permissions>

                {hasSanbox ? (
                    <ButtonSystem
                        ml="12px"
                        onClick={syncSanbox}
                        loading={loadingSync}
                    >
                        sincronizar sandbox
                    </ButtonSystem>
                ) : (
                    <></>
                )}
            </Flex>
        </Flex>
    );
}
