import { UseFornecedorProvider } from "../../Hook/useFornecedor";
import { Fornecedor } from "../../components/Fornecedor";

export function PageFornecedor() {
    return (
        <UseFornecedorProvider>
            <Fornecedor />
        </UseFornecedorProvider>
    );
}
