import styled, { DefaultTheme } from "styled-components";
import { VariantColors } from "aplication-yetz";
import iconeRelogio from "../../assets/sistema/relogio.svg";

interface ContainerProps {
    color: VariantColors;
}

interface ThemedProps extends ContainerProps {
    theme: DefaultTheme;
}

const getColor = ({ color, theme }: ThemedProps) => {
    return theme.colors[color];
};

export const Container = styled.div<ContainerProps>`
    position: relative;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    padding: 8px 0px 8px 45px;
    width: 240px;
    height: 56px;
    div {
        margin-bottom: -5px;
        position: relative;
        img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
        }
    }
    &:before {
        content: "";
        display: block;
        width: 23.01px;
        height: 23.01px;
        position: absolute;
        top: 20px;
        left: 15px;
        transform: translateY(-50%);
        -webkit-mask: url(${iconeRelogio});
        -webkit-mask-repeat: no-repeat;
        background-color: ${getColor};
    }
`;
