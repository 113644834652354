import React, { useEffect, useState } from "react";
import { Box, Flex, Heading, ButtonSystem } from "aplication-yetz";

import { useDetalheEstoque } from "../../Hook/useDetalheEstoque";

import { DadosModal } from ".";
import { formatDateTimeDb } from "../../util/helper";
import TextField from "@mui/material/TextField";
import Mask from "react-input-mask";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StaticDatePicker from "@mui/lab/StaticDatePicker";
import { ModalCalendario } from "./styles";
import imgClose from "../../assets/sistema/fechar-modal.svg";

interface CalendarioProps {
    rest: DadosModal;
}

export function Calendario(props: CalendarioProps) {
    const { updateData } = useDetalheEstoque();
    const [value, setValue] = React.useState<Date | null>();
    const [inputValue, setInputValue] = React.useState<string>("");
    const [erro, setErro] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    async function handelSubmit() {
        setLoading(true);
        if (inputValue === "") {
            setErro(true);
            setLoading(false);
            return;
        }

        const { carga_id, variacao_id } = props.rest;

        const data = formatDateTimeDb(value ? value.toUTCString() : "").split(
            " - "
        )[0];

        const [dia, mes, ano] = data.split(".");

        const dados = {
            carga_id,
            variacao_id,
            data: `${ano}-${mes}-${dia}`,
        };

        await updateData(dados, variacao_id);
        setInputValue("");
        setLoading(false);

        if (props.rest.onClose) {
            props.rest.onClose();
        }
    }

    useEffect(() => {
        if (props.rest.data) {
            const data = props.rest.data.split(" - ")[0];
            const [dia, mes, ano] = data.split(".");
            setValue(new Date(`${mes}/${dia}/${ano}`));
        }
    }, [props.rest.data]);

    return (
        <ModalCalendario>
            <Flex alignItems="center" justifyContent="space-between" mb="34px">
                <Heading as="h4" fontSize="mdl">
                    Alterar Data
                </Heading>

                <button
                    onClick={() => {
                        if (props.rest.onClose) {
                            props.rest.onClose();
                        }
                        setErro(false);
                    }}
                >
                    <img src={imgClose} alt="" />
                </button>
            </Flex>

            <Box mb="42px">
                <>
                    <Mask
                        value={inputValue}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setInputValue(e.target.value);
                        }}
                        mask={"99/99/9999"}
                        alwaysShowMask={false}
                    >
                        {() => (
                            <TextField
                                className="input-text"
                                label="NOVA DATA"
                                variant="standard"
                                fullWidth={true}
                            />
                        )}
                    </Mask>

                    {erro ? <span>Campo obrigatório </span> : <></>}
                </>
            </Box>

            <div className="container-calendario">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StaticDatePicker<Date>
                        orientation="portrait"
                        openTo="day"
                        value={value}
                        minDate={new Date(`01-01-${new Date().getFullYear()}`)}
                        shouldDisableYear={(year) => {
                            if (year.getFullYear() < new Date().getFullYear()) {
                                return true;
                            }
                            return false;
                        }}
                        onChange={(newValue) => {
                            setValue(newValue);
                            setInputValue(
                                formatDateTimeDb(
                                    newValue ? newValue.toUTCString() : ""
                                ).split(" - ")[0]
                            );
                            setErro(false);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        ToolbarComponent={() => <></>}
                    />
                </LocalizationProvider>
            </div>

            <ButtonSystem
                widthSpnier={20}
                heightSpiner={20}
                loading={loading}
                onClick={() => handelSubmit()}
            >
                ALTERAR DATA
            </ButtonSystem>
        </ModalCalendario>
    );
}
