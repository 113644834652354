import { TextImg, Text } from "aplication-yetz";
import { formatDateIso } from "../../util/helper";
import pcImg from "../../assets/sistema/pc.svg";
import whats from "../../assets/sistema/whats.svg";
import email from "../../assets/sistema/email.svg";
import seta from "../../assets/sistema/seta-branca.svg";
import { DetalheStyles } from "./styles";

import { Ingresso } from "./ingresso";
import { useCineMarkCombo } from "../../pages/CineMarkCombo/hook";
import { useCineMarkCombo as useCineMarkComboSandbox } from "../../pages/CineMarkComboSandBox/hook";
import { ButtonVoucherPDF } from "../ButtonVoucherPDF";
import { useRef } from "react";
// import { ButtonVoucherPDF } from "../ButtonVoucherPDF";

interface DetalheProps {
    date: string | null;
    code: string | null;
    password: string | null;
    descricao: string | null;
    expires_at: string | null;
    foto_capa: string | null;
    nome: string | null;
    selo: string | null;
    tutorial_observacao: string | null;
    tutorial_url: string | null;
    validade: string | null;
    valor: string | null;
    sandbox?: boolean;

    setModalWhatsapp: (value: boolean) => void;
    setModalEmail: (value: boolean) => void;

    currentRef: React.MutableRefObject<any>;
}

const selos = new Map([
    ["1", "Válido para utilização em loja virtual"],
    ["2", "Válido para utilização exclusiva em loja física"],
    ["3", "Válido exclusivo loja física e virtual"],
    ["4", "Válido exclusivo no app"],
    ["5", "Válido exclusivo no app e loja virtual"],
    ["6", "Exclusivo por telefone"],
]);

export function Detalhe(props: DetalheProps) {
    const {
        selo,
        descricao,
        date,
        nome,
        validade,
        expires_at,
        sandbox,
        setModalWhatsapp,
        setModalEmail,
    } = props;
    const { codes } = useCineMarkCombo();
    const { codes: codesSandBox } = useCineMarkComboSandbox();
    const componentRef = useRef<any>();
    const arrCodes = codes ? codes : codesSandBox;
    const width = window.screen.width;
    console.log(width);
    console.log(arrCodes);
    const data = date
        ? formatDateIso(date).split("-")[0].replace(/\./g, "/")
        : "";

    function renderValidade() {
        if (sandbox && expires_at !== null) {
            return (
                <span className="campo-verde">
                    Validade:{" "}
                    {formatDateIso(expires_at)
                        .split("-")[0]
                        .replace(/\./g, "/")}
                </span>
            );
        } else if (validade === "VENCIDO") {
            return <span className="campo-vermelho">Vencido em: {data}</span>;
        } else if (validade !== null && validade !== "VENCIDO") {
            return <span className="campo-verde">Validade: {data}</span>;
        } else if (validade === null) {
            return <span className="campo-amarelo">Validade: {data}</span>;
        }
    }

    return (
        <DetalheStyles>
            <div className="container-detalhe-itens">
                <TextImg img={pcImg} className="text-img">
                    {selo && selos.get(selo.toString())}
                </TextImg>

                <div className="compartilhar">
                    <div className="btn-pdf">
                        <ButtonVoucherPDF currentRef={componentRef} />
                    </div>
                    <h2>Aproveite e compartilhe</h2>
                    <div
                        className="compWhatsapp"
                        onClick={() => setModalWhatsapp(true)}
                    >
                        <img src={whats} alt="" />
                        Whatsapp
                        <img className="seta" src={seta} alt="" />
                    </div>
                    <div
                        className="compEmail"
                        onClick={() => setModalEmail(true)}
                    >
                        <img src={email} alt="" />
                        Email
                        <img className="seta" src={seta} alt="" />
                    </div>
                </div>

                {width <= 480 ? (
                    <div className="container-ingressos">
                        {arrCodes?.map((code, key) => {
                            return (
                                <Ingresso
                                    key={key}
                                    codes={code}
                                    count={key + 1}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <></>
                )}
            </div>

            <div className="descricao">
                <Text as="h6" fontWeight="bold" mb="16px">
                    Como funciona o Vale Presente {nome ? nome : ""}?
                </Text>

                <Text mb="36px">{descricao ? descricao : ""}</Text>

                <Text fontWeight="600">{renderValidade()}</Text>
            </div>
        </DetalheStyles>
    );
}
