import { useState } from "react";
import { toast } from "react-toastify";
import { Flex, Heading, TextImg, Text, Filter } from "aplication-yetz";

import { formatCurrentMoney } from "../../util/helper";
import { UpdateVariacao } from "../../Hook/useProduto";

import { Goback } from "../Goback";
import { Toggle } from "../../components/Toggle";
import { useDetalheEstoque } from "../../Hook/useDetalheEstoque";

import iconEstoque from "../../assets/sistema/icon-estoque.svg";

import { HeaderRight, SubHeaderStyles, HeaderStyles } from "./styles";
import { Permissions } from "../Permission";

interface HeaderProps {
    estoquer_atual: number;
    estoque_minimo: string | number;
    alerta_estoque: number;
    nome: string;
    valor: string;
    id: number;
}

export function Header(props: HeaderProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const { nome, valor, estoquer_atual, estoque_minimo, alerta_estoque, id } =
        props;
    const { updateVariacao } = useDetalheEstoque();

    async function updateVariacaoStatus(value: UpdateVariacao, id: number) {
        setLoading(true);
        const response = await updateVariacao(value, id);

        if (response.status !== 200) {
            toast.error("Erro ao atualizar a variacao");
            setLoading(false);
            return;
        }

        setLoading(false);
    }

    return (
        <HeaderStyles>
            <Flex>
                <Goback
                    title={`${nome} - ${formatCurrentMoney(parseFloat(valor))}`}
                />
            </Flex>

            <HeaderRight>
                <Permissions role="produto.variacao.update">
                    <Toggle
                        loading={loading}
                        active={alerta_estoque ? true : false}
                        onClick={() =>
                            updateVariacaoStatus(
                                {
                                    status_alerta_estoque: alerta_estoque
                                        ? 0
                                        : 1,
                                },
                                id
                            )
                        }
                        titulo="Aviso de Estoque"
                    />
                </Permissions>

                <TextImg img={iconEstoque} className="box-info-qtd-estoque">
                    Estoque atual
                    <Text fontSize="mdl" pl="16px" bold={true}>
                        {estoquer_atual}
                    </Text>
                </TextImg>

                <TextImg img={iconEstoque} className="box-info-qtd-estoque">
                    Estoque minimo
                    <Text fontSize="mdl" pl="16px" bold={true}>
                        {estoque_minimo}
                    </Text>
                </TextImg>
            </HeaderRight>
        </HeaderStyles>
    );
}

interface SubHeaderProps {
    setFiltro: (value: string) => void;
    setModalFiltro: (value: boolean) => void;
}

export function SubHeader(props: SubHeaderProps) {
    const { setFiltro, setModalFiltro } = props;

    return (
        <SubHeaderStyles>
            <TextImg img={iconEstoque}>
                <Heading as="h3" fontSize="mdl">
                    Detalhes do Estoque
                </Heading>
            </TextImg>

            <Flex ml="auto">
                <Filter
                    onChange={(e) => setFiltro(e.target.value)}
                    onClick={() => setModalFiltro(true)}
                />
            </Flex>
        </SubHeaderStyles>
    );
}
