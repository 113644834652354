import { Produtos } from "../../components/Produtos";
import { UseProdutoProvider } from "../../Hook/useProduto";

export function PageProdutos() {
    return (
        <UseProdutoProvider>
            <Produtos />
        </UseProdutoProvider>
    );
}
