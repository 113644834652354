import React, { useEffect, useState } from "react";
import { Flex, Text } from "aplication-yetz";
import TextField from "@mui/material/TextField";
import Mask from "react-input-mask";
import { FieldProps } from "formik";
import { Toggle } from "../Toggle";
import { TextAreaStyles } from "./styles";

interface IProps extends FieldProps {
    label: string;
    mask?: string;
    type?: string;
    parse?: "number" | "float";
    maxlength?: number;
    disabled?: boolean;
}

export function Input(props: IProps) {
    const {
        form,
        label,
        type = "text",
        field,
        parse,
        maxlength,
        disabled = false,
    } = props;
    const [value, setValue] = useState(field.value);

    function changeValue(e: React.ChangeEvent<HTMLInputElement>) {
        if (parse) {
            if (parse === "number") {
                form.setFieldValue(props.field.name, parseInt(e.target.value));
            } else {
                form.setFieldValue(
                    props.field.name,
                    parseFloat(e.target.value)
                );
            }
        } else {
            form.setFieldValue(props.field.name, e.target.value);
        }

        setValue(e.target.value);
    }

    useEffect(() => {
        if (field.value || field.value === 0 || field.value === "") {
            setValue(field.value);
        }
    }, [field.value]);

    return (
        <TextField
            className="input-text"
            label={label}
            type={type}
            value={value}
            disabled={disabled}
            variant="standard"
            fullWidth={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (maxlength) {
                    if (e.target.value.length <= maxlength) {
                        changeValue(e);
                    }
                } else {
                    changeValue(e);
                }
            }}
        />
    );
}

export function InputMask(props: IProps) {
    const { form, label, mask = "(99) 9 9999-9999", field, parse } = props;
    const [value, setValue] = useState<string>(field.value);

    function changeValue(e: React.ChangeEvent<HTMLInputElement>) {
        if (parse) {
            if (parse === "number") {
                form.setFieldValue(props.field.name, parseInt(e.target.value));
            } else {
                form.setFieldValue(
                    props.field.name,
                    parseFloat(e.target.value)
                );
            }
        } else {
            form.setFieldValue(props.field.name, e.target.value);
            setValue(e.target.value);
        }
    }

    useEffect(() => {
        if (field.value || field.value === 0 || field.value === "") {
            setValue(field.value);
        }
    }, [field.value]);

    return (
        <Mask
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValue(e.target.value);
                changeValue(e);
            }}
            mask={mask}
            maskPlaceholder=""
            alwaysShowMask={false}
        >
            {() => (
                <TextField
                    className="input-text"
                    label={label}
                    variant="standard"
                    fullWidth={true}
                />
            )}
        </Mask>
    );
}

export function InputMaskNomey(props: IProps) {
    const { form, label, field } = props;
    const [value, setValue] = useState<string>(field.value);

    function changeValue(value: string) {
        form.setFieldValue(props.field.name, "R$ " + parseFloat(value));
    }

    useEffect(() => {
        if (field.value || field.value === 0 || field.value === "") {
            setValue(field.value);
        }
    }, [field.value]);

    return (
        <TextField
            className="input-text"
            label={label}
            value={value}
            disabled={false}
            variant="standard"
            type="tel"
            fullWidth={true}
            prefix="R$"
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                let value = e.target.value;
                value = value.replace(/\D/g, "");
                value = value.replace(/(\d)(\d{2})$/, "$1.$2");
                value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
                setValue("R$ " + value);
                changeValue(value);
            }}
        />
    );
}

interface TextAreaProps extends FieldProps {
    label: string;
    cols?: number;
    rows?: number;
    has?: boolean;
    active?: boolean;
    onChangeActive?: () => void;
}

export function TextArea(props: TextAreaProps) {
    const {
        cols = 30,
        rows = 10,
        has = false,
        active = false,
        onChangeActive,
        form,
        label,
        field,
    } = props;
    const [value, setValue] = useState(field.value);

    useEffect(() => {
        if (field.value || field.value === 0 || field.value === "") {
            setValue(field.value);
        }
    }, [field.value]);

    return (
        <TextAreaStyles>
            <Flex alignItems="center" justifyContent="space-between" mb="10px">
                <Text as="p">{label}</Text>
                {has ? (
                    <Toggle active={active} onClick={onChangeActive} />
                ) : (
                    <></>
                )}
            </Flex>

            <textarea
                cols={cols}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    form.setFieldValue(props.field.name, e.target.value);
                    setValue(e.target.value);
                }}
                rows={rows}
                value={value}
            ></textarea>
        </TextAreaStyles>
    );
}
