import { KeyboardEvent, useState, useRef, useEffect } from "react";
import { Container } from "./styles";
import { Text } from "aplication-yetz";

interface CodeInputProps {
    code: string;
    setCode: (value: string) => void;
    hasError: boolean;
    setHasError: (value: boolean) => void;
}

export function CodeInput(props: CodeInputProps) {
    const { code, setCode, hasError, setHasError } = props;

    const inputs = useRef<HTMLInputElement[]>([]);

    const [primeir, setPrimeir] = useState("");
    const [segund, setSegund] = useState("");
    const [terceir, setTerceir] = useState("");
    const [quart, setQuart] = useState("");
    const [quint, setQuint] = useState("");
    const [sexto, setSexto] = useState("");

    function previousAndNextInput(e: KeyboardEvent<HTMLInputElement>) {
        if (e.code === "Backspace" || e.code === "Delete" || e.keyCode === 8) {
            const item = (e.target as HTMLInputElement).dataset.item;
            const prev = inputs.current[Number(item) - 1];

            if (prev) {
                (prev as HTMLInputElement).focus();
                setCode(code.substring(0, Number(item) - 1));
                setHasError(false);
            }
        } else {
            const regex = /^[0-9]$/;
            const input = e.target as HTMLInputElement;
            const value = input.value;
            const next = input.nextElementSibling;

            setCode(code + value);
            setHasError(false);

            if (regex.test(value)) {
                if (next) {
                    (next as HTMLInputElement).focus();
                }
            }
        }
    }

    useEffect(() => {
        if (hasError) {
            setHasError(true);
            inputs.current.forEach((item) => (item.value = ""));
            setPrimeir("");
            setSegund("");
            setTerceir("");
            setQuart("");
            setQuint("");
            setSexto("");
            setCode("");
        }
    }, [hasError, setHasError, inputs, setCode]);

    return (
        <Container className={`container-code ${hasError ? "error" : ""}`}>
            <input
                type="tel"
                max={1}
                maxLength={1}
                data-item="0"
                onChange={(e) => setPrimeir(e.target.value.replace(/\D/g, ""))}
                value={primeir}
                onKeyUp={(e: KeyboardEvent<HTMLInputElement>) =>
                    previousAndNextInput(e)
                }
                ref={(el: HTMLInputElement) => (inputs.current[0] = el)}
                pattern="[0-9]*"
                inputMode="numeric"
            />
            <input
                type="tel"
                max={1}
                maxLength={1}
                data-item="1"
                onChange={(e) => setSegund(e.target.value.replace(/\D/g, ""))}
                value={segund}
                onKeyUp={(e: KeyboardEvent<HTMLInputElement>) =>
                    previousAndNextInput(e)
                }
                ref={(el: HTMLInputElement) => (inputs.current[1] = el)}
                pattern="[0-9]*"
                inputMode="numeric"
            />
            <input
                type="tel"
                max={1}
                maxLength={1}
                data-item="2"
                onChange={(e) => setTerceir(e.target.value.replace(/\D/g, ""))}
                value={terceir}
                onKeyUp={(e: KeyboardEvent<HTMLInputElement>) =>
                    previousAndNextInput(e)
                }
                ref={(el: HTMLInputElement) => (inputs.current[2] = el)}
                pattern="[0-9]*"
                inputMode="numeric"
            />
            <input
                type="tel"
                max={1}
                maxLength={1}
                data-item="3"
                onChange={(e) => setQuart(e.target.value.replace(/\D/g, ""))}
                value={quart}
                onKeyUp={(e: KeyboardEvent<HTMLInputElement>) =>
                    previousAndNextInput(e)
                }
                ref={(el: HTMLInputElement) => (inputs.current[3] = el)}
                pattern="[0-9]*"
                inputMode="numeric"
            />

            <input
                type="tel"
                max={1}
                maxLength={1}
                data-item="4"
                onChange={(e) => setQuint(e.target.value.replace(/\D/g, ""))}
                value={quint}
                onKeyUp={(e: KeyboardEvent<HTMLInputElement>) =>
                    previousAndNextInput(e)
                }
                ref={(el: HTMLInputElement) => (inputs.current[4] = el)}
                pattern="[0-9]*"
                inputMode="numeric"
            />
            <input
                type="tel"
                max={1}
                maxLength={1}
                data-item="5"
                onChange={(e) => setSexto(e.target.value.replace(/\D/g, ""))}
                value={sexto}
                onKeyUp={(e: KeyboardEvent<HTMLInputElement>) =>
                    previousAndNextInput(e)
                }
                ref={(el: HTMLInputElement) => (inputs.current[5] = el)}
                pattern="[0-9]*"
                inputMode="numeric"
            />

            {hasError && (
                <Text as="small" fontSize="xss" textAlign="center" width="100%">
                    Código inválido
                </Text>
            )}
        </Container>
    );
}
