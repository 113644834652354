import { Table } from "aplication-yetz";
import { columns } from "./config";
import { Container } from "./styles";
import { useLogEstoque } from "../../../Hook/useLogEstoque";
import { Paginacao } from "../../tables";
import { When } from "../../When";
import { Loader } from "../../Loader";

export function TableLog() {
    const { logEstoque, getLogs, loading } = useLogEstoque();

    const nextPage =
        logEstoque?.current_page <= logEstoque?.last_page
            ? logEstoque.current_page + 1
            : logEstoque.current_page;

    const previusPage =
        logEstoque?.current_page >= 1
            ? logEstoque.current_page - 1
            : logEstoque.current_page;

    const canNextPage = nextPage <= logEstoque.last_page;
    const canPreviustPage = logEstoque?.from - 1 >= 1 ? true : false;

    const log = logEstoque?.data?.map((item) => {
        return {
            foto: (
                <div className="container-logo">
                    <img src={item?.foto_caṕa} alt="" />
                </div>
            ),
            status: item?.regra?.tipo,
            descricao: item?.regra?.name,
            linha: item.row + "",
        };
    });

    return (
        <Container>
            <When expr={!loading}>
                <>
                    <div className="container-table">
                        <Table
                            columns={columns}
                            data={log || []}
                            pageSize={100}
                        />
                    </div>

                    <Paginacao
                        page={logEstoque.current_page}
                        nextPage={() => getLogs(nextPage)}
                        previousPage={() => getLogs(previusPage)}
                        canPreviousPage={canPreviustPage}
                        canNextPage={canNextPage}
                        goToPage={(page) => getLogs(page)}
                        lastPage={logEstoque.last_page}
                    />
                </>
            </When>

            <When expr={loading}>
                <div className="container-loading">
                    <Loader />
                </div>
            </When>
        </Container>
    );
}
