import { toast } from "react-toastify";

import { ModalEmail } from "../ValePresente/modalEmail";
import { ModalWhatsapp } from "../ValePresente/modalWhatsapp";
import { Modal } from "../../components/Modal";

import { formatCurrentMoney, formatDateIso } from "../../util/helper";
import { Footer as FooterDesk } from "../../components/Footer";
import { ResponseVoucher } from "../../pages/MultiplosVoucherGrandCru";
import { Loader } from "../Loader";

import { Container } from "./stylesGrandCru";

import imgCopy from "../../assets/sistema/copy.svg";
import imgEditar from "../../assets/sistema/editar.svg";
import imgEmail from "../../assets/sistema/email.svg";
import imgWhats from "../../assets/sistema/whats.svg";
import { useRef, useState } from "react";
import { ButtonVoucherPDF } from "../ButtonVoucherPDF";
import { ButtonPersonalizado } from "./ButtonPersonalizado";
// import { ButtonVoucherPDF } from "../ButtonVoucherPDF";

interface UniclassCodigoProps {
    setModalTutorial: (value: boolean) => void;
    voucher: ResponseVoucher;
    loading: boolean;
    name?: string;
}

export function UniclassCodigo(props: UniclassCodigoProps) {
    const [modalEmail, setModalEmail] = useState(false);
    const [modalWhatsap, setModalWhatsapp] = useState(false);
    const componentRef = useRef<any>();
    const password = props?.voucher?.voucher
        ? props.voucher.voucher[0].password
        : "";

    const titleBtn = props?.voucher?.voucher
        ? props.voucher.voucher[0].external_link_label
        : "";
    const linkBtn = props?.voucher?.voucher
        ? props.voucher.voucher[0].external_link
        : "";
    const hasCustomBtn = linkBtn && titleBtn ? true : false;

    const configWhats = {
        onClose: () => setModalWhatsapp(false),
    };

    const configEmail = {
        onClose: () => setModalEmail(false),
    };
    const data = props?.voucher?.voucher
        ? formatDateIso(props?.voucher?.voucher[0].date || "")
              .split("-")[0]
              .replace(/\./g, "/")
        : "";

    const validade = props?.voucher?.voucher
        ? props.voucher?.voucher[0].validade
        : "";

    function copy(text: string) {
        const el = document.createElement("textarea");
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        toast.success(`Copiado`);
        return "Texto copiado com sucesso!";
    }

    function openLink() {
        window.location.href = linkBtn;
    }

    return (
        <Container bgColor="#0131FF">
            {props.loading ? (
                <div className="loader">
                    <Loader />
                </div>
            ) : (
                <>
                    <div className="container">
                        <div className="container-text">
                            <h3>MINHAS VANTAGENS</h3>
                        </div>

                        <div className="container-par">
                            <h5>
                                CRÉDITO DE{" "}
                                {formatCurrentMoney(
                                    parseFloat(
                                        props?.voucher?.voucher
                                            ? props?.voucher?.voucher[0].valor +
                                                  ""
                                            : ""
                                    )
                                )}
                                <br />
                                {props.name}
                            </h5>
                        </div>

                        <div className="card-retangulo grandCru">
                            <div className="container-voucher">
                                <div className="item">
                                    <span>código</span>
                                    <p className={`uniclass`}>
                                        {props.voucher.voucher
                                            ? props.voucher.voucher[0].codes[0]
                                            : ""}
                                        <button
                                            onClick={() =>
                                                copy(
                                                    props.voucher.voucher
                                                        ? props.voucher
                                                              .voucher[0]
                                                              .codes[0]
                                                        : ""
                                                )
                                            }
                                        >
                                            <img src={imgCopy} alt="" />
                                            copiar <br />
                                            código
                                        </button>
                                    </p>
                                </div>
                                {props?.voucher?.voucher &&
                                props?.voucher?.voucher[0].password ? (
                                    <div className="item">
                                        <span>senha</span>
                                        <p className="uniclass">{password}</p>
                                        <button
                                            onClick={() => copy(password || "")}
                                        >
                                            <img src={imgCopy} alt="" />
                                            copiar <br />
                                            senha
                                        </button>
                                    </div>
                                ) : null}

                                {validade === "VENCIDO" && (
                                    <small>Vencido em: {data}</small>
                                )}
                                {validade === "VENCIMENTO" && (
                                    <small>Vencimento em: {data}</small>
                                )}
                                {validade !== null &&
                                validade !== "VENCIDO" &&
                                validade !== "VENCIMENTO" ? (
                                    <small>
                                        Validade: {validade} dias a partir de{" "}
                                        {data}
                                    </small>
                                ) : null}
                                {validade === null && (
                                    <small className="campo-amarelo">
                                        Validade: {data}
                                    </small>
                                )}

                                {hasCustomBtn ? (
                                    <ButtonPersonalizado
                                        onClick={openLink}
                                        className="resgate-voucher"
                                        title={titleBtn}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>

                        <div className="btn-voucher">
                            <button
                                onClick={() => props.setModalTutorial(true)}
                            >
                                <img src={imgEditar} alt="" />
                                <p>Saiba como usar</p>
                            </button>
                        </div>

                        <div className="container-compartilhar">
                            <p>Aproveite e compartilhe</p>
                            <ButtonVoucherPDF currentRef={componentRef} />
                            <div className="container-btns">
                                <button
                                    className="whats"
                                    onClick={() => setModalWhatsapp(true)}
                                >
                                    <img src={imgWhats} alt="" />
                                    WhatsApp
                                </button>
                                <button
                                    className="email"
                                    onClick={() => setModalEmail(true)}
                                >
                                    <img src={imgEmail} alt="" />
                                    Email
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <Modal
                component={ModalWhatsapp}
                active={modalWhatsap}
                rest={configWhats}
            />
            <Modal
                component={ModalEmail}
                active={modalEmail}
                rest={configEmail}
            />

            <FooterDesk />
        </Container>
    );
}
