import styled from "styled-components";

export const Container = styled.div`
    height: calc(100vh - 48px);
    background-color: ${(props) => props.theme.colors.primary};
    padding: 0% 5%;
    .header-logo {
        padding-top: 89px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 122px;
    }

    .container-description {
        .container-img {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 26px;
        }
        h1 {
            font-family: "lato";
            font-size: 30px;
            font-weight: 700;
            line-height: 30px;
            text-align: center;
            color: #fcb614;
            margin-bottom: 16px;
        }

        p {
            width: 259px;
            margin: 0 auto;
            color: #fff;
            font-family: "Lato";
            font-size: 14px;
            font-weight: 400;
            line-height: 19.32px;
            text-align: center;
        }
    }
`;
