import { BoxDetalheProduto } from "../BoxDetalheProduto";
import { formataCounter, formatCurrentMoney } from "../../util/helper";
import { Head } from "../../Hook/useDetalhePedidoPlataforma";
import { BoxDetalheProdutoProps } from "../BoxDetalheProduto/types";
import imgPlataforma from "../../assets/sistema/menu-plataforma.svg";
import imgTotalPedidos from "../../assets/sistema/total-pedidos.svg";
import imgDinheiro from "../../assets/sistema/dinheiro.svg";

import { CardsStylesNovo } from "./styles";

interface CardsProps {
    pedidos: Head[];
}
export function Cards(props: CardsProps) {
    const { pedidos } = props;

    const porcentagem = pedidos.length
        ? parseFloat(
              Number(
                  (pedidos[0]?.total_vouchers_utilizados /
                      pedidos[0]?.total_vouchers) *
                      100
              ).toFixed(2)
          )
        : 0;
    const arrayCard: BoxDetalheProdutoProps[] = [
        {
            img: imgPlataforma,
            color: pedidos.length
                ? pedidos[0].light_color
                    ? pedidos[0].light_color
                    : ""
                : "",
            subTitulo: pedidos.length ? pedidos[0].name : "",
            titulo: "plataforma",
        },

        {
            img: imgTotalPedidos,
            subTitulo: pedidos.length
                ? pedidos[0].total_jobs
                    ? pedidos[0].total_jobs
                    : ""
                : "",
            titulo: "Jobs",
        },

        {
            img: imgTotalPedidos,
            subTitulo: pedidos.length
                ? formataCounter(pedidos[0].total_pedidos + "")
                : "",
            titulo: "TOTAL DE PEDIDOS",
        },

        {
            img: imgTotalPedidos,
            subTitulo: pedidos.length
                ? `${pedidos[0].total_vouchers} / ${pedidos[0].total_vouchers_utilizados} | ${porcentagem}%`
                : "",
            titulo: "TOTAL DE VOUCHERS",
        },

        {
            img: imgDinheiro,
            subTitulo: pedidos.length
                ? formatCurrentMoney(
                      parseFloat(pedidos[0].total_vouchers_reais)
                  )
                : "",
            titulo: "VALOR TOTAL",
        },
    ];
    return (
        <CardsStylesNovo
            gridTemplateColumns="388px 237px 237px 237px 237px"
            gridGap="30px"
        >
            {arrayCard.map((item, key) => (
                <BoxDetalheProduto {...item} key={key} />
            ))}
        </CardsStylesNovo>
    );
}
