import { Flex, Heading, Filter, ButtonSystem } from "aplication-yetz";
import { Permissions } from "../Permission";

interface HeaderProps {
    setModal: (active: boolean) => void;
    setFilter: (filter: string) => void;
}

export function Header(props: HeaderProps) {
    const { setModal, setFilter } = props;

    return (
        <Flex alignItems="center" justifyContent="space-between" mb="68px">
            <Heading as="h2" fontSize="lg">
                Categorias
            </Heading>
            <Filter
                ml="auto"
                mr="200px"
                onChange={(e) => setFilter(e.target.value)}
            />
            <Permissions role="categoria.store">
                <Flex ml="auto" justifyContent="space-between">
                    {/* <ButtonSystem mr="16px">
                    <img src={itensImg} alt="" />
                </ButtonSystem> */}

                    <ButtonSystem onClick={() => setModal(true)}>
                        {" "}
                        Adicionar categoria{" "}
                    </ButtonSystem>
                </Flex>
            </Permissions>
        </Flex>
    );
}
