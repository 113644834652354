import { Column } from "react-table";
import * as Yup from "yup";

export interface Data {
    id?: number;
    titulo: string | React.ReactChild | React.ReactChild[] | React.ReactNode;
    data_hora: string | React.ReactChild | React.ReactChild[] | React.ReactNode;
    periodo: string | React.ReactChild | React.ReactChild[] | React.ReactNode;
    criado_por:
        | string
        | React.ReactChild
        | React.ReactChild[]
        | React.ReactNode;
    status: string | React.ReactChild | React.ReactChild[] | React.ReactNode;
    excluir: string | React.ReactChild | React.ReactChild[] | React.ReactNode;
    acoes: string | React.ReactChild | React.ReactChild[] | React.ReactNode;
}

export const column: Column<Data>[] = [
    {
        Header: "Título",
        accessor: "titulo",
    },
    {
        Header: "DATA E HORA",
        accessor: "data_hora",
    },
    {
        Header: "período",
        accessor: "periodo",
    },
    {
        Header: "criado por",
        accessor: "criado_por",
    },
    {
        Header: "status",
        accessor: "status",
    },
    {
        Header: "excluir",
        accessor: "excluir",
        width: 100,
    },
    {
        Header: "AÇÕES",
        accessor: "acoes",
        width: 100,
    },
];

export interface FormRelatorio {
    title: string;
    start: string;
    end: string;
}

export const initialValues: FormRelatorio = {
    title: "",
    start: "",
    end: "",
};

export const validation = Yup.object().shape({
    title: Yup.string().required("Campo obrigatório"),
    start: Yup.string().required("Campo obrigatório"),
    end: Yup.string().required("Campo obrigatório"),
});
