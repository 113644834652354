import cardsBloqueado from "../../assets/sistema/acao-bloqueada.png";

import { StylesAlerta } from "./styles";

export function Bloqueado() {
    return (
        <StylesAlerta>
            <div className="container-img">
                <img src={cardsBloqueado} alt="" />
            </div>

            <div className="container-text">
                <p>
                    Esse bloqueio pode ser temporário e está relacionado à ação
                    da qual participa.
                </p>
                <br />
                <br />
                <p>Se tiver dúvida, utilize o FALE CONOSCO.</p>
            </div>
        </StylesAlerta>
    );
}
