import { BoxGaleryStyles } from "./styles";
import imgExcluir from "../../assets/sistema/excluir.svg";
import imgEditar from "../../assets/sistema/editar-vinho.svg";
import { useProduto } from "../../Hook/useProduto";

interface Box {
    id: string;
    img: string;
}

interface BoxGaleryProps extends Box {
    arrayGalery: Box[];
}

export function BoxGalery(props: BoxGaleryProps) {
    const { id, img } = props;
    const { setGaleriaItemId, setFileImgProduto, setAlertDialog } =
        useProduto();

    return (
        <BoxGaleryStyles>
            <div className="container-btns">
                <label htmlFor={id}>
                    <img src={imgEditar} alt="editar" />
                </label>
                <button
                    type="button"
                    onClick={() => {
                        setAlertDialog(true);
                        setGaleriaItemId(id);
                    }}
                >
                    <img src={imgExcluir} alt="excluir" />
                </button>
            </div>

            <input
                type="file"
                id={id}
                accept="image/png, image/gif, image/jpeg"
                onChange={async (e) => {
                    if (e.target.files?.length) {
                        setAlertDialog(true);
                        setGaleriaItemId(id);
                        setFileImgProduto(e.target.files);
                    }
                }}
            />

            <div className="container-img">
                <img src={img} alt={id} />
            </div>
        </BoxGaleryStyles>
    );
}
