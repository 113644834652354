import { FilterStyles } from "./styles";
import { Select } from "../Select";
import { useVouchers } from "../../Hook/useVouchers";
export function Filter() {
    const { setFilter } = useVouchers();

    const options = [
        {
            value: 1,
            label: "PROCESSSANDO",
        },
        {
            value: 2,
            label: "AGUARDANDO",
        },
        {
            value: 3,
            label: "APROVADO",
        },
        {
            value: 4,
            label: "REPROVADO",
        },
    ];

    return (
        <FilterStyles>
            <h1>Filtro</h1>

            <Select
                options={options}
                onChange={(value) => setFilter(value.label)}
                placeholder="Filtro"
            />
        </FilterStyles>
    );
}
