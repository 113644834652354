import { Box, Flex, Text, Heading } from "aplication-yetz";
import { getLocalStore } from "../../util/helper";

export function SubHeader() {
    const localStore = getLocalStore();
    return (
        <Flex>
            <Box mb="4.375rem">
                <Text color="black" pb="2rem">
                    Olá {localStore?.name ? localStore.name : ""}
                </Text>

                <Heading as="h2" fontSize="xl">
                    Como deseja prosseguir?
                </Heading>
            </Box>
        </Flex>
    );
}
