import styled from "styled-components";
import { Container as ButtonVoucherPDF } from "../ButtonVoucherPDF/styles";
import imgFundo from "../../assets/sistema/fundo-btn-voucher.svg";
import imgLoadingFundo from "../../assets/sistema/fundo-loading.svg";

interface ContainerProps {
    bgColor?: string;
}

export const Container = styled.div<ContainerProps>`
    background: ${(props) => (props.bgColor ? props.bgColor : "#000d3c")};
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: "Zen Kaku Gothic New";
    &.voucher-link {
        .card-retangulo {
            &.grandCru {
                margin-bottom: 33px;
            }
        }

        .item {
            h4 {
                text-transform: capitalize;
            }
        }
        .container-compartilhar {
            p {
                &.text-comaprtilhe {
                    padding-bottom: 17px;
                    border-bottom: 1px solid #e1e1e1;
                }
            }
            ${ButtonVoucherPDF} {
                padding-top: 0px;
                border-top: unset;
                margin-bottom: 22px;
            }
        }
    }
    &.schweppesintense {
        background: linear-gradient(196deg, #e86972 -5.68%, #febf3e 89.16%);
        .text {
            color: #000 !important;
        }
    }
    .loader {
        height: calc(100vh - 81px);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .container {
        margin: 0 auto;
        margin-bottom: auto;
        padding: 45px 0px;
        h5 {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            color: #ffffff;
            &.copo-stanley {
                width: 300px;
                margin: 0 auto;
            }
        }

        h4 {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            color: #ffffff;
            margin-bottom: 44px;
        }

        .container-text {
            margin-bottom: 33px;
            h3 {
                font-style: normal;
                font-size: 40px;
                line-height: 46px;
                text-align: center;
                color: #ffffff;
                p {
                    color: #ffffff;
                    font-weight: 400;
                }
                span {
                    font-weight: 400;
                }
            }
        }

        .container-ingresso {
            text-align: center;
            margin-top: 32px;
            margin-bottom: 32px;
        }

        .container-par {
            margin-bottom: 33px;
        }

        .card-retangulo {
            width: 309px;
            height: auto;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-bottom: 47px;
            .esq {
                height: 86%;
                position: absolute;
                top: 50%;
                left: 1.5px;
                transform: translateY(-50%);
                /* height: 100%; */
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;
                span {
                    display: block;
                    background: #000d3c;
                    width: 7px;
                    height: 7px;
                    border-radius: 50%;
                    &:first-child {
                        width: 22px;
                        height: 22px;
                        transform: translate(-10%, -40%);
                    }
                    &:last-child {
                        width: 22px;
                        height: 22px;
                        transform: translate(-10%, 40%);
                    }
                }
            }

            .dir {
                height: 86%;
                position: absolute;
                top: 50%;
                right: 1.5px;
                transform: translateY(-50%);
                /* height: 100%; */
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;
                span {
                    display: block;
                    background: #000d3c;
                    width: 7px;
                    height: 7px;
                    border-radius: 50%;
                    &:first-child {
                        width: 22px;
                        height: 22px;
                        transform: translate(-10%, -40%);
                    }
                    &:last-child {
                        width: 22px;
                        height: 22px;
                        transform: translate(-10%, 40%);
                    }
                }
            }

            .item {
                position: relative;
                p {
                    font-family: "Zen Kaku Gothic New" !important;
                    font-weight: 900;
                }

                .btn-opne-link {
                    border-radius: 4px;
                    background: #f7941d;

                    height: 39px;
                    position: static;
                    transform: translateY(16px);
                    margin: 0px;
                    margin-bottom: 16px;
                    padding: 0 20px;
                    h4 {
                        color: #fff;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        margin: 0px;
                        &.title-link-sem-valor {
                            font-size: 12px;
                        }
                    }
                }
            }

            .container-voucher {
                width: 100%;
                padding: 23px 19px 18px 16px;
                img {
                    margin-right: 6px;
                }
                button {
                    font-size: 8px;
                    text-transform: uppercase;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-left: 14px;
                    text-align: left;
                    display: flex;
                    align-items: center;
                    position: absolute;
                    top: 50%;
                    right: 0px;
                    transform: translateY(-40%);
                    color: #000;
                    &.resgate-voucher {
                        position: static;
                        justify-content: center;
                        color: #fff;
                        background-color: #f7941d;
                        transform: unset;
                        margin: 0px;
                        text-transform: unset;
                        font-size: 14px;
                        width: 100%;
                        height: 39px;
                        border-radius: 4px;
                        margin-top: 16px;
                        img {
                            display: block;
                            margin-right: 8px;
                        }
                    }
                }

                span {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12.2734px;
                    line-height: 16px;
                    text-align: center;
                    letter-spacing: 0.04em;
                    text-transform: uppercase;
                    color: #4b4b4b;
                    white-space: nowrap;
                    display: block;
                    margin-bottom: 6px;
                }

                small {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                    display: block;
                    text-align: center;
                    color: #000d3c;
                }

                p {
                    font-style: normal;
                    font-weight: 700;
                    /* font-size: 19px; */
                    line-height: 24px;
                    text-align: center;
                    color: #000000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &.small {
                        font-size: 14px;
                    }

                    &.uniclass {
                        font-size: 14px;
                        img {
                            margin-left: 6px;
                        }
                        button {
                            transform: translate(0%, -35%);
                        }
                    }
                }
            }

            &.grandCru {
                background: #f1f1f1;
                border-radius: 8px;
                .container-voucher {
                    p {
                        justify-content: flex-start;
                    }
                    span {
                        text-align: initial;
                    }
                    .item {
                        margin-bottom: 15px;
                        padding-bottom: 15px;
                        border-bottom: 0.767087px solid #d9d9d9;
                        &:nth-child(2) {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }

        .btn-voucher {
            margin-bottom: 33px;
            button {
                position: relative;
                width: 275px;
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: #ffffff;
                    -webkit-mask: url(${imgFundo}) no-repeat;
                    -webkit-mask-size: cover;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }

                img {
                    position: relative;
                    z-index: 20;
                }

                p {
                    position: relative;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13.5569px;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    color: #000d3c;
                    z-index: 99;
                    margin-left: 8px;
                }
            }
        }

        .container-compartilhar {
            width: 282px;
            margin: 0 auto;
            margin-bottom: 44px;
            p {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #ffffff;
                border-bottom: 1px solid #E1E1E1;
                padding-bottom: 17px;
                margin-bottom: 17px;
            }

            .container-btns {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 21px;
                button {
                    position: relative;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: #ffffff;
                    width: 100%;
                    height: 39px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding-left: 15px;

                    img {
                        display: block;
                        margin-right: 11px;
                    }

                    &.whats {
                        background: #28c554;
                        border-radius: 4px;
                    }

                    &.email {
                        background: #f7941d;
                        border-radius: 4px;
                    }
                }
            }
        }

        .container-descricao {
            width: 309px;
            margin: 0 auto;
            p {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                text-align: center;
                color: #f5f5f5;
            }
        }

        .container-btn-validade {
            background: transparent;
            border-radius: 8px;
            max-width: 349px;
            width: fit-content;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -35px;
            small {
                font-family: "Inter";
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 15px;
                color: #000d3c;
                white-space: nowrap;
                border-top: 0.5px solid #d6d5d5;
                padding-top: 5px;
            }
        }
    }

    .footer-desk {
        background-color: #fff;
    }

    @media (max-width: 1366px) {
        .footer-desk {
            & > div {
                width: 90%;
            }
        }
    }

    @media (max-width: 480px) {
        .container {
            padding-top: 69px;
            padding-bottom: 37px;

            .btn-voucher {
                margin-bottom: 33px;
            }

            .card-retangulo {
                margin-bottom: 33px;
            }

            .container-compartilhar {
                margin-bottom: 43px;
            }

            h5 {
                font-size: 24px;
                font-weight: bold;
            }

            h4 {
                margin-bottom: 44px;
            }

            .container-descricao {
                margin-bottom: 0px;
            }

            .container-btn-validade {
                button {
                    font-size: 14px;
                }
            }
        }
        .container {
            width: 90%;
            .container-text {
                h3 {
                    font-size: 24px;
                    line-height: normal;
                }
            }

            .container-ingresso {
                img {
                    width: 100%;
                }
            }
        }
    }
`;

export const FooterStyles = styled.div`
    border-top: 1px solid #ececec;
    margin-top: auto;
    background-color: #000d3c;

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px;
        height: 81px;
    }

    p {
        grid-column: span 2;
        color: #fff;
    }

    @media (max-width: 1024px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
        p {
            grid-column: unset;
        }
    }

    @media (max-width: 480px) {
        .container {
            flex-direction: column;
            img {
                margin-bottom: 24px;
            }
        }
    }

    @media (max-width: 480px) {
        flex-direction: column;
        align-items: center;
        text-align: center;

        .container {
            height: auto;
            padding: 16px 0px;
        }
    }
`;

export const ModalLoading = styled.div`
    background: linear-gradient(180deg, #e2e2e2 0%, #ffffff 100%);
    border-radius: 40px;
    padding: 48px 27px 42px;
    h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 130%;
        text-align: center;
        color: #000d3c;
        margin-bottom: 21px;
    }

    .container-img {
        margin-bottom: 27px;
    }

    .container-loader {
        width: 58px;
        height: 58px;
        margin: 0 auto;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 51%;
            transform: translate(-50%, -50%);
            background: url(${imgLoadingFundo}) no-repeat;
            width: 59px;
            height: 59px;
            z-index: 20;
            -webkit-animation: spin 1.3s linear infinite;
            animation: spin 1.3s linear infinite;
        }
    }

    @keyframes spin {
        0% {
            transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }

    @media (max-width: 480px) {
        width: 90%;
        .container-img {
            img {
                width: 100%;
            }
        }
    }
`;
