import styled from "styled-components";

export const Container = styled.div`
    .header-detalhe-pedido {
        padding-bottom: 48px;
        border-bottom: 1px solid #ececec;
        margin-bottom: 51px;
    }

    .cards-detalhe-pedido {
        padding-bottom: 52px;
        border-bottom: 1px solid #ececec;
        margin-bottom: 90px;
    }

    .text-icone-voucher {
        &:before {
            width: 28px;
            height: 29px;
        }
    }

    .content-filtro {
        padding-bottom: 64px;
    }

    .img-card {
        width: 100%;
        height: 100%;
    }
`;
