import { Column } from "react-table";
import { Data } from "./types";

export const column: Column<Data>[] = [
    {
        Header: "NOME",
        accessor: "nome",
    },
    {
        Header: "DESCRICAO",
        accessor: "descricao",
    },

    {
        Header: "CNPJ",
        accessor: "cnpj",
    },

    {
        Header: "EDITAR",
        accessor: "editar",
    },

    // {
    //     Header: " EXCLUIR",
    //     accessor: "excluir",
    // },
];
