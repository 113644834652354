import { HistoricoCargas } from "../../components/HistoricoCargas";
import { EstoqueProvider } from "../../Hook/useEstoque";

export function PageHistoricoCargas() {
    return (
        <EstoqueProvider>
            <HistoricoCargas />
        </EstoqueProvider>
    );
}
