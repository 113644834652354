import { useState, useRef } from "react";
import { Box, Flex, Heading, ButtonSystem } from "aplication-yetz";
import { useUser } from "../../Hook/useUser";
import { Select, DateTime } from "../Select";
import { Option } from "../Select/types";
import { ModalFiltroDados } from ".";
import imgClose from "../../assets/sistema/fechar-modal.svg";

import { ModalFiltroStyles } from "./styles";

interface ModalFiltroProps {
    rest: {
        setModalFiltroDados: (dados: ModalFiltroDados) => void;
        ModalFiltroDados: ModalFiltroDados;
        onClose: () => void;
    };
}

export function ModalFiltro(props: ModalFiltroProps) {
    const { users } = useUser();
    const [user, setUser] = useState<string>("");
    const [data, setData] = useState<string>("");

    const clearSelect = useRef<HTMLButtonElement>(null);
    const clearDate = useRef<HTMLButtonElement>(null);

    const options: Option[] = users.map((user) => {
        return {
            label: user.name,
            value: user.id,
        };
    });

    function handleFilter() {
        if (props.rest.setModalFiltroDados) {
            props.rest.setModalFiltroDados({
                user,
                data,
            });
        }

        if (props.rest.onClose) {
            props.rest.onClose();
        }
    }

    function handleClear() {
        setUser("");
        setData("");

        props.rest.setModalFiltroDados({
            user: "",
            data: "",
        });

        if (props.rest.onClose) {
            props.rest.onClose();
        }

        if (clearSelect.current) {
            clearSelect.current.click();
        }

        if (clearDate.current) {
            clearDate.current.click();
        }
    }

    return (
        <ModalFiltroStyles>
            <Flex alignItems="center" justifyContent="space-between" mb="34px">
                <Heading as="h4" fontSize="mdl">
                    Filtros
                </Heading>

                <button
                    onClick={() => {
                        if (props.rest.onClose) {
                            props.rest.onClose();
                        }
                    }}
                >
                    <img src={imgClose} alt="" />
                </button>
            </Flex>

            <Box mb="42px">
                <Box mb="32px">
                    <Select
                        clear={clearSelect}
                        onChange={(value) => setUser(value.label)}
                        placeholder="ENVIADO POR"
                        options={options}
                    />
                </Box>

                <div className="container-calendario">
                    <DateTime
                        clear={clearDate}
                        initialValue={null}
                        onChange={(value) => {
                            const data = value.split(" ");
                            const [dia, mes, ano] = data[0].split("/");

                            setData(
                                `${ano}-${mes}-${dia} ${data[1].substring(
                                    0,
                                    5
                                )}`
                            );
                        }}
                    />
                </div>
            </Box>

            <Flex justifyContent="space-between" width="250px">
                <ButtonSystem onClick={handleFilter}>Filtrar</ButtonSystem>
                {user || data ? (
                    <ButtonSystem onClick={handleClear}>Limpar</ButtonSystem>
                ) : (
                    <></>
                )}
            </Flex>
        </ModalFiltroStyles>
    );
}
