import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { routes } from "./routes";
import { SwitchRoutes } from "./components/SwitchRoutes";

import { GlobalStyle } from "./styles/global";
import Interceptor from "./services/interceptor";
import "react-toastify/dist/ReactToastify.css";

export function App() {
    return (
        <>
            <GlobalStyle />
            <Interceptor />
            <BrowserRouter>
                <SwitchRoutes routes={routes} />
                <ToastContainer />
            </BrowserRouter>
        </>
    );
}
