import styled from "styled-components";
import { Box } from "aplication-yetz";

export const TextAreaStyles = styled(Box)`
    margin-bottom: 32px;
    &:last-child {
        margin-bottom: 0px;
    }
    p {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: ${({ theme }) => theme.colors.black};
        padding-left: 18px;
        position: relative;
        &:before {
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            background-color: ${({ theme }) => theme.colors.primary};
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
            border-radius: 50%;
        }
    }

    textarea {
        border: 1px solid #cfcfcf;
        width: 100%;
        height: 100%;
        padding: 24px;
    }
`;
