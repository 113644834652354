import { FornecedorById } from "../../Hook/useCompras";
import { useCarrinho } from "./Hook";
import { formatCurrentMoney } from "../../util/helper";
import { ProdutoItem } from "./styles";
import imgCard from "../../assets/sistema/card-ifood.png";
import { toast } from "react-toastify";

export function Produto(props: FornecedorById) {
    const { foto_capa, id, nome, valor_reais } = props;
    const { carrinho, setCarrinho } = useCarrinho();

    function addCompras(compras: FornecedorById) {
        if (carrinho.find((item) => item.id === compras.id)) {
            toast.error("Produto já adicionado ao carrinho");
        } else {
            setCarrinho([...carrinho, { ...compras, quantidade: 1 }]);
        }
    }

    return (
        <ProdutoItem onClick={() => addCompras(props)}>
            <div className="container-produto">
                <div className="container-img">
                    <img src={foto_capa ? foto_capa : imgCard} alt="" />
                </div>

                <p>{id}</p>
                <p>{nome}</p>
                <p>
                    {valor_reais
                        ? formatCurrentMoney(parseFloat(valor_reais))
                        : "--"}
                </p>
                <button>Adicionar</button>
            </div>
        </ProdutoItem>
    );
}
