import * as Yup from "yup";

export interface Myform{
    nome: string;
    job: string;
}

export const initialValues: Myform = {
    nome: "",
    job: "",
};

export const schema = Yup.object().shape({
    nome: Yup.string().required("Campo é obrigatório"),
    job: Yup.string().required("Campo é obrigatório"),
});
