import { Column } from "react-table";
import { Data } from "./types";

export const column: Column<Data>[] = [
    {
        Header: "PEDIDO",
        accessor: "pedido",
    },
    {
        Header: "DATA/HORA",
        accessor: "data_hora",
    },
    // {
    //     Header: "PLATAFORMA",
    //     accessor: "plataforma",
    // },
    {
        Header: "QTD",
        accessor: "qtd",
    },
    {
        Header: "VALOR TOTAL",
        accessor: "valor_total",
    },
    {
        Header: "Registro",
        accessor: "customer",
    },
    {
        Header: "STATUS",
        accessor: "status",
    },
    {
        Header: "Job",
        accessor: "job",
    },
    {
        Header: "AÇÕES",
        accessor: "acaoes",
        width: 83,
        maxWidth: 83,
    },
];
