import { useEffect, useState } from "react";
import { useCompras } from "../../Hook/useCompras";
import { Select } from "../../components/Select";
import { Option } from "../../components/Select/types";
import { HeaderStyles } from "./styles";

interface HeaderProps {
    setFiltro: (value: number) => void;
}

export function Header(props: HeaderProps) {
    const { setFiltro } = props;
    const { getFornecedor } = useCompras();
    const [options, setOptions] = useState<Option[]>([]);

    useEffect(() => {
        async function fetchData() {
            const dados = await getFornecedor();
            setOptions(
                dados.map((item) => ({ value: item.id, label: item.nome }))
            );
        }
        fetchData();
    }, [getFornecedor]);

    return (
        <HeaderStyles>
            <Select
                options={options}
                placeholder="Fornecedor"
                onChange={(value) => setFiltro(value.value)}
            />
        </HeaderStyles>
    );
}
