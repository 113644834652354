import React, { createContext, useContext, useState, useEffect } from "react";
import { Roles } from "./useUser";
import { api } from "../services";
import { Variacao } from "./useProduto";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";

interface UseComprasProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface UseComprasData {
    compras: DataCompras;
    loadingCompras: boolean;
    // getCompras: () => Promise<DataCompras>;
    getFornecedor: () => Promise<Fornecedor[]>;
    getComprasByFornecedor(
        id?: number
    ): Promise<AxiosResponse<FornecedorById[]>>;
    storeCompra(values: StoreCompras): Promise<AxiosResponse>;
    aprovaCompra(id?: number): Promise<AxiosResponse>;
    reprovaCompra(id?: number): Promise<AxiosResponse>;
    paginacaoCompras: (page: number) => void;
    itensCompras: DataItensCompras;
    getItensCompra: (id: number, page?: string) => void;
    loadingItensCompra: boolean;
    modal: boolean;
    setModal: (value: boolean) => void;
    setFilter: (value: string) => void;
}

export interface DataCompras {
    data: Compras[];
    current_page: number;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string | null;
    per_page: number;
    prev_page_url: string | null;
    to: number;
    total: number;
}

export interface Compras {
    id: number;
    fornecedor_id: number;
    user_id: number;
    valor_total: string;
    approved_at: null | string;
    approve_user_id: null | string;
    status: string;
    created_at: string;
    updated_at: string;
    fornecedor: Fornecedor;
    user: User;
    itens: number;
    itens_sucesso: number;
    nome: string;
    job: string;
}

export interface Fornecedor {
    id: number;
    nome: string;
    descrição: null | string;
    cnpj: null | string;
    created_at: string;
    updated_at: string;
}

interface User {
    id: number;
    name: string;
    email: string;
    status: number;
    color: null | string;
    light_color: null | string;
    phone_number: string;
    voucher_limit_time: null | string;
    last_login: string;
    created_at: null | string;
    updated_at: string;
    whitelist: boolean;
    "sem-estoque": boolean;
    "pedido-outlet": boolean;
    roles: Roles[];
}

export interface DataItensCompras {
    data: Itens[];
    current_page: number;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string | null;
    per_page: number;
    prev_page_url: string | null;
    to: number;
    total: number;
}

interface Itens {
    id: number;
    compra_id: number;
    produto_variacao_id: number;
    fornecedor_code: string;
    quantidade: number;
    valor: string;
    variacao: Variacao;
    status: string;
    log: string;
}

export interface FornecedorById {
    id: number;
    produto_id: number;
    nome: string;
    status: number;
    valor_yetz: string;
    valor_reais: string;
    foto_capa: string;
    alerta_estoque: number;
    status_alerta_estoque: number;
    created_at: null | string;
    updated_at: null | string;
    quantidade?: number;
}

export interface StoreCompras {
    job?: string;
    nome?: string;
    fornecedor_id: number;
    variacoes: StoreVaricao[];
}

interface StoreVaricao {
    produto_variacao_id: number;
    quantidade: number;
}

const UseComprasContext = createContext<UseComprasData>({} as UseComprasData);

export function UseCompraProvider(props: UseComprasProviderProps) {
    const { children } = props;
    const [itensCompras, setItensCompras] = useState<DataItensCompras>(
        {} as DataItensCompras
    );
    const [compras, setCompras] = useState<DataCompras>({} as DataCompras);
    const [loadingCompras, setLoadingCompras] = useState<boolean>(false);
    const [modal, setModal] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>("");
    const [loadingItensCompra, setLoadingItensCompra] =
        useState<boolean>(false);

    async function getCompras(filter?: string) {
        setLoadingCompras(true);

        try {
            const { data } = await api.get<DataCompras>(`/compra${filter}`);
            setCompras(data);
        } catch (error) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
        } finally {
            setLoadingCompras(false);
        }
    }

    async function paginacaoCompras(page: number) {
        setLoadingCompras(true);
        const hasFilter = filter ? `&STATUS=${filter}` : "";
        try {
            const { data } = await api.get<DataCompras>(
                `compra?page=${page}${hasFilter}`
            );
            setCompras(data);
        } catch (error) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
        } finally {
            setLoadingCompras(false);
        }
    }

    async function getItensCompra(id: number, page?: string) {
        setLoadingItensCompra(true);
        try {
            const params: Record<string, string> = {};

            if (page?.length) {
                params["page"] = page;
            }

            const { data } = await api.get<DataItensCompras>(
                `/compra/${id}/itens`,
                {
                    params,
                }
            );
            setItensCompras(data);
            return data;
        } catch (error) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
        } finally {
            setLoadingItensCompra(false);
        }
    }

    async function getFornecedor() {
        try {
            const { data } = await api.get<Fornecedor[]>("/fornecedor");
            return data;
        } catch (error) {
            return error as Fornecedor[];
        }
    }

    async function getComprasByFornecedor(id: number = 1) {
        try {
            const response = await api.get<FornecedorById[]>(
                `fornecedor/${id}/produtos`
            );
            return response;
        } catch (error: any) {
            return error.response as AxiosResponse;
        }
    }

    async function storeCompra(values: StoreCompras) {
        try {
            const response = await api.post<StoreCompras>("/compra", {
                ...values,
            });

            toast.success("Compra salva com sucesso!");
            return response;
        } catch (error: any) {
            toast.error("Erro ao salvar compra!");
            return error;
        }
    }

    async function aprovaCompra(id: number) {
        try {
            const response = await api.post<StoreCompras>("/compra/aprovar", {
                compra_id: id,
            });

            toast.success("Compra aprovada com sucesso!");
            return response;
        } catch (error: any) {
            toast.error("Erro ao aprovar compra!");
            return error;
        }
    }

    async function reprovaCompra(id: number) {
        try {
            const response = await api.post<StoreCompras>("/compra/reprovar", {
                compra_id: id,
            });

            toast.success("Compra reprovada com sucesso!");
            return response;
        } catch (error: any) {
            toast.error("Erro ao reprovar compra!");
            return error;
        }
    }

    useEffect(() => {
        const hasFilter = filter ? `?STATUS=${filter}` : "";
        if (window.location.pathname === "/compra") {
            getCompras(hasFilter);
        }
    }, [filter]);

    return (
        <UseComprasContext.Provider
            value={{
                compras,
                loadingCompras,
                // getCompras,
                modal,
                setModal,
                setFilter,
                getFornecedor,
                getComprasByFornecedor,
                storeCompra,
                aprovaCompra,
                reprovaCompra,
                paginacaoCompras,
                itensCompras,
                getItensCompra,
                loadingItensCompra,
            }}
        >
            {children}
        </UseComprasContext.Provider>
    );
}

export function useCompras() {
    const context = useContext(UseComprasContext);
    return context;
}
