import styled from "styled-components";

interface CardStyledProps {
    bgIcone: string;
}

export const CardStyled = styled.div<CardStyledProps>`
    .container-card {
        padding: 28px 24px;
        background-color: #ffffff;
        width: 100%;
        .container-mes {
            margin-bottom: 27.5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            h3 {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: #000000;
            }

            .container-icone {
                background-color: ${(props) => props.bgIcone};
                border-radius: 50%;
                width: 45px;
                height: 45px;
                position: absolute;
                top: 0px;
                right: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .container-total {
            border-bottom: 1px solid #ebebeb;
            margin-bottom: 16px;
            .item {
                display: grid;
                grid-template-columns: 160px 1fr;
                grid-gap: 32px;
                margin-bottom: 16px;
                h5 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 15px;
                    letter-spacing: 0.06em;
                    text-transform: uppercase;
                    color: #4b4b4b;
                }

                h6 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    color: #000000;
                    white-space: nowrap;
                }
            }
        }

        .detalhes {
            border-bottom: 1px solid #ebebeb;
            margin-bottom: 16px;
            .container-item {
                display: grid;
                grid-template-columns: 190px 50px 1fr;
                margin-bottom: 16px;
                h5 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0.06em;
                    text-transform: uppercase;
                    color: #000000;
                }

                h6 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 17px;
                    white-space: nowrap;
                    color: #000000;
                }
            }
        }

        .mostrar-detalhes {
            display: flex;
            align-items: center;
            justify-content: space-between;
            button {
                display: flex;
                align-items: center;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.06em;
                text-transform: uppercase;
                color: #930035;
                transition: all 0.5s;
                &:first-child {
                    display: none;
                }
                &.btn-detalhes {
                    margin-left: auto;
                    position: relative;
                    &.active {
                        &:before {
                            transition: all 0.5s;
                            bottom: -30px;
                            opacity: 1;
                        }
                    }
                    &:before {
                        content: "";
                        display: inline-block;
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 15px solid ${(props) => props.bgIcone};
                        position: absolute;
                        /* bottom: -30px; active */
                        bottom: -60px;
                        opacity: 0;
                        right: -5px;
                    }
                    img {
                        display: block;
                        margin-left: 8px;
                    }
                }
            }
        }
    }
`;

export const ContainerYetz = styled.div`
    display: grid;
    grid-template-columns: 550px 1fr 1fr 1fr;
    position: relative;
    overflow: hidden;
    margin-bottom: 37px;
    ${CardStyled} {
        border: 1px solid #e0e0e0;
        border-left: none;
    }

    .container-mes {
        ${CardStyled} {
            .container-card {
                border-left: 1px solid #e0e0e0;
                background: #f9f9f9;
                .mostrar-detalhes {
                    button {
                        &:first-child {
                            display: block;
                        }
                    }
                }
                .container-total {
                    .item {
                        grid-template-columns: 280px 1fr;
                    }
                }
                .detalhes {
                    .container-item {
                        grid-template-columns: 310px 30px 1fr;
                    }
                }
                .container-mes {
                    h3 {
                        font-size: 20px;
                    }
                    .container-icone {
                        display: none;
                    }
                }
            }
        }
    }

    .container-table {
        width: 100%;
        grid-column: span 4;
        height: 0px;
        overflow: hidden;
        transition: all 0.5s;
        div[role="table"] {
            padding: 72px 92px;
        }
        &.active {
            transition: all 0.5s;
        }
        &.link {
            background-color: #d3faff;
        }

        &.promo {
            background-color: #f1e3ff;
        }
        &.cards {
            background-color: #ffeed3;
        }
    }
`;

export const ContainerTable = styled.div`
    .item-voucher {
        display: flex;
        align-items: center;
        img {
            width: 40px;
            height: 27px;
            border-radius: 4px;
        }
        span {
            margin-left: 16px;
        }
    }
`;
