import { toast } from "react-toastify";

import { Box, Text, TextImg, Flex } from "aplication-yetz";
import { formatCurrentMoney } from "../../util/helper";
import { BoxCodigo, CardsStyles } from "./styles";
import { BoxDetalheProduto } from "../BoxDetalheProduto";

import pcImg from "../../assets/sistema/pc.svg";
import img01 from "../../assets/sistema/money.svg";
import imgCopy from "../../assets/sistema/copy.svg";

interface CardProps {
    password: string | null;
    valor: string | null;
    foto_capa: string | null;
    selo: string | null;
    codes?: any;
}

const selos = new Map([
    ["1", "Válido para utilização em loja virtual"],
    ["2", "Válido para utilização exclusiva em loja física"],
    ["3", "Válido exclusivo loja física e virtual"],
    ["4", "Válido exclusivo no app"],
    ["5", "Válido exclusivo no app e loja virtual"],
    ["6", "Exclusivo por telefone"],
]);

export function Cards(props: CardProps) {
    const { password, valor, foto_capa, selo, codes } = props;
    const width = window.screen.width;

    const config = {
        img: img01,
        subTitulo: valor ? formatCurrentMoney(parseFloat(valor)) : "",
        titulo: "Valor",
    };

    const codeLenght = codes ? codes[0].length : 0;

    let size = "mdl" as "mdl" | "md";

    if (codeLenght >= 15) {
        size = "md";
    }

    function copy(value: string) {
        navigator.clipboard.writeText(value);
        toast.success(`Cupom copiado`);
    }

    return (
        <CardsStyles>
            <Box>
                <img src={foto_capa ? foto_capa : ""} alt="" className="card" />
            </Box>

            {width < 480 ? (
                <div className="container-selo">
                    <TextImg img={pcImg} className="text-img">
                        {selo && selos.get(selo.toString())}
                    </TextImg>
                </div>
            ) : (
                <></>
            )}

            <div className="box-valor">
                <BoxDetalheProduto {...config} />
            </div>

            <BoxCodigo password={!password ? "password" : ""}>
                <div className="codigo-vale">
                    <Text
                        fontSize="xss"
                        textTransform="uppercase"
                        color="gray300"
                        as="small"
                    >
                        código
                    </Text>
                    <Flex>
                        <Text
                            fontSize={size}
                            fontWeight="bold"
                            marginRight="20px"
                            as="h4"
                        >
                            {codes ? codes[0] : ""}
                        </Text>
                        <button onClick={() => copy(codes ? codes[0] : "")}>
                            <img src={imgCopy} alt="" />
                            copiar <br />
                            código
                        </button>
                    </Flex>
                </div>

                {password && (
                    <Box>
                        <Text
                            fontSize="xss"
                            textTransform="uppercase"
                            color="gray300"
                        >
                            Senha
                        </Text>

                        <Text fontSize="mdl" fontWeight="bold">
                            {password}
                        </Text>
                    </Box>
                )}
            </BoxCodigo>
        </CardsStyles>
    );
}
