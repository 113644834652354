import Mask from "react-input-mask";
import { Formik, Form, ErrorMessage } from "formik";
import { useVoucherConfig } from "../../Hook/useVoucherConfig";
import img from "../../assets/sistema/fechar-branco.svg";
import whats from "../../assets/sistema/whats.svg";
import { ResponseVoucher } from "../../pages/MultiplosVoucherGrandCru";

import { ModalCompartilha } from "./styles";
import { WhatsApp, whatsApp, validationWhatsApp } from "./config";

interface CompartilhaProps {
    rest: {
        onClose: () => void;
        voucher?: ResponseVoucher;
    };
}

export function ModalWhatsapp(props: CompartilhaProps) {
    const { pedidoShareUrl } = useVoucherConfig();
    const hasCupom =
        props.rest.voucher?.voucher && props.rest.voucher.voucher[0].cupom;

    async function compartilhaWhats(values: WhatsApp) {
        let celular = "55" + values.phone.replace(/\D/g, "");

        await pedidoShareUrl("whatsapp");

        window.location.href =
            "https://api.whatsapp.com/send?phone=" +
            celular +
            `&text=Aqui está o link do seu ${
                hasCupom ? "cupom" : "voucher digital"
            }: ` +
            window.location;
    }

    return (
        <ModalCompartilha>
            <div className="header">
                <h2>Compartilhar no Whatsapp</h2>

                <button onClick={() => props.rest.onClose()}>
                    <img src={img} alt="" />
                </button>
            </div>
            <div className="body">
                <p>
                    Preencha o campo abaixo com o celular para o qual deseja
                    enviar o link do seu {hasCupom ? "cupom" : "voucher"}. asasd
                </p>

                <Formik
                    enableReinitialize
                    validateOnMount
                    initialValues={whatsApp}
                    validationSchema={validationWhatsApp}
                    onSubmit={compartilhaWhats}
                >
                    {(props) => {
                        const { handleChange, handleBlur, values } = props;
                        const { phone } = values;
                        return (
                            <Form>
                                <div className="campo-telefone">
                                    <img src={whats} alt="" />
                                    <Mask
                                        mask="(99) 99999-9999"
                                        placeholder="TELEFONE"
                                        name="phone"
                                        type="tel"
                                        autoComplete="new-password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={phone}
                                    />
                                </div>
                                <ErrorMessage name="phone" component="span" />
                                <span>
                                    Você pode deixar esse campo em branco, se
                                    quiser escolher na sua lista de contatos do
                                    Whatsapp.
                                </span>

                                <button type="submit" disabled={!props.isValid}>
                                    COMPARTILHAR
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </ModalCompartilha>
    );
}
