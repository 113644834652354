import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    .container-title {
        button {
            display: flex;
            align-items: center;
            p {
                color: #930035;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%; /* 12px */
                letter-spacing: 0.96px;
                text-transform: uppercase;
                margin-left: 16px;
            }
        }
    }

    .container-btns {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px;
        width: 260px;
        button {
            border: 1px solid #930035;
            border-radius: 4px;
            background: transparent;
            color: #930035;
            font-size: 12px;
            padding: 8px;
            text-transform: uppercase;
            &.aprovar {
                color: #fff;
                background: #930035;
            }
        }
    }
`;
