import styled from "styled-components";

export const Acordion = styled.div`
    padding-top: 68px;
    /* .container-tables {
        height: 137px;
        overflow: hidden;
    } */
`;

export const Container = styled.div`
    display: block;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    .tr {
        &.header {
            &.hide-header {
                display: none !important;
            }
        }
    }
    .container-tables {
        height: 75px;
        overflow: hidden;
        transition: all 0.5s;
        &.active {
            transition: all 0.5s;
        }
        .icones-produto {
            display: flex;
            align-items: center;
            a {
                margin-right: 10px;
            }
        }
    }

    &.editar-acao {
    }

    .status {
        border: none;
        border: 1px solid #1cde90;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        color: #1cde90;
        background-color: transparent;
        cursor: auto;
        &.processando {
            color: #ffa500;
            border: 1px solid #ffa500;
        }

        &.agendada {
            color: #0097d5;
            border: 1px solid #0097d5;
        }

        &.disparada {
        }

        &.rascunho {
            color: #9d9d9d;
            border: 1px solid #9d9d9d;
        }
    }

    .table {
        border-spacing: 0;

        .header {
            background: transparent;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #fff;
            padding-bottom: 32px;
            width: fit-content;
            border-bottom: 2px solid #5f5f5f;
            .th {
                align-self: center;
                overflow-wrap: anywhere;
            }
        }

        .tr {
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 100%;
            color: #4a4a4a;

            width: 100% !important;
            :last-child {
                .td {
                    border-bottom: 0;
                }
            }
            &.body {
                border-radius: 4px;
                font-weight: 600;
                font-size: 14px;
                line-height: 120%;
                position: relative;
                .td {
                    align-self: center;
                    overflow-wrap: anywhere;
                    &.center {
                        text-align: center;
                    }
                    span {
                        &.enviado {
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 100%;
                            color: #1cde90;
                            border: 1px solid #1cde90;
                            box-sizing: border-box;
                            border-radius: 4px;
                            padding: 5px;
                        }
                    }
                }

                .opcoes {
                    position: absolute;
                    right: 10px;
                    top: 45%;
                    cursor: pointer;
                }

                .dropdown-opcoes {
                    background-color: #fff;
                    border-radius: 4px;
                    height: 0px;
                    position: absolute;
                    right: 10px;
                    top: 60%;
                    overflow: hidden;
                    transition: all 0.5s;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    padding: 0 20px;
                    z-index: 1;
                    &.active {
                        overflow: inherit;
                        height: 100px;
                        transition: all 0.5s;
                    }
                    span {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 100%;
                        color: #fefefe;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        img {
                            margin-right: 13px;
                            width: 20px;
                        }
                    }
                }
            }
        }

        .td {
            .visualizado,
            span {
                display: flex;
                justify-content: center;
                img {
                    margin-right: 13px;
                }
            }
        }

        .th,
        .td {
            margin: 0;
            padding: 0.5rem;

            ${
                "" /* In this example we use an absolutely position resizer,
       so this is required. */
            }
            position: relative;

            :last-child {
                border-right: 0;
            }

            .resizer {
                display: inline-block;
                width: 10px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                transform: translateX(50%);
                z-index: 1;
                ${
                    "" /* prevents from scrolling while dragging on touch devices */
                }
                touch-action:none;
            }
        }
    }
`;
