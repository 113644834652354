import imgOpenLink from "../../../assets/sistema/uniclass-link.svg";
import { Container } from "./styles";

export interface ButtonPersonalizadoProps {
    onClick: () => void;
    title: string;
    className?: string;
}

export function ButtonPersonalizado(props: ButtonPersonalizadoProps) {
    const { onClick, title, className } = props;
    const tamanhoTextoMax = title.length > 20 ? "xs" : "";

    return (
        <Container onClick={onClick} className={`${className} btn-opne-link`}>
            <div className="container-img">
                <img src={imgOpenLink} alt="" />
            </div>
            <h4 className={`${tamanhoTextoMax}`}>{title}</h4>
        </Container>
    );
}
