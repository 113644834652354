import React, { createContext, useContext } from "react";
import { Roles } from "./useUser";
import { api } from "../services";
import { Variacao } from "./useProduto";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";

interface UseComprasProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface UseComprasData {
    getCompras: () => Promise<Compras[]>;
    getFornecedor: () => Promise<Fornecedor[]>;
    getComprasByFornecedor(
        id?: number
    ): Promise<AxiosResponse<FornecedorById[]>>;
    storeCompra(values: StoreCompras): Promise<AxiosResponse>;
}

interface DataCompras {
    data: Compras[];
}

interface Compras {
    id: number;
    fornecedor_id: number;
    user_id: number;
    valor_total: string;
    approved_at: null | string;
    approve_user_id: null | string;
    status: string;
    created_at: string;
    updated_at: string;
    fornecedor: Fornecedor;
    user: User;
    itens: Itens[];
}

export interface Fornecedor {
    id: number;
    nome: string;
    descrição: null | string;
    cnpj: null | string;
    created_at: string;
    updated_at: string;
}

interface User {
    id: number;
    name: string;
    email: string;
    status: number;
    color: null | string;
    light_color: null | string;
    phone_number: string;
    voucher_limit_time: null | string;
    last_login: string;
    created_at: null | string;
    updated_at: string;
    whitelist: boolean;
    "sem-estoque": boolean;
    "pedido-outlet": boolean;
    roles: Roles[];
}

interface Itens {
    id: number;
    compra_id: number;
    produto_variacao_id: number;
    fornecedor_code: string;
    quantidade: number;
    valor: string;
    variacao: Variacao;
}

export interface FornecedorById {
    id: number;
    produto_id: number;
    nome: string;
    status: number;
    valor_yetz: string;
    valor_reais: string;
    foto_capa: string;
    alerta_estoque: number;
    status_alerta_estoque: number;
    created_at: null | string;
    updated_at: null | string;
    quantidade?: number;
}

export interface StoreCompras {
    job?: string;
    nome?: string;
    fornecedor_id: number;
    variacoes: StoreVaricao[];
}

interface StoreVaricao {
    produto_variacao_id: number;
    quantidade: number;
}

const UseComprasContext = createContext<UseComprasData>({} as UseComprasData);

export function UseRelatorioProvider(props: UseComprasProviderProps) {
    const { children } = props;

    async function getCompras() {
        try {
            const { data } = await api.get<DataCompras>("/compra");
            return data.data;
        } catch (error) {
            return error as Compras[];
        }
    }

    async function getFornecedor() {
        try {
            const { data } = await api.get<Fornecedor[]>("/fornecedor");
            return data;
        } catch (error) {
            return error as Fornecedor[];
        }
    }

    async function getComprasByFornecedor(id: number = 1) {
        try {
            const response = await api.get<FornecedorById[]>(
                `fornecedor/${id}/produtos`
            );
            return response;
        } catch (error: any) {
            return error.response as AxiosResponse;
        }
    }

    async function storeCompra(values: StoreCompras) {
        try {
            const response = await api.post<StoreCompras>("/compra", {
                ...values,
            });

            toast.success("Compra salva com sucesso!");
            return response;
        } catch (error: any) {
            toast.error("Erro ao salvar compra!");
            return error;
        }
    }

    return (
        <UseComprasContext.Provider
            value={{
                getCompras,
                getFornecedor,
                getComprasByFornecedor,
                storeCompra,
            }}
        >
            {children}
        </UseComprasContext.Provider>
    );
}

export function useCompras() {
    const context = useContext(UseComprasContext);
    return context;
}
