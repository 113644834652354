import { Form, Field, FieldProps, ErrorMessage } from "formik";
import TextField from "@mui/material/TextField";
import { Flex, Text } from "aplication-yetz";

import { Input } from "../../Input";
import { Toggle } from "../../Toggle";
import { formataMoney, formatCurrentYETZ } from "../../../util/helper";
import { FormVariacao } from "../type";
import { Select } from "../../Select";
import { Option } from "../../../types";

export interface FormCupomProps extends FieldProps<any, FormVariacao> {
    hasVariacao: boolean;
}

export function FormCupom(props: FormCupomProps) {
    const { form, hasVariacao } = props;
    const { setFieldValue, values } = form;
    const { status, tipo_desconto } = values;

    const tipoDesconto: Option[] = [
        {
            label: "REAIS",
            value: 1,
        },
        {
            label: "PERCENTUAL",
            value: 2,
        },
    ];

    return (
        <Form>
            <div className="container-form">
                <div className="container-form">
                    <Select
                        placeholder="TIPO DE DESCONTO"
                        onChange={(item) => {
                            setFieldValue(
                                "tipo_desconto",
                                item.label.toLowerCase()
                            );
                            setFieldValue("desconto", "");
                        }}
                        options={tipoDesconto}
                    />
                </div>
            </div>

            {tipo_desconto === "reais" ? (
                <div className="container-form">
                    <TextField
                        className="input-text"
                        label="VALOR EM REAIS"
                        name="valor_reais"
                        variant="standard"
                        fullWidth={true}
                        disabled={hasVariacao}
                        value={values.valor_reais}
                        onChange={(e) => {
                            setFieldValue(
                                "valor_reais",
                                formataMoney(e.target.value)
                            );

                            let valor = formataMoney(e.target.value).replace(
                                "R$ ",
                                ""
                            );

                            valor = valor.replace(/\./g, "");

                            const valor_yetz_formatado = parseFloat(
                                valor.replace(/,/g, ".")
                            );

                            const valor_yetz = valor_yetz_formatado * 20;

                            setFieldValue(
                                "valor_yetz",
                                formatCurrentYETZ(valor_yetz)
                            );
                        }}
                    />
                    <ErrorMessage component="small" name="valor_reais" />
                </div>
            ) : (
                <div className="container-form">
                    <TextField
                        className="input-text"
                        disabled={hasVariacao}
                        label="PERCENTUAL"
                        name="porcentagem"
                        value={values.porcentagem}
                        variant="standard"
                        fullWidth={true}
                        onChange={(e) => {
                            const onlyNumbers = parseInt(
                                e.target.value.replace(/\D/g, "") || "0"
                            );

                            if (onlyNumbers > 100) {
                                return;
                            }

                            setFieldValue("porcentagem", onlyNumbers);
                        }}
                    />
                    <ErrorMessage component="small" name="porcentagem" />
                </div>
            )}

            {tipo_desconto !== "percentual" && (
                <div className="container-form">
                    <TextField
                        className="input-text"
                        label="VALOR EM YETZ"
                        name="valor_yetz"
                        value={values.valor_yetz}
                        variant="standard"
                        fullWidth={true}
                        onChange={(e) => {
                            const value = parseInt(
                                values.valor_yetz.replace(/\D/g, "")
                            );

                            setFieldValue(
                                "valor_yetz",
                                formatCurrentYETZ(value)
                            );
                        }}
                    />
                    <ErrorMessage component="small" name="valor_yetz" />
                </div>
            )}

            <div className="container-form">
                <Field
                    component={Input}
                    // disabled={hasVariacao}
                    type="number"
                    // maxlength={2}
                    // max="2"
                    name="alerta_estoque"
                    label="ALERTA ESTOQUE"
                />
                <ErrorMessage component="small" name="alerta_estoque" />
            </div>

            <div
                className={`container-form status  ${
                    hasVariacao ? "bloquear" : ""
                }`}
            >
                <Flex justifyContent="space-between" width="100%">
                    <Text color="gray300">STATUS</Text>
                    <Toggle
                        titulo="Ativo"
                        active={status ? true : false}
                        onClick={() => setFieldValue("status", !status ? 1 : 0)}
                    />
                </Flex>
            </div>
        </Form>
    );
}
