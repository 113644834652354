import { Table } from "aplication-yetz";
import { column, Data } from "./types";
import { BoxTabela } from "./styles";

import img from "../../assets/sistema/logo-card-05.svg";

export function TableOutletGestao() {
    const data: Data[] = [
        {
            voucher: "Janeiro",
            qtd: "R$ 1.000,00",
            tempo: "10.000",
        },
        {
            voucher: "Janeiro",
            qtd: "R$ 1.000,00",
            tempo: "10.000",
        },
        {
            voucher: "Janeiro",
            qtd: "R$ 1.000,00",
            tempo: "10.000",
        },
    ];

    return (
        <BoxTabela>
            <div className="titulo outlet">
                <img src={img} alt="" />
                <h1>Vouchers Outlet</h1>
            </div>

            <Table data={data} columns={column} />
        </BoxTabela>
    );
}
