import { useState } from "react";
import { Text, Flex } from "aplication-yetz";
import { ChangeEvent, useEffect } from "react";
// import { Toggle } from "../../components/Toggle";
import { Container } from "./styles";

import imgPassos from "../../assets/sistema/remove-passos.svg";

interface TextAreaProps {
    title: string;
    cols?: number;
    rows?: number;
    has?: boolean;
    active?: boolean;
    onChangeActive?: () => void;
    onChange: (value: string) => void;
    onChangeButton?: () => void;
    name: string;
    value?: string;
}

export function TextArea(props: TextAreaProps) {
    const [values, setValues] = useState<string>("");
    const {
        title,
        cols = 30,
        rows = 10,
        has = false,
        // active = false,
        // onChangeActive,
        onChange,
        name,
        onChangeButton,
        value,
    } = props;

    useEffect(() => {
        if (value || value === "") {
            setValues(value);
        }
    }, [value]);

    return (
        <Container>
            <Flex alignItems="center" justifyContent="space-between" mb="10px">
                <>
                    <Text as="p">{title}</Text>
                    {!has ? (
                        <button
                            type="button"
                            onClick={() => {
                                if (onChangeButton) {
                                    onChangeButton();
                                }
                            }}
                        >
                            <img src={imgPassos} alt="" />
                        </button>
                    ) : (
                        <></>
                    )}
                </>
                {has ? <></> : <></>}
            </Flex>

            <textarea
                name={name}
                id=""
                cols={cols}
                value={values}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                    onChange(event.target.value);
                    setValues(event.target.value);
                }}
                rows={rows}
            ></textarea>
        </Container>
    );
}
