import { useState } from "react";

import {
    BarChart,
    Bar,
    ReferenceLine,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";

import { BoxTabela } from "./styles";

import img from "../../assets/sistema/logo-card-13.svg";

const data = [
    { name: "1", uv: 300 },
    { name: "2", uv: 145 },
    { name: "3", uv: 100 },
    { name: "4", uv: 8 },
    { name: "5", uv: 100 },
    { name: "6", uv: 9 },
    { name: "7", uv: 53 },
    { name: "8", uv: 252 },
    { name: "9", uv: 79 },
    { name: "10", uv: 294 },
    { name: "12", uv: 43 },
    { name: "13", uv: 74 },
    { name: "14", uv: 71 },
    { name: "15", uv: 117 },
    { name: "16", uv: 186 },
    { name: "17", uv: 16 },
    { name: "18", uv: 125 },
    { name: "19", uv: 222 },
    { name: "20", uv: 372 },
    { name: "21", uv: 182 },
    { name: "22", uv: 164 },
    { name: "23", uv: 316 },
    { name: "24", uv: 131 },
    { name: "25", uv: 291 },
    { name: "26", uv: 47 },
    { name: "27", uv: 415 },
    { name: "28", uv: 182 },
    { name: "29", uv: 93 },
    { name: "30", uv: 99 },
    { name: "31", uv: 52 },
];

const dataAnual = [
    { name: "Jan", uv: 300 },
    { name: "Fev", uv: 145 },
    { name: "Mar", uv: 100 },
    { name: "Abr", uv: 8 },
    { name: "Maio", uv: 100 },
    { name: "Jun", uv: 9 },
    { name: "Jul", uv: 53 },
    { name: "Ago", uv: 252 },
    { name: "Set", uv: 79 },
    { name: "Out", uv: 294 },
    { name: "Nov", uv: 80 },
    { name: "Dez", uv: 43 },
];

export function GraficoResgates() {
    const [tipoGrafico, setTipoGrafico] = useState("diario");

    return (
        <BoxTabela style={{ width: "100%", height: 400 }}>
            <div className="topoRelatorio">
                <div className="titulo">
                    <img src={img} alt="" />
                    <h1>Resgates</h1>
                </div>
                <div className="tipos">
                    <span
                        className={tipoGrafico === "diario" ? "active" : ""}
                        onClick={() => setTipoGrafico("diario")}
                    >
                        Diário
                    </span>
                    <span
                        className={tipoGrafico === "anual" ? "active" : ""}
                        onClick={() => setTipoGrafico("anual")}
                    >
                        Anual
                    </span>
                </div>
            </div>
            <ResponsiveContainer>
                <BarChart
                    width={500}
                    height={300}
                    data={tipoGrafico === "diario" ? data : dataAnual}
                    margin={{
                        top: 20,
                        right: 0,
                        left: 0,
                        bottom: 30,
                    }}
                >
                    <CartesianGrid strokeDasharray="2" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend
                        verticalAlign="top"
                        wrapperStyle={{ lineHeight: "40px" }}
                    />
                    <ReferenceLine y={0} stroke="#000" />
                    {/* <Brush dataKey="name" height={30} stroke="#8884d8" /> */}
                    <Bar dataKey="uv" fill="#82ca9d" />
                </BarChart>
            </ResponsiveContainer>
        </BoxTabela>
    );
}
