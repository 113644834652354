import { ButtonSystem } from "aplication-yetz";
import { useState } from 'react'
import { formatCurrentMoney } from "../../util/helper";
import { useCarrinho } from "./Hook";
import { useCompras, StoreCompras } from '../../Hook/useCompras'
import { Input } from "../Input";
import {
    Formik,
    Field,
    Form,
    FormikProps,
    FormikHelpers,
    ErrorMessage,
} from "formik";
import { initialValues, schema, Myform } from "./types";
import { CardFinalizarStyles } from "./styles";
import imgFechar from "../../assets/sistema/fechar-modal.svg";
import imgCard from "../../assets/sistema/card-ifood.png";

interface CardFinalizarProps {
    handleClose: () => void;
    fornecedor: number;
}

export function FinalizarCompra(props: CardFinalizarProps) {
    const { handleClose, fornecedor } = props;
    const { carrinho, setCarrinho } = useCarrinho();
    const { storeCompra } = useCompras()
    const [loading, setLoading] = useState(false);

    const totalGeral = carrinho.reduce((acc, carrinho) => {
        return acc + parseFloat(carrinho.valor_reais) * carrinho?.quantidade;
    }, 0);

    return (
        <CardFinalizarStyles>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={schema}
                onSubmit={async (values: Myform, actions: FormikHelpers<Myform>) => {
                    setLoading(true)
                    const obj: StoreCompras = {
                        ...values,
                        fornecedor_id: fornecedor,
                        variacoes: [
                            ...carrinho.map((car) => {
                                return {
                                    produto_variacao_id: car.id,
                                    quantidade: car.quantidade,
                                };
                            }),
                        ],
                    };

                    
                    const response = await storeCompra(obj);

                    if(response.status === 200) {
                        handleClose();
                        actions.resetForm()
                        setCarrinho([])
                    }

                    setLoading(false)
                }}
            >
                {(props: FormikProps<Myform>) => {
                    return (
                        <Form>
                            <div className="content-finalizar-compra">
                                <div className="header-finalizar">
                                    <h1>Finalizar Compra</h1>
                                    <button
                                        onClick={() => handleClose()}
                                        type="button"
                                    >
                                        <img src={imgFechar} alt="" />
                                    </button>
                                </div>

                                <div className="container-form-obs">
                                    <div className="container-form">
                                        <Field
                                            component={Input}
                                            name="nome"
                                            label="TÍTULO DA COMPRA"
                                        />
                                    </div>
                                    <ErrorMessage
                                        name="nome"
                                        component="span"
                                    />
                                    <div className="container-form">
                                        <Field
                                            component={Input}
                                            name="job"
                                            label="JOB"
                                        />
                                    </div>
                                    <ErrorMessage name="job" component="span" />
                                </div>

                                <div className="container-produtos">
                                    <div className="container-hedaer-produtos">
                                        <h3>Produtos da compra</h3>
                                    </div>

                                    {carrinho.map((carrinho) => {
                                        return (
                                            <div key={carrinho.id} className="produto">
                                                <div className="esq">
                                                    <div className="container-img">
                                                        <img
                                                            src={
                                                                carrinho.foto_capa
                                                                    ? carrinho.foto_capa
                                                                    : imgCard
                                                            }
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="container-info">
                                                        <h3>{carrinho.nome}</h3>
                                                        <span>
                                                            {formatCurrentMoney(
                                                                parseFloat(
                                                                    carrinho.valor_reais
                                                                )
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="dir">
                                                    <div className="qtd">
                                                        <h4>QTD</h4>
                                                        <span>
                                                            {
                                                                carrinho.quantidade
                                                            }
                                                        </span>
                                                    </div>

                                                    <div className="total">
                                                        <h4>Total</h4>
                                                        <span>
                                                            {formatCurrentMoney(
                                                                parseFloat(
                                                                    carrinho.valor_reais
                                                                ) *
                                                                    carrinho?.quantidade
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}

                                    <p>
                                        Simply dummy text of the printing and
                                        typesetting industry has been the
                                        industry's standard dummy text ever
                                        since
                                    </p>
                                </div>
                            </div>

                            <div className="total-compra">
                                <div className="container-total">
                                    <small>Total</small>
                                    <p>{formatCurrentMoney(totalGeral)}</p>
                                </div>

                                <ButtonSystem loading={loading}>FINALIZAR compra</ButtonSystem>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </CardFinalizarStyles>
    );
}
