import { Table, TextImg, Box, Text } from "aplication-yetz";
import { useDetalheEstoque, Extrair } from "../../Hook/useDetalheEstoque";
import {
    formatDateTimeDb,
    formatDateIso,
    filterDetalheEstoque,
} from "../../util/helper";
import { column } from "./config";
import { Data } from "./types";
import { DadosModal, ModalFiltroDados } from ".";

import { Validade } from "../../components/Validade";

import iconeEstoque from "../../assets/sistema/icon-estoque.svg";
import iconeUser from "../../assets/sistema/userAlerta.svg";
import { toast } from "react-toastify";
import { Permissions } from "../Permission";

interface TableDetalheProps {
    filtro: string;
    setModal: (active: boolean) => void;
    setModalExtrair: (active: boolean) => void;
    setDadosModal: (dados: DadosModal) => void;
    setIdsExtracao: (values: Extrair) => void;
    modalFiltroDados: ModalFiltroDados;
}

export function TableDetalheEstoque(props: TableDetalheProps) {
    const { detalheEstoque } = useDetalheEstoque();
    const { setModal, setDadosModal, filtro, modalFiltroDados } = props;
    const { variacao } = detalheEstoque;

    const arrFormat = detalheEstoque.data.map((dados) => {
        const {
            id,
            validade,
            variacao_total,
            variacao_disp,
            created_at,
            user,
            atualizar_validade,
            outlet,
            vencido,
            pre_outlet,
            valido,
        } = dados;

        const status = {
            outlet,
            vencido,
            pre_outlet,
            valido,
        };

        const { name } = user;

        return {
            id,
            validade: validade ? formatDateTimeDb(validade) : "",
            validade_db: validade ? validade.substring(0, 16) : "",
            variacao_total,
            variacao_disp,
            created_at: formatDateIso(created_at),
            outlet,
            name,
            status,
            atualizar_validade,
            fornecedor: dados?.fornecedor?.nome || "",
        };
    });

    const arrFilter = filterDetalheEstoque(arrFormat, filtro, modalFiltroDados);

    const dados: Data[] = arrFilter.map((dados) => {
        const {
            id,
            validade,
            variacao_total,
            variacao_disp,
            created_at,
            outlet,
            name,
            status,
            validade_db,
            atualizar_validade,
            fornecedor,
        } = dados;

        return {
            id,
            dataHora: created_at,
            quantidade: (
                <TextImg img={iconeEstoque}>
                    {variacao_total} / {variacao_disp}
                </TextImg>
            ),
            enviadoPor: (
                <TextImg
                    color="gray300"
                    className={`icone-us ${outlet ? "active" : ""}`}
                    img={iconeUser}
                >
                    <Text>{name}</Text>
                </TextImg>
            ),
            validade: (
                <Permissions role="carga.update.date">
                    <button
                        onClick={() => {
                            if (atualizar_validade) {
                                setModal(true);
                                setDadosModal({
                                    data: validade,
                                    variacao_id: variacao.id,
                                    carga_id: id,
                                });
                            } else {
                                toast.error(
                                    "Não é possível alterar a validade desta carga!"
                                );
                            }
                        }}
                    >
                        <Validade
                            {...status}
                            text={validade}
                            validade={validade_db}
                        />
                    </button>
                </Permissions>
            ),
            fornecedor: fornecedor,
        };
    });

    return (
        <Box pt="62px">
            <Table columns={column} data={dados} />
        </Box>
    );
}
