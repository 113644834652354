import { Box, TextImg } from "aplication-yetz";
// import { useCineMarkCombo } from "../../pages/CineMarkCombo/hook";
// import { useCineMarkCombo as useCineMarkComboSandbox } from "../../pages/CineMarkComboSandBox/hook";
import { CardsStyles } from "./styles";
import { Ingresso } from "./ingresso";

import pcImg from "../../assets/sistema/pc.svg";
import imgCombo from "../../assets/sistema/cinemarkCombo.png";

interface CardProps {
    code: string | null;
    password: string | null;
    valor: string | null;
    foto_capa: string | null;
    selo: string | null;
    codesArray?: string[][];
}

const selos = new Map([
    ["1", "Válido para utilização em loja virtual"],
    ["2", "Válido para utilização exclusiva em loja física"],
    ["3", "Válido exclusivo loja física e virtual"],
    ["4", "Válido exclusivo no app"],
    ["5", "Válido exclusivo no app e loja virtual"],
    ["6", "Exclusivo por telefone"],
]);

export function Cards(props: CardProps) {
    const { selo, codesArray } = props;
    // const { codes } = useCineMarkCombo();
    // const { codes: codesSandBox } = useCineMarkComboSandbox();
    // const arrCodes = codes ? codes : codesSandBox;
    const width = window.screen.width;

    return (
        <CardsStyles>
            <Box className="container-img">
                <img src={imgCombo} alt="" />
            </Box>

            <div className="container-voucher">
                {codesArray?.map((code, key) => {
                    return <Ingresso key={key} codes={code} count={key + 1} />;
                })}
            </div>

            {width <= 480 ? (
                <div className="container-selo">
                    <TextImg img={pcImg} className="text-img">
                        {selo && selos.get(selo.toString())}
                    </TextImg>
                </div>
            ) : (
                <></>
            )}
        </CardsStyles>
    );
}
