import { Box, Grid, BoxSimple } from "aplication-yetz";

import { Link } from "react-router-dom";

import { config } from "./config";
import { Header } from "./header";
import { SubHeader } from "./subHeader";

export function Sistema() {
    return (
        <div className="container">
            <Box>
                <Header />

                <SubHeader />

                <Grid gridTemplateColumns="repeat(6,1fr)" gridGap="30px">
                    {config.map((item) => (
                        <Link to={item.link}>
                            <BoxSimple color="gray800" {...item} />
                        </Link>
                    ))}
                </Grid>
            </Box>
        </div>
    );
}
