import { memo } from "react";
import { Flex, Text, Box } from "aplication-yetz";
import CountUp from "react-countup";
import { formatCurrentMoney, formataCounter } from "../../util/helper";
import { Plataforma } from "../../Hook/usePedido";

import { Progress } from "../ProgressBar";
import { CardStyles } from "./styles";

function Card(props: Plataforma) {
    const {
        name,
        total_pedidos,

        total_vouchers_reais,

        total_vouchers,
        total_jobs,

        total_vouchers_utilizados,
        color,
        light_color,
    } = props;

    const porcentagem = parseFloat(
        Number((total_vouchers_utilizados / total_vouchers) * 100).toFixed(2)
    );

    return (
        <CardStyles>
            <div
                className="container-icone"
                style={{
                    background: light_color ? light_color : "#fff",
                }}
            ></div>

            <Flex className="plataforma">
                <Box>
                    <Text as="p">PLATAFORMA</Text>
                    <Text as="h3" textTransform="uppercase">
                        {name}
                    </Text>
                </Box>

                <Flex>
                    {total_jobs ? (
                        <Box mr="10px">
                            <Text as="p">Jobs</Text>
                            <CountUp
                                end={total_jobs ? total_jobs : 0}
                                duration={1}
                                style={{
                                    background: light_color
                                        ? light_color
                                        : "#fff",
                                }}
                            />
                        </Box>
                    ) : (
                        <></>
                    )}

                    <Box>
                        <Text as="p">PEDIDOS</Text>
                        <CountUp
                            end={total_pedidos}
                            duration={1}
                            style={{
                                background: light_color ? light_color : "#fff",
                            }}
                        />
                    </Box>
                </Flex>
            </Flex>
            <Box className="vouchers">
                <Box mb="24px">
                    <Text as="p">VOUCHERS / UTILIZADOS</Text>
                    <Text as="h3" mb="16px">
                        {formataCounter(total_vouchers + "")} •{" "}
                        <CountUp
                            end={total_vouchers_utilizados}
                            duration={1}
                            formattingFn={(value) => formataCounter(value + "")}
                        />
                    </Text>

                    <Progress
                        width={porcentagem}
                        color={color ? color : "#33BACC"}
                    />
                </Box>

                <Flex justifyContent="space-between" alignItems="center">
                    <Text as="p">VALOR</Text>
                    <Text as="h4">
                        <CountUp
                            end={parseInt(total_vouchers_reais)}
                            duration={1.5}
                            formattingFn={(value) => formatCurrentMoney(value)}
                        />
                    </Text>
                </Flex>
            </Box>
        </CardStyles>
    );
}

export const CardMemo = memo(Card);
