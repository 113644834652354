import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    .container-loader {
        width: 150px;
        height: 150px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

export const Modal = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    width: 547px;
    height: 100vh;
    padding: 33px;
`;
