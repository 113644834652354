import { useState, useEffect } from "react";
import { useCompras } from "../../Hook/useCompras";
import { Box, Text, ButtonSystem } from "aplication-yetz";
import { Field, ErrorMessage, FieldProps } from "formik";
import { Input } from "../../components/Input";
import { Select } from "../../components/Select";
import { Option } from "../../components/Select/types";

import setUploadeImg from "../../assets/sistema/seta-upload.svg";
import closeImg from "../../assets/sistema/close.svg";

interface FormularioProps extends FieldProps {
    setPreview: (active: boolean) => void;
    setFornecedor: any;
    modalActive?: boolean;
}

export function Formulario(props: FormularioProps) {
    const { getFornecedor } = useCompras();
    const [options, setOptions] = useState<Option[]>([]);
    const { field, form, setPreview, setFornecedor, modalActive } = props;
    const { setFieldValue } = form;
    const nome = field.value?.name;

    useEffect(() => {
        async function fetchData() {
            const dados = await getFornecedor();
            setOptions(
                dados.map((item) => ({ value: item.id, label: item.nome }))
            );
        }
        if (modalActive) {
            fetchData();
        }
    }, [modalActive, getFornecedor]);

    return (
        <>
            <Box width="229px">
                <Field component={Input} name="nome" label="Carga estoque" />
                <ErrorMessage component="small" name="nome" />
            </Box>

            <Box width="229px">
                <Select
                    options={options}
                    placeholder="Fornecedor"
                    onChange={(item) => {
                        setFieldValue("fornecedor_id", item.value);
                        setFornecedor(item.label);
                    }}
                />
            </Box>

            <div className="container-btns">
                <div className="label-arquivo">
                    <label htmlFor="file" className="btn-upload">
                        {!nome ? <img src={setUploadeImg} alt="" /> : null}
                        <Text color="primary" ellipsis={true} as="p">
                            {nome ? nome : "SELECIONE O ARQUIVO"}
                        </Text>
                    </label>
                    {nome ? (
                        <button
                            type="button"
                            onClick={(e) => {
                                e.stopPropagation();
                                setFieldValue("produtos", "");
                            }}
                        >
                            <img src={closeImg} alt="" />
                        </button>
                    ) : (
                        <></>
                    )}
                </div>

                <input
                    type="file"
                    name="file"
                    id="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) => {
                        setFieldValue(
                            "produtos",
                            e.target.files?.length ? e.target.files[0] : null
                        );
                    }}
                />

                <ButtonSystem
                    background={`${nome ? "primary" : "gray200"}`}
                    type="button"
                    color="white"
                    className={`${nome ? "" : "desativado"}`}
                    onClick={() => setPreview(true)}
                >
                    ENVIAR
                </ButtonSystem>
            </div>
        </>
    );
}
