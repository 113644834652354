import { ButtonSystem } from "aplication-yetz";
import { useFornecedor } from "../../../Hook/useFornecedor";
import { Container } from "./styles";
import { useState } from "react";
export function Excluir() {
    const { setopenExcluirModal, deleteFornecedor } = useFornecedor();
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <Container>
            <h1>Excluir</h1>

            <p>
                Atenção está ação não é irreversível, <br />
                deseja realmente excluir?
            </p>

            <div className="container-btns">
                <ButtonSystem
                    background="gray800"
                    onClick={() => setopenExcluirModal(false)}
                >
                    Não
                </ButtonSystem>

                <ButtonSystem
                    loading={loading}
                    // @ts-ignore
                    disabled={loading}
                    onClick={async () => {
                        setLoading(true);
                        deleteFornecedor();
                        setLoading(false);
                    }}
                >
                    Sim
                </ButtonSystem>
            </div>
        </Container>
    );
}
