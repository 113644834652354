import React, { createContext, useContext, useState } from "react";
interface UseCineMarkCombo {
    setCodes: (values: string[][]) => void;
    codes: string[][];
}

interface UseCineMarkComboProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

const UseCineMarkComboContext = createContext<UseCineMarkCombo>(
    {} as UseCineMarkCombo
);

export function UseCineMarkComboProvider(props: UseCineMarkComboProviderProps) {
    const { children } = props;
    const [codes, setCodes] = useState<string[][]>([]);

    return (
        <UseCineMarkComboContext.Provider value={{ codes, setCodes }}>
            {children}
        </UseCineMarkComboContext.Provider>
    );
}

export function useCineMarkCombo() {
    const context = useContext(UseCineMarkComboContext);
    return context;
}
