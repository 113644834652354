import styled from "styled-components";

export const Container = styled.div`
    .content-produto {
        width: 1140px;
    }
`;

export const HeaderStyles = styled.div`
    width: 100%;
    display: grid;
    align-items: end;
    grid-template-columns: 1fr 1fr;
    grid-gap: 70px;
    margin-bottom: 50px;
    div + div {
        width: 100%;
        input {
            padding-right: 40px;
        }
        button {
            svg {
                &.filter {
                    right: 0px;
                }
            }
        }
    }
`;

export const ProdutoItem = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 90px;
    border-bottom: 1px solid #ececec;
    .container-produto {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        align-items: center;
        width: 100%;
        .container-img {
            img {
                width: 87px;
                height: 58px;
                border-radius: 4px;
            }
        }

        p {
            margin: 0 80px 0px 48px;
            &:last-child {
                margin: 0px;
            }
        }
    }

    .container-dir {
        display: flex;
        align-items: center;
        p {
            margin: 0 80px 0px 80px;
            &:last-child {
                margin: 0px;
            }
        }
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
    }

    button {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        color: #930035;

        border: 1px solid #930035;
        border-radius: 4px;

        width: 122px;
        height: 40px;
        transition: all 0.5s;
        &:hover {
            background-color: #930035;
            border: 1px solid #fff;
            color: #fff;
            transition: all 0.5s;
        }
    }
`;

export const ProdutosStyles = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    width: 470px;
    height: 100vh;
    width: 470px;
    height: 100vh;
    .header-carrinho {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 58px;
        padding-right: 71px;
        height: 113px;
        background: #f9f9f9;
        h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
        }

        span {
            background: #ffffff;
            border-radius: 8px;
            width: 42px;
            height: 39px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #232323;
        }
    }

    .content-body {
        padding-top: 50px;
        height: calc(100vh - 113px - 113px);
        overflow-y: scroll;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
            width: 5px;
        }

        &::-webkit-scrollbar:horizontal {
            height: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(89, 92, 118, 0.5);
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(255, 255, 255, 0.1);
        }
        div {
            &:last-child {
                border-bottom: 1px solid unset;
            }
            &:first-child {
                margin-top: 0px;
            }
        }
    }

    .footer-carrinho {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 113px;
        padding-left: 58px;
        padding-right: 62px;
        margin: 0 auto;
        background: #f9f9f9;
        .total {
            span {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.06em;
                text-transform: uppercase;
                color: #959595;
                display: block;
                margin-bottom: 10px;
            }

            small {
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 100%;
                color: #941a32;
                display: block;
            }
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 198px;
            height: 48px;
            background: #930035;
            border-radius: 4px;

            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            text-transform: uppercase;
            color: #ffffff;
        }
    }
`;

export const CardStyles = styled.div`
    margin: 0 auto;
    margin-top: 24px;
    border-bottom: 1px solid #ededed;
    width: 345px;
    padding-bottom: 24px;
    .card-header {
        p {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            color: #2b2b2b;
            margin-bottom: 8px;
        }

        span {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 16px;
            line-height: 100%;
            color: #941a32;
            display: block;
        }
    }

    .img-contador {
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
            &.img {
                width: 68.49px;
                height: 46px;
                border-radius: 4px;
            }
        }

        .container-contador {
            display: flex;
            align-items: center;
            position: relative;
            background: #ffffff;
            border: 2px solid #efefef;
            border-radius: 6px;
            width: 211px;
            height: 46px;
            button {
                color: #941a32;
                font-size: 40px;
                height: 100%;
                line-height: 10px;
                &.soma {
                    position: absolute;
                    top: 0px;
                    left: 20px;
                }

                &.sub {
                    position: absolute;
                    top: 0px;
                    right: 23px;
                }
            }
            input {
                border: none;
                text-align: center;
                height: 100%;
                width: 100%;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }

    .excluir {
        background: #eeeeee;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 46px;
        width: 46px;
    }
`;

export const CardFinalizarStyles = styled.div`
    width: 547px;
    padding-top: 30px;
    height: 100vh;

    overflow-y: scroll;
    form {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 5px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 92, 118, 0.5);
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
    }
    .content-finalizar-compra {
        width: 437px;
        margin: 0 auto;
        .header-finalizar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 34px;
            h1 {
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                color: #000000;
            }
        }

        .container-form-obs {
            margin-bottom: 57px;
        }

        .container-produtos {
            p {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                color: #828282;
                padding-top: 16px;
                padding-bottom: 31px;
            }
            .container-hedaer-produtos {
                margin-bottom: 24px;
                h3 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    color: #000000;
                }
            }

            .produto {
                display: flex;
                padding-bottom: 19px;
                border-bottom: 1px solid #ececec;
                margin-bottom: 19px;
                span {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 100%;
                    color: #941a32;
                }

                h4 {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    color: #000000;
                }

                .esq {
                    display: flex;
                    align-items: center;
                    .container-img {
                        img {
                            width: 68px;
                            height: 46px;
                            border-radius: 4px;
                        }
                    }

                    .container-info {
                        margin-left: 16px;
                        h3 {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 100%;
                            color: #2b2b2b;
                        }
                    }
                }

                .dir {
                    display: flex;
                    align-items: center;
                    margin-left: 44px;
                    .qtd {
                        text-align: center;
                    }
                    .total {
                        margin-left: 44px;
                    }
                }
            }
        }
    }

    .total-compra {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 107px;
        background: #f9f9f9;
        margin-top: auto;
        padding: 28px 50px;
        .container-total {
            small {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #000000;
                display: block;
            }

            p {
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                color: #941a32;
                display: block;
            }
        }
    }
`;
