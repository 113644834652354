import styled from "styled-components";
import { Flex, Box, Grid } from "aplication-yetz";

export const Container = styled.div`
    border-bottom: 1px solid #ececec;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    padding-bottom: 51px;
    .detalhe-produto {
        &::before {
            width: 30.01px;
            top: 45%;
        }
    }
`;

export const RegrasStyles = styled.div`
    display: grid;
    grid-template-columns: 443px 347px 329px 1fr;
    justify-content: space-between;
    grid-gap: 100px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 49px;
    margin-bottom: 56px;
`;

export const ContainerImg = styled(Flex)`
    border-bottom: 1px solid #ececec;
    padding-bottom: 26px;
    margin-bottom: 34px;
`;

export const ContainerModal = styled.div`
    width: 547px;
    height: 80vh;
    padding: 33px 29px 93px 59px;
    background-color: ${({ theme }) => theme.colors.white};
    overflow-y: scroll;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 5px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 92, 118, 0.5);
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
    }
    .text-img {
        margin-left: 12px;
        &:before {
            height: 18.01px;
        }
    }

    .container-form {
        &:last-child {
            margin-bottom: 58px;
        }
    }
`;

export const ContainerModalTutorial = styled(Box)`
    width: 547px;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 38px 38px 52px 38px;
    height: 100vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 5px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 92, 118, 0.5);
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
    }
    .passos {
        width: 100%;
        height: 48px;
        border: 2px dashed ${({ theme }) => theme.colors.gray300};
        box-sizing: border-box;
        border-radius: 4px;
        color: ${({ theme }) => theme.colors.primary};
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 64px;
    }

    .container-erro-passo-a-passo {
        position: relative;
        top: -20px;
    }
`;

export const BoxCards = styled(Grid)`
    .img-destaque {
        width: 272px;
        height: 182px;
        border-radius: 5px;
        object-fit: contain;
    }
`;

export const LoaderStyles = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ModalVariacao = styled.div`
    background: ${({ theme }) => theme.colors.white};
    padding: 33px 56px 33px 56px;
    width: 576px;
    height: 100vh;
    overflow-y: scroll;
`;
