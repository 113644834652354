import { ExpiradoStylesPersonalite } from "./styles";

interface ExpiradoProps {
    date: string;
    templateId?: number | null;
}

const color = [
    "#000d3c",
    "#000d3c",
    "#000d3c",
    "#000d3c",
    "#000d3c",
    "#000d3c",
    "#0131FF",
    "#000d3c",
    "#0131FF",
];

export function ExpiradoPersonalite(props: ExpiradoProps) {
    const templateId = props.templateId || 0;
    return (
        <ExpiradoStylesPersonalite bg={color[templateId]}>
            <h1>ops!</h1>

            <button>LAMENTAMOS</button>

            <p>
                Esse voucher expirou em {props.date}. Lembramos que você tem
                direito a um benefício por mês.
            </p>

            <small>
                Se tiver dúvidas acione o <br />
                FALE CONOSCO: promo@yetzpromo.com.br
            </small>
        </ExpiradoStylesPersonalite>
    );
}
