import { useState } from "react";
import { Loader } from "../Loader";
import { Table, Text, Flex } from "aplication-yetz";
import { Link } from "react-router-dom";
import { formatDateIso, filterPlataforma } from "../../util/helper";
import { usePlataforma } from "../../Hook/usePlataforma";
import { Header } from "./header";
import { SemEstoque } from "./semEstoque";
import { Status } from "./status";
import { Outlet } from "./outLet";

import { column } from "./config";
import { Data } from "./types";

import { ContainerTable } from "../../components/tables/styles";
import direitaImg from "../../assets/sistema/direita.svg";
import { Permissions } from "../Permission";

export function Plataforma() {
    const { plataforma, loadingPataforma } = usePlataforma();
    const [filter, setFilter] = useState<string>("");

    const arrFilter = filterPlataforma(filter, plataforma);

    if (loadingPataforma)
        return (
            <div className="container-loading">
                <Loader />
            </div>
        );

    const data: Data[] = arrFilter.map((item) => {
        const { id, name, email, roles, status } = item;
        const [role] = roles;

        return {
            id,
            nome: name,
            usuario: email,
            data: role?.created_at ? formatDateIso(role?.created_at) : "",
            sem_estoque: (
                <Permissions role="usuario.update">
                    <SemEstoque active={item["sem-estoque"]} id={id} />
                </Permissions>
            ),
            pedido_outlet: (
                <Permissions role="usuario.update">
                    <Outlet active={item["pedido-outlet"]} id={id} />
                </Permissions>
            ),
            status: (
                <Permissions role="usuario.update">
                    <Status active={status ? true : false} id={id} />
                </Permissions>
            ),
            catalogo: (
                <Permissions role="produto.variacao.index">
                    <Link
                        to={{
                            pathname: `/plataforma/detalhe/${id}`,
                            state: {
                                id,
                            },
                        }}
                    >
                        <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            width="100%"
                        >
                            <Text color="primary">Catálogo</Text>
                            <Flex alignItems="center" pt="2px">
                                <img src={direitaImg} alt="" />
                            </Flex>
                        </Flex>
                    </Link>
                </Permissions>
            ),
        };
    });

    return (
        <>
            <Header setFilter={setFilter} />
            <ContainerTable>
                <Table data={data} columns={column} />
            </ContainerTable>
        </>
    );
}
