import { Gestao } from "../../components/Gestao";
import { UseGestaoProvider } from "../../Hook/useGestao";

export function PageGestao() {
    return (
        <UseGestaoProvider>
            <Gestao />
        </UseGestaoProvider>
    );
}
