import { statusItens } from "../../components/ContainerStatus";
import { Column } from "react-table";
import { Data, modalData } from "./types";

export const columns: Column<Data>[] = [
    {
        Header: "id",
        accessor: "id",
    },
    {
        Header: "Nome",
        accessor: "nome",
    },
    {
        Header: "Valor",
        accessor: "valor",
    },
    {
        Header: "Valor Yetz",
        accessor: "valor_yetz",
    },
    {
        Header: "Estoque",
        accessor: "estoque",
    },
    {
        Header: "Status",
        accessor: "status",
        width: 350,
        maxWidth: 350,
    },
    // {
    //     Header: "Aviso Estoque",
    //     accessor: "aviso_estoque",
    // },
    // {
    //     Header: "Disponibilidade",
    //     accessor: "disp",
    // },
    {
        Header: "Editar",
        accessor: "editar",
        width: 50,
        maxWidth: 50,
    },
];

export const columnModal: Column<modalData>[] = [
    {
        Header: "IMG",
        accessor: "img",
        width: 120,
        maxWidth: 120,
    },
    {
        Header: "ID",
        accessor: "id",
        width: 75,
        maxWidth: 75,
    },
    {
        Header: "NOME",
        accessor: "nome",
        width: 200,
        maxWidth: 200,
    },
    {
        Header: "QUANTIDADE",
        accessor: "vencimento",
    },
    {
        Header: "V. EM REAIS",
        accessor: "valor_em_reais",
        width: 90,
        maxWidth: 90,
    },
    {
        Header: "V. EM YETZ",
        accessor: "valor_em_yetz",
    },
];

export const status: statusItens[] = [
    {
        valor: 45,
        colors: "gray700",
    },
    {
        valor: 45,
        colors: "orange400",
    },
    {
        valor: 45,
        colors: "orange200",
    },
    {
        valor: 45,
        colors: "green300",
    },
];
