import styled from "styled-components";

export const Form = styled.div`
    button {
        background-color: ${({ theme }) => theme.colors.white};
    }
`;

export const Container = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
`;
