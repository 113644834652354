import { useState } from "react";
import { Contador } from "../Couner";
import { useProduto, UpdateVariacao } from "../../Hook/useProduto";
import { toast } from "react-toastify";

interface AlertaEstoqueProps {
    alerta_estoque: string | number;
    id: number;
}

export function AlertaEstoque(props: AlertaEstoqueProps) {
    const { alerta_estoque, id } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const { updateVariacao } = useProduto();

    async function updateVariacaoContador(value: UpdateVariacao, id: number) {
        setLoading(true);
        const response = await updateVariacao(value, id);

        if (response.status !== 200) {
            toast.error("Erro ao atualizar o valor da variação");
            return;
        }

        setLoading(false);
    }

    return (
        <Contador
            loading={loading}
            initialValue={
                typeof alerta_estoque === "number"
                    ? alerta_estoque
                    : parseInt(alerta_estoque)
            }
            onChange={(value) =>
                updateVariacaoContador({ alerta_estoque: value.toString() }, id)
            }
        />
    );
}
