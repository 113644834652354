import { useState } from "react";
import { Toggle } from "../Toggle";
import { useProduto, UpdateVariacao } from "../../Hook/useProduto";
import { toast } from "react-toastify";

interface DisponibilidadeProps {
    status: number;
    id: number;
}

export function Disponibilidade(props: DisponibilidadeProps) {
    const { status, id } = props;
    const { updateVariacao } = useProduto();

    const [loading, setLoading] = useState<boolean>(false);
    async function updateVariacaoStatus(value: UpdateVariacao, id: number) {
        setLoading(true);
        const response = await updateVariacao(value, id);

        if (response.status !== 200) {
            toast.error("Erro ao atualizar a variação");
            setLoading(false);
            return;
        }

        setLoading(false);
    }

    return (
        <Toggle
            active={status ? true : false}
            loading={loading}
            onClick={() => updateVariacaoStatus({ status: status ? 0 : 1 }, id)}
        />
    );
}
