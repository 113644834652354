import styled from "styled-components";
import { Flex, Box } from "aplication-yetz";

export const Container = styled.div`
    border-bottom: 1px solid #ececec;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    padding-bottom: 51px;
    .detalhe-produto {
        &::before {
            width: 30.01px;
            top: 45%;
        }
    }
`;

export const RegrasStyles = styled.div`
    display: grid;
    grid-template-columns: 443px 347px 329px 1fr;
    justify-content: space-between;
    grid-gap: 100px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 49px;
    margin-bottom: 56px;
`;

export const ContainerImg = styled(Flex)`
    border-bottom: 1px solid #ececec;
    padding-bottom: 26px;
    margin-bottom: 34px;
    button {
        &.remover-img {
            position: absolute;
            top: 10px;
            right: 10px;
            img {
                width: 15px;
            }
        }
    }
    label[for="file"] {
        cursor: pointer;
    }
    img {
        &.file {
            width: 272px;
            height: 182px;
            border-radius: 5px;
            object-fit: cover;
        }
    }
`;

export const ContainerModal = styled.div`
    width: 547px;
    height: 100vh;
    padding: 33px 39px 93px 39px;
    background-color: ${({ theme }) => theme.colors.white};
    overflow-y: scroll;
    .galeria {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 16px;
        margin-top: 32px;
    }
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 5px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 92, 118, 0.5);
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
    }
    .text-img {
        margin-left: 12px;
        &:before {
            height: 18.01px;
        }
    }

    .container-form {
        &:last-child {
            margin-bottom: 58px;
        }
    }
`;

export const ContainerModalTutorial = styled(Box)`
    width: 547px;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 38px 38px 52px 38px;
    height: 80vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 5px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 92, 118, 0.5);
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
    }
    .passos {
        width: 100%;
        height: 48px;
        border: 2px dashed ${({ theme }) => theme.colors.gray300};
        box-sizing: border-box;
        border-radius: 4px;
        color: ${({ theme }) => theme.colors.primary};
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 64px;
    }
`;

export const TextArea = styled(Box)`
    margin-bottom: 32px;
    &:last-child {
        margin-bottom: 0px;
    }
    p {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: ${({ theme }) => theme.colors.black};
        padding-left: 18px;
        position: relative;
        &:before {
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            background-color: ${({ theme }) => theme.colors.primary};
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
            border-radius: 50%;
        }
    }

    textarea {
        border: 1px solid #cfcfcf;
        width: 100%;
        height: 100%;
        padding: 24px;
    }
`;

export const BoxGaleryStyles = styled.div`
    width: 100%;
    position: relative;
    .container-btns {
        position: absolute;
        top: 8px;
        right: 0px;
        z-index: 30;
        display: flex;
        button,
        label {
            display: flex;
            align-items: center;
            margin-right: 8px;
            background-color: ${(props) => props.theme.colors.white};
            border-radius: 5px;
        }
    }

    .container-img {
        width: 100%;
        img {
            width: 100%;
            border-radius: 8px;
        }
    }
`;
