import { TextImg, Flex, Filter } from "aplication-yetz";
import imgCarrinho from "../../assets/sistema/carrinho.svg";

interface FiltroProps {
    setValueFilter: (value: string) => void;
}

export function Filtro(props: FiltroProps) {
    const { setValueFilter } = props;

    return (
        <Flex
            pr="55px"
            justifyContent="space-between"
            className="content-filtro"
        >
            <TextImg
                color="black"
                img={imgCarrinho}
                className="text-icone-voucher"
            >
                Lista de Voucher
            </TextImg>
            <Filter onChange={(e) => setValueFilter(e.target.value)} />
        </Flex>
    );
}
