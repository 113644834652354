import { Categoria } from "./types";
import * as Yup from "yup";

export const initialValues: Categoria = {
    nome: "",
    descricao: ""
};

export const addSchema = Yup.object().shape({
    nome: Yup.string().required("Campo obrigatório"),
    descricao: Yup.string().required("Campo obrigatório")
});
