import styled from "styled-components";

interface ContainerProps {
    color?: string;
}

export const Container = styled.div<ContainerProps>`
    background: #cecece;
    height: 5px;
    width: 100%;
    position: relative;
    .progress {
        background-color: ${(props) => props.color || "#33bacc"};
        width: 0%;
        height: 100%;
        transition: width 1s;
    }
    small {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: ${(props) => props.color || "#33bacc"};
        position: absolute;
        top: -30px;
        right: 0px;
    }
    span {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: ${(props) => props.color || "#33bacc"};
        position: absolute;
        top: -6px;
        right: -40px;
    }
`;
