import { VariantColors, Flex, Box } from "aplication-yetz";
import { Status } from "../Status";

export interface statusItens {
    valor: number;
    colors: VariantColors;
}

interface containerStatusProps {
    itens: statusItens[];
}

export function ContainerStatus(props: containerStatusProps) {
    const { itens } = props;

    return (
        <Flex width="300px">
            {itens.map((item, key) => (
                <Box width="100%" pl="20px" key={key}>
                    <Status {...item} />
                </Box>
            ))}
        </Flex>
    );
}
