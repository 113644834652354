import { useVoucherConfig } from "../../Hook/useVoucherConfig";
import img from "../../assets/sistema/fechar-branco.svg";
import whats from "../../assets/sistema/whats.svg";

import { ModalCompartilha } from "./styles";

interface CompartilhaProps {
    rest: {
        onClose: () => void;
    };
}

export function ModalWhatsapp(props: CompartilhaProps) {
    const { pedidoShareUrl } = useVoucherConfig();
    async function compartilhaWhats() {
        const input = document.querySelector(
            "#input-whatsapp"
        ) as HTMLInputElement;

        let celular = "";

        if (input.value) {
            celular = "55" + input.value;
        }
        await pedidoShareUrl("whatsapp");
        window.open(
            "https://api.whatsapp.com/send?phone=" +
                celular +
                "&text=Aqui está o link do seu voucher digital: " +
                window.location,
            "_target"
        );
    }

    return (
        <ModalCompartilha>
            <div className="header">
                <h2>Compartilhar no Whatsapp</h2>
                <img src={img} alt="" onClick={() => props.rest.onClose()} />
            </div>
            <div className="body">
                <p>
                    Preencha o campo abaixo com o celular para o qual deseja
                    enviar o link do seu voucher.
                </p>
                <div className="campo-telefone">
                    <img src={whats} alt="" />
                    <input
                        type="text"
                        id="input-whatsapp"
                        placeholder="(00) 0 0000-0000"
                    />
                </div>
                <span>
                    Você pode deixar esse campo em branco, se quiser escolher na
                    sua lista de contatos do Whatsapp.
                </span>

                <button onClick={() => compartilhaWhats()}>COMPARTILHAR</button>
            </div>
        </ModalCompartilha>
    );
}
