import { Login } from "../../components/Login";
import MobileDetect from "mobile-detect";
import { Footer } from "../../components/Footer";
import { UseAuthenticationProvider } from "../../Hook/useAuthentication";
import { LoginMobile } from "../../components/Login/Mobile";

export function PageLogin() {
    const hasMobile = new MobileDetect(window.navigator.userAgent).mobile();

    return (
        <UseAuthenticationProvider>
            {hasMobile ? <LoginMobile /> : <Login />}

            <Footer />
        </UseAuthenticationProvider>
    );
}
