import React, { createContext, useContext, useState } from "react";

interface UseComprasProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface UseCarrinho {
    carrinho: FornecedorById[];
    setCarrinho(carrinho: FornecedorById[]): void;
}

export interface FornecedorById {
    id: number;
    produto_id: number;
    nome: string;
    status: number;
    valor_yetz: string;
    valor_reais: string;
    foto_capa: string;
    alerta_estoque: number;
    status_alerta_estoque: number;
    created_at: null | string;
    updated_at: null | string;
    quantidade: number;
}

const UseCarrinhoContext = createContext<UseCarrinho>({} as UseCarrinho);

export function UseCarrinhoProvider(props: UseComprasProviderProps) {
    const { children } = props;
    const [carrinho, setCarrinho] = useState<FornecedorById[]>([]);

    return (
        <UseCarrinhoContext.Provider
            value={{
                carrinho,
                setCarrinho,
            }}
        >
            {children}
        </UseCarrinhoContext.Provider>
    );
}

export function useCarrinho() {
    const context = useContext(UseCarrinhoContext);
    return context;
}
