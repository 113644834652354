import React from "react";
import { ThemeContextProvider } from "./contexts/ThemeContext";
import { UseVoucherConfigProvider } from "./Hook/useVoucherConfig";

interface ProvidersProps {
    children?: React.ReactChild | React.ReactChild[];
}

export default function Providers({ children }: ProvidersProps) {
    return (
        <ThemeContextProvider>
            <UseVoucherConfigProvider>{children}</UseVoucherConfigProvider>
        </ThemeContextProvider>
    );
}
