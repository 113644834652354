import { UseProdutoProvider } from "../../Hook/useProduto";
import { ProdutoDetalhe } from "../../components/ProdutoDetalhe";

export function DetalheProduto() {
    return (
        <UseProdutoProvider>
            <ProdutoDetalhe />
        </UseProdutoProvider>
    );
}
