import { Heading, Flex } from "aplication-yetz";
// import { ChangeEvent } from "react";

interface HeaderProps {
    setFilter: (filter: string) => void;
}

export function Header(props: HeaderProps) {
    // const { setFilter } = props;

    return (
        <Flex
            justifyContent="space-between"
            alignItems="flex-end"
            pr="55px"
            mb="78px"
        >
            <Heading as="h2" fontSize="lg">
                Vouchers expirados
            </Heading>

            {/* <Filter
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilter(e.target.value)
                }
            /> */}
        </Flex>
    );
}
