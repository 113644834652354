import { Flex, Heading } from "aplication-yetz";

import img from "../../assets/sistema/fechar-modal.svg";

import { Modal } from "./styles";

interface TutorialProps {
    rest: {
        code: string;
        password: string;
        descricao: string;
        foto_capa: string;
        nome: string;
        selo: string;
        tutorial_observacao: string;
        tutorial_url: string;
        validade: string;
        valor: string;
        tutorial_passos: string[];
        onClose: () => void;
    };
}

export function Tutorial(props: TutorialProps) {
    return (
        <Modal>
            <Flex justifyContent="space-between" mb="32px">
                <Heading fontSize="mdl" as="h2">
                    Tutorial do Produto
                </Heading>

                <button onClick={() => props.rest.onClose()}>
                    <img src={img} alt="" />
                </button>
            </Flex>
        </Modal>
    );
}
