import { useState } from "react";
import { formatCurrentMoney } from "../../util/helper";
import { FornecedorById } from "../../Hook/useCompras";
import { useCarrinho } from "./Hook";
import { ProdutosStyles, CardStyles } from "./styles";
import imgExcluir from "../../assets/sistema/excluir.svg";
import { Permissions } from "../Permission";

interface CarrinhoProps {
    setFinalizarCompra: (boolean: boolean) => void;
}

export function Carrinho(props: CarrinhoProps) {
    const { setFinalizarCompra } = props;
    const { carrinho } = useCarrinho();

    const total = carrinho.reduce((acc, carrinho) => {
        return acc + parseFloat(carrinho.valor_reais) * carrinho?.quantidade;
    }, 0);

    return (
        <ProdutosStyles>
            <div className="header-carrinho">
                <h3>Meu Carrinho</h3>
                <span>{carrinho.length}</span>
            </div>

            <div className="content-body">
                {carrinho.map((item) => (
                    <Card {...item} />
                ))}
            </div>

            <div className="footer-carrinho">
                <div className="total">
                    <span>Total</span>
                    <small>
                        {total
                            ? formatCurrentMoney(total)
                            : formatCurrentMoney(0)}
                    </small>
                </div>

                <Permissions role="voucher-yetz.store">
                    <button onClick={() => setFinalizarCompra(true)}>
                        GERAR VOUCHERS
                    </button>
                </Permissions>
            </div>
        </ProdutosStyles>
    );
}

export function Card(props: FornecedorById) {
    const [contador, setContador] = useState(1);
    const { setCarrinho, carrinho } = useCarrinho();
    const { nome, valor_reais, id, foto_capa } = props;

    function removeCarrinho(id: number) {
        const newCarrinho = carrinho.filter((item) => item.id !== id);
        setCarrinho(newCarrinho);
    }

    return (
        <CardStyles>
            <div className="card-header">
                <p>{nome}</p>
                <span>
                    {valor_reais
                        ? formatCurrentMoney(parseFloat(valor_reais))
                        : "--"}
                </span>
            </div>

            <div className="img-contador">
                <img src={foto_capa} alt="Produto" className="img" />

                <div className="container-contador">
                    <button
                        className="soma"
                        onClick={() => {
                            if (contador > 0) {
                                setContador(contador - 1);
                                setCarrinho(
                                    carrinho.map((carrinho) => {
                                        if (carrinho.id === id) {
                                            return {
                                                ...carrinho,
                                                quantidade: carrinho.quantidade
                                                    ? carrinho.quantidade - 1
                                                    : 0,
                                            };
                                        }
                                        return carrinho;
                                    })
                                );
                            }
                        }}
                    >
                        -
                    </button>

                    <input
                        type="number"
                        value={contador}
                        onChange={(e) => {
                            setContador(parseInt(e.target.value));
                            setCarrinho(
                                carrinho.map((carrinho) => {
                                    if (carrinho.id === id) {
                                        return {
                                            ...carrinho,
                                            quantidade: parseInt(
                                                e.target.value
                                            ),
                                        };
                                    }
                                    return carrinho;
                                })
                            );
                        }}
                    />

                    <button
                        className="sub"
                        onClick={() => {
                            setContador(contador + 1);
                            setCarrinho(
                                carrinho.map((carrinho) => {
                                    if (carrinho.id === id) {
                                        return {
                                            ...carrinho,
                                            quantidade: carrinho.quantidade
                                                ? carrinho.quantidade + 1
                                                : 0,
                                        };
                                    }
                                    return carrinho;
                                })
                            );
                        }}
                    >
                        +
                    </button>
                </div>

                <button className="excluir" onClick={() => removeCarrinho(id)}>
                    <img src={imgExcluir} alt="Excluir" />
                </button>
            </div>
        </CardStyles>
    );
}
