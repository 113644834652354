import { MutableRefObject, useRef } from "react";
import ReactTooltip from "react-tooltip";
import { Text, Flex } from "aplication-yetz";
import { Column, Cell } from "react-table";
import {
    useTable,
    useFlexLayout,
    useResizeColumns,
    usePagination,
} from "react-table";
import { v4 as uuidv4 } from "uuid";
import { useCompras } from "../../Hook/useCompras";

import addProdutoImg from "../../assets/sistema/add-produto.svg";
import setaBaixoImg from "../../assets/sistema/seta-baixo.svg";
import cancelar from "../../assets/sistema/fechar-modal.svg";

import { Data } from "./types";

import { Container } from "./styles";
import { Permissions } from "../Permission";

export type TableProps<T extends object> = {
    data: Array<T>;
    columns: Column<T>[];
    index: number;
    pageSize?: number;
    setModalItens: any;
};

export interface handleOpenProps {
    arrayRefs: MutableRefObject<HTMLDivElement[]>;
    refAtaul: number;
    idCompra: any;
    setModalItens: any;
}

export const Variacao = (props: handleOpenProps) => {
    const { idCompra, setModalItens } = props;

    const { aprovaCompra, reprovaCompra, getItensCompra } = useCompras();

    async function aprova(idCompra: any) {
        const response = await aprovaCompra(idCompra);

        if (response.status === 200) {
            window.location.reload();
        }
    }

    async function reprova(idCompra: any) {
        const response = await reprovaCompra(idCompra);

        if (response.status === 200) {
            window.location.reload();
        }
    }

    return (
        <Flex alignItems="center" justifyContent="space-between" pr="8px">
            <Permissions role="compra.aprovar">
                <button
                    onClick={() => aprova(idCompra)}
                    data-tip
                    data-for={"aprova" + idCompra}
                >
                    <img src={addProdutoImg} alt="" />
                </button>
                <ReactTooltip id={"aprova" + idCompra?.toString()} type="error">
                    <span>Aprovar</span>
                </ReactTooltip>
            </Permissions>

            <Permissions role="compra.reprovar">
                <button
                    className="reprovaCompra"
                    onClick={() => reprova(idCompra)}
                    data-tip
                    data-for={"reprova" + idCompra}
                >
                    <img src={cancelar} alt="" />
                </button>
                <ReactTooltip
                    id={"reprova" + idCompra?.toString()}
                    type="error"
                >
                    <span>Reprovar</span>
                </ReactTooltip>
            </Permissions>

            <Permissions role="carga.show">
                <button
                    onClick={() => {
                        getItensCompra(idCompra);
                        setModalItens(true);
                    }}
                >
                    <img src={setaBaixoImg} alt="" />
                </button>
            </Permissions>
        </Flex>
    );
};

export function Table(props: TableProps<Data>) {
    const { columns, data, index, setModalItens } = props;

    const length = data.length;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,

        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: props.pageSize ? props.pageSize : 10,
            },
        },
        useFlexLayout,
        useResizeColumns,
        usePagination
    );
    const refItens = useRef<HTMLDivElement[]>([]);

    const itens = data.map((item) => item.compra_feita_por);

    return (
        <>
            <Container>
                <div {...getTableProps()} className="table">
                    <div>
                        {headerGroups.map((headerGroup) => (
                            <div
                                {...headerGroup.getHeaderGroupProps()}
                                className={`tr header ${
                                    index !== 0 ? "hide-header" : ""
                                }`}
                            >
                                {headerGroup.headers.map((column) => {
                                    if (column.Header === "STATUS") {
                                        console.log(column.Header);
                                    }
                                    return (
                                        <div
                                            {...column.getHeaderProps()}
                                            className="th"
                                        >
                                            {column.render("Header")}
                                            <div
                                                {...column.getResizerProps()}
                                                className={`resizer ${
                                                    column.isResizing
                                                        ? "isResizing"
                                                        : ""
                                                }`}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        ))}
                    </div>

                    {itens.map((item, key) => {
                        return (
                            <div
                                {...getTableBodyProps()}
                                ref={(el: HTMLDivElement) => {
                                    refItens.current[key] = el;
                                }}
                                className="container-tables"
                            >
                                {page.map(function (row, key) {
                                    prepareRow(row);
                                    return (
                                        <div
                                            {...row.getRowProps()}
                                            className="tr body"
                                            key={uuidv4()}
                                        >
                                            {row.cells.map(
                                                (cell: Cell<Data>) => {
                                                    // console.log(cell.value);
                                                    const idCell = uuidv4();
                                                    return (
                                                        <div
                                                            {...cell.getCellProps()}
                                                            className="td"
                                                            key={idCell}
                                                        >
                                                            {cell.column
                                                                .Header ===
                                                            "Ações" ? (
                                                                <Variacao
                                                                    arrayRefs={
                                                                        refItens
                                                                    }
                                                                    refAtaul={
                                                                        key
                                                                    }
                                                                    idCompra={
                                                                        data[0]
                                                                            .n_da_compra
                                                                    }
                                                                    setModalItens={
                                                                        setModalItens
                                                                    }
                                                                />
                                                            ) : (
                                                                <Text>
                                                                    <>
                                                                        {cell.render(
                                                                            "Cell"
                                                                        )}
                                                                    </>
                                                                </Text>
                                                            )}
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}

                    {length > 10 ? (
                        <div className="pagination">
                            <div className="pre">
                                <button
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                >
                                    {"<<"}
                                </button>{" "}
                                <button
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                >
                                    {"<"}
                                </button>{" "}
                            </div>
                            <div className="next">
                                <button
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                >
                                    {">"}
                                </button>{" "}
                                <button
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                >
                                    {">>"}
                                </button>{" "}
                            </div>
                            <span>
                                Page{" "}
                                <strong>
                                    {pageIndex ? pageIndex + 1 : 0} de{" "}
                                    {pageOptions ? pageOptions.length : 0}
                                    {"  "}
                                </strong>
                                {"  "}
                            </span>
                            <span>| Vá para pagina: </span>{" "}
                            <div className="container-input">
                                <input
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={(e) => {
                                        const page = e.target.value
                                            ? Number(e.target.value) - 1
                                            : 0;
                                        gotoPage(page);
                                    }}
                                    style={{ width: "100px" }}
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
            </Container>
        </>
    );
}
