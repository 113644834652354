import { Text } from "aplication-yetz";
import { Container } from "./styles";
import { BoxDetalheProdutoProps } from "./types";

export function BoxDetalheProduto(props: BoxDetalheProdutoProps) {
    const { img, subTitulo, titulo, color } = props;

    return (
        <Container color={color ? color : ""}>
            <div className="container-img">
                <img src={img} alt="" />
            </div>

            <div className="container-text">
                <Text fontSize="md" textTransform="uppercase">
                    {titulo}
                </Text>
                <Text bold={true} data-text textTransform="uppercase">
                    {subTitulo}
                </Text>
            </div>
        </Container>
    );
}
