import { Container } from "./styles";
import { Header } from "./header";
import { TableLog } from "./table";
export function LogEstoque() {
    return (
        <Container>
            <Header />
            <TableLog />
        </Container>
    );
}
