import { Text } from "aplication-yetz";
import { Container } from "./styles";

interface CheckProps {
    text: string;
    active: boolean;
    onclick: () => void;
}

export function Check(props: CheckProps) {
    const { text, active, onclick } = props;

    return (
        <Container className={`${active ? "active" : ""}`} onClick={onclick}>
            <div className="check"></div>
            <Text fontWeight="bold" color="primary">
                {text}
            </Text>
        </Container>
    );
}
