import { Flex, Heading, Filter, TextImg, ButtonSystem } from "aplication-yetz";
import { ChangeEvent, useCallback } from "react";
import debounce from "lodash.debounce";
import { toast } from "react-toastify";

import { api } from "../../services/";

import { LinkStyles } from "./styles";

import historicoImg from "../../assets/sistema/historico.svg";
import estrelaImg from "../../assets/sistema/estrela.svg";
import { Permissions } from "../Permission";

interface HeaderProps {
    setFiltro: (value: string) => void;
    setModalCarga: (value: boolean) => void;
    setModalVerificado: (value: boolean) => void;
    handleToggleHistoricoCargas: () => void;
}

export function Header(props: HeaderProps) {
    const { setFiltro, setModalCarga, setModalVerificado } = props;

    // eslint-disable-next-line
    const debouncedChange = useCallback(
        debounce((value: string) => setFiltro(value), 800),
        []
    );

    function handleFilterChange(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();

        const newValue = event.target.value;

        debouncedChange(newValue);
    }

    async function exportar() {
        try {
            const outputFilename = `${Date.now()}.csv`;

            const response: any = await api.get(`estoque/export`, {
                responseType: "blob",
            });

            var data = new Blob([response.data]);
            var csvURL = window.URL.createObjectURL(data);
            var tempLink;
            tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", outputFilename);
            tempLink.click();

            toast.success("Download realizado com sucesso!");
        } catch (error: any) {
            console.log(error.response);

            toast.error("Erro ao exportar arquivo");
        }
    }

    return (
        <Flex justifyContent="space-between" mb="68px">
            <Heading as="h2" fontSize="lg">
                Estoque
            </Heading>
            <Filter onChange={handleFilterChange} />

            <Flex width="900px" justifyContent="space-between">
                <Permissions role="relatorio.vouchers_expirados.index">
                    <LinkStyles to="/produtos-expirados">
                        <TextImg color="primary" img={estrelaImg}>
                            Vouchers expirados
                        </TextImg>
                    </LinkStyles>
                </Permissions>

                <Permissions role="estoque.verify.pedido.item.status">
                    <button onClick={() => setModalVerificado(true)}>
                        <TextImg color="primary" img={estrelaImg}>
                            Informar verificados
                        </TextImg>
                    </button>
                </Permissions>

                <Permissions role="carga.index">
                    <LinkStyles to="/estoque/historico">
                        <TextImg color="primary" img={historicoImg}>
                            Histórico de cargas
                        </TextImg>
                    </LinkStyles>
                </Permissions>

                <Permissions role="carga.export">
                    <ButtonSystem
                        fontSize="sm"
                        textTransform="uppercase"
                        onClick={() => exportar()}
                    >
                        Exportar
                    </ButtonSystem>
                </Permissions>

                <Permissions role="estoque.import">
                    <ButtonSystem
                        fontSize="sm"
                        textTransform="uppercase"
                        onClick={() => setModalCarga(true)}
                    >
                        Adicionar estoque
                    </ButtonSystem>
                </Permissions>
            </Flex>
        </Flex>
    );
}
