import { useState } from "react";

import { useDetalhePedido } from "../../Hook/useDetalhePedidoPlataforma";

import { Header } from "./header";
import { SubHeader } from "./novoSubHeader";
import { Table } from "./novaTable";
import { Loader } from "../Loader";
import { Cards } from "./Cards";

import { ContainerNovaPlataforma } from "./styles";

export function NovoDetalhe() {
    const { pedidos, loadingDetalhePedido } = useDetalhePedido();
    const [filtro, setFiltro] = useState<string>("");

    if (
        Object.values(pedidos.table ? pedidos.table : {}).length === 0 &&
        loadingDetalhePedido === true
    ) {
        return (
            <div className="container-loading">
                <Loader />
            </div>
        );
    }

    const data = pedidos.table ? pedidos.table : {};

    return (
        <ContainerNovaPlataforma>
            {Array.isArray(data) && data.length === 0 ? (
                <>
                    <Header />
                    <SubHeader setFiltro={setFiltro} />
                </>
            ) : (
                <>
                    <Header />
                    <Cards pedidos={pedidos.head} />

                    <SubHeader setFiltro={setFiltro} />

                    <Table pedidos={data} filtro={filtro} />
                </>
            )}
        </ContainerNovaPlataforma>
    );
}
