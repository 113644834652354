import { Box, Table } from "aplication-yetz";
import { column, Data } from "./config";
import { formataMoney } from "../../util/helper";
import { ContainerTable } from "./styles";

export interface Detalhes {
    foto_capa: string;
    plataforma: string;
    produto: string;
    status: string;
    total: number;
    valor_reais: string;
}

interface TableVouchersExpiradosProps {
    data: Detalhes[];
}

export function TableVouchersExpirados(props: TableVouchersExpiradosProps) {
    const { data } = props;
    const dados: Data[] = data.map((item) => {
        const total = parseFloat(item.valor_reais) * item.total;
        return {
            plataforma: item.plataforma,
            voucher: (
                <Box className="item-voucher">
                    <img src={item.foto_capa} alt="" />
                    <span>{item.produto}</span>
                </Box>
            ),
            qtd: item.total + "",
            valor: formataMoney(item.valor_reais),
            total: formataMoney(total.toFixed(2) + ""),
            status: item.status,
        };
    });

    return (
        <ContainerTable>
            <Table columns={column} data={dados} />
        </ContainerTable>
    );
}
