import * as Yup from "yup";

// eslint-disable-next-line no-useless-escape
const regexCPF = /^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}$/;

export interface MyFormValues {
    document: string;
    password: string;
}

export const initialValues: MyFormValues = {
    document: "",
    password: "",
};

export const validation = Yup.object().shape({
    document: Yup.string()
        .required("Campo Obrigatório")
        .matches(regexCPF, "CPF inválido"),
    password: Yup.string().required("Campo Obrigatório"),
});
