import { Column } from "react-table";
import { Data } from "./types";

export const column: Column<Data>[] = [
    {
        Header: "Nome",
        accessor: "nome",
        width: 250,
        maxWidth: 250,
    },
    {
        Header: "Usuário",
        accessor: "usuario",
    },
    {
        Header: "id",
        accessor: "id",
    },
    {
        Header: "data / hora",
        accessor: "dataHora",
    },
    {
        Header: "Voucher",
        accessor: "voucher",
    },
    {
        Header: "valor",
        accessor: "valor",
    },
    {
        Header: "Ações",
        accessor: "acoes",
    },
];
