import { ModalProps } from "./types";
import { Container } from "./styles";

export function Modal(props: ModalProps) {
    const {
        component: Component,
        active,
        onClick,
        right,
        maxWidthMobile,
        hide,
        ...rest
    } = props;

    return (
        <Container
            onClick={onClick}
            active={active}
            data-closemodal="close"
            right={right}
            maxWidthMobile={maxWidthMobile}
            className={hide ? "hide" : ""}
        >
            <div className="container-body">
                <Component {...rest} />
            </div>
        </Container>
    );
}
