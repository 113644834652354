import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
// import { AxiosResponse } from "axios";
import { api } from "../services";
import { FormRelatorio } from "../components/RelatorioVoucher/config";
import { FormikHelpers } from "formik";
import { formatDateDb } from "../util/helper";

interface UseRelatorioVouchersProviderProps {
    children: React.ReactNode;
}

const useRelatorioVouchersContext = createContext<UseRelatorioVoucher>(
    {} as UseRelatorioVoucher
);

interface UseRelatorioVoucher {
    data: RelatorioVoucher[];
    loading: boolean;
    setIdRelatorio: (value: number | null) => void;
    create(
        values: FormRelatorio,
        formikHelpers: FormikHelpers<FormRelatorio>
    ): Promise<void>;

    deleteRelatorio(): Promise<any>;

    modalCreate: boolean;
    setModalCreate: (value: boolean) => void;

    modalDelete: boolean;
    setModalDelete: (value: boolean) => void;
}

export interface RelatorioVoucher {
    data: RelatorioVoucher[];
}
export interface RelatorioVoucher {
    created_at: string;
    end: string;
    file: string;
    id: number;
    start: string;
    status: string;
    title: string;
    updated_at: string;
    user_id: 1;
    usuario: string;
}

export function UseRelatorioVouchersProvider(
    props: UseRelatorioVouchersProviderProps
) {
    const { children } = props;
    const [loading, setLoading] = useState(true);
    const [modalCreate, setModalCreate] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [data, setData] = React.useState<RelatorioVoucher[]>([]);
    const [idRelatorio, setIdRelatorio] = useState<number | null>(null);

    async function create(
        values: FormRelatorio,
        formikHelpers: FormikHelpers<FormRelatorio>
    ) {
        values = {
            ...values,
            end: formatDateDb(values.end),
            start: formatDateDb(values.start),
        };

        try {
            const response = await api.post("relatorio/compras-carga", values);
            formikHelpers.resetForm();
            toast.success("Relatório de compra criado com sucesso!");
            formikHelpers.setSubmitting(false);
            setModalCreate(false);
            setLoading(true);
            return response;
        } catch (error: any) {
            toast.error("Erro ao criar relatório de compra");
            setModalCreate(false);
            return error;
        }
    }

    async function deleteRelatorio() {
        try {
            const response = await api.delete(
                `/relatorio/compras-carga/${idRelatorio}`
            );
            toast.success("Relatório de compra excluido com sucesso!");
            setModalDelete(false);
            setLoading(true);
            return response;
        } catch (error: any) {
            toast.error("Erro ao excluir relatório de compra");
            setModalDelete(false);
            return error;
        }
    }

    async function fetch() {
        try {
            const response = await api.get<RelatorioVoucher>(
                "/relatorio/compras-carga"
            );
            setData(response.data.data);
            setLoading(false);
        } catch (error) {
            toast.error("Erro ao buscar relatórios de compra");
            return error;
        }
    }

    useEffect(() => {
        if (loading) fetch();
    }, [loading]);

    return (
        <useRelatorioVouchersContext.Provider
            value={{
                loading,
                data,
                setIdRelatorio,
                create,
                modalCreate,
                setModalCreate,
                deleteRelatorio,
                modalDelete,
                setModalDelete,
            }}
        >
            {children}
        </useRelatorioVouchersContext.Provider>
    );
}

export function useRelatorioVouchers() {
    const context = useContext(useRelatorioVouchersContext);
    return context;
}
