import { NovoPedido } from "../../components/Pedido/novoPedido";
import { UsePedidoProvider } from "../../Hook/usePedido";

export function PagePedidos() {
    return (
        <UsePedidoProvider>
            <NovoPedido />
        </UsePedidoProvider>
    );
}
